/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Utils } from "src/modules/utils/shared/utils";
import { PrimaryGeneratedColumn } from "typeorm";

export class SmBaseEntity {
    @PrimaryGeneratedColumn()
    id: number = 0;

    getStringRepresentation(): string {
        return this.getEntityName() + ": " + this.id;
    }

    getEntityName(): string {
        return Utils.getTypeName(this);
    }

    toString(): string {
        return this.getStringRepresentation();
    }
}
