import { Type } from "class-transformer";
import { StringMatcher } from "./StringMatcher";

export class EnaioIndexDataSelectParams {
    use = true;
    onlyChanges = true;
    lastN = 0;
    @Type(() => Date)
    fromDate: Date;
    @Type(() => Date)
    toDate: Date;
    @Type(() => StringMatcher)
    userNames: StringMatcher;
    diff = false;
    useInternalNames = true;
}
