/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Column, Entity, Unique } from "typeorm";

@Entity()
@Unique("main", ["templateId", "fieldName"])
export class TemplateFieldPersistentData extends SmBaseEntity {

    @Column({default: ""})
    templateId: string = "";

    @Column({default: ""})
    fieldName: string = "";

    @Column({default: ""})
    toolTip: string = "";

    caption: string = "";

    constructor(templateId = "", fieldName = "", toolTip = "", caption = "") {
        super();
        this.templateId = templateId;
        this.fieldName = fieldName;
        this.toolTip = toolTip;
        this.caption = caption;
    }
}
