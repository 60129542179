/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";
import { DatabaseUser } from "src/modules/database/shared/database-user.entity";
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Utils } from "src/modules/utils/shared/utils";
import { Column, Entity, JoinTable, ManyToMany } from "typeorm";

@Entity()
export class UserGroup extends SmBaseEntity {
    @Column({default: ""})
    name: string = "";

    @ManyToMany(() => DatabaseUser, { cascade: ["remove"] })
    @Type(() => DatabaseUser)
    @JoinTable()
    members: DatabaseUser[];

    getStringRepresentation(): string {
        return Utils.isNoe(this.name) ? "Benutzergruppe" : this.name;
    }
}
