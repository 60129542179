/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";
import { CustomInitializer } from "src/modules/sm-base/shared/custom-initializer.interface";
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Utils } from "src/modules/utils/shared/utils";
import { Column, Entity, OneToMany } from "typeorm";
import { TemplateDocumentFieldState } from "./template-document-field-state.entity";

@Entity()
export class TemplateDocumentState extends SmBaseEntity implements CustomInitializer {
    @Column({default: ""})
    templateId: string = "";

    @Column({default: ""})
    templateString: string = "";

    @Column({default: false})
    finished: boolean = false;

    @Column({default: 0})
    enaioObjectId: number = 0;

    @Column({default: ""})
    selectedTemplate: string = "";

    @OneToMany(() => TemplateDocumentFieldState, o => o.document, { eager: true, cascade: true})
    @Type(() => TemplateDocumentFieldState)
    fields: TemplateDocumentFieldState[];

    customInitializer(): void {
        this.fields = Utils.arrayEnsure(this.fields);
    }
}
