import { Type } from "class-transformer";
import { EnaioGuiField } from "./EnaioGuiField";

export class EnaioWorkflowMask {
    id: string;
    name: string;
    flags = 0;
    frameWidth = 0;
    frameHeight = 0;
    @Type(() => EnaioGuiField)
    fields: EnaioGuiField[];
}
