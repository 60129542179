/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Utils } from "src/modules/utils/shared/utils";
import { Entity } from "typeorm";
import { MandatoryLinkType } from "./mandatory-link-type.enum";

@Entity()
export class MandatoryItem {

    static fromInternal(s: string): MandatoryItem {
        return new MandatoryItem(Utils.toNumber(s[0]) as MandatoryLinkType, s.slice(1));
    }

    constructor(public type: MandatoryLinkType, public value: string) {
    }

    toInternal(): string {
        return Utils.toString(this.type as number) + this.value;
    }

}
