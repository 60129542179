/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { DateInterval } from 'src/modules/utils/shared/date-interval.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne } from 'typeorm';
import { ClassRegister } from './class-register.entity';

@Entity()
export class ClassHoliday extends SmBaseEntity {

    @ManyToOne(() => ClassRegister, (o: ClassRegister) => o.sickNotes, { onDelete: "CASCADE", nullable: false, orphanedRowAction: "delete"})
    classRegister: ClassRegister;

    @Column()
    @Type(() => Date)
    from: Date;

    @Column()
    @Type(() => Date)
    to: Date;

    @Column({default: ""})
    title: string = "";

    getDateIntv(): DateInterval {
        return new DateInterval(this.from, this.to);
    }

    getStringRepresentation(): string {
        return Utils.dateFormatDefaultDate(this.from) + " - " + Utils.dateFormatDefaultDate(this.to) + " - " + this.title;
    }
}
