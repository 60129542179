<ul>
    <li *ngFor="let item of _items; index as index" [class.selected]="index == selectedIndex" (click)="select(index)">
        {{_Utils.isNoe(item[labelField]) ? placeholder : item[labelField]}}
        <i *ngIf="index == selectedIndex" class="menu-button fas fa-ellipsis-v" (click)="openContextMenu($event)">
        </i>
    </li>
    <li (click)="addItemClicked()">
        <i class="fas fa-plus">
        </i>
        <span class="add-text">{{addText}}</span>
    </li>
</ul>
<p-contextMenu #cm [model]="menuItems"></p-contextMenu>
