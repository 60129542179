import { Utils } from "src/modules/utils/shared/utils";
import { EnaioCertificatesTools } from "./enaio-certificates-tools";
import { MarkAssignment } from "./mark-assignment.entity";

export class Competency {
    id = 0;
    grades: boolean[] = [];
    schoolType = 0;
    specialRequirement = false;
    domain = "";
    level1 = "";
    level2 = "";
    title = "";
    sortDomain = 0;
    sortLevel1 = 0;
    sortLevel2 = 0;
    sortTitle = 0;
    optional = false;
    regional = false;
    gymnasium = false;
    remark = "";
    topics = "";

    getPathString(): string {
        return Utils.arrayItemsToString(Utils.arrayWithout([this.domain, this.level1, this.level2, this.title], ""), " -> ");
    }

    toMarkAssignment(): MarkAssignment {
        let result = new MarkAssignment();
        result.fillFromCompetency(this);
        return result;
    }

    isConductGrade(): boolean {
        return EnaioCertificatesTools.isConductGrade(this.domain);
    }
}
