/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Competency } from 'src/modules/enaio-certificates/shared/competency.model';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { GradeBookSchoolClass } from './grade-book-school-class.entity';

@Entity()
export class ConductGrade extends SmBaseEntity {
    @ManyToOne(() => GradeBookSchoolClass, (o: GradeBookSchoolClass) => o.exams, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    schoolClass: GradeBookSchoolClass;

    @Column({default: 0})
    pupilId: number = 0;

    @Column({default: 0})
    competencyId: number = 0;

    @Column({default: -1})
    grade: number = -1;

    isRated(): boolean {
        return this.grade >= 0;
    }

    getStringRepresentation(competencies: Competency[] = null): string {
        let comp = competencies != null ? competencies.find(c => c.id == this.competencyId) : null;
        return comp != null ? comp.title : "Kompetenz " + this.competencyId;
    }

}
