import { Type } from "class-transformer";
import { EnaioGuiField } from "./EnaioGuiField";

export class EnaioGuiPage {
    name = "";
    iconId = 0;
    pos = 0;
    pageId = "";
    @Type(() => EnaioGuiField)
    fields: EnaioGuiField[];
}
