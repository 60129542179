import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { Utils } from 'src/modules/utils/shared/utils';
import { RestEndpoint } from '../../models/rest-endpoint.model';
import { BackendResponse } from '../../shared/backend-response.model';

@Component({
    selector: 'app-activate-user',
    templateUrl: './activate-user.component.html',
    styleUrls: ['./activate-user.component.scss']
})
export class ActivateUserComponent implements OnInit {

    running = true;
    resultType = "";
    resultMessage = "";

    constructor(public app: MainAppService, private route: ActivatedRoute, private messageService: MessageService) { }

    ngOnInit(): void {
        this.app.subscribeToParamsChanges(this.route, this.init.bind(this));
    }

    async init(params: any): Promise<void> {
        try {
            await RestEndpoint.main().query({key: params.key}).post().run("api/app/auth/activate").get(BackendResponse);
            this.resultType = "success";
            this.resultMessage = "Die Aktivierung war erfolgreich";
            this.running = false;
        }
        catch (ex) {
            let error = Utils.fromPlainUnsafe(BackendResponse, Utils.fromJson(ex.error as string));
            this.resultType = "error";
            this.resultMessage = "Die Aktivierung war nicht erfolgreich. " + error.items[0].message;
            this.running = false;
        }
    }

}
