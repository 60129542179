/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Utils } from "src/modules/utils/shared/utils";
import { Column, Entity } from "typeorm";

@Entity()
export class JobDescription extends SmBaseEntity {
    @Column({default: ""})
    number: string = "";

    @Column({default: ""})
    name: string = "";

    @Column({default: ""})
    group: string = "";

    getStringRepresentation(): string {
        return Utils.isNoe(this.name) ? "Dienstart" : this.name;
    }
}
