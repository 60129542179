import { Type } from 'class-transformer';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { EnaioInboxProcessDto } from './enaio-inbox-process-dto.model';
import { FrontendWorkflowInboxDefinition } from './frontend-workflow-inbox-definition.model';

export class EnaioInboxDto {

    @Type(() => EnaioInboxProcessDto)
    processes: EnaioInboxProcessDto[];
    processToInboxMapping: OrdinaryObject<string>;
    @Type(() => FrontendWorkflowInboxDefinition)
    inboxes: FrontendWorkflowInboxDefinition[];

}
