import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { EnaioCall } from "./EnaioCall";
import { EnaioEventFound } from "./EnaioEventFound";

export class EnaioCallFindEventTexts extends EnaioCall <EnaioEventFound> {
    text: string;
    regex = false;
    caseSensitive = false;
    onlyActiveWfModels = true;
    onlyCurrentWfModels = true;
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioEventFound);
    }

}
