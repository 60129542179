/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { DomainSettings } from './domain-settings.entity';
import { ExamType } from './exam-type.entity';

@Entity()
export class ExamTypeFactor extends SmBaseEntity {

    @ManyToOne(() => DomainSettings, (o: DomainSettings) => o.examTypeFactors, { onDelete: "CASCADE", nullable: true, orphanedRowAction: 'delete'})
    settings: DomainSettings;

    @ManyToOne(() => ExamType, (o: ExamType) => o.exams, { onDelete: "CASCADE", nullable: true, orphanedRowAction: 'delete'})
    examType: ExamType;

    @Column({default: 1})
    factor: number = 1;

}
