export enum DatevRechercheType {
    enaioObject = 0,
    enaioWorkflow = 1,
    datev = 2,
    dhLog = 3,
    dhDone = 4,
    dhError = 5
}
export namespace DatevRechercheType{
    export function getName(value: DatevRechercheType): string {
        switch (value) {
            case DatevRechercheType.enaioObject:
                return "enaioObject";
            case DatevRechercheType.enaioWorkflow:
                return "enaioWorkflow";
            case DatevRechercheType.datev:
                return "datev";
            case DatevRechercheType.dhLog:
                return "dhLog";
            case DatevRechercheType.dhDone:
                return "dhDone";
            case DatevRechercheType.dhError:
                return "dhError";
        }
    }
}
