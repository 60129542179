import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'sm-check-box-with-count',
    templateUrl: './check-box-with-count.component.html',
    styleUrls: ['./check-box-with-count.component.scss']
})
export class CheckBoxWithCountComponent {

    _value = 0;
    @Input()
    max = 2;

    @Output()
    valueChange = new EventEmitter<number>();

    @Input()
    set value(value: number) {
        this._value = value;
    }

    change(value: boolean): void {
        this._value = value ? this._value > 0 ? this._value : 1 : 0;
        this.valueChange.emit(this._value);
    }

    click(): void {
        this._value++;
        if (this._value > this.max) {
            this._value = 1;
        }
        this.valueChange.emit(this._value);
    }
}
