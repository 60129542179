import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DhTools } from '../../models/dh-tools.model';

@Component({
    selector: 'app-enaio-server-info',
    templateUrl: './enaio-server-info.component.html',
    styleUrls: ['./enaio-server-info.component.scss']
  })
  export class EnaioServerInfoComponent extends ComponentView {

    serverInfo: string = null;

    async initParams(): Promise<boolean> {
        await this.getServerInfo();
        return true;
    }

    async getServerInfo(): Promise<void> {
        this.serverInfo = await DhTools.backendCall("api/enaio/serverInfo").getString();
    }

}
