import { EnaioObjectType } from "./EnaioObjectType";
import { Type } from "class-transformer";
import { EnaioCabinet } from "./EnaioCabinet";

export class EnaioObjectDef {
    @Type(() => EnaioCabinet)
    cabinets: EnaioCabinet[];


    getObjectTypeById(id: number): EnaioObjectType {
        for (let cabinet of this.cabinets) {
            for (let objectType of cabinet.objectTypes) {
                if (objectType.Id == id) {
                    return objectType;
                }
            }
        }
        return null;
    }


}
