<sm-toolbar *ngIf="isAdmin">
    <p-button styleClass="p-button-warning" icon="fas fa-cog" label="Administration"
        (onClick)="adminClick()"></p-button>
    <p-button styleClass="p-button-warning" icon="fas fa-head-side-cough" label="Krankmeldungen"
        (onClick)="sickNotesClick()"></p-button>
    <p-dropdown [styleClass]="'width200'" id="year" [options]="years" [(ngModel)]="year"
        (onChange)="selectedYearChanged()">
    </p-dropdown>
</sm-toolbar>
<p-card header="Bitte wählen Sie eine Klasse aus">
    <p-message *ngIf="noSchoolType" severity="warn"
        text="Sie sehen keine Klassen, weil ihr Benutzer keinem Schulzweig zugeordnet ist. Bitte wenden Sie sich an das Sekretariat!"></p-message>
    <ng-container *ngIf="!noSchoolType">
        <div *ngFor="let grade of getExistingGrades()" class="grid">
            <ng-container *ngFor="let schoolClass of schoolClasses">
                <div *ngIf="schoolClass.grade == grade" class="col-6 md:col-2 xl:col-1">
                    <p-card [styleClass]="'ui-card-shadow sm-card ' + schoolClass.getGuiClass()">
                        <a [style.width]="'100%'" class="ui-card-title"
                            routerLink="/enaio-certificates/class-registers/class-register/{{schoolClass.id}}/{{isHalfYear ? 1 : 0}}"
                            routerLinkActive="active">{{schoolClass.getDisplayName()}}</a>
                    </p-card>
                </div>
            </ng-container>
            <br>
        </div>
    </ng-container>
</p-card>
