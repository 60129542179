import { Type } from "class-transformer";
export class EnaioLockedResource {
    resourceId: string;
    resourceName: string;
    @Type(() => Date)
    lockTime: Date;
    sessionGuid: string;
    userName: string;
    stationId: string;
    stationName: string;
}
