/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { GradeSettings } from './grade-settings.entity';

@Entity()
export class MarkTranslation extends SmBaseEntity {

    @ManyToOne(() => GradeSettings, (o: GradeSettings) => o.markTranslation, { onDelete: "CASCADE", nullable: true, orphanedRowAction: 'delete'})
    settings: GradeSettings;

    @Column({default: 0})
    fromPercent: number = 0;

    @Column({default: ""})
    text: string = "";

    @Column({default: ""})
    textualGrade: string = "";

    @Column({default: false})
    forDictation: boolean = false;

    constructor(fromPercent = 0, text = "", forDictation: boolean = false) {
        super();
        this.fromPercent = fromPercent;
        this.text = text;
        this.forDictation = forDictation;
    }
}
