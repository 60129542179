import { Component, inject } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { ClassRegisterSettings } from '../../shared/class-register-settings.entity';
import { ClassRegister } from '../../shared/class-register.entity';
import { MissDayDto } from '../../shared/miss-day.dto';
import { Pupil } from '../../shared/pupil.model';

@Component({
  selector: 'app-class-analyses-miss-days',
  templateUrl: './class-analyses-miss-days.component.html',
  styleUrls: ['./class-analyses-miss-days.component.scss']
})
export class ClassAnalysesMissDaysComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    id: number;
    classRegister: ClassRegister;
    settings: ClassRegisterSettings;
    pupils: Pupil[];
    missDays: MissDayDto[];
    months: Date[];
    monthNames: string[];
    sum: number[][][];
    total: number[][];
    toolTips: string[][];

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.classRegister = await this.service.restGetClassRegister(this.id, null, null, true);
        this.pupils = await this.service.restGetPupils(this.classRegister.schoolClassId, false);
        this.settings = await this.service.restGetClassRegisterSettings(this.classRegister.enaioSchoolClass.getYearNumber(), this.classRegister.isHalfYear);
        this.missDays = Utils.arraySort(await RestEndpoint.main().findById(this.id).run("api/cer/classregister/missdays").list(MissDayDto));

        let missingPupils = Utils.arrayGetUnique(this.missDays.map(md => md.pupilId)).filter(pupilId => !this.pupils.some(p => p.id == pupilId));
        for (let pupilId of missingPupils) {
            let p = await this.service.restGetPupil(pupilId);
            if (p != null) {
                this.pupils.push(p);
            }
        }

        this.months = this.settings.getMonthDates();
        this.monthNames = this.months.map(m => Utils.getMonthNameShort(m.getMonth()));

        this.sum = Utils.createArray3Dim(this.pupils.length, this.months.length, 4, 0);
        this.toolTips = Utils.createArray2Dim(this.pupils.length, this.months.length, "");
        for (let missDay of this.missDays) {
            let pupilIndex = this.pupils.findIndex(p => p.id == missDay.pupilId);
            if (pupilIndex < 0) {
                continue;
            }
            let monthIndex = this.months.findIndex(m => m.getFullYear() == missDay.date.getFullYear() && m.getMonth() == missDay.date.getMonth());
            if (monthIndex < 0) {
                continue;
            }
            this.sum[pupilIndex][monthIndex][(missDay.excused ? 0 : 1) + (missDay.hour >= 0 ? 2 : 0)]++;
            this.toolTips[pupilIndex][monthIndex] += Utils.dateFormatDefaultDate(missDay.date) + (missDay.hour >= 0 ? " " + (missDay.hour + 1) + ". Stunde" : "") + (missDay.excused ? " (E)" : "") + "\n";
        }
        this.total = this.sum.map(p => [p.reduce((prev, current) => prev + current[0], 0), p.reduce((prev, current) => prev + current[1], 0), p.reduce((prev, current) => prev + current[2], 0), p.reduce((prev, current) => prev + current[3], 0)]);

        await this.service.updateNavigationClassRegister(this.id, [
            { label: "Auswertungen", routerLink: ["/enaio-certificates", "class-registers", "analyses", this.id] },
            { label: "Fehlzeiten", routerLink: ["/enaio-certificates", "class-registers", "analyses", "miss-days", this.id] }
        ]);
        return true;
    }

}
