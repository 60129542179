import { Type } from "class-transformer";
import { EnaioRunningActivityState } from "./EnaioRunningActivityState";
import { EnaioWorkItem } from "./EnaioWorkItem";

export class EnaioActivity {
    modelId: string;
    instanceId: string;
    name: string;
    @Type(() => Date)
    creationTime: Date;
    @Type(() => Date)
    endTime: Date;
    @Type(() => Date)
    accessTime: Date;
    @Type(() => Date)
    reminderTime: Date;
    @Type(() => Date)
    closureTime: Date;
    owner: string;
    ownerId: string;
    state: EnaioRunningActivityState;
    loopCount = 0;
    loopId: string;
    inUserTray = false;
    warningDeadlineExceeded = false;
    activityNumber = 0;
    @Type(() => EnaioWorkItem)
    workItems: EnaioWorkItem[];
}
