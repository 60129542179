import { Pupil } from "./pupil.model";
import { SchoolType } from "./school-type.enum";

export class GuiMarkAssignment {
    id: number;
    competencyId = 0;
    courseId = 0;
    domain = "";
    level1 = "";
    level2 = "";
    title = "";
    sortDomain = 0;
    sortLevel1 = 0;
    sortLevel2 = 0;
    sortTitle = 0;
    optional = false;
    mark = 0;
    trend = 0;
    note = "";
    hidden = true;
    assignedBy = "";
    assignedDate: Date = null;
    level1Span = 0;
    level2Span = 0;

    isValid(pupil: Pupil): boolean {
        return !this.optional || pupil.optionalCompetencies.includes(this.domain);
    }

    isVisible(pupil: Pupil): boolean {
        return (!this.hidden || pupil.getSchoolType() != SchoolType.special) && this.isValid(pupil);
    }
}
