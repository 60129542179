import { Type } from "class-transformer";
import { EnaioWorkflowMaskFieldDefinition } from "./EnaioWorkflowMaskFieldDefinition";

export class EnaioWorkflowMaskDefinition {
    id = "";
    name = "";
    flags = 0;
    width = 0;
    height = 0;
    @Type(() => EnaioWorkflowMaskFieldDefinition)
    fields: EnaioWorkflowMaskFieldDefinition[];
}
