<ng-container *transloco="let t">
    <div #main class="grid">
        <p-button class="toolbar-element" icon="fas fa-save" [label]="t('general.save')" (onClick)="save()">
        </p-button>
        <p-button class="toolbar-element" icon="fas fa-times" [label]="t('general.cancel')" (onClick)="cancel()">
        </p-button>
        <p-button class="toolbar-element" icon="fas fa-times" [label]="t('general.translation.restoreDefault')" (onClick)="restore()">
        </p-button>
        <p-button class="toolbar-element" icon="fas fa-times" [label]="t('general.export')" (onClick)="export()">
        </p-button>
        <p-button class="toolbar-element" icon="fas fa-times" [label]="t('general.import')" (onClick)="import()">
        </p-button>
    </div>
    <p-table [value]="lines" [styleClass]="'p-datatable-gridlines mt-5'">
        <ng-template pTemplate="body" let-line let-index="rowIndex">
            <tr [pSelectableRow]="line">
            <td>{{line.key}}</td>
            <td pEditableColumn>
                <p-cellEditor [style]="{'width': '100%', 'height': '100%'}">
                    <ng-template pTemplate="input">
                        <input pInputText class="w-full" [(ngModel)]="line.value">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{line.value}}
                    </ng-template>
                </p-cellEditor>
            </td>
        </tr>
        </ng-template>
    </p-table>
</ng-container>
