<div class="grid">
    <p-card *ngIf="editingAllowed === false" class="col-12" header="Bearbeitung nicht freigegeben">
        <span style="color: red">Die Bearbeitung der Zeugnisse wurde durch den Administrator noch nicht freigegeben.</span>
    </p-card>
</div>
<ng-container *ngIf="editingAllowed && inited">
    <sm-toolbar>
        <p-button *ngIf="!isPast" icon="fas fa-save" label="Speichern" (onClick)="save()">
        </p-button>
        <p-button icon="fas fa-save" label="Klassenspiegel exportieren" (onClick)="export()">
        </p-button>
        <p-message *ngIf="bufferedIsChanged" severity="warn" text="WICHTIG: Alle vorgenommen Änderungen werden erst beim Betätigen des Speichern-Buttons dauerhaft übernommen"></p-message>
    </sm-toolbar>
    <div class="card">
        <p-tabView>
            <p-tabPanel header="Klassenübersicht">
                <p-table [value]="pupils" sortField="lastName" [styleClass]="'p-datatable-gridlines'">
                    <ng-template pTemplate="header">
                    <tr>
                        <th class="align-left" style="width: 40px">#</th>
                        <th class="align-left" style="width:155px" [pSortableColumn]="'lastName'">Nachname<p-sortIcon [field]="'lastName'"></p-sortIcon></th>
                        <th class="align-left" style="width:155px" [pSortableColumn]="'firstName'">Vorname<p-sortIcon [field]="'firstName'"></p-sortIcon></th>
                        <th *ngFor="let domain of domains" class="align-left" style="height:150px; width: 50px"><span style="writing-mode: vertical-rl; height: 100%">{{domain.label}}</span></th>
                        <th class="primary-color" [attr.colspan]="gradeSettings.gradeType == _GradeType.percent ? gradeSettings.isUseTextualGrades() ? 3 : 2 : 1" style="width: 200px">&Oslash;</th>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-pupil let-index="rowIndex">
                    <tr [pSelectableRow]="pupil">
                        <td>{{index + 1}}</td>
                        <td>{{pupil.lastName}}</td>
                        <td>{{pupil.firstName}}</td>
                        <td *ngFor="let domain of domains" class="align-left">{{formatValueBoth(averageMap[pupil.id + "|" + domain.label], averageMapTextualGrade[pupil.id + "|" + domain.label], gradeSettings.gradeType == _GradeType.percent ? _NumberFormatMode.decimalZero : _NumberFormatMode.decimalOne)}}</td>
                        <td class="primary-color" style="width: 50px">{{formatValue(averageMap[pupil.id + "|"], _NumberFormatMode.decimalOne)}}</td>
                        <td *ngIf="gradeSettings.gradeType == _GradeType.percent" class="primary-color" style="width: 150px">{{formatTextualGrade(averageMap[pupil.id + "|"])}}</td>
                        <td *ngIf="gradeSettings.gradeType == _GradeType.percent && gradeSettings.isUseTextualGrades()" class="primary-color" style="width: 50px">{{formatValue(averageMapTextualGrade[pupil.id + "|"])}}</td>
                    </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <p-tabPanel header="Fachnoten">
                <sm-toolbar class="col-12">
                    <p-dropdown [styleClass]="'width300'" id="domain" [options]="domains"
                        [(ngModel)]="selectedDomain">
                    </p-dropdown>
                    <p-button icon="fas fa-eye" label="Leistungsarten anzeigen" (onClick)="showExamTypes($event)">
                    </p-button>
                    <p-button pRipple type="button" icon="fas fa-plus" class="p-button-rounded" (click)="addExam($event)"></p-button>
                    <span class="option-div">
                        <p-checkbox class="option ml-3" name="showTranslation" [(ngModel)]="showTranslation" [binary]="true"></p-checkbox>
                        <label class="form-label ml-2">Übersetzung anzeigen</label>
                    </span>
                </sm-toolbar>
                <p-table [value]="pupils" sortField="lastName" [styleClass]="'p-datatable-gridlines'" [frozenValue]="[{}]" (onEditComplete)="gradeUpdated()">
                    <ng-template pTemplate="colgroup" let-columns>
                        <colgroup>
                            <col style="width:40px">
                            <col style="width:155px">
                            <col style="width:155px">
                            <col *ngFor="let exam of examsByDomain[selectedDomain]" style="width:100px">
                            <col style="width:40px">
                            <col *ngIf="gradeSettings.gradeType == _GradeType.percent" style="width:100px">
                            <col *ngIf="gradeSettings.gradeType == _GradeType.percent && gradeSettings.isUseTextualGrades()" style="width:50px">
                            <col *ngIf="usesGradeCertificates" style="width:50px">
                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header">
                    <tr>
                        <th class="align-left">#</th>
                        <th class="align-left" [pSortableColumn]="'lastName'">Nachname<p-sortIcon [field]="'lastName'"></p-sortIcon></th>
                        <th class="align-left" [pSortableColumn]="'firstName'">Vorname<p-sortIcon [field]="'firstName'"></p-sortIcon></th>
                        <th *ngFor="let exam of examsByDomain[selectedDomain]; index as i" class="align-left mouse-pointer" class="ellipsis" (click)="editExam($event, exam)">{{exam.examType != null ? exam.examType.title : ""}}<br>{{exam.title}}<br>{{(exam.date | date:'dd.MM.yyyy')}}</th>
                        <th class="primary-color" [attr.colspan]="gradeSettings.gradeType == _GradeType.percent ? gradeSettings.isUseTextualGrades() ? 3 : 2 : 1">&Oslash;</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th *ngFor="let exam of examsByDomain[selectedDomain]">&Oslash; {{_Utils.formatNumber(exam.average, 2)}}</th>
                        <th></th>
                        <th *ngIf="gradeSettings.gradeType == _GradeType.percent"></th>
                        <th *ngIf="gradeSettings.gradeType == _GradeType.percent && gradeSettings.isUseTextualGrades()"></th>
                        <th *ngIf="usesGradeCertificates">Zeugnisnote</th>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-pupil let-index="rowIndex">
                    <tr [pSelectableRow]="pupil">
                        <td>{{index + 1}}</td>
                        <td>{{pupil.lastName}}</td>
                        <td>{{pupil.firstName}}</td>
                        <td *ngFor="let exam of examsByDomain[selectedDomain]" pEditableColumn class="mouse-pointer">
                            <p-cellEditor *ngVar="exam.getGradeForPupil(pupil.id) as grade">
                                <ng-template pTemplate="input">
                                    <sm-grade-editor [(value)]="grade.grade" [gradeType]="gradeSettings.gradeType"></sm-grade-editor>
                                    <p-button pRipple type="button" icon="fas fa-pencil-alt" class="p-button-rounded" [style]="{'margin-left': '10px'}" (click)="editGrade($event, grade)"></p-button>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{grade.grade == null || grade.grade == -1 ? '' : grade.grade + (showTranslation ? ' ' + formatTextualGrade(grade.grade, false, exam.isDictation()) : '')}}
                                    <p-badge *ngIf="grade.hasDifferingTitle()" [value]="'!'" styleClass="mr-2" [style]="{'margin-left': '10px', 'font-size': '1.25rem'}"
                                    [pTooltip]="(_Utils.isNoe(grade.title) ? '' : 'Thema: ' + grade.title) + '<br>' + (grade.date == null ? '' : 'Datum: ' + (grade.date | date:'dd.MM.yyyy'))" [escape]=false></p-badge>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="primary-color">{{formatValue(averageMap[pupil.id + "|" + selectedDomain])}}</td>
                        <td *ngIf="gradeSettings.gradeType == _GradeType.percent" class="primary-color">{{formatTextualGrade(averageMap[pupil.id + "|" + selectedDomain])}}</td>
                        <td *ngIf="gradeSettings.gradeType == _GradeType.percent && gradeSettings.isUseTextualGrades()" class="primary-color">{{formatValue(averageMapTextualGrade[pupil.id + "|" + selectedDomain])}}</td>
                        <td *ngIf="usesGradeCertificates && pupilsWithCertificates.includes(pupil.id)" class="primary-color" pEditableColumn class="mouse-pointer">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-dropdown id="certificateGrade" [editable]="false" [styleClass]="'w-full'" [options]="certificateGradeItems" [(ngModel)]="certificateGradeMap[pupil.id + '|' + selectedDomain]">
                                    </p-dropdown>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{certificateGradeMap[pupil.id + '|' + selectedDomain]}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="usesGradeCertificates && !pupilsWithCertificates.includes(pupil.id)" class="primary-color xbg-red-200" [style.color]="'black'">
                            Kein Zeugnis!
                        </td>
                    </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <p-tabPanel *ngIf="useHeadNotes" header="Kopfnoten">
                <p-table [value]="pupils" sortField="lastName" [styleClass]="'p-datatable-gridlines'" (onEditComplete)="gradeUpdated()">
                    <ng-template pTemplate="header">
                    <tr>
                        <th class="align-left" style="width: 50px">#</th>
                        <th class="align-left" [pSortableColumn]="'lastName'">Nachname<p-sortIcon [field]="'lastName'"></p-sortIcon></th>
                        <th class="align-left" [pSortableColumn]="'firstName'">Vorname<p-sortIcon [field]="'firstName'"></p-sortIcon></th>
                        <th *ngFor="let grade of conductCompetencies" class="align-left" style="height:250px; width: 50px"><span style="writing-mode: vertical-rl; height: 100%">{{grade.title}}</span></th>
                        <th class="primary-color" colspan=2 style="width: 200px">&Oslash;</th>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-pupil let-index="rowIndex">
                    <tr [pSelectableRow]="pupil">
                        <td>{{index + 1}}</td>
                        <td>{{pupil.lastName}}</td>
                        <td>{{pupil.firstName}}</td>
                        <td *ngFor="let grade of conductCompetencies" pEditableColumn class="align-left mouse-pointer">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <sm-grade-editor [(value)]="conductGradeMap[pupil.id.toString() + '|' + grade.id.toString()].grade"  [gradeType]="gradeSettings.gradeType"></sm-grade-editor>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{formatValue(conductGradeMap[pupil.id.toString() + '|' + grade.id.toString()].grade, _NumberFormatMode.decimalZero)}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="primary-color" style="width: 50px">{{formatValue(averageMap[pupil.id + "|conduct"])}}</td>
                        <td class="primary-color" style="width: 150px">{{formatTextualGrade(averageMap[pupil.id + "|conduct"])}}</td>
                    </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <p-tabPanel header="Historie">
                <p-table [value]="copiedHistory" sortField="changeDate" [sortOrder]="-1">
                    <ng-template pTemplate="header">
                    <tr>
                        <th class="align-left" [pSortableColumn]="'changeDate'" [style.width.px]="200">Datum/Uhrzeit<p-sortIcon [field]="'changeDate'"></p-sortIcon></th>
                        <th class="align-left" [pSortableColumn]="'changedBy'" [style.width.px]="150">Benutzer<p-sortIcon [field]="'changedBy'"></p-sortIcon></th>
                        <th class="align-left" [pSortableColumn]="'pupilId'">Schüler<p-sortIcon [field]="'modification'"></p-sortIcon></th>
                        <th class="align-left" [pSortableColumn]="'modification'">Änderung<p-sortIcon [field]="'modification'"></p-sortIcon></th>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-entry>
                    <tr [pSelectableRow]="entry">
                        <td>{{entry.changeDate | date: 'dd.MM.yyyy HH:mm'}}</td>
                        <td>{{entry.changedBy}}</td>
                        <td>{{entry.pupilId == 0 ? "---" : pupilNamesById[entry.pupilId] || "-Unbekannt-"}}</td>
                        <td>{{entry.modification}}</td>
                    </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
        </p-tabView>
    </div>
</ng-container>

<p-overlayPanel #opExamTypeFactors>
    <ng-template pTemplate>
        <sm-table class="col-12" [data]="examTypeFactorsTable" [allowExport]="false"></sm-table>
    </ng-template>
</p-overlayPanel>



