/* eslint-disable @typescript-eslint/no-inferrable-types */
import { ColumnTransformerFloat } from 'src/modules/database/shared/column-transformer-float';
import { Column, Entity, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Certificate } from './certificate.entity';

@Entity()
export class CertificateDomain {
    @PrimaryGeneratedColumn()
    id: number = 0;

    @Column({default: ""})
    domain: string = "";

    @Column({default: ""})
    teacher: string = "";

    @Column({ default: "", length: 10_000 })
    topics: string = "";

    @Column({type: 'decimal', default: -1, transformer: new ColumnTransformerFloat()})
    averageGrade: number = -1;

    @ManyToOne(_type => Certificate, o => o.domains, {onDelete: 'CASCADE', orphanedRowAction: "delete"})
    certificate: Certificate;

    wasUpdated(updated: CertificateDomain): boolean {
        return this.teacher !== updated.teacher || this.topics !== updated.topics;
    }
}
