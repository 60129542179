import { Component } from '@angular/core';

@Component({
  selector: 'app-test-demo-main',
  templateUrl: './test-demo-main.component.html',
  styleUrls: ['./test-demo-main.component.scss']
})
export class TestDemoMainComponent {

}
