import { Type } from "class-transformer";
import { EnaioActivity } from "./EnaioActivity";
import { EnaioProcessHistory } from "./EnaioProcessHistory";
import { EnaioProcessState } from "./EnaioProcessState";
import { EnaioRunningActivity } from "./EnaioRunningActivity";
import { EnaioWorkflowModel } from "./EnaioWorkflowModel";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class EnaioProcess {
    id: string;
    modelId: string;
    historicModelId: string;
    creatorId: string;
    @Type(() => Date)
    creationTime: Date;
    @Type(() => Date)
    endTime: Date;
    state: EnaioProcessState;
    isHistoric = false;
    @Type(() => Date)
    finishingTime: Date;
    parentRActivityId: string;
    roleId: string;
    name: string;
    organisationId: string;
    lockCount = 0;
    @Type(() => Date)
    lockTime: Date;
    serverId = 0;
    threadId = 0;
    activityCounter = 0;
    subject: string;
    numEscalations = 0;
    priority = 0;
    objectId = 0;
    @Type(() => EnaioActivity)
    activities: EnaioActivity[];
    @Type(() => EnaioProcessHistory)
    history: EnaioProcessHistory[];
    @Type(() => EnaioRunningActivity)
    runningActivities: EnaioRunningActivity[];
    @Type(() => EnaioRunningActivity)
    currentActivity: EnaioRunningActivity;
    variables: OrdinaryObject;
    documentIds: number[];
    @Type(() => Date)
    lastActivityDateDetailed: Date;
    @Type(() => Date)
    lastActivityDate: Date;
    @Type(() => EnaioWorkflowModel)
    model: EnaioWorkflowModel;
    modelName: string;
}
