/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { ClassRegister } from './class-register.entity';

@Entity()
export class ClassHistory extends SmBaseEntity {
    @ManyToOne(() => ClassRegister, (o: ClassRegister) => o.history, { onDelete: "CASCADE", nullable: false, orphanedRowAction: "delete"})
    classRegister: ClassRegister;

    @Column({default: ""})
    transactionGuid: string = "";

    @Column({default: ""})
    changedBy: string = "";

    @Column()
    @Type(() => Date)
    changeDate: Date;

    @Column({default: ""})
    modification: string = "";

    constructor(schoolClass: ClassRegister = null, transactionGuid = "", changedBy = "", changeDate: Date = null, modification = "") {
        super();
        this.classRegister = schoolClass;
        this.transactionGuid = transactionGuid;
        this.changedBy = changedBy;
        this.changeDate = changeDate;
        this.modification = modification;
    }

}
