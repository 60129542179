import { GeneralObjectKey } from "src/modules/enaio/shared/GeneralObjectKey";
import { GeneralObjectActionProvider } from "src/modules/sm-base/shared/general-object-action-provider.model";
import { ObjectAction } from "src/modules/sm-base/shared/object-action.model";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { DocumentHandlerMainComponent } from "../components/document-handler-main/document-handler-main.component";
import { DhTools } from "./dh-tools.model";
import { Utils } from "src/modules/utils/shared/utils";

export class DhObjectActions implements GeneralObjectActionProvider {

    static generators: OrdinaryObject<() => ObjectAction[]> = {
        "enaio.object": () => [
            {
                getCaption: () => "In GUI öffnen",
                isUsable: () => true,
                perform: async value => {
                    await DocumentHandlerMainComponent.instance.activateTab("enaioObjects");
                    await DocumentHandlerMainComponent.instance.viewEnaioObjects.select((value as GeneralObjectKey).key.id as number);
                    return null;
                }
            }
        ],
        "enaio.object.history": () => [
            {
                getCaption: () => "SimLog-Suche nach enaio Objekt-ID",
                isUsable: () => true,
                perform: async value => {
                    await DocumentHandlerMainComponent.instance.activateTab("simLog");
                    await DocumentHandlerMainComponent.instance.viewSimLog.load(null, "enaio Objekt-ID",
                        Utils.dateAdd((value as GeneralObjectKey).key.date as Date, "minute", -1),
                        Utils.dateAdd((value as GeneralObjectKey).key.date as Date, "minute", 1),
                        { "Objekt-ID": (value as GeneralObjectKey).key.id as number });
                    return null;
                }
            }
        ],
        "enaio.workflowModel": () => [
            {
                getCaption: () => "Workflow-Modell öffnen",
                isUsable: () => true,
                perform: async value => {
                    await DocumentHandlerMainComponent.instance.activateTab("enaioWorkflowModels");
                    await DocumentHandlerMainComponent.instance.viewEnaioWorkflowModels.select((value as GeneralObjectKey).key.id as string);
                    return null;
                }
            }
        ],
        "file.lineNumber": () => [
            {
                getCaption: () => "Datei an dieser Zeile öffnen",
                isUsable: () => true,
                perform: async value => {
                    await DhTools.backendCall("api/system/openFileInNotepadPlusPlus", { fileName: (value as GeneralObjectKey).key.fileName as string, lineNumber: (value as GeneralObjectKey).key.lineNumber as string}).getText();
                    return null;
                }
            }
        ]
    };

    getActions(value: GeneralObjectKey): ObjectAction[] {
        return DhObjectActions.generators[value.type] != null ? DhObjectActions.generators[value.type]() ?? [] : [];
    }
}
