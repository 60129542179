
export class DateInterval {

    constructor(public from: Date, public to: Date) {
    }

    contains(d: Date): boolean {
        return d.getTime() >= this.from.getTime() && d.getTime() <= this.to.getTime();
    }
}
