import { Observable, Subscription, timer } from "rxjs";

export class SmTimer {

    private t: Observable<number>;
    private subscription: Subscription;
    private running = false;

    constructor(initial: number, period: number, private onTimer: () => void | Promise<void>, private skipIfRunning: boolean = false) {
        this.t = timer(initial, period);
        this.subscription = this.t.subscribe(async _ => skipIfRunning ? this.onTimerInternal() : onTimer());
    }

    destroy(): void {
        this.subscription.unsubscribe();
    }

    async onTimerInternal(): Promise<void> {
        if (this.skipIfRunning && this.running) {
            console.log("Skipping");
            return;
        }
        this.running = true;
        try {
            await this.onTimer();
        }
        finally {
            this.running = false;
        }
    }
}
