import { Component, Input } from '@angular/core';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';

@Component({
    selector: 'app-copy-clipboard-text',
    templateUrl: './copy-clipboard-text.component.html',
    styleUrls: ['./copy-clipboard-text.component.scss']
})
export class CopyClipboardTextComponent {

    @Input()
    text = "";

    async copyToClipboard(): Promise<void> {
        await GuiUtils.copyToClipboard(this.text);
    }
}
