<p-table *ngIf="metaData != null" [value]="metaData" selectionMode="single">
    <ng-template pTemplate="header">
      <tr>
        <th class="align-left" pSortableColumn="field">Feld<p-sortIcon field="field"></p-sortIcon></th>
        <th class="align-left" pSortableColumn="value">Wert<p-sortIcon field="value"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-field>
      <tr [pSelectableRow]="field">
          <td>{{field.field.getBestDisplayName()}}</td>
          <td>{{field.getDisplayValue()}}</td>
    </tr>
    </ng-template>
  </p-table>
