import { Type } from "class-transformer";
import { EnaioObjectId } from "./EnaioObjectId";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class EnaioAddonAddReq {
    @Type(() => EnaioObjectId)
    objectType: EnaioObjectId;
    @Type(() => EnaioObjectId)
    fields: EnaioObjectId[];
    canLock = false;
    applyUserRights = false;
    allowModification = false;
    skipVerify = false;
    unused: OrdinaryObject<string>;
}
