import { EnaioTableDataType } from "./EnaioTableDataType";

export class EnaioGuiFieldTableColumn {
    position = 0;
    guid = "";
    name = "";
    internalName = "";
    dbName = "";
    type: EnaioTableDataType;
    length = 0;
    colWidth = 0;
    color = 0;
    textAlign = 0;
    valuesId = "";
    readOnly = false;
    listItems: string[];
}
