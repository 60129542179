/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { GradeBookSchoolClass } from './grade-book-school-class.entity';

@Entity()
export class GradeHistory extends SmBaseEntity {
    @ManyToOne(() => GradeBookSchoolClass, (o: GradeBookSchoolClass) => o.exams, { onDelete: "CASCADE", nullable: false, orphanedRowAction: "delete"})
    schoolClass: GradeBookSchoolClass;

    @Column({default: 0})
    pupilId: number = 0;

    @Column({default: ""})
    transactionGuid: string = "";

    @Column({default: ""})
    changedBy: string = "";

    @Column()
    @Type(() => Date)
    changeDate: Date;

    @Column({default: ""})
    modification: string = "";

    constructor(pupilId: number, modification: string) {
        super();
        this.pupilId = pupilId || 0;
        this.modification = modification || "";
    }
}
