export enum EnaioParamType {
    string = 1,
    int = 2,
    bool = 3,
    double = 4,
    dateTime = 5,
    xmlBinary = 6
}
export namespace EnaioParamType{
    export function getName(value: EnaioParamType): string {
        switch (value) {
            case EnaioParamType.string:
                return "string";
            case EnaioParamType.int:
                return "int";
            case EnaioParamType.bool:
                return "bool";
            case EnaioParamType.double:
                return "double";
            case EnaioParamType.dateTime:
                return "dateTime";
            case EnaioParamType.xmlBinary:
                return "xmlBinary";
        }
    }
}
