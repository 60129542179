<ng-container *ngIf="inited">
    <div class="center-child">
        <p-button icon="fas fa-arrow-left" (onClick)="prevWeek()" [disabled]="date == calendarWeeksGui[0].value">
        </p-button>
        <p-dropdown class="toolbar-element" [styleClass]="'width300'" id="week" [options]="calendarWeeksGui" [(ngModel)]="date" (ngModelChange)="changeWeek()">
        </p-dropdown>
        <p-button icon="fas fa-arrow-right" (onClick)="nextWeek()" [disabled]="date == calendarWeeksGui[calendarWeeksGui.length - 1].value">
        </p-button>
        <p-tag class="week-tag" [value]="isBWeek ? 'B-Woche' : 'A-Woche'"></p-tag>
    </div>
    <p-table [value]="cells" [styleClass]="'p-datatable-gridlines'">
        <ng-template pTemplate="header">
            <tr>
                <th class="align-left">Stunde</th>
                <th *ngFor="let index of rowIndexes" class="align-left" [style.width]="(100 / rowIndexes.length) + '%'">{{weekDayNames[index]}} {{_Utils.dateFormat(_Utils.dateAdd(date, 'day', index), 'dd.mm')}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-index="rowIndex">
            <tr [pSelectableRow]="row">
                <td class="align-left">{{index + 1}}</td>
                <td *ngFor="let index of rowIndexes" class="timetable-cell" [class.shade]="row[index].isHoliday" [class.cell-free]="!row[index].isHoliday && row[index].getStatus() == ''" [class.mouse-pointer]="!isFuture && !row[index].isHoliday && row[index].getStatus() != '' && row[index].getStatus() != 'slash'" (click)="clickLesson(row[index])">
                    <ng-container *ngIf="!row[index].isHoliday">
                        <div *ngVar="row[index].getStatus() as status">
                            {{row[index].getTitle()}}
                            <span *ngIf="row[index].getSubstituteDomain() != null" class="substitute-domain">({{row[index].getSubstituteDomain()}})</span>
                            <i *ngIf="status != '' && status != 'A'" [class]="'cell-status fas fa-' + status" [pTooltip]="getStatusToolTip(status)"></i>
                            <span *ngIf="status == 'A'" class="cell-status cell-status-text">A</span>
                        </div>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <p-dialog header="Auswahl der Unterrichtseinheit" [(visible)]="displayModalSelectLesson" [modal]="true" [style]="{width: '700px', height: '400px'}" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
        <div class="grid">
            <p-card *ngFor="let entry of modalSelectLessonEntries" class="col-12 timetable-cell mouse-pointer" [class.cell-free]="entry.status == ''" [class.mouse-pointer]="entry.status != ''" (click)="modalSelectSlot(entry)">
                {{entry.domain}}
                <span *ngIf="entry.getSubstituteDomain() != null" class="substitute-domain">({{entry.getSubstituteDomain()}})</span>
                <i *ngIf="entry.status != ''" [class]="'cell-status fas fa-' + entry.status"></i>
            </p-card>
        </div>
        <p-footer>
            <button type="button" pButton icon="fas fa-times" (click)="displayModalSelectLesson=false" label="Abbrechen" class="ui-button-secondary"></button>
        </p-footer>
    </p-dialog>
</ng-container>
