export class EnaioGuiFieldListItem {
    text = "";
    value: any;
    icon = 0;
    level = 0;
    columns: string[];

    constructor(text: string, value: any, icon: number) {
        this.text = text;
        this.value = value;
        this.icon = icon;
    }


}
