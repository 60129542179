/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Column, Entity, OneToMany } from "typeorm";
import { LearningContent } from "./learning-content.entity";
import { Type } from "class-transformer";

@Entity()
export class LearningContentType extends SmBaseEntity {
    @Column({default: ""})
    name: string = "";

    @OneToMany(() => LearningContent, o => o.contentType)
    @Type(() => LearningContent)
    learningContents: LearningContent[];

    getStringRepresentation(): string {
        return this.name;
    }
}
