import { Type } from "class-transformer";
import { EnaioObjectId } from "./EnaioObjectId";
import { EnaioTableRow } from "./EnaioTableRow";

export class EnaioTableAssignment {
    @Type(() => EnaioObjectId)
    field: EnaioObjectId;
    fillMissingColumnsFromExisting = false;
    useUpdateKey = false;
    @Type(() => EnaioTableRow)
    rows: EnaioTableRow[];
}
