import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tag-button',
  templateUrl: './tag-button.component.html',
  styleUrls: ['./tag-button.component.scss']
})
export class TagButtonComponent {

  @Input()
  value = "";
  @Input()
  icon = "";
  @Input()
  severity = "info";
  @Input()
  toolTip: string = null;
  @Input()
  active = true;
  @Input()
  clickable = false;
  @Output()
  onClick = new EventEmitter<any>();

  click(): void {
      if (this.clickable) {
          this.onClick.emit(null);
      }
  }
}
