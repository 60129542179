/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne } from 'typeorm';
import { Certificate } from './certificate.entity';

@Entity()
export class CertificateGrade extends SmBaseEntity {
    @ManyToOne(() => Certificate, (o: Certificate) => o.certificateGrades, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    certificate: Certificate;

    @Column({default: 0})
    pupilId: number = 0;

    @Column({default: ""})
    domain: string = "";

    @Column({default: ""})
    grade: string = "";

    @Column({nullable: true})
    @Type(() => Date)
    modifiedDate: Date;

    @Column({default: ""})
    modifiedBy: string = "";

    isRated(): boolean {
        return !Utils.isNoe(this.grade);
    }

    constructor(pupilId: number = 0, domain: string = "", grade: string = "") {
        super();
        this.pupilId = pupilId;
        this.domain = domain;
        this.grade = grade;
    }

    getStringRepresentation(): string {
        return this.domain;
    }

    getModifiedText(): string {
        return this.modifiedDate == null ? "" : this.modifiedBy + " - " + Utils.dateFormatDefault(this.modifiedDate);
    }

}
