import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ClassConstructor } from "class-transformer";
import { firstValueFrom } from "rxjs";
import { ClassType } from "src/modules/utils/shared/class-type";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { Utils } from "src/modules/utils/shared/utils";
import { LocatorService } from "../services/locator.service";

export class HttpResult {
    constructor(private text: Promise<string>, private from: HttpConnector) {
    }

    async get<R extends object>(type: ClassType<R>, allowNull?: boolean): Promise<R> {
        return Utils.customInitialize(await (this.from.constantResult != null ? Promise.resolve(this.from.constantResult) : Utils.fromPlainUnsafe(type, await this.getPlainOne(allowNull))));
    }

    async getOrdinaryObject<R extends object>(type: ClassType<R>, allowNull?: boolean): Promise<OrdinaryObject<R>> {
        let o = await (this.from.constantResult != null ? Promise.resolve(this.from.constantResult) : await this.getPlainOne(allowNull));
        let result: OrdinaryObject<R> = {};
        for (let key of Utils.getOwnPropertyNames(o)) {
            result[key] = Utils.fromPlainUnsafe(type, o[key]);
        }
        return result;
    }

    async getNumber(): Promise<number> {
        return (await this.get(Number)) as number;
    }

    async getBool(): Promise<boolean> {
        return (await this.get(Boolean)) as boolean;
    }

    async getString(): Promise<string> {
        return this.text;
    }

    async list<R extends object>(type: ClassType<R>): Promise<R[]> {
        return (await (this.from.constantResult != null ? Promise.resolve(this.from.constantResult) : Utils.fromPlainArray(type, await this.getPlain()))).map(o => Utils.customInitialize(o));
    }

    async listNumbers(): Promise<number[]> {
        return (await this.list(Number)) as number[];
    }

    async listStrings(): Promise<string[]> {
        return (await this.list(String)) as string[];
    }

    async listObjects(): Promise<OrdinaryObject[]> {
        return (await this.list(Object)) as OrdinaryObject[];
    }

    async getText(): Promise<string> {
        try {
            return await this.text;
        }
        catch (error) {
            if (error.status == 0) {
                error.message = "Keine Verbindung zum Backend " + error.message;
            }
            throw error;
        }
    }

    private async getPlain(): Promise<any[]> {
        let t = await this.text;
        try {
            return Utils.fromJson(t);
        }
        catch (error) {
            throw new Error("Es trat ein Fehler auf beim Interpretieren der Rückgabe der URL '" + this.from.generateUrl() + ".\nRückgabetext: " + Utils.crop(t, 300) + ".\nFehler: " + error);
        }
    }

    private async getPlainOne(allowNull?: boolean): Promise<any> {
        let t = await this.text;
        try {
            if (t == "" && allowNull) {
                return null;
            }
            let result = Utils.fromJson(t);
            return Utils.isArray(result) ? result[0] : result;
        }
        catch (error) {
            throw new Error("Es trat ein Fehler auf beim Interpretieren der Rückgabe der URL '" + this.from.generateUrl() + ".\nRückgabetext: " + Utils.crop(t, 300) + ".\nFehler: " + error);
        }
    }
}

export class HttpConnector {

    http: HttpClient;
    baseUrl: string;

    contentType: "" | "application/json" | "application/x-www-form-urlencoded" = "application/json";
    _method = "get";
    _body: any;
    _action: string;
    _query: string[] = [];

    constantResult: any;

    constructor(baseUrl: string = null) {
        this.http = LocatorService.injector.get(HttpClient);
        this.baseUrl = baseUrl;
    }

    method(method: string): this {
        this._method = method;
        return this;
    }

    post(): this {
        return this.method("post");
    }

    put(): this {
        return this.method("put");
    }

    delete(): this {
        return this.method("delete");
    }

    body(value: any): this {
        this._body = value;
        return this;
    }

    contentTypeForm(): this {
        this.contentType = "application/x-www-form-urlencoded";
        return this;
    }

    query(params: any): this {
        if (params) {
            for (let key of Utils.getOwnPropertyNames(params)) {
                this._query.push(key + "=" + params[key]);
            }
        }
        return this;
    }

    action(value: string): this {
        this._action = value;
        return this;
    }

    findByIdOrEmpty(type: ClassConstructor<any>, id: number): this {
        if (id == 0) {
            this.constantResult = (type as any).forFrontend != null ? (type as any).forFrontend() : new type();
            return this;
        }
        return this.findById(id);
    }

    findById(id: number): this {
        return this.find({id});
    }

    find(queryItems: any = null): this {
        return this.query(queryItems);
    }

    upsert(entity: any): this {
        if (entity.id == 0) {
            this.post();
        }
        else {
            this.put();
        }
        return this.body(entity);
    }

    run(action = ""): HttpResult {
        this.action(action);
        if (this.constantResult != null) {
            return new HttpResult(null, this);
        }

        let url = this.generateUrl();
        switch (this._method) {
            case "get":
                return new HttpResult(firstValueFrom(this.http.get(url, { responseType: "text"})), this);
            case "post":
                return new HttpResult(firstValueFrom(this.http.post(url, this.getBodyText(), { headers: new HttpHeaders({ 'Content-Type': this.contentType }), responseType: "text"})), this);
            case "put":
                return new HttpResult(firstValueFrom(this.http.put(url, this.getBodyText(), { headers: new HttpHeaders({ 'Content-Type': this.contentType }), responseType: "text"})), this);
            case "delete":
                return new HttpResult(firstValueFrom(this.http.delete(url, { responseType: "text"})), this);
        }
        return null;
    }

    async runArrayBuffer(action = ""): Promise<ArrayBuffer> {
        this.action(action);
        let url = this.generateUrl();
        switch (this._method) {
            case "get":
                return firstValueFrom(this.http.get(url, { responseType: "arraybuffer"}));
            case "post":
                return firstValueFrom(this.http.post(url, this.getBodyText(), { headers: new HttpHeaders({ 'Content-Type': this.contentType }), responseType: "arraybuffer"}));
            case "put":
                return firstValueFrom(this.http.put(url, this.getBodyText(), { headers: new HttpHeaders({ 'Content-Type': this.contentType }), responseType: "arraybuffer"}));
            case "delete":
                return firstValueFrom(this.http.delete(url, { responseType: "arraybuffer"}));
        }
        return null;
    }

    async runBlob(action = ""): Promise<Blob> {
        this.action(action);
        let url = this.generateUrl();
        switch (this._method) {
            case "get":
                return firstValueFrom(this.http.get(url, { responseType: "blob"}));
            case "post":
                return firstValueFrom(this.http.post(url, this.getBodyText(), { headers: new HttpHeaders({ 'Content-Type': this.contentType }), responseType: "blob"}));
            case "put":
                return firstValueFrom(this.http.put(url, this.getBodyText(), { headers: new HttpHeaders({ 'Content-Type': this.contentType }), responseType: "blob"}));
            case "delete":
                return firstValueFrom(this.http.delete(url, { responseType: "blob"}));
        }
        return null;
    }

    public generateUrl(): string {
        return this.getModelUrl() + (Utils.isNoe(this._action) ? "" : "/" + this._action) + Utils.httpQueryItemsToString(this._query);
    }

    private getModelUrl(appendSlash = false): string {
        return this.baseUrl + (appendSlash ? "/" : "");
    }

    private getBodyText(): any {
        if (this._body instanceof FormData) {
            return this._body;
        }
        switch (this.contentType) {
            case "application/json":
                return Utils.toJson(this._body);
            case "application/x-www-form-urlencoded":
                return Utils.serializeForm(this._body, null);
            case "":
                return "";
        }
    }
}
