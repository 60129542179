import { Type } from "class-transformer";
import { EnaioCallDescription } from "./EnaioCallDescription";
import { EnaioErrorAnalysis } from "./EnaioErrorAnalysis";
import { JobResultPartialError } from "./JobResultPartialError";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class JobResult {
    items: OrdinaryObject;
    errorMessage: string;
    responseResult = 0;
    @Type(() => JobResultPartialError)
    partialErrors: JobResultPartialError[];
    metaData: OrdinaryObject;
    @Type(() => EnaioCallDescription)
    call: EnaioCallDescription;
    @Type(() => EnaioErrorAnalysis)
    errorAnalysis: EnaioErrorAnalysis;
}
