/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { CustomInitializer } from 'src/modules/sm-base/shared/custom-initializer.interface';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne, OneToMany } from 'typeorm';
import { DatabaseUser } from './database-user.entity';
import { NotificationLink } from './notification-link.entity';

@Entity()
export class Notification extends SmBaseEntity implements CustomInitializer {
    @ManyToOne(() => DatabaseUser, null, { onDelete: "CASCADE", eager: true, nullable: false})
    user: DatabaseUser;

    @Column({default: ""})
    senderId: string = "";

    @Column()
    @Type(() => Date)
    date: Date = null;

    @Column({default: ""})
    subject: string = "";

    @Column({default: ""})
    message: string = "";

    @Column({default: ""})
    url: string = "";

    @Column({default: ""})
    internalId: string = "";

    @Column({nullable: true})
    @Type(() => Date)
    readDate: Date = null;

    @OneToMany(() => NotificationLink, o => o.notification, { cascade: true, eager: true })
    @Type(() => NotificationLink)
    links: NotificationLink[];

    customInitializer(): void {
        this.links = Utils.arrayEnsure(this.links);
    }

    getStringRepresentation(): string {
        return this.subject;
    }

}
