import { Type } from "class-transformer";
import { EnaioClientEvent } from "./EnaioClientEvent";
import { EnaioEventFoundText } from "./EnaioEventFoundText";
import { EnaioWorkflowEventDefinition } from "./EnaioWorkflowEventDefinition";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class EnaioEventFound {
    clientEventDefinitions: OrdinaryObject<EnaioClientEvent>;
    wfEventDefinitions: OrdinaryObject<EnaioWorkflowEventDefinition>;
    @Type(() => EnaioEventFoundText)
    matches: EnaioEventFoundText[];
}
