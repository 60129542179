<sm-toolbar>
    <p-button [disabled]="!isChanged()" icon="fas fa-save" label="Speichern" (onClick)="save()">
    </p-button>
</sm-toolbar>
<p-table [value]="rows" [styleClass]="'p-datatable-gridlines'" selectionMode="single">
    <ng-template pTemplate="header">
      <tr *ngIf="!isSecondary">
        <th class="align-left" width="33%">Unterrichtsfach</th>
        <th class="align-left" width="33%">Erste Lehrkraft</th>
        <th class="align-left" width="33%">Zweite Lehrkraft</th>
      </tr>
      <tr *ngIf="isSecondary">
        <th class="align-left" width="20%">Unterrichtsfach</th>
        <th class="align-left" width="20%">Erste Lehrkraft gymnasial</th>
        <th class="align-left" width="20%">Zweite Lehrkraft gymnasial</th>
        <th class="align-left" width="20%">Erste Lehrkraft regional</th>
        <th class="align-left" width="20%">Zweite Lehrkraft regional</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-index="rowIndex">
      <tr [pSelectableRow]="row">
        <td>
          {{row.domain}}
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
             <p-dropdown id="teacher" [filter]="true" [styleClass]="'w-full'" [options]="teachersGui" [ngModel]="row.teacher" (ngModelChange)="changeTeacher($event, row)">
              </p-dropdown>
           </ng-template>
           <ng-template pTemplate="output">
               {{getTeacherByUsername(row.teacher)}}
           </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
             <p-dropdown id="teacherSecond" [filter]="true" [styleClass]="'w-full'" [options]="teachersGui" [ngModel]="row.teacherSecond" (ngModelChange)="changeTeacherSecond($event, row)">
              </p-dropdown>
           </ng-template>
           <ng-template pTemplate="output">
               {{getTeacherByUsername(row.teacherSecond)}}
           </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn *ngIf="isSecondary">
          <p-cellEditor>
            <ng-template pTemplate="input">
             <p-dropdown id="teacherRegional" [filter]="true" [styleClass]="'w-full'" [options]="teachersGui" [ngModel]="row.teacherRegional" (ngModelChange)="changeTeacherRegional($event, row)">
              </p-dropdown>
           </ng-template>
           <ng-template pTemplate="output">
               {{getTeacherByUsername(row.teacherRegional)}}
           </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn *ngIf="isSecondary">
          <p-cellEditor>
            <ng-template pTemplate="input">
             <p-dropdown id="teacherRegionalSecond" [filter]="true" [styleClass]="'w-full'" [options]="teachersGui" [ngModel]="row.teacherRegionalSecond" (ngModelChange)="changeTeacherRegionalSecond($event, row)">
              </p-dropdown>
           </ng-template>
           <ng-template pTemplate="output">
               {{getTeacherByUsername(row.teacherRegionalSecond)}}
           </ng-template>
          </p-cellEditor>
        </td>
    </tr>
    </ng-template>
</p-table>
