<p-message class="col-12" severity="warn" text="Aktuell nur Suchen, kein Ersetzen"></p-message>
<div class="flex-row">
    <div class="flex-item-fixed width700">
        <sm-form  [form]="formSearch">
        </sm-form>
        <p-button icon="fas fa-search" label="Suchen" (onClick)="search()"></p-button>
        <p-tree *ngIf="result != null" class="full-height-440" [value]="tree" selectionMode="single" [filter]=true [filterBy]="'data.name,data.internalName,data.tableName'" [(selection)]="selectedMatch" (selectionChange)="selectedMatchChanged()">
        </p-tree>
    </div>
    <div class="flex-item-fill">
        <app-ace-editor class="width100p full-height-200x" [class.hidden]="selectedMatch == null" #ace [text]="selectedMatch == null ? '' : _Utils.asSingle(selectedMatch).data.wfEventId != null ? result.wfEventDefinitions[_Utils.asSingle(selectedMatch).data.wfEventId].code : result.clientEventDefinitions[_Utils.asSingle(selectedMatch).data.clientEventId].code"></app-ace-editor>
    </div>
</div>
