<div class="flex-row">
    <div class="flex-item-fixed width300">
        <p-tree [value]="warningTypesTree" selectionMode="multiple" [(selection)]="selectedWarningTypes">
        </p-tree>
        <p-button icon="fas fa-run" label="Aktualisieren" (onClick)="find()">
        </p-button>
        <p-button icon="fas fa-format" label="Fehler beheben" (onClick)="fix()">
        </p-button>
    </div>
    <div class="flex-item-fill">
        <p-message *ngIf="warnings?.length == 1000" rowclass="col-12" severity="warn" text="Es werden nicht alle Warnungen angezeigt. Menge ist auf 1000 Einträge begrenzt"></p-message>
        <p-tree *ngIf="tree != null" class="col-12 full-height-180" [value]="tree" selectionMode="checkbox" [filter]=true>
        </p-tree>
    </div>
</div>
