import { Utils } from "src/modules/utils/shared/utils";
import { Comparable } from "../../utils/shared/comparable";
import { ClassRegisterSettings } from "./class-register-settings.entity";
import { SchoolType } from "./school-type.enum";

export class SchoolClass implements Comparable<SchoolClass> {
    id: number;
    grade: number;
    title: string;
    year: string;
    type: string;
    profile: string;
    teacher: string;
    permissions: string[];

    getDisplayName(): string {
        return this.grade + this.title;
    }

    compareTo(other: SchoolClass): number {
        let result = this.grade - other.grade;
        return result !== 0 ? result : this.title.localeCompare(other.title);
    }

    hasPermission(userName: string, onlyMain = false): boolean {
        return Utils.stringEqualsCi(this.teacher, userName) || !onlyMain && this.permissions.some(s => Utils.stringEqualsCi(s, userName));
    }

    hasPermissionClassRegister(userName: string, isAdmin: boolean, settings: ClassRegisterSettings): boolean {
        let st = this.getSchoolType();
        return st != null && (isAdmin || settings.getTeacher(userName)?.isForSchoolType(st));
    }

    isForCertificates(): boolean {
        return this.isValidType();
    }

    isForGradeBook(): boolean {
        let t = this.getSchoolType();
        return t == SchoolType.elementary || t == SchoolType.secondary;
    }

    getSchoolType(): SchoolType {
        return this.type == "Grundschule" ? SchoolType.elementary : this.type == "Gesamtschule" ? SchoolType.secondary : this.type == "Förderschule" ? SchoolType.special : null;
    }

    getGuiClass(): string {
        let st = this.getSchoolType();
        return st == SchoolType.elementary ? "primary-color" : st == SchoolType.secondary ? "primary-color-lighter" : st == SchoolType.special ? "primary-color-darker" : "";
    }

    getYearNumber(): number {
        return Utils.toNumber(this.year.slice(0, 4));
    }

    isValidType(): boolean {
        return this.getSchoolType() != null;
    }

    usesGradeCertificates(): boolean {
        return (this.grade == 9 || this.grade == 10) && this.getSchoolType() == SchoolType.secondary;
    }

    usesExamTypeCalculation(): boolean {
        return this.getSchoolType() == SchoolType.secondary;
    }

    getUseAfterSchoolCenter(): boolean {
        return this.grade <= 6 && (this.getSchoolType() == SchoolType.elementary || this.getSchoolType() == SchoolType.special);
    }

    isManager(userName: string, settings: ClassRegisterSettings): boolean {
        return Utils.stringEqualsCi(settings.getManager(this.getSchoolType()), userName);
    }

}
