import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { EnaioCall } from "./EnaioCall";
import { EnaioUserGroup } from "./EnaioUserGroup";

export class EnaioCallGetUserGroupList extends EnaioCall <EnaioUserGroup[]> {
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioUserGroup, 1);
    }

}
