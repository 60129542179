export class TimedCache<T> {
    maxAgeInMillis: number;
    fetcher: () => Promise<T>;
    data: T;
    lastFetch = 0;

    currentFetcher: Promise<T>;

    constructor(maxAgeInMillis: number, fetcher: () => Promise<T>) {
        this.maxAgeInMillis = maxAgeInMillis;
        this.fetcher = fetcher;
    }

    async get(forceFetch?: boolean): Promise<T> {
        let now = Date.now();
        if (this.lastFetch + this.maxAgeInMillis < now || this.lastFetch == 0 || forceFetch) {
            let cf = this.currentFetcher;
            if (cf == null) {
                this.currentFetcher = this.fetcher();
                cf = this.currentFetcher;
            }
            try {
                this.data = await cf;
            }
            catch (error) {
                this.currentFetcher = null;
                throw error;
            }

            this.currentFetcher = null;
            this.lastFetch = Date.now();
        }
        return this.data;
    }

    purge(): void {
        this.data = null;
        this.lastFetch = 0;
    }
}
