<p-card>
    <div class="grid top">
        <div class="col-4">
            <div class="option-div">
                <p-checkbox class="option" name="showSpeakingNames" [(ngModel)]="speakingNames" [binary]="true"></p-checkbox>
                <label class="form-label">Sprechende Namen</label>
            </div>
            <div class="option-div">
                <p-checkbox class="option" name="showQuickValues" [(ngModel)]="quickValues" [binary]="true"></p-checkbox>
                <label class="form-label">Quick Values</label>
            </div>
        </div>
        <div class="col-8">
            {{helpText}}
        </div>
    </div>
</p-card>
<p-tabView class="col-12">
    <p-tabPanel header="Config Editor">
        <div *ngIf="loaded" class="grid">
            <p-orderList class="col-2" [value]="getWorkers()" [(selection)]="selectedWorkers" [listStyle]="{'max-height':'30rem'}" header="Ablauf" [dragdrop]="true" (selectionChange)="selectWorker()">
                <ng-template let-entry pTemplate="item">
                    <div class="order-icon fas fa-bars">
                    </div>
                    {{getShortTypeName(entry.typeName)}}
                </ng-template>
            </p-orderList>
            <app-config-editor-tree #configEditorTree [def]="def" [(helpText)]="helpText" [speakingNames]="speakingNames" [quickValues]="quickValues">
            </app-config-editor-tree>
        </div>
    </p-tabPanel>
    <p-tabPanel header="JSON">
        <textarea pInputTextarea spellcheck=false [ngModel]="json"></textarea>
    </p-tabPanel>
</p-tabView>
