import { Utils } from "src/modules/utils/shared/utils";
import { Type } from "class-transformer";
import { EnaioObjectId } from "./EnaioObjectId";

export class EnaioMetaDataField {
    @Type(() => EnaioObjectId)
    field: EnaioObjectId;
    value: any;

    constructor(field: EnaioObjectId, value: any) {
        this.field = field;
        this.value = value;
    }

    getDisplayValue(): string {
        if (Utils.regexMatches(Utils.toString(this.value), "[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}")) {
            return this.value === "0001-01-01T00:00:00" ? "" : new Date(Utils.toString(this.value)).toLocaleString();
        }
        else {
            return this.value;
        }
    }


}
