export class ButtonDefinition {
    id = "";
    caption = "";
    isSuccess = false;
    icon = "";

    constructor(id: string, caption: string, isSuccess: boolean, icon: string) {
        this.id = id;
        this.caption = caption;
        this.isSuccess = isSuccess;
        this.icon = icon;
    }
}
