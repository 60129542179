<ng-container *ngIf="editable">
    <p-treeTableCellEditor *ngIf="type == _TableCellType.text">
        <ng-template pTemplate="input">
            <input pInputText class="w-full" [(ngModel)]="value" (ngModelChange)="afterEdit()">
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && value == null" class="badge badge-no" (click)="buttonClick()">null</span>
            {{value}}
        </ng-template>
    </p-treeTableCellEditor>
    <p-treeTableCellEditor *ngIf="type == _TableCellType.dropdown">
        <ng-template pTemplate="input">
            <p-dropdown [styleClass]="'w-full'" [options]="editor.listItems" optionLabel="label" optionValue="value" [(ngModel)]="value" (onChange)="afterEdit()"></p-dropdown>
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && value == null" class="badge badge-no" (click)="buttonClick()">null</span>
            {{getListItemSpeakingName()}}
        </ng-template>
    </p-treeTableCellEditor>
    <p-treeTableCellEditor *ngIf="type == _TableCellType.number">
        <ng-template pTemplate="input">
            <p-inputNumber class="w-full" [(ngModel)]="value" (ngModelChange)="afterEdit()"></p-inputNumber>
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && value == null" class="badge badge-no" (click)="buttonClick()">null</span>
            {{value}}
        </ng-template>
    </p-treeTableCellEditor>
    <p-treeTableCellEditor *ngIf="type == _TableCellType.byteSize">
        <ng-template pTemplate="input">
            <p-inputNumber class="w-full" [(ngModel)]="value" (ngModelChange)="afterEdit()"></p-inputNumber>
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && value == null" class="badge badge-no" (click)="buttonClick()">null</span>
            {{_Utils.toByteSizeString(value)}}
        </ng-template>
    </p-treeTableCellEditor>
    <p-treeTableCellEditor *ngIf="type == _TableCellType.duration">
        <ng-template pTemplate="input">
            <p-inputNumber class="w-full" [(ngModel)]="value" (ngModelChange)="afterEdit()"></p-inputNumber>
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && value == null" class="badge badge-no" (click)="buttonClick()">null</span>
            {{_Utils.toDurationString(value)}}
        </ng-template>
    </p-treeTableCellEditor>
    <span *ngIf="type == _TableCellType.yesNo" class="badge mouse-pointer" [class.badge-yes]="value" [class.badge-no]="!value" (click)="switchBool()">{{value ? "Ja" : "Nein"}}</span>
    <p-treeTableCellEditor *ngIf="type == _TableCellType.color">
        <ng-template pTemplate="input">
            <input pInputText [(ngModel)]="value">
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && value == null" class="badge badge-no" (click)="buttonClick()">null</span>
            {{value}}
        </ng-template>
    </p-treeTableCellEditor><p-treeTableCellEditor *ngIf="type == _TableCellType.dateTime">
        <ng-template pTemplate="input">
            <input pInputText [(ngModel)]="value" (ngModelChange)="afterEdit()">
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && value == null" class="badge badge-no" (click)="buttonClick()">null</span>
            {{value}}
        </ng-template>
    </p-treeTableCellEditor>
    <p-treeTableCellEditor *ngIf="type == _TableCellType.date">
        <ng-template pTemplate="input">
            <input pInputText [(ngModel)]="value" (ngModelChange)="afterEdit()">
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && value == null" class="badge badge-no" (click)="buttonClick()">null</span>
            {{value}}
        </ng-template>
    </p-treeTableCellEditor>
<p-treeTableCellEditor *ngIf="type == _TableCellType.button">
        <ng-template pTemplate="input">
            <input pInputText [(ngModel)]="value">
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && value == null" class="badge badge-no" (click)="buttonClick()">null</span>
            {{value}}
        </ng-template>
    </p-treeTableCellEditor>
</ng-container>
<ng-container *ngIf="!editable && value != null">
    <span *ngIf="type == _TableCellType.text">{{value}}</span>
    <span *ngIf="type == _TableCellType.number">{{value}}</span>
    <span *ngIf="type == _TableCellType.yesNo" class="badge" [class.badge-yes]="value" [class.badge-no]="!value">{{value ? "Ja" : "Nein"}}</span>
    <span *ngIf="type == _TableCellType.color" class="badge" [style.background]="_Utils.toColorHexString(value)">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <span *ngIf="type == _TableCellType.date">{{value | date:'dd.MM.yyyy'}}</span>
    <span *ngIf="type == _TableCellType.dateTime">{{value | date:'dd.MM.yyyy HH:mm:ss'}}</span>
    <span *ngIf="type == _TableCellType.button">
        <button pButton type="button" class="ui-button-secondary" [icon]="value" (click)="buttonClick()"></button>
    </span>
</ng-container>
