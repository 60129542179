import { OrdinaryObject } from "./ordinary-object.model";

export class FieldMetaData {

    name: string;
    description: string;

    //Date

    dateIntervalToField: string;
    dateIntervalFromField: string;
    allowEmptyInterval: boolean;

    constructor(name: string, description: string, props: Partial<FieldMetaData>) {
        this.name = name;
        this.description = description;
        if (props != null) {
            Object.assign(this, props);
        }
    }

    validate(value: any, onObject: any, errors: OrdinaryObject): boolean {
        let result = true;
        if (value instanceof Date) {
            if (this.dateIntervalToField != null) {
                let to = onObject[this.dateIntervalToField];
                if (to != null) {
                    if (this.allowEmptyInterval) {
                        if (value.getTime() > to.getTime()) {
                            errors[this.name] = "Der Wert des Feldes '" + this.description + "' darf nicht nach dem Wert des Feldes '" + this.dateIntervalToField + "' liegen";
                            result = false;
                        }
                    }
                    else if (value.getTime() >= to.getTime()) {
                        errors[this.name] = "Der Wert des Feldes '" + this.description + "' muss vor dem Wert des Feldes '" + this.dateIntervalToField + "' liegen";
                        result = false;
                    }
                }
            }
            if (this.dateIntervalFromField != null) {
                let from = onObject[this.dateIntervalFromField];
                if (from != null) {
                    if (this.allowEmptyInterval) {
                        if (value.getTime() < from.getTime()) {
                            errors[this.name] = "Der Wert des Feldes '" + this.description + "' darf nicht vor dem Wert des Feldes '" + this.dateIntervalFromField + "' liegen";
                            result = false;
                        }
                    }
                    else if (value.getTime() <= from.getTime()) {
                        errors[this.name] = "Der Wert des Feldes '" + this.description + "' muss nach dem Wert des Feldes '" + this.dateIntervalFromField + "' liegen";
                        result = false;
                    }
                }
            }
        }
        return result;
    }

}
