import { Type } from "class-transformer";
import { LocalAppCacheEntry } from "./LocalAppCacheEntry";

export class DeploymentOptionsDocumentHandler {
    updateId: string;
    customer: string;
    globalName: string;
    appVersion: string;
    full = false;
    buildGui = false;
    components: string[];
    extension = "exe";
    outputFileName: string;
    @Type(() => LocalAppCacheEntry)
    localFiles: LocalAppCacheEntry[];
}
