<ng-container *transloco="let t">
    <div class="grid">
<!--        <app-dashboard-card class="col-6 lg:col-3" icon="fas fa-cog" [title]="t('lea.generalSettings.typeName')" [menuButton]="false" [routerLink]="['/enaio-learn', 'admin', 'general-settings']"></app-dashboard-card>
-->
        <app-dashboard-card *ngIf="isAdmin" class="col-6 lg:col-3" icon="fas fa-users" [title]="t('lea.user.typeNamePlural')" [menuButton]="false" [routerLink]="['/enaio-learn', 'admin', 'users']"></app-dashboard-card>
        <app-dashboard-card *ngIf="isAdmin" class="col-6 lg:col-3" icon="fas fa-users" [title]="t('lea.externalUser.typeNamePlural')" [menuButton]="false" [routerLink]="['/enaio-learn', 'admin', 'external-users']"></app-dashboard-card>
        <app-dashboard-card class="col-6 lg:col-3" icon="fas fa-user-group" [title]="t('lea.userGroup.typeNamePlural')" [menuButton]="false" [routerLink]="['/enaio-learn', 'admin', 'user-groups']"></app-dashboard-card>
        <app-dashboard-card *ngIf="isAdmin" class="col-6 lg:col-3" icon="fas fa-user-tie" [title]="t('lea.jobDescription.typeNamePlural')" [menuButton]="false" [routerLink]="['/enaio-learn', 'admin', 'job-descriptions']"></app-dashboard-card>
        <app-dashboard-card *ngIf="isAdmin" class="col-6 lg:col-3" icon="fas fa-glasses" [title]="t('lea.inspectionPhase.typeNamePlural')" [menuButton]="false" [routerLink]="['/enaio-learn', 'admin', 'inspection-phases']"></app-dashboard-card>
        <app-dashboard-card *ngIf="isAdmin" class="col-6 lg:col-3" icon="fas fa-lightbulb" [title]="t('lea.category.typeNamePlural')" [menuButton]="false" [routerLink]="['/enaio-learn', 'admin', 'learning-content-categories']"></app-dashboard-card>
        <app-dashboard-card *ngIf="isAdmin" class="col-6 lg:col-3" icon="fas fa-spell-check" [title]="t('lea.texts.title')" [menuButton]="false" [routerLink]="['/enaio-learn', 'admin', 'texts']"></app-dashboard-card>
    </div>
</ng-container>
