import { Type } from "class-transformer";
import { EnaioObjectId } from "./EnaioObjectId";

export class EnaioObjectDefChange {
    @Type(() => EnaioObjectId)
    archive: EnaioObjectId;
    @Type(() => EnaioObjectId)
    objectType: EnaioObjectId;
    @Type(() => EnaioObjectId)
    field: EnaioObjectId;
    oldValue: string;
    newValue: string;
    change: string;
    @Type(() => Date)
    date: Date;
}
