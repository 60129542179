/* eslint-disable @typescript-eslint/no-inferrable-types */

export class EnaioLearnTools {

    static getRatingTexts(): string[] {
        return [
            "Inhalt",
            "Relevanz für das Arbeitsfeld",
            "Umfang",
            "Verständlichkeit"
        ];
    }
}
