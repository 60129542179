/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { ClassRegisterSettings } from './class-register-settings.entity';

@Entity()
export class Holiday extends SmBaseEntity {

    @ManyToOne(() => ClassRegisterSettings, (o: ClassRegisterSettings) => o.holidays, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    settings: ClassRegisterSettings;

    @Column()
    @Type(() => Date)
    from: Date;

    @Column()
    @Type(() => Date)
    to: Date;

    @Column({default: ""})
    title: string = "";
}
