import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { AppTab } from '../app-template/models/app-tab.model';
import { MainAppService } from '../app-template/services/main-app.service';
import { PrimeModule } from '../prime/prime.module';
import { FeatureModule } from '../sm-base/models/feature.module';
import { AuthenticationService } from '../sm-base/services/authentication.service';
import { FeatureModuleService } from '../sm-base/services/feature-module.service';
import { SmBaseModule } from '../sm-base/sm-base.module';
import { UtilsModule } from '../utils/utils.module';
import { AnalysesOpenItemsComponent } from './components/analyses-open-items/analyses-open-items.component';
import { AnalysesParticipationsComponent } from './components/analyses-participations/analyses-participations.component';
import { AnalysesRatingsComponent } from './components/analyses-ratings/analyses-ratings.component';
import { AttendContentComponent } from './components/attend-content/attend-content.component';
import { ChapterListComponent } from './components/chapter-list/chapter-list.component';
import { EditCitationsComponent } from './components/edit-citations/edit-citations.component';
import { EditExternalUserComponent } from './components/edit-external-user/edit-external-user.component';
import { EditExternalUsersComponent } from './components/edit-external-users/edit-external-users.component';
import { EditInspectionPhaseComponent } from './components/edit-inspection-phase/edit-inspection-phase.component';
import { EditInspectionPhasesComponent } from './components/edit-inspection-phases/edit-inspection-phases.component';
import { EditJobDescriptionComponent } from './components/edit-job-description/edit-job-description.component';
import { EditJobDescriptionsComponent } from './components/edit-job-descriptions/edit-job-descriptions.component';
import { EditLearningContentCategoriesComponent } from './components/edit-learning-content-categories/edit-learning-content-categories.component';
import { EditLearningContentCategoryComponent } from './components/edit-learning-content-category/edit-learning-content-category.component';
import { EditLearningContentTypeComponent } from './components/edit-learning-content-type/edit-learning-content-type.component';
import { EditLearningContentTypesComponent } from './components/edit-learning-content-types/edit-learning-content-types.component';
import { EditLearningContentComponent } from './components/edit-learning-content/edit-learning-content.component';
import { EditLearningSettingsComponent } from './components/edit-learning-settings/edit-learning-settings.component';
import { EditMyProfileComponent } from './components/edit-my-profile/edit-my-profile.component';
import { EditTextsComponent } from './components/edit-texts/edit-texts.component';
import { EditUserGroupComponent } from './components/edit-user-group/edit-user-group.component';
import { EditUserGroupsComponent } from './components/edit-user-groups/edit-user-groups.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { EditUsersComponent } from './components/edit-users/edit-users.component';
import { FinalQuestionsComponent } from './components/final-questions/final-questions.component';
import { LearningAdminComponent } from './components/learning-admin/learning-admin.component';
import { LearningAnalysesComponent } from './components/learning-analyses/learning-analyses.component';
import { LearningHomeComponent } from './components/learning-home/learning-home.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { QuestionsComponent } from './components/questions/questions.component';
import { EnaioLearnRoutingModule } from './enaio-learn-routing.module';
import { EnaioLearnService } from './services/enaio-learn.service';
import { NgxJoditModule } from 'ngx-jodit'

@NgModule({
    declarations: [
        EditLearningContentTypeComponent,
        EditLearningContentTypesComponent,
        EditLearningContentCategoryComponent,
        EditLearningContentCategoriesComponent,
        EditLearningContentComponent,
        EditLearningSettingsComponent,
        LearningAdminComponent,
        AttendContentComponent,
        EditJobDescriptionComponent,
        EditJobDescriptionsComponent,
        EditUsersComponent,
        EditUserComponent,
        LearningHomeComponent,
        ChapterListComponent,
        EditInspectionPhaseComponent,
        EditInspectionPhasesComponent,
        NotificationsComponent,
        LearningAnalysesComponent,
        EditMyProfileComponent,
        EditTextsComponent,
        EditCitationsComponent,
        QuestionsComponent,
        FinalQuestionsComponent,
        AnalysesParticipationsComponent,
        AnalysesOpenItemsComponent,
        AnalysesRatingsComponent,
        EditUserGroupsComponent,
        EditUserGroupComponent,
        EditExternalUserComponent,
        EditExternalUsersComponent
    ],
    providers: [
        EnaioLearnService
    ],
    imports: [
        CommonModule,
        EnaioLearnRoutingModule,
        SmBaseModule,
        PrimeModule,
        UtilsModule,
        TranslocoModule,
        NgxJoditModule,
        PdfJsViewerModule
    ]
})
export class EnaioLearnModule implements FeatureModule {

    constructor(private featureModuleService: FeatureModuleService, private app: MainAppService, private service: EnaioLearnService, private auth: AuthenticationService) {
        featureModuleService.register(this);
    }

    registerSidebar(model: AppTab[]): void {
        this.auth.onUserChanged.subscribe(async () => {
            let profile = await this.service.getMyProfile();
            this.app.toolbarButtons.find(b => b.id == "admin").visible = profile.isAdminOrManager();
            this.app.toolbarButtons.find(b => b.id == "analyses").visible = profile.isAdminOrManager() || profile.mayRunAnalyses;
        });

        model.push(new AppTab("learn-home", "Home", "fas fa-home", 110, [], ["/enaio-learn", "home"]));
        model.push(new AppTab("learn-my-contents", "Meine Lerninhalte", "fas fa-star", 120, [], ["/enaio-learn", "my-contents"]));
        model.push(new AppTab("learn-contents", "Lerninhalte bearbeiten", "fas fa-graduation-cap", 130, [], ["/enaio-learn", "learning-contents"]));
        model.push(new AppTab("learn-reports", "Auswertungen", "fas fa-pie-chart", 140, [], ["/enaio-learn", "home"]));
        model.push(new AppTab("learn-admin", "Administration", "fas fa-cog", 150, [], ["/enaio-learn", "admin"]));

        this.app.useNotifications = true;
        this.app.toolbarNotificationsButton = true;
        this.app.toolbarButtons = [...this.app.toolbarButtons,
        {
            id: "myProfile",
            label: "Mein Profil",
            routerLink: ["/enaio-learn", "my-profile"],
            icon: "fas fa-user-cog",
            visible: true
        },
        {
            id: "admin",
            label: "Administration",
            routerLink: ["/enaio-learn", "admin"],
            icon: "fas fa-cog",
            visible: false
        },
        {
            id: "analyses",
            label: "Auswertungen",
            routerLink: ["/enaio-learn", "analyses"],
            icon: "fas fa-chart-pie",
            visible: false
        }];
    }

    async updateSidebarVisibility(_: AppTab[]): Promise<void> {
    }

    getMainRoute(): Route {
        return this.featureModuleService.createModuleRoute("enaio-learn", () => EnaioLearnRoutingModule);
    }
}
