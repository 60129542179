import { Component, OnInit } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { AppMainComponent } from 'src/modules/app-template/components/app-main/app-main.component';
import { Config } from 'src/modules/app-template/models/config.model';
import { MenuService } from 'src/modules/app-template/services/app.menu.service';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { LoginUserDatabase } from 'src/modules/database/shared/login-user-database.model';
import { AuthenticationService } from 'src/modules/sm-base/services/authentication.service';
import { FeatureModuleService } from 'src/modules/sm-base/services/feature-module.service';
import { SmBaseRoutingModule2 } from 'src/modules/sm-base/sm-base-routing2.module';
import { SmBaseModule } from 'src/modules/sm-base/sm-base.module';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'root-component',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit {
    constructor(private app: MainAppService, private auth: AuthenticationService, private featureModuleService: FeatureModuleService, private menu: MenuService, private router: Router) {
    }

    async ngOnInit(): Promise<void> {
        for (let module of this.featureModuleService.getModules()) {
            module.registerSidebar(this.menu.model);
        }

        if (Config.get().hasAdminUsers) {
            this.app.toolbarButtons = [...this.app.toolbarButtons,
                {
                    id: "adminUsers",
                    label: "Benutzerverwaltung",
                    routerLink: ["/base2", "admin", "editGeneralUsers"],
                    icon: "fas fa-cog",
                    visible: false
                }
            ];
        }

        this.menu.model = Utils.arraySortBy(this.menu.model, item => item.sort);
        for (let tab of this.menu.model) {
            tab.menuItems = Utils.arraySortBy(tab.menuItems, item => item.sort);
        }

        let childRoutes = this.featureModuleService.getModules().map(module => module.getMainRoute());
        childRoutes.push({
            path: "",
            redirectTo: Utils.stringDef(Config.get().mainRoute, childRoutes[0].path),
            pathMatch: "full"
        });
        childRoutes.push(this.featureModuleService.createModuleRoute("base2", () => SmBaseRoutingModule2));

        let routes: Routes = [
            {
                path: 'base',
                loadChildren: () => SmBaseModule
            },
            {
                path: '',
                component: AppMainComponent,
                children: childRoutes
            }
        ];

        this.router.resetConfig(routes);

        this.auth.onUserChanged.subscribe(async () => {
            await this.updateVisibility();
        });
        await this.updateVisibility();
    }

    private async updateVisibility(): Promise<void> {
        for (let module of this.featureModuleService.getModules()) {
            await module.updateSidebarVisibility(this.menu.model);
        }
        this.menu.updateVisibility();
        let b = this.app.toolbarButtons.find(tb => tb.id == "adminUsers");
        if (b != null) {
            b.visible = (await this.auth.getUserDetails()).getAdditional("database", LoginUserDatabase)?.raw?.isAdmin ?? false;
        }
    }
}
