import { Utils } from "src/modules/utils/shared/utils";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { EnaioAssignment } from "./EnaioAssignment";
import { EnaioObjectId } from "./EnaioObjectId";
import { EnaioTableAssignment } from "./EnaioTableAssignment";
import { EnaioTableRow } from "./EnaioTableRow";
import { Type } from "class-transformer";
import { EnaioWorkItemParameterMode } from "./EnaioWorkItemParameterMode";
import { EnaioWorkItemParamValue } from "./EnaioWorkItemParamValue";

export class EnaioWorkItemParameter {
    formFieldId: string;
    dataFieldId: string;
    name: string;
    mode: EnaioWorkItemParameterMode;
    infoText: string;
    selection: string;
    listType: string;
    @Type(() => EnaioWorkItemParamValue)
    value: EnaioWorkItemParamValue;

    workValue: any;

    convert(o: any): any {
        let v1 = Utils.getProperty(o, "String");
        if (v1 != null) {
            return Utils.getPropertyDef(v1, "_text", "");
        }
        v1 = Utils.getProperty(o, "IntegerSafe");
        if (v1 != null) {
            let v = Utils.getPropertyDef(v1, "_text", "");
            return v == "" ? "" : Utils.toNumber(v);
        }
        v1 = Utils.getProperty(o, "FloatSafe");
        if (v1 != null) {
            let v = Utils.getPropertyDef(v1, "_text", "");
            return v == "" ? "" : Utils.toNumber(v);
        }
        v1 = Utils.getProperty(o, "DateSafe");
        if (v1 != null) {
           // let v = Utils.getPropertyDef(v1, "_text", "");
           // return v == "" ? "" : Utils.dateFromString(v, "DD.MM.YYYY");
            return Utils.getPropertyDef(v1, "_text", "");
        }
        v1 = Utils.getProperty(o, "List");
        if (v1 != null) {
            let itemsPlain = Utils.getProperty(v1, "ListItem");
            let items = itemsPlain == null ? [] : Utils.isArray(itemsPlain) ? itemsPlain as any[] : [itemsPlain];
            return items.map(item => this.convert(item));
        }
        v1 = Utils.getProperty(o, "Record");
        if (v1 != null) {
            let membersPlain = Utils.getProperty(v1, "Member", true);
            let members = Utils.isArray(membersPlain) ? membersPlain as any[] : [membersPlain];
            let result: OrdinaryObject = {};
            for (let member of members) {
                result[Utils.getProperty<string>(Utils.getProperty(member, "_attributes", true), "Name", true)] = this.convert(member);
            }
            return result;
        }

        return null;
    }

    getPlainValue(): any {
        let convert = require('xml-js');
        let js = Utils.fromJson(Utils.toString(convert.xml2json(this.value.plain, {compact: true, spaces: 4})));

        let wfvar = Utils.getProperty(js, "WFVar", true);

        let result = this.convert(wfvar);
        if (result == null) {
            console.log(this.value.plain + "\n" + Utils.toJson(js, true));
        }
        return result;
    }

    generateRecordItem(): OrdinaryObject {
        if (this.value.types == null) {
            return null;
        }

        let keys = Utils.getOwnPropertyNames(this.value.types);
        if (keys.length === 0) {
            return null;
        }
        else if (keys.length > 1) {
            throw new Error("Kann kein RecordItem generieren. Mehrere Typedefinition gefunden");
        }
        else {
            let type = this.value.types[keys[0]];
            let result: OrdinaryObject = {};
            for (let member of type.recordMembers) {
                result[member.name] = "";
            }
            return result;
        }
    }

    toAssignment(): EnaioAssignment {
        return new EnaioAssignment(EnaioObjectId.byName(this.name), this.value.value);
    }

    toTableAssignment(): EnaioTableAssignment {
        return Utils.fromPlain(EnaioTableAssignment, {
            field: EnaioObjectId.byName(this.name),
            rows: (this.value.value as any[]).map(row => Utils.fromPlain(EnaioTableRow, {
                columns: Utils.getOwnPropertyNames(row).map(fieldName => new EnaioAssignment(EnaioObjectId.byInternalName(fieldName), row[fieldName]))
            }))
        });
    }

}
/* eslint-disable vars-on-top, no-var */
declare var require: any;
