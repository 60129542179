import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { EnaioCall } from "./EnaioCall";
import { EnaioUserIdentification } from "./EnaioUserIdentification";
import { EnaioUserPrefs } from "./EnaioUserPrefs";

export class EnaioCallGetUserPrefs extends EnaioCall <EnaioUserPrefs> {
    @Type(() => EnaioUserIdentification)
    user: EnaioUserIdentification;
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioUserPrefs);
    }

}
