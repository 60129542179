import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { DatabaseTools } from 'src/modules/database/shared/database-tools';
import { AuthenticationService } from 'src/modules/sm-base/services/authentication.service';
import { AuthRules } from 'src/modules/sm-base/shared/auth-rules.model';
import { RestEndpoint } from '../../models/rest-endpoint.model';
import { FirstLoginComponent } from '../first-login/first-login.component';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'sm-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    form: FormGroup;
    authRules: AuthRules;
    waiting = false;

    constructor(public app: MainAppService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private authentication: AuthenticationService,
        private messageService: MessageService
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.authRules = await RestEndpoint.main().find().run("api/app/auth/authRules").get(AuthRules);

        this.form = this.fb.group({
            userName: ['', Validators.required],
            password: ['', Validators.required],
            remember: [false]
        });
    }

    async send(): Promise<void> {
        let userName = this.form.get('userName').value as string;
        if (Utils.isNoe(userName)) {
            this.messageService.add({ severity: 'error', summary: 'Fehler', detail: 'Bitte geben Sie einen Benutzernamen ein' });
            return;
        }
        this.waiting = true;
        try {
            let resultCode = await this.authentication.login(userName, this.form.get('password').value as string, this.form.get('remember').value as boolean);
            switch (resultCode) {
                case 0:
                    await this.router.navigateByUrl(this.route.snapshot.queryParams.returnUrl as string || '/');
                    break;
                case 1:
                    this.messageService.add({ severity: 'error', summary: 'Anmeldung fehlgeschlagen', detail: 'Die Kombination aus Benutzername und Passwort ist unbekannt' });
                    break;
                case DatabaseTools.errorIdLoginAnswerQuestions:
                case DatabaseTools.errorIdLoginMustSetPassword:
                    FirstLoginComponent.currentPassword = this.form.get('password').value;
                    await this.router.navigate(['base', 'firstlogin'], { queryParams: { userName: this.form.get('userName').value }});
                    break;
            }
        }
        finally {
            this.waiting = false;
        }
    }
}
