import { EnaioTools } from "./enaio-tools.model";
import { Type } from "class-transformer";
import { EnaioUserIdentification } from "./EnaioUserIdentification";
import { EnaioWorkflowDefinition } from "./EnaioWorkflowDefinition";
import { EnaioWorkflowFamily } from "./EnaioWorkflowFamily";
import { EnaioWorkflowModelState } from "./EnaioWorkflowModelState";

export class EnaioWorkflowModel {
    id: string;
    name: string;
    instanceName: string;
    processCount = 0;
    familyId: string;
    description: string;
    version = 0;
    iconId = 0;
    packageId: string;
    @Type(() => EnaioUserIdentification)
    creator: EnaioUserIdentification;
    @Type(() => Date)
    creationDate: Date;
    state: EnaioWorkflowModelState;
    @Type(() => EnaioWorkflowFamily)
    family: EnaioWorkflowFamily;
    @Type(() => EnaioWorkflowDefinition)
    definition: EnaioWorkflowDefinition;

    getHtmlTitle(): string {
        let s = (this.state == EnaioWorkflowModelState.active || this.state == EnaioWorkflowModelState.inUse ? "<b>" + this.name + "</b>" : this.name) + " (v" + this.version + ")";
        s += " <span style='color: $00ffff'>[" + EnaioTools.workflowModelStateToString(this.state) + "]</span>";
        if (this.state == EnaioWorkflowModelState.deleted) {
            s = "<span style='text-decoration: line-through'>" + s + "</span>";
        }
        return s;
    }


}
