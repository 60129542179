import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioChangeEntryType } from './EnaioChangeEntryType';
import { EnaioProcessState } from './EnaioProcessState';
import { DatevRechercheType } from './DatevRechercheType';

export class EnaioHelper {

    static datevRechercheTypeToString(value: DatevRechercheType): string {
        switch (value) {
            case DatevRechercheType.datev:
                return "datev";
            case DatevRechercheType.enaioObject:
                return "enaioObject";
            case DatevRechercheType.enaioWorkflow:
                return "enaioWorkflow";
            case DatevRechercheType.dhDone:
                return "dhDone";
            case DatevRechercheType.dhError:
                return "dhError";
            case DatevRechercheType.dhLog:
                return "dhLog";
            default:
                return "[" + Utils.toString(value) + "]";
        }
    }

    static changeEntryTypeToString(value: EnaioChangeEntryType): string {
        switch (value) {
            case EnaioChangeEntryType.objectChange:
                return "Objekt-Änderung";
            case EnaioChangeEntryType.workflowChange:
                return "Workflow-Änderung";
            case EnaioChangeEntryType.eventLogEntry:
                return "EventLog-Eintrag";
            default:
                return "[" + Utils.toString(value) + "]";
        }
    }

    static processStateToString(state: EnaioProcessState): string {
        switch (state) {
            case EnaioProcessState.unknown:
                return "Unbekannt";
            case EnaioProcessState.initialising:
                return "Initialisierung";
            case EnaioProcessState.running:
                return "Läuft";
            case EnaioProcessState.stopped:
                return "Unterbrochen";
            case EnaioProcessState.finished:
                return "Beendet";
            case EnaioProcessState.error:
                return "Fehler";
        }
    }

    static processHistoryTypeToString(value: number): string {
        switch (value) {
            case 1:
                return "Prozess vorbereitet";
            case 2:
                return "Prozess gestartet";
            case 3:
                return "Prozess beendet";
            case 4:
                return "Aktivität vorbereitet";
            case 5:
                return "Aktivität gestartet";
            case 6:
                return "Aktivität beendet";
            case 7:
                return "Variablen kopiert";
            case 10:
                return "Vorgangsschritt gestartet";
            case 11:
                return "Vorgangsschritt personalisiert";
            case 13:
                return "Vorgangsschritt gespeichert";
            case 14:
                return "Vorgangsschritt beendet";
            case 15:
                return "Timer erzeugt";
            case 16:
                return "Timer gelöscht";
            case 17:
                return "Mahnfrist überschritten";
            case 18:
                return "Als verspätet gekennzeichnet";
            case 19:
                return "Skript gestartet";
            case 20:
                return "Skript beendet";
            case 21:
                return "Vom System angehalten";
            case 22:
                return "Vorgangsschritt zugewiesen";
            case 23:
                return "Vorgangsschritt entfernt";
            case 24:
                return "Prozess angehalten";
            case 25:
                return "Aktivität angehalten";
            case 26:
                return "Prozess fortgesetzt";
            case 27:
                return "Aktivität fortgesetzt";
            case 28:
                return "Auf Aktivität zurückgesetzt";
            case 29:
                return "Aktivität gelöscht (durch Zurücksetzen)";
            case 30:
                return "Variablen geändert";
            case 31:
                return "Vorgangsschritt entpersonalisiert";
            case 33:
                return "Vorgangsschritt entpersonalisiert (Administration)";
            case 35:
                return "Vorgangsschritt gelöscht???";
            case 36:
                return "Prozess gelöscht???";
            case 37:
                return "Bearbeiter zugewiesen";
            case 39:
                return "Neuer Schleifendurchlauf";
            default:
                return "Unbekannt (" + value + ")";
        }
    }

    static toNumber(input: any): number {
        return Utils.toNumber(input);
    }

    static toString(input: any): string {
        if (input instanceof Date) {
            let date: Date = input;
            return date.getDate().toString().padStart(2, "0") + "." + (date.getMonth() + 1).toString().padStart(2, "0") + "." + date.getFullYear();
        }
        else {
            return Utils.toString(input);
        }
    }

    static toDate(input: any, copyIfDate = false): Date {
        if (input instanceof Date) {
            return copyIfDate ? new Date(input) : input;
        }
        else if (Utils.isString(input)) {
            let parts = (input as string).split(".");
            if (parts.length === 3) {
                let parts2 = parts[2].split(" ");
                if (parts2.length === 2) {
                    let parts3 = parts2[1].split(":");
                    if (parts3.length === 2) {
                        return new Date(Utils.toNumber(parts2[0]), Utils.toNumber(parts[1]) - 1, Utils.toNumber(parts[0]), Utils.toNumber(parts3[0]), Utils.toNumber(parts3[1]));
                    }
                }
                else {
                    return new Date(Utils.toNumber(parts[2]), Utils.toNumber(parts[1]) - 1, Utils.toNumber(parts[0]));
                }
            }
            return new Date(input as string);
        }
        return null;
    }

    static notImplemented(): void {
        throw new Error("Diese Skriptfunktion ist noch nicht implementiert");
    }

}
