import { Component, Input, ViewChild } from '@angular/core';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'enaio-document-file-preview',
    templateUrl: './document-file-preview.component.html',
    styleUrls: ['document-file-preview.component.scss']
})
export class DocumentFilePreviewComponent {

    @ViewChild('viewer') viewer;

    _documentId = 0;
    @Input()
    anonymousGuid: string;

    @Input()
    set documentId(value: number) {
        this._documentId = value;
        if (this._documentId === 0) {
            if (this.viewer != null) {
                this.viewer.pdfSrc = "";
                this.viewer.refresh();
            }
        }
        else if (!Utils.isNoe(this.anonymousGuid)) {
            RestEndpoint.main().query({anonymousGuid: this.anonymousGuid, id: this._documentId, convert: true}).runBlob("api/doc/def/anonymousdocument").then(b => {
                if (this.viewer != null) {
                    this.viewer.pdfSrc = b;
                    this.viewer.refresh();
                }
            }).catch(_ => {
                if (this.viewer != null) {
                    this.viewer.pdfSrc = "";
                    this.viewer.refresh();
                }
            });
        }
        else {
            RestEndpoint.main().query({id: this._documentId, convert: true}).runBlob("api/doc/document/document").then(b => {
                if (this.viewer != null) {
                    this.viewer.pdfSrc = b;
                    this.viewer.refresh();
                }
            }).catch(_ => {
                if (this.viewer != null) {
                    this.viewer.pdfSrc = "";
                    this.viewer.refresh();
                }
            });
        }

    }

    get documentId(): number {
        return this._documentId;
    }

}
