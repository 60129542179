
export class FrontendFieldGuiHint {

    text = "";
    icon = "";
    color = "";
    toolTip: string;
    onClick: () => void;

   constructor(text: string, icon: string, color: string, toolTip?: string, onClick?: () => void) {
       this.text = text;
       this.icon = icon;
       this.color = color;
       this.toolTip = toolTip;
       this.onClick = onClick;
   }
}
