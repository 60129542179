<div *ngIf="inited">
    <sm-toolbar>
        <p-button icon="fas fa-save" label="Speichern" (onClick)="save()">
        </p-button>
    </sm-toolbar>
    <p-tabView class="col-12">
        <p-tabPanel header="Ergebnisse" [headerStyleClass]="history ? '' : 'hidden'">
            <p-table [value]="rows" [styleClass]="'p-datatable-gridlines'">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="align-left" style="width:155px" rowspan=2>Nachname</th>
                        <th class="align-left" style="width:155px" rowspan=2>Vorname</th>
                        <th *ngFor="let day of covidTestDays" class="align-left" style="width: 150px; max-width: 150px" colspan=3>{{day.day | date: 'dd.MM.yyyy'}}</th>
                    </tr>
                    <tr>
                        <ng-container *ngFor="let day of covidTestDays; index as index" >
                            <th class="align-left" style="width: 50px; max-width: 50px">Nasal</th>
                            <th class="align-left" style="width: 50px; max-width: 50px">Speichel</th>
                            <th class="align-left" style="width: 50px; max-width: 50px">Selbst</th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row let-index="rowIndex">
                    <tr [pSelectableRow]="row">
                        <td *ngIf="row.pupilId != 0">{{pupilsById[row.pupilId].lastName}}</td>
                        <td *ngIf="row.pupilId == 0" pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-dropdown [styleClass]="'width100'" id="person" [options]="personOptions" [ngModel]="row.person" (ngModelChange)="changePerson($event, row)">
                                    </p-dropdown>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{row.person}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td>{{row.pupilId != 0 ? pupilsById[row.pupilId].firstName : ''}}</td>
                        <ng-container *ngFor="let day of covidTestDays; index as index" >
                            <ng-container *ngIf="row.tests[index] != null">
                                <td class="align-left"><sm-check-box-with-count [(value)]="row.tests[index].numNasal" [max]=3></sm-check-box-with-count></td>
                                <td class="align-left"><sm-check-box-with-count [(value)]="row.tests[index].numSaliva" [max]=3></sm-check-box-with-count></td>
                                <td class="align-left"><sm-check-box-with-count [(value)]="row.tests[index].numSelf" [max]=3></sm-check-box-with-count></td>
                            </ng-container>
                            <ng-container *ngIf="row.tests[index] == null">
                                <td class="align-left"></td>
                                <td class="align-left"></td>
                                <td class="align-left"></td>
                            </ng-container>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template *ngIf="history" pTemplate="footer">
                    <tr *ngFor="let person of [[''], ['Lehrer'], ['upF'], ['B', 's. Pers.'], null]">
                        <td>Gesamtsumme {{person == null ? '' : person[0] == '' ? "Schüler" : person}}</td>
                        <td></td>
                        <ng-container *ngFor="let day of covidTestDays; index as index" >
                            <td class="align-left">{{getSum(index, null, person, 0)}}</td>
                            <td class="align-left">{{getSum(index, null, person, 1)}}</td>
                            <td class="align-left">{{getSum(index, null, person, 2)}}</td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>
            <sm-toolbar *ngIf="!history">
                <p-button icon="fas fa-add" label="Neue Zeile" (onClick)="addRow()">
                </p-button>
            </sm-toolbar>
    </p-tabPanel>
    <p-tabPanel *ngIf="history" header="Auswertung">
        <p-table [value]="[[''], ['Lehrer'], ['upF'], ['B', 's. Pers.'], null]" [styleClass]="'p-datatable-gridlines'">
            <ng-template pTemplate="header">
                <tr>
                    <th class="align-left" style="width:155px" rowspan=2>Kategorie</th>
                    <ng-container >
                        <th *ngFor="let week of calendarWeeks" class="align-left" style="width: 50px; max-width: 50px" colspan=4>KW {{week}}</th>
                    </ng-container>
                </tr>
                <tr>
                    <ng-container *ngFor="let week of calendarWeeks">
                        <th class="align-left" style="width: 50px; max-width: 50px">Nasal</th>
                        <th class="align-left" style="width: 50px; max-width: 50px">Speichel</th>
                        <th class="align-left" style="width: 50px; max-width: 50px">Selbst</th>
                        <th class="align-left" style="width: 50px; max-width: 50px">Gesamt</th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row let-index="rowIndex">
                <tr [pSelectableRow]="row">
                    <td>Gesamtsumme {{row == null ? '' : row[0] == '' ? "Schüler" : _Utils.arrayItemsToString(row)}}</td>
                    <ng-container *ngFor="let week of calendarWeeks">
                        <td class="align-left">{{getSum(null, week, row, 0)}}</td>
                        <td class="align-left">{{getSum(null, week, row, 1)}}</td>
                        <td class="align-left">{{getSum(null, week, row, 2)}}</td>
                        <td class="align-left">{{getSum(null, week, row, -1)}}</td>
                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
    </p-tabPanel>
    </p-tabView>
</div>
