export enum EnaioChangeEntryType {
    objectChange = 0,
    workflowChange = 1,
    eventLogEntry = 2
}
export namespace EnaioChangeEntryType{
    export function getName(value: EnaioChangeEntryType): string {
        switch (value) {
            case EnaioChangeEntryType.objectChange:
                return "objectChange";
            case EnaioChangeEntryType.workflowChange:
                return "workflowChange";
            case EnaioChangeEntryType.eventLogEntry:
                return "eventLogEntry";
        }
    }
}
