export class Bounds {

    constructor(public x: number, public y: number, public width: number, public height: number) {
    }

    getBottom(): number {
        return this.y + this.height;
    }

    getRight(): number {
        return this.x + this.width;
    }

    shift(x: number, y: number): Bounds {
        return new Bounds(this.x + x, this.y + y, this.width, this.height);
    }
}
