import { Component, Input } from '@angular/core';

@Component({
  selector: 'tree-item-with-icons',
  templateUrl: './tree-item-with-icons.component.html',
  styleUrls: ['./tree-item-with-icons.component.scss']
})
export class TreeItemWithIconsComponent {

    @Input()
    label = "";
    @Input()
    icons: string[] = [];
}
