import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnaioPayrollMainComponent } from './components/enaio-payroll-main/enaio-payroll-main.component';
import { PayrollUsersComponent } from './components/payroll-users/payroll-users.component';

const routes: Routes = [
    {
        path: 'home',
        component: EnaioPayrollMainComponent
    },
    {
        path: 'admin/users',
        component: PayrollUsersComponent
    },
    {
        path: '',
        redirectTo: "home",
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EnaioPayrollRoutingModule {
}
