import { Type } from "class-transformer";
import { PasswordRules } from "./password-rules.model";

export class AuthRules {
    userNameIsEmail = false;
    anonymousOnly = false;
    canRegister = false;
    canRevokePassword = false;
    canRevokePasswordSelf = true;
    revokePasswordMessage: string = null;
    revokePasswordAfterMessage: string = null;
    useRecoveryQuestions = false;
    minRecoveryQuestions = 3;
    correctRecoveryQuestions = 3;
    @Type(() => PasswordRules)
    passwordRules = new PasswordRules();
}
