<ng-container *ngIf="field.addOnButtons == null">
    <ng-container [ngTemplateOutlet]="child"></ng-container>
</ng-container>
<div *ngIf="field.addOnButtons != null" class="p-inputgroup">
    <ng-container [ngTemplateOutlet]="child"></ng-container>

    <ng-container *ngFor="let addOn of field.addOnButtons">
        <span *ngIf="!addOn.isButton" class="p-inputgroup-addon">
            <i [class]="addOn.icon"></i>
        </span>
        <button *ngIf="addOn.isButton" type="button" pButton pRipple [class]="addOn.icon" styleClass="p-button-warn" (click)="clickAddOn(addOn, $event)"></button>
    </ng-container>
</div>
