<div [class]="'grid card overview-box overview-box-' + boxColor" (click)="click($event)">
    <div class="col-4 overview-box-icon">
        <i [class]="icon"></i>
    </div>
    <div class="col-8">
        <span class="overview-box-count">{{count}}</span>
        <span class="overview-box-name">{{text}}</span>
    </div>
    <div class="col-12 overview-box-footer">
        {{footerText}}
    </div>
