import { Tree } from "primeng/tree";
import { Utils } from "../shared/utils";
import { GuiUtils } from "./gui-utils";
import { TreeNode2 } from "./tree-node2.model";
import { TreeNode } from "primeng/api";
import { ArrayOrSingle } from "src/modules/sm-base/shared/array-or-single.model";

export class TreeSelectionHelper {

    tree: Tree;
    items: TreeNode2[] = [];
    selected: ArrayOrSingle<TreeNode> = [];
    currentlyUnselectable: any[] = [];

    constructor(tree: Tree) {
        this.tree = tree;
    }

    setSelected(selected: any[]): void {
        this.selected = GuiUtils.flattenTree(this.items).filter(node => selected.find(item => node.data == item) != null);
        for (let node of Utils.asArray(this.selected)) {
            (node as any).selected = true;
        }
        for (let node of this.items) {
            this.updatePartiallySelected(node);
        }
    }

    getSelected(): any[] {
        return Utils.asArray(this.selected).map(node => node.data);
    }

    setItems(items: TreeNode2[], applyUnselectable = false): void {
        let oldSel: any[] = null;
        let oldUnsel: any[] = null;
        let flatNodes = GuiUtils.flattenTree(items);
        if (applyUnselectable) {
            oldUnsel = [...this.currentlyUnselectable];
            oldSel = [...Utils.asArray(this.selected)];
            this.currentlyUnselectable = Utils.asArray(this.selected).filter(node => flatNodes.find(node2 => node2.data == node.data) == null);
            this.selected = Utils.arrayWithoutNull(Utils.asArray(this.selected).map(node => flatNodes.find(node2 => node2.data == node.data)));
        }
        let ex = GuiUtils.flattenTree(this.items).filter(node => node.expanded).map(node => flatNodes.find(node2 => node.data == node2.data));
        this.items = items;
        if (applyUnselectable) {
            this.selected = Utils.arrayWithoutNull([...oldSel, ...oldUnsel].map(node => flatNodes.find(node2 => node2.data == node.data)));
            for (let node of flatNodes) {
                (node as any).selected = false;
            }
            for (let node of Utils.asArray(this.selected)) {
                (node as any).selected = true;
            }
            for (let node of ex) {
                node.expanded = true;
            }
            for (let node of this.items) {
                this.updatePartiallySelected(node);
            }
        }

    }

    private updatePartiallySelected(node: TreeNode2): void {
        let anySel = false;
        let anyUnsel = false;
        for (let child of node.children ?? []) {
            this.updatePartiallySelected(child);
            anySel ||= (child as any).selected;
            anyUnsel ||= !(child as any).selected;
        }
        node.partialSelected = anySel && anyUnsel;
        if (node.children != null) {
            (node as any).selected = anySel;
            //Hier müssen die neu selektierten parent noch in this.selected übernommen werden
    }
    }

}
