import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-wait-container',
  templateUrl: './wait-container.component.html',
  styleUrls: ['./wait-container.component.scss']
})
export class WaitContainerComponent {

  @Input()
  loading = false;

}
