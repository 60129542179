import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { EnaioCall } from "./EnaioCall";
import { EnaioObjectDef } from "./EnaioObjectDef";

export class EnaioCallGetObjectDef extends EnaioCall <EnaioObjectDef> {
    buffered = false;
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioObjectDef);
    }

}
