// dynamic-tooltip.directive.ts
import { Directive, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDynamicTooltip]'
})
export class DynamicTooltipDirective {

  @Output()
  onToolTip = new EventEmitter();

  constructor(private el: ElementRef) {}

  @HostListener('mouseover')
  onMouseOver(): void {
    const isTruncated = this.isTextTruncated(this.el.nativeElement as HTMLElement);
    if (isTruncated) {
      this.onToolTip.emit({ element: this.el.nativeElement, truncated: isTruncated });
    }
  }

  isTextTruncated(element: HTMLElement): boolean {
    //In Tabellen wird ein Element zu weit unten erwischt. Wir suchen das Parent-Element, welches die Größen gesetzt hat
    let e = element.scrollWidth > 0 || element.scrollHeight > 0 || element.parentElement == null ? element : element.parentElement;
    return e.scrollWidth > e.clientWidth;
  }
}
