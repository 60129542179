/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { CustomInitializer } from 'src/modules/sm-base/shared/custom-initializer.interface';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne, OneToMany } from 'typeorm';
import { ExamTypeFactor } from './exam-type-factor.entity';
import { ExamType } from './exam-type.entity';
import { SchoolTypeSettings } from './school-type-settings.entity';

@Entity()
export class DomainSettings extends SmBaseEntity implements CustomInitializer {
    @ManyToOne(() => SchoolTypeSettings, (o: SchoolTypeSettings) => o.domainSettings, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    settings: SchoolTypeSettings;

    @Column({default: ""})
    domain: string = "";

    @OneToMany(() => ExamTypeFactor, o => o.settings, { cascade: true })
    @Type(() => ExamTypeFactor)
    examTypeFactors: ExamTypeFactor[];

    customInitializer(): void {
        this.examTypeFactors = Utils.arrayEnsure(this.examTypeFactors);
    }

    getExamTypeFactor(id: number): number {
        let result = this.examTypeFactors?.find(f => f.examType?.id == id);
        return result != null ? result.factor : 1;
    }

    getExamTypeFactorNormalised(id: number, examTypes: ExamType[]): number {
        let sum = 0;
        for (let etf of examTypes) {
            sum += this.getExamTypeFactor(etf.id);
        }
        return this.getExamTypeFactor(id) * 100 / sum;
    }
}
