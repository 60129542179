/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne } from 'typeorm';
import { ClassRegister } from './class-register.entity';

@Entity()
export class ClassFieldTrip extends SmBaseEntity {

    @ManyToOne(() => ClassRegister, (o: ClassRegister) => o.sickNotes, { onDelete: "CASCADE", nullable: false, orphanedRowAction: "delete"})
    classRegister: ClassRegister;

    @Column()
    @Type(() => Date)
    fromDate: Date;

    @Column({default: -1})
    fromHour: number = -1;

    @Column()
    @Type(() => Date)
    toDate: Date;

    @Column({default: -1})
    toHour: number = -1;

    @Column({default: ""})
    title: string = "";

    @Column()
    approvalToken: string = "";

    @Column({ nullable: true })
    @Type(() => Date)
    approvedDate: Date;

    @Column()
    approvedBy: string = "";

    includes(date: Date, hour: number): boolean {
        let fromHour = Utils.dateEq(date, this.fromDate) ? this.fromHour : -1;
        let toHour = Utils.dateEq(date, this.toDate) ? this.toHour : -1;
        return Utils.dateGeq(date, this.fromDate) && Utils.dateLeq(date, this.toDate) && (fromHour == -1 || fromHour <= hour) && (toHour == -1 || toHour >= hour);
    }

}
