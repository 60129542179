<sm-toolbar *ngIf="isAdmin">
    <p-button styleClass="p-button-warning" icon="fas fa-cog" label="Administration" (onClick)="adminClick()">
    </p-button>
</sm-toolbar>
<p-card header="Bitte wählen Sie eine Klasse aus">
  <div *ngFor="let grade of getExistingGrades()" class="grid">
    <ng-container *ngFor="let schoolClass of schoolClasses">
      <div *ngIf="schoolClass.grade == grade && (isAdmin || schoolClass.hasPermission(userName))" class="col-6 md:col-2 xl:col-1">
        <p-card [styleClass]="'ui-card-shadow sm-card ' + schoolClass.getGuiClass()">
          <a [style.width]="'100%'" class="ui-card-title" routerLink="/enaio-certificates/pupils/{{schoolClass.id}}/{{isHalfYear ? 1 : 0}}"
              routerLinkActive="active">{{schoolClass.getDisplayName()}}</a>
        </p-card>
      </div>
    </ng-container>
    <br>
  </div>
</p-card>
