<div class="main" [class.vertical]="vertical">
    <p-inplace *ngFor="let item of items; index as index" #inplace class="item" (onActivate)="activate($event, inplace)">
        <ng-template pTemplate="display">
            <p-chip [styleClass]="'chip'" [label]="_Utils.stringDef(displayField == null ? item : item[displayField], '-Klicken zum Editieren')" styleClass="mr-2" [removable]="true" (onRemove)="removeItem(item)"></p-chip>
        </ng-template>
        <ng-template pTemplate="content">
            <input #input type="text" value="{{displayField == null ? item : item[displayField]}}" pInputText autofocus
                (keypress)="keyPress($event, item, index, inplace, input)" (focusout)="focusLost(item)" (input)="change($event)">
        </ng-template>
    </p-inplace>
    <p-chip class="mouse-pointer" [styleClass]="'chip'" label="&nbsp;" icon="fas fa-plus-circle" styleClass="mr-2" (click)="addItem()"></p-chip>
</div>
