import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'sm-color-editor',
    templateUrl: './color-editor.component.html',
    styleUrls: ['./color-editor.component.scss']
})
export class ColorEditorComponent implements OnInit {

    _value: string;

    @Output()
    valueChange = new EventEmitter<number>();

    ngOnInit(): void {
    }

    @Input()
    set value(value: number) {
        this._value = Utils.toColorHexString(value);
    }

    colorChanged(value: any): void {
        this._value = value;
        this.valueChange.emit(Utils.toColorNumber(this._value));
    }

    hexChanged(value: any): void {
        this._value = value;
        this.valueChange.emit(Utils.toColorNumber(this._value));
    }
}
