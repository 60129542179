import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { AppTab } from '../app-template/models/app-tab.model';
import { EnaioCertificateService } from '../enaio-certificates/services/enaio-certificate.service';
import { EnaioDocumentsModule } from '../enaio-documents/enaio-documents.module';
import { PrimeModule } from '../prime/prime.module';
import { FeatureModule } from '../sm-base/models/feature.module';
import { FeatureModuleService } from '../sm-base/services/feature-module.service';
import { SmBaseModule } from '../sm-base/sm-base.module';
import { ApplicantFormComponent } from './components/applicant-form/applicant-form.component';
import { EnaioResultListViewComponent } from './components/enaio-result-list-view/enaio-result-list-view.component';
import { EnaioResultListComponent } from './components/enaio-result-list/enaio-result-list.component';
import { OrderFormComponent } from './components/order-form/order-form.component';
import { TravelCostsFormComponent } from './components/travel-costs-form/travel-costs-form.component';
import { TestRoutingModule } from './test-routing.module';
import { TestInvoiceApprovalComponent } from './components/test-invoice-approval/test-invoice-approval.component';
import { TestDemoMainComponent } from './components/test-demo-main/test-demo-main.component';

@NgModule({
  declarations: [
    EnaioResultListComponent,
    EnaioResultListViewComponent,
    ApplicantFormComponent,
    OrderFormComponent,
    TravelCostsFormComponent,
    TestInvoiceApprovalComponent,
    TestDemoMainComponent
  ],
  imports: [
    CommonModule,
    EnaioDocumentsModule,
    PrimeModule,
    SmBaseModule
  ]
})
export class TestModule implements FeatureModule {

    constructor(private featureModuleService: FeatureModuleService, private enaioCertificateService: EnaioCertificateService) {
        featureModuleService.register(this);
    }

    registerSidebar(_: AppTab[]): void {
    }

    async updateSidebarVisibility(_: AppTab[]): Promise<void> {
    }

    getMainRoute(): Route {
        return this.featureModuleService.createModuleRoute("test", () => TestRoutingModule);
    }

}
