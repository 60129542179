import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { AppTemplateModule } from '../app-template/app-template.module';
import { AppMenuItem } from '../app-template/models/app-menu-item.model';
import { AppTab } from '../app-template/models/app-tab.model';
import { PrimeModule } from '../prime/prime.module';
import { FeatureModuleService } from '../sm-base/services/feature-module.service';
import { SmBaseModule } from '../sm-base/sm-base.module';
import { Utils } from '../utils/shared/utils';
import { EnaioFillTemplateComponent } from './components/enaio-fill-template/enaio-fill-template.component';
import { EnaioTemplatesRoutingModule } from './enaio-templates-routing.module';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { UtilsModule } from '../utils/utils.module';
import { EditTemplateFieldsComponent } from './components/edit-fields/edit-fields.component';
import { EditTemplateFieldComponent } from './components/edit-field/edit-field.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    declarations: [
        EnaioFillTemplateComponent,
        EditTemplateFieldsComponent,
        EditTemplateFieldComponent
    ],
    imports: [
        AppTemplateModule,
        CommonModule,
        PrimeModule,
        SmBaseModule,
        UtilsModule,
        EnaioTemplatesRoutingModule,
        PdfJsViewerModule,
        TranslocoModule
    ]
})
export class EnaioTemplatesModule {

    constructor(private featureModuleService: FeatureModuleService) {
        featureModuleService.register(this);
    }

    registerSidebar(model: AppTab[]): void {
        model.push(new AppTab("templates", "Vorlagen", "", 150, [
            Utils.fromPlain(AppMenuItem, { sort: 100, label: "Vorlagen", icon: "", routerLink: ["/enaio-templates", "home"] })
        ]));
    }

    async updateSidebarVisibility(_: AppTab[]): Promise<void> {
    }

    getMainRoute(): Route {
        return this.featureModuleService.createModuleRoute("enaio-templates", () => EnaioTemplatesRoutingModule);
    }
}
