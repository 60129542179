import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Inplace } from 'primeng/inplace';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'inplace-object-list-editor',
    templateUrl: './inplace-object-list-editor.component.html',
    styleUrls: ['./inplace-object-list-editor.component.scss']
})
export class InplaceObjectListEditorComponent implements OnInit {

    _Utils = Utils;

    @Input()
    items: any[];
    @Input()
    flat: boolean;
    @Input()
    vertical: boolean;
    @Input()
    emptyItem: any;
    @Input()
    keyField: string;
    @Input()
    displayField: string;
    @ViewChildren(Inplace)
    private nodes: QueryList<Inplace>;

    private currentNode: Inplace;
    private currentInput: any;

    ngOnInit(): void {
    }

    removeItem(item: any): void {
        let index = this.flat ? this.items.indexOf(item) : this.items.findIndex(item2 => item2.id == item.id);
        if (index >= 0) {
            this.items.splice(index, 1);
        }
    }

    addItem(): void {
        this.items.push(Utils.cloneDeep(this.emptyItem));
    }

    activate(event: any, item: Inplace): void {
        this.currentNode = item;
        for (let node of this.nodes) {
            if (node != item) {
                node.deactivate();
            }
        }
    }

    focusLost(_: Inplace): void {
  //      this.currentNode.deactivate();
    }

    keyPress(event: KeyboardEvent, item: any, index: number, x: Inplace, input: any): void {
        // eslint-disable-next-line deprecation/deprecation
        if (event.keyCode == 13) {
            if (this.flat) {
                this.items[index] = input.value;
            }
            else {
                item[this.displayField] = input.value;
            }
            x.deactivate();
        }
    }

    change(event): void {
        this.currentInput = event.target;
    }
}
