/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { CustomInitializer } from 'src/modules/sm-base/shared/custom-initializer.interface';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne, OneToMany } from 'typeorm';
import { Exam } from './exam.entity';
import { SchoolTypeSettings } from './school-type-settings.entity';

@Entity()
export class ExamType extends SmBaseEntity implements CustomInitializer {

    @ManyToOne(() => SchoolTypeSettings, (o: SchoolTypeSettings) => o.examTypes, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    settings: SchoolTypeSettings;

    @OneToMany(() => Exam, o => o.examType)
    @Type(() => Exam)
    exams: Exam[];

    @Column({default: ""})
    title: string = "";

    customInitializer(): void {
        this.exams = Utils.arrayEnsure(this.exams);
    }

    isInvalidForGrade(grade: number): boolean {
        return grade <= 8 && (this.title == "Klausuren" || this.title == "Leistungskontrollen") || grade >= 9 && (this.title == "kGN" || this.title == "gGN");
    }
}
