import { NgForOf } from '@angular/common';
import { Component } from '@angular/core';
import { GridsterComponent, GridsterConfig, GridsterItem, GridsterItemComponent } from 'angular-gridster2';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';

@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.scss'],
  standalone: true,
  imports: [NgForOf, GridsterComponent, GridsterItemComponent]

})
export class DashboardMainComponent extends ComponentView {

    options: GridsterConfig;
   dashboard: GridsterItem[];


   async initParams(): Promise<boolean> {
    this.options = {
    };

    this.dashboard = [
      {cols: 2, rows: 1, y: 0, x: 0},
      {cols: 2, rows: 2, y: 0, x: 2}
    ];

    return Promise.resolve(true);
  }
}
