import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {

    constructor(private auth: AuthenticationService, private router: Router) {
        GuiUtils.checkSingleton(this);
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.auth.getToken() != null || this.auth.allowAnonymous) {
            return true;
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate(['base', 'login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
    }
}
