import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { EnaioCall } from "./EnaioCall";
import { EnaioChangeEntry } from "./EnaioChangeEntry";

export class EnaioCallGetChangesInTimeFrame extends EnaioCall <EnaioChangeEntry[]> {
    @Type(() => Date)
    beginDate: Date;
    @Type(() => Date)
    endDate: Date;
    getObjectChanges = false;
    getWorkflowChanges = false;
    getLogs = false;
    processIds: string[];
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioChangeEntry, 1);
    }

}
