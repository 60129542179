<div class="flex-row ti">
    <span>
        <ng-content>
        </ng-content>
        {{label}}
    </span>
    <span>
        <span *ngFor="let icon of icons" [class]="icon + ' mr-1'"></span>
    </span>
</div>
