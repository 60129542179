import { Directive, ElementRef, OnInit } from "@angular/core";
import { GuiUtils } from "src/modules/utils/misc/gui-utils";

//TODO mit primeng 15/16 prüfen, ob Problem noch besteht. Dann verwenden, sonst Klasse entfernen
@Directive({
    selector: '[autoFocusColumnFilters]'
})
export class AutoFocusColumnFiltersDirective implements OnInit {
    constructor(private elRef: ElementRef) { }

    ngOnInit(): void {
        // Note: setTimeout is only needed for use-case where user clicks column filter when another one is already open

        new MutationObserver(() => {
            GuiUtils.angularTimer(() => {
                (document.querySelector('p-columnfilterformelement .p-inputtext') as unknown as any)?.focus();
            });
        }).observe(this.elRef.nativeElement as Node, { attributes: false, childList: true, subtree: true });
    }
}
