<div #main class="grid">
    <sm-toolbar class="col-12">
        <p-button icon="fas fa-cog" label="Config-Ordner an Zwischenablage" (onClick)="copyConfigToClipboard()"></p-button>
        <p-button *ngIf="devMode" icon="fas fa-cog" label="Config-Ordner an Dev" (onClick)="copyConfigToDev()"></p-button>
        <p-button *ngIf="devMode" icon="fas fa-cog" label="Update" (onClick)="getUpdate()"></p-button>
    </sm-toolbar>
    <div *ngIf="metaData != null" class="col-4">
        <sm-toolbar class="col-12">
            <p-button *ngIf="waitText == null" class="mr-2" icon="fas fa-cog" label="Update einspielen" (onClick)="runUpdate()"></p-button>
            <p-button class="mr-2" icon="fas fa-times" label="Update abbrechen" (onClick)="cancelUpdate()"></p-button>
            <p-message *ngIf="waitText != null" severity="error" [text]="'Update muss warten: ' + waitText"></p-message>
        </sm-toolbar>
        <p-tree #tree class="full-height-240" selectionMode="checkbox" [value]="changesTree.items" [(selection)]="changesTree.selected" (onNodeSelect)="selectFile($event)" (onNodeUnselect)="selectFile($event)">
        </p-tree>
    </div>
    <app-text-diff *ngIf="metaData != null" class="col-8 full-height-200x" [fileName]="fileName" [left]="selLeftText" [right]="selRightText" leftFileName="(Alt) Auf diesem System" rightFileName="(Neu) Aus dem Update">
    </app-text-diff>
</div>
<app-progressor-overlay [progressor]="progressor.value"></app-progressor-overlay><!---->
