<sm-toolbar>
    <p-button icon="fas fa-save" label="Aktuellen Stand speichern" (onClick)="save()"></p-button>
    <p-button icon="fas fa-archive" label="Fertigstellen und Ablegen" (onClick)="finish()"></p-button>
    <p-button icon="fas fa-file-word" label="Als Word-Dokument herunterladen" (onClick)="saveAsWordDocument()"></p-button>
    <p-button icon="fas fa-file-pdf" label="Als PDF öffnen / Drucken" (onClick)="openPdf()"></p-button>
    <p-button icon="fas fa-undo" label="Felder zurücksetzen" (onClick)="reset()"></p-button>
    <p-button icon="fas fa-cog" label="Felder bearbeiten" (onClick)="editFields()"></p-button>
</sm-toolbar>
<p-splitter *ngIf="!finished" [style]="{'height': 'calc(100vh - 150px)'}" styleClass="mb-5">
    <ng-template pTemplate>
        <p-panel class="fields" header="Felder" [style]="{'width': '100%'}">
            <sm-form class="col-12" [form]="form">
            </sm-form>
        </p-panel>
    </ng-template>
    <ng-template pTemplate>
        <p-panel class="preview" header="Vorschau">
            <div *ngIf="generated != null && generated.missingFields.length > 0">
                Warnung: Die folgenden Felder in der Vorlage konnten nicht gefüllt werden: {{Utils.arrayItemsToString(generated.missingFields)}}
            </div>
            <div style="height: 95%">
                <ng2-pdfjs-viewer #pdfViewer [print]=false></ng2-pdfjs-viewer>
            </div>
        </p-panel>
    </ng-template>
</p-splitter>
<div *ngIf="finished">Das Dokument wurde erfolgreich abgelegt</div>
