import { Type } from "class-transformer";
import { EnaioScriptType } from "./EnaioScriptType";
import { EnaioWorkflowDefinition } from "./EnaioWorkflowDefinition";

export class EnaioWorkflowEventDefinition {
    @Type(() => EnaioWorkflowDefinition)
    parent: EnaioWorkflowDefinition;
    id = "";
    appId = "";
    clientTypeId = "";
    type = 0;
    param = "";
    code = "";
    language = 0;
    scriptType: EnaioScriptType;
}
