import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';
import { CovidTestDay } from '../../shared/covid-test-day.entity';

@Component({
  selector: 'app-covid-tests-analyses',
  templateUrl: './covid-tests-analyses.component.html',
  styleUrls: ['./covid-tests-analyses.component.scss']
})
export class CovidTestsAnalysesComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    isSecretary: boolean;
    covidTestDays: CovidTestDay[];

    async initParams(): Promise<boolean> {
        this.isSecretary = await this.service.isSecretary();

        if (!this.isSecretary) {
            await this.app.messageDialog.info("Sie besitzen nicht die nötigen Rechte für diese Ansicht", "Fehler");
            this.app.navigateBack();
            return false;
        }

       // this.covidTestDays = Utils.arrayReverse(Utils.arraySort(await this.app.loadDataHandler(async () =>
         //   RestEndpoint.main().query({ schoolClassId: this.schoolClassId, isHalfYear: this.isHalfYear }).run("api/cer/covidtestday").list(CovidTestDay), this)));

        return true;

  }

}
