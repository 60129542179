import { Utils } from "src/modules/utils/shared/utils";

export class PasswordRules {
    minLength = 8;
    mustContainLowerChar = false;
    mustContainUpperChar = false;
    mustContainDigitChar = false;
    mustContainSpecialChar = false;

    isSafe(password: string): boolean {
        if (password.length < this.minLength) {
            return false;
        }

        let lowerChars = Utils.getRangeChar("a", "z");
        let upperChars = Utils.getRangeChar("A", "Z");
        let digitChars = Utils.getRangeChar("0", "9");

        let specialChars = new Set(["_", ".", "*", "-", "+", ":", "#", "!", "?", "%", "{", "}", "|", "@", "[", "]", ";", "=", "“", "&", "$", "\\", "/", ",", "(", ")"]);
        let hasLowerChar = false;
        let hasUpperChar = false;
        let hasDigitChar = false;
        let hasSpecialChar = false;

        for (let i = 0; i < password.length; i++) {
            let char = password.slice(i, i + 1);
            if (lowerChars.includes(char)) {
                hasLowerChar = true;
            }
            if (upperChars.includes(char)) {
                hasUpperChar = true;
            }
            if (digitChars.includes(char)) {
                hasDigitChar = true;
            }
            if (specialChars.has(char)) {
                hasSpecialChar = true;
            }
        }

        return (!this.mustContainLowerChar || hasLowerChar) && (!this.mustContainUpperChar || hasUpperChar) && (!this.mustContainDigitChar || hasDigitChar) && (!this.mustContainSpecialChar || hasSpecialChar);
    }

    getRulesText(): string {
        let t1 = this.minLength > 0 ? "mindestens " + this.minLength + " Zeichen lang sein" : "";
        let a2 = Utils.arrayWithoutNull([
            this.mustContainLowerChar ? "einen Kleinbuchstaben" : null,
            this.mustContainUpperChar ? "einen Großbuchstaben" : null,
            this.mustContainDigitChar ? "eine Ziffer" : null,
            this.mustContainSpecialChar ? "ein Sonderzeichen" : null
        ]);
        let t2 = a2.length == 0 ? "" : "mindestens " + (a2.length == 1 ? a2[0] : a2.length == 2 ? a2[0] + " und " + a2[1] : Utils.arrayItemsToString(Utils.arraySubList(a2, 0, a2.length - 2)) + " und " + a2[a2.length - 1]) + " enthalten";
        return t1 == "" && t2 == "" ? "" : "Das Passwort muss " + t1 + (t1 != "" && t2 != "" ? " und " : "") + t2;
    }
}
