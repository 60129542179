import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { SmTimer } from 'src/modules/sm-base/models/sm-timer.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningContentFinalQuestion } from '../../shared/learning-content-final-question.entity';
import { LearningContentParticipant } from '../../shared/learning-content-participant.entity';
import { LearningContent } from '../../shared/learning-content.entity';

@Component({
  selector: 'app-final-questions',
  templateUrl: './final-questions.component.html',
  styleUrls: ['./final-questions.component.scss']
})
export class FinalQuestionsComponent extends ComponentView {

    _Utils = Utils;

    service = inject(EnaioLearnService);

    id: number;

    part: LearningContentParticipant;
    content: LearningContent;
    questions: LearningContentFinalQuestion[];
    answers: string[][];
    givenAnswersSingle: number[];
    givenAnswersMulti: number[][];

    constructor() {
        super();
        this.neededParams = { id: "number" };
    }

    async initParams(): Promise<boolean> {
        this.part = await RestEndpoint.main().findByIdOrEmpty(LearningContentParticipant, this.id).run("api/lrn/learningcontent/personal").get(LearningContentParticipant);
        this.content = this.part.learningContent;
        this.questions = Utils.arraySubList(Utils.arrayShuffle(this.content.finalExamQuestions.filter(q => q.answersValid())), 0, this.content.getFinalExamNumQuestions() - 1);
        this.answers = this.questions.map(q => Utils.arrayShuffle([...q.correctAnswers, ...q.wrongAnswers]));
        this.givenAnswersSingle = this.questions.map(_ => -1);
        this.givenAnswersMulti = this.answers.map(a => new Array(a.length));

        this.addTimer(new SmTimer(60_000, 60_000, () => this.checkActivity()));

        this.service.updateNavigation(false, [
            { label: this.content.title, routerLink: ["/enaio-learn", "attend", this.content.id] },
            { label: "Abschlussprüfung", routerLink: ["/enaio-learn", "final-exam", this.content.id] }]);

        return true;
    }

    checkActivity(): void {

    }

    getScore(): number {
        let goodCount = 0;
        for (let i = 0; i < this.questions.length; i++) {
            let good = false;
            if (this.questions[i].correctAnswers.length == 1) {
                good = this.givenAnswersSingle[i] == this.answers[i].indexOf(this.questions[i].correctAnswers[0]);
            }
            else {
                good = true;
                for (let j = 0; j < this.answers[i].length; j++) {
                    if (this.givenAnswersMulti[i][j] && !this.questions[i].correctAnswers.includes(this.answers[i][j])) {
                        good = false;
                    }
                    if (!this.givenAnswersMulti[i][j] && this.questions[i].correctAnswers.includes(this.answers[i][j])) {
                        good = false;
                    }
                }
            }
            if (good) {
                goodCount++;
            }
        }

        return goodCount * 100 / this.questions.length;
    }

    async send(): Promise<void> {
        if (!await this.app.messageDialog.yesNo(this.app.t("lea.attend.finalExam.sendMessage", {max: this.content.finalExamNumTries}), this.app.t("general.warning"))) {
            return;
        }

        let score = Math.round(this.getScore());
        if (score < this.content.finalExamPassPercentage) {
            await RestEndpoint.main().query({id: this.part.id}).post().run("api/lrn/learningcontentparticipant/finalExamFailed").getText();
            await this.app.messageDialog.info(this.app.t("lea.attend.finalExam.failedMessage", {score, min: this.content.finalExamPassPercentage}), this.app.t("general.info"));
        }
        else {
            await RestEndpoint.main().query({id: this.part.id, score}).post().run("api/lrn/learningcontentparticipant/finalExamSucceeded").getText();
            await this.app.messageDialog.info(this.app.t("lea.attend.finalExam.successMessage", {score}), this.app.t("general.info"));
        }
        await this.app.navigateTo(["/enaio-learn", "attend", this.content.id]);
    }

    async cancel(): Promise<void> {
        if (!await this.app.messageDialog.yesNo(this.app.t("lea.attend.finalExam.cancelMessage"), this.app.t("general.warning"))) {
            return;
        }
        await this.app.navigateTo(["/enaio-learn", "attend", this.content.id]);
    }

}
