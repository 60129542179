import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class TableRow {

    raw: any;
    values: OrdinaryObject;
    style: string;
    styleClass: string;

    constructor(raw: any, values: OrdinaryObject = null, style: string = null, styleClass: string = null) {
        this.raw = raw;
        this.values = values ?? raw;
        this.style = style;
        this.styleClass = styleClass;
    }

}
