<ng-container *ngIf="inited">
    <sm-toolbar>
        <p-button [disabled]="!isChanged()" icon="fas fa-save" label="Speichern" (onClick)="save()">
        </p-button>
    </sm-toolbar>
    <p-tabView>
        <p-tabPanel header="Allgemein">
            <sm-form [form]="form"></sm-form>
        </p-tabPanel>
        <p-tabPanel header="Erlaubte Fächer">
            <sm-table [data]="allowedDomainsTable" [allowSelection]=false [allowExport]=false></sm-table>
        </p-tabPanel>
        <p-tabPanel header="Extra-Fächer">
            <sm-table [data]="specialDomainsTable" [allowInsert]=true [allowDelete]=true [allowSelection]=false [allowExport]=false></sm-table>
        </p-tabPanel>
        <p-tabPanel header="Klassenämter">
            <div class="grid">
                <string-list-editor class="col-12" [items]="settings.dutyNames">
                </string-list-editor>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Belehrungstexte">
            <div class="grid">
                <string-list-editor class="col-12" [items]="settings.instructionTexts">
                </string-list-editor>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Lehrer">
            <sm-table [data]="teacherTable" [allowSelection]=false [allowExport]=false></sm-table>
        </p-tabPanel>
        <p-tabPanel header="Ferien">
            <sm-table [data]="holidayTable" [allowInsert]=true [allowDelete]=true [allowSelection]=false [allowExport]=false></sm-table>
        </p-tabPanel>
        <p-tabPanel header="A/B Wochen">
            <p-message severity="error" text="WICHTIG: Bitte zuerst unter Allgemein Start- und Enddatum des Schuljahres festlegen und speichern. Werden Start- oder Enddatum nachträglich geändert, verliert diese Zuordnung ihre Gültigkeit" styleClass="mb-4"></p-message>
            <sm-table [data]="bWeeksTable" [allowSelection]=false [allowSort]=false [allowExport]=false></sm-table>
        </p-tabPanel>
    </p-tabView>
</ng-container>
