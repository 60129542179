import { AttachedDocumentHandlerProcess } from "src/modules/enaio/shared/AttachedDocumentHandlerProcess";

export class DhRunningInstance {

    guid = "";
    commandLine = "";
    configName = "";

    constructor(process: AttachedDocumentHandlerProcess) {
        this.guid = process.guid;
        this.commandLine = process.commandLine;
        this.configName = process.configName;
    }
}
