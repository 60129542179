/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { Notification } from './notification.entity';

@Entity()
export class NotificationLink extends SmBaseEntity {
    @ManyToOne(() => Notification, o => o.links, { onDelete: "CASCADE", nullable: false})
    notification: Notification;

    @Column({default: ""})
    title: string = "";

    @Column({default: ""})
    url: string = "";

    @Column({default: ""})
    icon: string = "";

    constructor(title = "", url = "", icon = "") {
        super();
        this.title = title;
        this.url = url;
        this.icon = icon;
    }

    getStringRepresentation(): string {
        return this.title;
    }

}
