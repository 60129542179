import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Config } from 'src/modules/app-template/models/config.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { Utils } from 'src/modules/utils/shared/utils';
import { RestEndpoint } from '../models/rest-endpoint.model';
import { BackendResponse } from '../shared/backend-response.model';
import { LoginUser } from '../shared/login-user.model';

@Injectable({providedIn: "root"})
export class AuthenticationService {

    onUserChanged = new Subject<string>();

    allowAnonymous = false;

    getUserDetailsRunning = false;

    private accessToken: string = null;
    private userName: string = null;
    private userDetails: LoginUser = null;

    constructor(private router: Router) {
        GuiUtils.checkSingleton(this);
        this.allowAnonymous = Config.get().allowAnonymous;
    }

    getToken(): string {
        if (this.accessToken == null) {
            let accessToken = localStorage.getItem('accessToken');
            if (!Utils.isNoe(accessToken)) {
                this.accessToken = accessToken;
            }
        }
        return this.accessToken;
    }

    getUserName(): string {
        if (this.userName == null) {
            let userName = localStorage.getItem('userName');
            if (!Utils.isNoe(userName)) {
                this.setUserName(userName);
            }
        }
        return this.userName;
    }

    getEnaioUserId(): string {
        //Temporär
        return "3F8B74FB078A4D4DA938E0822E5DDEC0";
        //   return "52C6CE1BC13D443A9C52FD4B84E3FFB0";
    }

    async clearLogin(returnUrl = true): Promise<void> {
        if (this.allowAnonymous) {
            return;
        }

        this.setToken(null);
        this.setUserName(null);
        if (returnUrl && this.router.url.includes("/base/login")) {
            returnUrl = false;
        }
        await this.router.navigateByUrl("/base/login" + (returnUrl ? "?returnUrl=" + Utils.stringRemovePrefix(this.router.url, "/base/login?returnUrl=") : ""));
    }

    isLoggedIn(): boolean {
        return this.allowAnonymous || !Utils.isNoe(this.userName);
    }

    setToken(accessToken: string): void {
        this.accessToken = accessToken;
        localStorage.setItem('accessToken', accessToken || "");
    }

    setUserName(userName: string, userDetails?: LoginUser): void {
        if (userName == this.userName) {
            return;
        }
        this.userName = userName;
        this.userDetails = userDetails;
        localStorage.setItem('userName', userName || "");
        this.onUserChanged.next(userName);
    }

    async login(userName: string, password: string, _remember: boolean): Promise<number> {
        try {
            let res = await (Config.get().backendV2 ? RestEndpoint.main() : RestEndpoint.main().contentTypeForm()).body({ userName, password }).post().run("api/app/auth/login").get(LoginUser);
            if (!Utils.isNoe(res.access_token)) {
                this.setToken(res.access_token);
                this.setUserName(userName, res);
                return 0;
            }
        }
        catch (ex) {
            let error = Utils.fromPlainUnsafe(BackendResponse, Utils.fromJson(Utils.toString(ex.error)));
            return error.items == null ? 1 : error.items[0].errorId;
        }
        return 1;
    }

    async logout(): Promise<void> {
        await RestEndpoint.main().run("api/app/auth/logout").getText();
        await this.clearLogin(false);
    }

    getUserDetailsPromise: Promise<LoginUser> = null;

    async getUserDetails(): Promise<LoginUser> {
        if (this.allowAnonymous) {
            return new LoginUser("Anonymous", "", null);
        }
        if (this.userDetails == null) {
            if (this.getUserDetailsPromise == null) {
                this.getUserDetailsPromise = RestEndpoint.main().run("api/app/auth/getuserdetails").get(LoginUser).then(res => {
                    this.getUserDetailsPromise = null;
                    this.userDetails = Utils.fromPlain(LoginUser, res);
                    return this.userDetails;
                });
            }
            return this.getUserDetailsPromise;
        }

        return this.userDetails;
    }

    getUserDetailsSync(): LoginUser {
        if (this.allowAnonymous) {
            return new LoginUser("Anonymous", "", null);
        }
        if (this.userDetails == null && this.getUserDetailsPromise == null) {
            this.getUserDetailsPromise = RestEndpoint.main().run("api/app/auth/getuserdetails").get(LoginUser).then(res => {
                this.getUserDetailsPromise = null;
                this.userDetails = Utils.fromPlain(LoginUser, res);
                return this.userDetails;
            });
        }

        return this.userDetails;
    }
}
