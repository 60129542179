import { Type } from "class-transformer";
import { EnaioGuiField } from "./EnaioGuiField";

export class EnaioWorkflowMaskFieldDefinition {
    id = "";
    name = "";
    internalName = "";
    flags = 0;
    flags1 = 0;
    flags2 = 0;
    inputLeft = 0;
    inputTop = 0;
    inputRight = 0;
    inputBottom = 0;
    fieldLeft = 0;
    fieldTop = 0;
    fieldRight = 0;
    fieldBottom = 0;
    tabOrder = 0;
    toolTip = "";
    init = "";
    dataType = "";
    maxLength = 0;
    valuesId = "";
    fieldName = "";
    pageId = "";
    defaultUrl = "";
    regexp = "";
    maskFieldVal = "";
    @Type(() => EnaioGuiField)
    guiField: EnaioGuiField;
}
