import { TemplateFieldSource } from "./template-field-source.enum";

export class TemplateSourceDefinition {

    name = "";
    source: TemplateFieldSource = TemplateFieldSource.document;
    allFields = false;

    constructor(name: string, source: TemplateFieldSource, allFields?: boolean) {
        this.name = name;
        this.source = source;
        this.allFields = allFields;
    }

}
