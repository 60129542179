export enum EnaioGuiControlType {
    pageControl = 0,
    groupBox = 1,
    table = 2,
    image = 3,
    checkBox = 4,
    radioButton = 5,
    datePicker = 6,
    comboBox = 7,
    label = 8,
    button = 9,
    textArea = 10,
    textBox = 11,
    webControl = 12
}
export namespace EnaioGuiControlType{
    export function getName(value: EnaioGuiControlType): string {
        switch (value) {
            case EnaioGuiControlType.pageControl:
                return "pageControl";
            case EnaioGuiControlType.groupBox:
                return "groupBox";
            case EnaioGuiControlType.table:
                return "table";
            case EnaioGuiControlType.image:
                return "image";
            case EnaioGuiControlType.checkBox:
                return "checkBox";
            case EnaioGuiControlType.radioButton:
                return "radioButton";
            case EnaioGuiControlType.datePicker:
                return "datePicker";
            case EnaioGuiControlType.comboBox:
                return "comboBox";
            case EnaioGuiControlType.label:
                return "label";
            case EnaioGuiControlType.button:
                return "button";
            case EnaioGuiControlType.textArea:
                return "textArea";
            case EnaioGuiControlType.textBox:
                return "textBox";
            case EnaioGuiControlType.webControl:
                return "webControl";
        }
    }
}
