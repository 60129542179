/* eslint-disable @typescript-eslint/no-inferrable-types */
import { CustomInitializer } from 'src/modules/sm-base/shared/custom-initializer.interface';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne } from 'typeorm';
import { ClassRegister } from './class-register.entity';

@Entity()
export class ClassDuty extends SmBaseEntity implements CustomInitializer {
    @ManyToOne(() => ClassRegister, (o: ClassRegister) => o.duties, { onDelete: "CASCADE", nullable: false, orphanedRowAction: "delete"})
    classRegister: ClassRegister;

    @Column({default: ""})
    type: string = "";

    @Column({default: 0})
    main: number = 0;

    @Column("int", {array: true, default: []})
    substitutes: number[];

    @Column("text", {default: ""})
    mainText: string = "";

    @Column("text", {default: ""})
    substitutesText: string = "";

    public customInitializer(): void {
        this.substitutes = Utils.arrayEnsure(this.substitutes);
    }
}
