import { Type } from "class-transformer";
import { EnaioFieldIdentification } from "./EnaioFieldIdentification";
import { EnaioObjectType } from "./EnaioObjectType";
import { EnaioOrganisation } from "./EnaioOrganisation";
import { EnaioOrganisationObject } from "./EnaioOrganisationObject";
import { EnaioProcess } from "./EnaioProcess";
import { EnaioUserIdentification } from "./EnaioUserIdentification";
import { EnaioWorkflowFamily } from "./EnaioWorkflowFamily";
import { EnaioWorkflowModel } from "./EnaioWorkflowModel";

export class EnaioIdSearchResult {
    @Type(() => EnaioUserIdentification)
    users: EnaioUserIdentification[];
    @Type(() => EnaioOrganisation)
    organisations: EnaioOrganisation[];
    @Type(() => EnaioOrganisationObject)
    orgObjects: EnaioOrganisationObject[];
    @Type(() => EnaioOrganisationObject)
    orgUsers: EnaioOrganisationObject[];
    @Type(() => EnaioWorkflowModel)
    wfModels: EnaioWorkflowModel[];
    @Type(() => EnaioWorkflowFamily)
    wfFamilies: EnaioWorkflowFamily[];
    @Type(() => EnaioProcess)
    processes: EnaioProcess[];
    @Type(() => EnaioObjectType)
    objectTypes: EnaioObjectType[];
    @Type(() => EnaioFieldIdentification)
    fields: EnaioFieldIdentification[];
}
