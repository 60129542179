import { Injectable } from '@angular/core';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    static instance: TranslationService;

    translation: Promise<OrdinaryObject>;

    constructor() {
        TranslationService.instance = this;
    }

    async loadTranslations(): Promise<OrdinaryObject> {
        this.translation = RestEndpoint.main().run("api/app/translation/texts").getText().then(s => Utils.fromJson(s));
        return this.translation;
    }

}
