/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { CustomInitializer } from 'src/modules/sm-base/shared/custom-initializer.interface';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne, Unique } from 'typeorm';
import { ClassRegisterSettings } from './class-register-settings.entity';
import { ClassRegister } from './class-register.entity';
import { EnaioCertificatesTools } from './enaio-certificates-tools';

@Entity()
@Unique(["classRegister", "weekDay", "hour", "validFrom"])
export class ClassTimetableEntry extends SmBaseEntity implements CustomInitializer {
    @ManyToOne(() => ClassRegister, (o: ClassRegister) => o.timetableEntries, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    classRegister: ClassRegister;

    @Column({default: 0})
    weekDay: number = 0;

    @Column({default: 0})
    hour: number = 0;

    @Column({nullable: true})
    @Type(() => Date)
    validFrom: Date;

    @Column({default: ""})
    domain: string = "";

    @Column({default: ""})
    domainWeekB: string = "";

    @Column({default: ""})
    courseTitle: string = "";

    @Column({default: ""})
    courseTitleWeekB: string = "";

    @Column("int", { array: true, nullable: true })
    courseIds: number[] = [];

    @Column("int", { array: true, nullable: true })
    courseIdsWeekB: number[] = [];

    customInitializer(): void {
        this.courseIds = Utils.arrayEnsure(this.courseIds);
        this.courseIdsWeekB = Utils.arrayEnsure(this.courseIdsWeekB);
    }

    getDomainForWeek(date: Date, settings: ClassRegisterSettings): string {
        return this.getDomainForWeekType(settings.isBWeek(date));
    }

    getDomainForWeekType(isBWeek: boolean): string {
        return isBWeek && !Utils.isNoe(this.domainWeekB) ? this.domainWeekB : this.domain;
    }

    getCourseTitleForWeekType(isBWeek: boolean): string {
        return isBWeek && this.isCourses(isBWeek) && !Utils.isNoe(this.domainWeekB) ? this.courseTitleWeekB : this.courseTitle;
    }

    getCourseIdsForWeekType(isBWeek: boolean): number[] {
        return isBWeek && this.isCourses(isBWeek) && !Utils.isNoe(this.domainWeekB) ? this.courseIdsWeekB : this.courseIds;
    }

    isCourses(isWeek: boolean): boolean {
        return this.getDomainForWeekType(isWeek) == EnaioCertificatesTools.getSpecialDomainCourses();
    }
}
