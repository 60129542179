<div class="dashboard-card-main card-main" [style.background]="_backgroundColor" [style.border]="border" [style.color]="fontColor" [style.height]="'100%'" (click)="click()">
    <div class="dashboard-card-container">
        <i *ngIf="!iconIsFile && icon != null && icon != ''" [class]="'dashboard-card-icon ' + icon" [style.background-color]="iconBackgroundColor" [style.color]="iconColor"></i>
        <i *ngIf="!iconIsFile && icon2 != null && icon2 != ''" [class]="'dashboard-card-icon ' + icon2" [style.background-color]="iconBackgroundColor" [style.color]="iconColor"></i>
        <img *ngIf="iconIsFile && icon != null && icon != ''" [src]="icon" [class]="'dashboard-card-icon'" [style.background-color]="iconBackgroundColor" [style.color]="iconColor">
        <img *ngIf="iconIsFile && icon2 != null && icon2 != ''" [src]="icon" [class]="'dashboard-card-icon'" [style.background-color]="iconBackgroundColor" [style.color]="iconColor">
        <button *ngIf="menuButton" pButton pRipple type="button" class="p-button-rounded" [icon]="'fas fa-ellipsis-v'"></button>
    </div>
    <p class="dashboard-card-title">
        {{title}}
    </p>
    <p *ngIf="additionalText != ''" class="dashboard-card-info" [innerHTML]=additionalText>
    </p>
</div>
