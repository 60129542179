import { Type } from "class-transformer";
import { DatevRechercheType } from "./DatevRechercheType";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class DatevRechercheResult {
    type: DatevRechercheType;
    title = "";
    data: OrdinaryObject;
    @Type(() => Date)
    date: Date;
    documentNumber = "";
    originalDocumentNumber = "";
    amount = 0;
    accountNumber = "";
    contraAccountNumber = "";
    id = "";
}
