import { Type } from "class-transformer";
export class EnaioWorkflowPackageDefinition {
    id = "";
    name = "";
    description = "";
    type = 0;
    creatorId = "";
    @Type(() => Date)
    creationTime: Date;
    procCount = 0;
    versionCount = 0;
    priority = "";
}
