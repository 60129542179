import { Type } from "class-transformer";
import { EnaioOrganisationObject } from "./EnaioOrganisationObject";
import { EnaioUserIdentification } from "./EnaioUserIdentification";

export class EnaioRunningActivity {
    id: string;
    activityId: string;
    processId: string;
    state = 0;
    subState = 0;
    loopCounter = 0;
    activityNumber = 0;
    osType = 0;
    creatorId: string;
    @Type(() => Date)
    creationTime: Date;
    reminderPeriod = 0;
    reminderState = 0;
    @Type(() => Date)
    endTime: Date;
    @Type(() => Date)
    wakeUpTime: Date;
    @Type(() => Date)
    startTime: Date;
    rLoopId: string;
    loopState = 0;
    lastCheckpoint = 0;
    targetState = 0;
    suspendStateBefore = 0;
    suspendStateAfter = 0;
    name: string;
    numEscalations = 0;
    containerId: string;
    mainAdhocId: string;
    @Type(() => EnaioUserIdentification)
    personalisedBy: EnaioUserIdentification;
    @Type(() => EnaioUserIdentification)
    inboxUsers: EnaioUserIdentification[];
    @Type(() => EnaioOrganisationObject)
    inboxRoles: EnaioOrganisationObject[];
    activityName: string;
}
