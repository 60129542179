export enum EnaioAccessType {
    openExec = 0,
    delete = 1,
    writeMetaData = 2,
    readMetaData = 3,
    update = 4
}
export namespace EnaioAccessType{
    export function getName(value: EnaioAccessType): string {
        switch (value) {
            case EnaioAccessType.openExec:
                return "openExec";
            case EnaioAccessType.delete:
                return "delete";
            case EnaioAccessType.writeMetaData:
                return "writeMetaData";
            case EnaioAccessType.readMetaData:
                return "readMetaData";
            case EnaioAccessType.update:
                return "update";
        }
    }
}
