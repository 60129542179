<div #content [appDisable]="uiBlocked">
    <div class="grid">
        <sm-toolbar class="col-12">
            <span>
                <span class="field-label">Textsuche:</span>
                <input pInputText class="toolbar-element" [(ngModel)]="filterText" (ngModelChange)="filterChanged()">
            </span>
            <span>
                <span class="field-label2">Zeitbereich:</span>
                <ng-container *ngIf="monthOnly">
                    <p-calendar dateFormat="MM yy" [(ngModel)]="filterFromDate" view="month" [yearNavigator]="true" yearRange="1950:2030" [readonlyInput]="true"  (ngModelChange)="filterChanged()"></p-calendar>
                    <span class="field-label2">-</span>
                    <p-calendar dateFormat="MM yy" [(ngModel)]="filterToDate" view="month" [yearNavigator]="true" yearRange="1950:2030" [readonlyInput]="true" (ngModelChange)="filterChanged()"></p-calendar>
                </ng-container>
                <ng-container *ngIf="!monthOnly">
                    <p-calendar dateFormat="dd.mm.yy" [(ngModel)]="filterFromDate" (ngModelChange)="filterChanged()"></p-calendar>
                    <span class="field-label2">-</span>
                    <p-calendar dateFormat="dd.mm.yy" [(ngModel)]="filterToDate" (ngModelChange)="filterChanged()"></p-calendar>
                </ng-container>
            </span>
            <p-button class="toolbar-element field-label2" icon="fas fa-cloud-download-alt" label="Alle angezeigten Dokumente herunterladen" (onClick)="downloadAll()">
            </p-button>
        </sm-toolbar>
    </div>
    <div class="grid">
        <p-card *ngFor="let month of sortedMonths" class="col-12" [header]="_Utils.getMonthName(_Utils.toNumber(_Utils.getSubstring(month, 4, 6))) + ' ' + _Utils.getSubstring(month, 0, 4)">
            <div class="grid">
                <p-card *ngFor="let document of byMonths[month]" class="xl:col-2 lg:col-3 md:col-6 sm:col-12">
                    <ng-template pTemplate="header">
                        <span style="margin: 5px" [innerHTML]="document.title | keepHtml"></span>
                    </ng-template>
                    <div style="position: relative">
                        <img class="mouse-pointer payroll-document" [src]="document.getIconPath()" (click)="openDocument(document)">
                        <button pButton pRipple type="button" icon="fas fa-cloud-download-alt" class="p-button-rounded" style="position:absolute; bottom: 0px; right: 0px" (click)="download(document)"></button>
                    </div>
                </p-card>
            </div>
        </p-card>
    </div>
    <p-dialog header="Vorschau" [(visible)]="displayModal" [modal]="true" [style]="{width: '80%', height: '80%'}" [contentStyle]="{width: '100%', height: '100%'}" [baseZIndex]="10000"
        [draggable]="true" [resizable]="true">
        <div style="width: 100%; height: 100%">
            <ng2-pdfjs-viewer #viewer ></ng2-pdfjs-viewer>
        </div>
    </p-dialog>
</div>
