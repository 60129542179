/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Column, Entity, ManyToOne } from "typeorm";
import { LearningContentAttachmentType } from "./learning-content-attachment-type.enum";
import { LearningContentChapter } from "./learning-content-chapter.entity";

@Entity()
export class LearningContentAttachment extends SmBaseEntity {
    @ManyToOne(() => LearningContentChapter, (o: LearningContentChapter) => o.citations, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    chapter: LearningContentChapter;

    @Column({default: LearningContentAttachmentType.other})
    type: LearningContentAttachmentType = LearningContentAttachmentType.other;

    @Column({default: ""})
    title: string = "";

    @Column({default: ""})
    fileName: string = "";

    getIcon(): string {
        return this.type == LearningContentAttachmentType.pdf ? "file-pdf" : this.type == LearningContentAttachmentType.audio ? "file-audio" : "file";
    }

    calculateType(): void {
        let lc = this.fileName.toLowerCase();
        this.type = lc.endsWith(".pdf") ? LearningContentAttachmentType.pdf : lc.endsWith(".wav") || lc.endsWith(".mp3") ? LearningContentAttachmentType.audio : LearningContentAttachmentType.other;
    }

}

