import { Type } from "class-transformer";
import { EnaioClientType } from "./EnaioClientType";
import { EnaioEventType } from "./EnaioEventType";
import { EnaioObjectId } from "./EnaioObjectId";
import { EnaioWorkflowActivityDefinition } from "./EnaioWorkflowActivityDefinition";
import { EnaioWorkflowApplicationDefinition } from "./EnaioWorkflowApplicationDefinition";
import { EnaioWorkflowDataField } from "./EnaioWorkflowDataField";
import { EnaioWorkflowDataType } from "./EnaioWorkflowDataType";
import { EnaioWorkflowEventDefinition } from "./EnaioWorkflowEventDefinition";
import { EnaioWorkflowMaskDefinition } from "./EnaioWorkflowMaskDefinition";
import { EnaioWorkflowModel } from "./EnaioWorkflowModel";
import { EnaioWorkflowModelParticipant } from "./EnaioWorkflowModelParticipant";
import { EnaioWorkflowPackageDefinition } from "./EnaioWorkflowPackageDefinition";

export class EnaioWorkflowDefinition {
    id = "";
    organisationId = "";
    organisationName = "";
    responsibleIds: string[];
    @Type(() => EnaioWorkflowModelParticipant)
    participants: EnaioWorkflowModelParticipant[];
    fileResponsibleId = "";
    @Type(() => EnaioObjectId)
    allowedObjectTypes: EnaioObjectId[];
    @Type(() => EnaioWorkflowPackageDefinition)
    groupPackage: EnaioWorkflowPackageDefinition;
    @Type(() => EnaioWorkflowPackageDefinition)
    familyPackage: EnaioWorkflowPackageDefinition;
    @Type(() => EnaioEventType)
    eventTypes: EnaioEventType[];
    @Type(() => EnaioWorkflowDataField)
    dataFields: EnaioWorkflowDataField[];
    @Type(() => EnaioWorkflowDataType)
    dataTypes: EnaioWorkflowDataType[];
    @Type(() => EnaioWorkflowActivityDefinition)
    activities: EnaioWorkflowActivityDefinition[];
    @Type(() => EnaioWorkflowApplicationDefinition)
    applications: EnaioWorkflowApplicationDefinition[];
    @Type(() => EnaioWorkflowMaskDefinition)
    masks: EnaioWorkflowMaskDefinition[];
    @Type(() => EnaioWorkflowEventDefinition)
    events: EnaioWorkflowEventDefinition[];
    @Type(() => EnaioClientType)
    clientTypes: EnaioClientType[];
    clientTypesAll = false;
    @Type(() => EnaioWorkflowModel)
    model: EnaioWorkflowModel;
    StartableBy: string[];
}
