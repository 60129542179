import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';

@Component({
    selector: 'app-app-starter',
    templateUrl: './app-starter.component.html',
    styleUrls: ['./app-starter.component.scss']
  })
 export class AppStarterComponent extends ComponentView {

    _Utils = Utils;

    appNames: OrdinaryObject<string[]>;

    async initParams(): Promise<boolean> {
        this.appNames = Utils.arrayToMultiMap(await DhTools.backendCall("api/tools/getAppStarters").listStrings(), s => Utils.getSubstringFrom(s, "|"), s => Utils.getSubstringTo(s, "|"));
        return Promise.resolve(true);
    }

    async start(appName: string): Promise<void> {
        await DhTools.backendCall("api/tools/startApp", { appName }).getString();
    }

}
