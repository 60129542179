import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MainAppService } from '../../services/main-app.service';
import { AppMainComponent } from '../app-main/app-main.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopbarComponent {

    constructor(public app: MainAppService, public appComponent: AppMainComponent) {
    }

    getUserName(): string {
        return this.app.getUserName();
    }

    getUserNameShort(): string {
        let name = this.getUserName();
        return name != null && name.length > 0 ? name.substring(0, 1) : "?";
    }

    async logout(): Promise<void> {
        await this.app.auth.logout();
    }

    buttonClick(button: MenuItem): void {
        if (button.command != null) {
            button.command(null);
        }
    }
}
