import { AppMenuItem } from "./app-menu-item.model";

export class AppTab {

    visible = false;
    iconIsFile = false;

    constructor(public id: string, public label: string, public icon: string, public sort: number, public menuItems: AppMenuItem[], public routerLink: string[] = null, visible?: boolean, iconIsFile?: boolean) {
        this.visible = visible;
        this.iconIsFile = iconIsFile;
    }
}
