import { Type } from "class-transformer";
import { FrontendWorkflowInboxColumnDefinition } from "./frontend-workflow-inbox-column-definition.model";

export class FrontendWorkflowInboxDefinition {

    title: string;
    @Type(() => FrontendWorkflowInboxColumnDefinition)
    columns: FrontendWorkflowInboxColumnDefinition[];
    includeItems: string[];

}
