export enum EnaioWorkflowDefinitionSelectData {
    dataFields = 0,
    clientTypes = 1,
    rest = 2
}
export namespace EnaioWorkflowDefinitionSelectData{
    export function getName(value: EnaioWorkflowDefinitionSelectData): string {
        switch (value) {
            case EnaioWorkflowDefinitionSelectData.dataFields:
                return "dataFields";
            case EnaioWorkflowDefinitionSelectData.clientTypes:
                return "clientTypes";
            case EnaioWorkflowDefinitionSelectData.rest:
                return "rest";
        }
    }
}
