import { Type } from "class-transformer";
export class ErrorLogEntry {
    id: string;
    raw: string;
    @Type(() => Date)
    date: Date;
    handlerConfig: string;
    workerName: string;
    objectName: string;
    objectIndex: number = null;
    exceptionType: string;
    errorMessage: string;
    errorGroup: string;
}
