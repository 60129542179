import { Type } from "class-transformer";
import { EnaioObjectId } from "./EnaioObjectId";

export class EnaioFieldChange {
    @Type(() => EnaioObjectId)
    field: EnaioObjectId;
    tableRowIndex = -1;
    @Type(() => EnaioObjectId)
    tableColumn: EnaioObjectId;
    oldValue: any;
    newValue: any;
    remark: string;
}
