export enum EnaioProcessState {
    unknown = 0,
    initialising = 1,
    running = 2,
    stopped = 3,
    finished = 6,
    error = 7
}
export namespace EnaioProcessState{
    export function getName(value: EnaioProcessState): string {
        switch (value) {
            case EnaioProcessState.unknown:
                return "unknown";
            case EnaioProcessState.initialising:
                return "initialising";
            case EnaioProcessState.running:
                return "running";
            case EnaioProcessState.stopped:
                return "stopped";
            case EnaioProcessState.finished:
                return "finished";
            case EnaioProcessState.error:
                return "error";
        }
    }
}
