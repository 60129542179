<div class="grid">
    <sm-toolbar class="col-12">
        <p-button icon="fas fa-save" label="Speichern" (onClick)="saveInternal()">
        </p-button>
        <p-button icon="fas fa-times" label="Abbrechen" (onClick)="cancel()">
        </p-button>
    </sm-toolbar>
    <p-card class="col-12">
        <sm-form [form]="form">
        </sm-form>
    </p-card>
</div>
