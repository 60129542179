/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { SchoolTypeSettings } from './school-type-settings.entity';

@Entity()
export class DomainHoursPerWeek extends SmBaseEntity {

    @ManyToOne(() => SchoolTypeSettings, (o: SchoolTypeSettings) => o.domainHoursPerWeek, { onDelete: "CASCADE", nullable: true, orphanedRowAction: 'delete'})
    settings: SchoolTypeSettings;

    @Column({default: ""})
    domain: string = "";

    @Column({default: 0})
    grade: number = 0;

    @Column({default: 0})
    count: number = 0;

    constructor(domain: string = "", grade: number = 0) {
        super();
        this.domain = domain;
        this.grade = grade;
    }

}
