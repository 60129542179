import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { Utils } from 'src/modules/utils/shared/utils';
import { LearningContentFinalQuestion } from '../../shared/learning-content-final-question.entity';
import { LearningContentQuestion } from '../../shared/learning-content-question.entity';

@Component({
    selector: 'app-questions',
    templateUrl: './questions.component.html',
    styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

    _chapterQuestions: (LearningContentQuestion | LearningContentFinalQuestion)[];

    chapterAnswers: string[][];
    answer: number = null;
    answerCorrect: boolean = null;
    answers: boolean[] = [];
    incorrectAnswersGiven: number[] = [];
    correctAnswers: boolean[] = [];
    numTries = 0;
    nextQuestionTimeoutId = 0;

    _currentQuestion = -1;
    @Output()
    currentQuestion = new EventEmitter<number>();
    @Output()
    done = new EventEmitter<void>();
    @Output()
    smileyStatus = new EventEmitter<number>();

    constructor(private app: MainAppService) {
    }

    ngOnInit(): void {
    }

    @Input()
    set chapterQuestions(value: (LearningContentQuestion | LearningContentFinalQuestion)[]) {
        this._chapterQuestions = value;
        this.chapterAnswers = this._chapterQuestions.map(q => Utils.arrayShuffle([...q.correctAnswers, ...q.wrongAnswers]));
        this._currentQuestion = value.length > 0 ? 0 : -1;
        this.currentQuestion.emit(this._currentQuestion);
        this.answers = this.chapterAnswers.length == 0 ? [] : Utils.getArrayOfConstant(false, this.chapterAnswers[this._currentQuestion].length);
        this.answerCorrect = null;
    }

    finishQuestion(): void {
        if (this.nextQuestionTimeoutId > 0) {
            clearTimeout(this.nextQuestionTimeoutId);
        }
        let good = false;
        if (this._chapterQuestions[this._currentQuestion].correctAnswers.length == 1) {
            good = this.answer == this.chapterAnswers[this._currentQuestion].indexOf(this._chapterQuestions[this._currentQuestion].correctAnswers[0]);
        }
        else {
            good = true;
            for (let i = 0; i < this.answers.length; i++) {
                if (this.answers[i] && !this._chapterQuestions[this._currentQuestion].correctAnswers.includes(this.chapterAnswers[this._currentQuestion][i])) {
                    good = false;
                }
                if (!this.answers[i] && this._chapterQuestions[this._currentQuestion].correctAnswers.includes(this.chapterAnswers[this._currentQuestion][i])) {
                    good = false;
                }
            }
        }

        this.numTries++;
        if (good) {
            this.nextQuestion(true);
        }
        else {
            this.answerCorrect = false;
            this.smileyStatus?.emit(2);

            if (this.numTries >= 2) {
                this.correctAnswers = this.chapterAnswers[this._currentQuestion].map(a => this._chapterQuestions[this._currentQuestion].correctAnswers.includes(a));
                if (this.nextQuestionTimeoutId > 0) {
                    clearTimeout(this.nextQuestionTimeoutId);
                }
                this.nextQuestionTimeoutId = Utils.setTimerOnce(2000, () => {
                    this.nextQuestion(false);
                });
            }

            this.incorrectAnswersGiven = this._chapterQuestions[this._currentQuestion].correctAnswers.length == 1 ? [this.answer] : Utils.arrayWithoutNull(Utils.arrayMapWithIndex(this.answers, (selected, index) => selected ? index : null));
        }
    }

    nextQuestion(smiley: boolean): void {
        this._currentQuestion++;
        this.currentQuestion.emit(this._currentQuestion);
        this.numTries = 0;
        this.correctAnswers = null;
        this.answerCorrect = null;
        if (smiley) {
            this.smileyStatus?.emit(1);
        }
        this.answer = null;
        this.answers = this.chapterAnswers.length == 0 || this._currentQuestion >= this._chapterQuestions.length ? [] : Utils.getArrayOfConstant(false, this.chapterAnswers[this._currentQuestion].length);
        if (this._currentQuestion >= this._chapterQuestions.length) {
            this.app.showToast("success", this.app.t("lea.attend.messages.answersOk_title"), this.app.t("lea.attend.messages.answersOk"));
            this.done.emit(null);
        }
    }

}
