<div *ngIf="type == 1" class="grid">
    <div class="col-6">
        <div class="mt-3 mb-3">
            <sm-form [form]="formFilter">

            </sm-form>
        </div>
        <p-treeTable [value]="tree" class="mt-5 mb-5 p-datatable-striped" selectionMode="checkbox">
            <ng-template pTemplate="caption">
                <div class="flex">
                    <p-treeTableHeaderCheckbox></p-treeTableHeaderCheckbox>
                    <span class="ml-2">Alle</span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>Kreditor/Debitor</th>
                    <th>Rechnungsnummer</th>
                    <th>Datum</th>
                    <th>Betrag</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode">
                    <td>
                        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                        <p-treeTableCheckbox [value]="rowNode"></p-treeTableCheckbox>
                        {{rowData[0]}}
                    </td>
                    <td>{{rowData[1]}}</td>
                    <td>{{rowData[2]}}</td>
                    <td>{{rowData[3]}}</td>

                </tr>
            </ng-template>
        </p-treeTable>
        <div class="mt-5">
            <sm-form [form]="form">

            </sm-form>
            <sm-toolbar>
                <p-button styleClass="p-button-warning" icon="fas fa-check" label="3 Rechnungen freigeben">
                </p-button>
                <p-button styleClass="p-button-danger" icon="fas fa-times" label="3 Rechnungen ablehnen">
                </p-button>
            </sm-toolbar>
        </div>

    </div>
    <enaio-document-file-preview class="col-6 full-height-100x" [documentId]="548837"></enaio-document-file-preview>
</div>
<div *ngIf="type == 2" class="grid d1 mt-3 ml-3 mr-3">
    <div class="grid col-12">
        <div class="col-2">
           <div><b>Kreditor/Debitor:</b></div>
           <div>Test-Spedition</div>
        </div>
        <div class="col-2">
            <div><b>Rechnungsdatum:</b></div>
            <div>07.11.2023</div>
         </div>
         <div class="col-2">
            <div><b>Leistungsdatum:</b></div>
            <div>5.11.2023</div>
         </div>
         <div class="col-2">
            <div><b>Rechnungsnummer:</b></div>
            <div>5468464325153</div>
         </div>
         <div class="col-2">
            <div><b>Betrag:</b></div>
            <div>970,70</div>
         </div>
         <div class="col-2">
            <div><b>Sachkonto:</b></div>
            <div>5450</div>
         </div>
    </div>
    <div class="col-12">
        <div class="mt-5">
            <sm-toolbar>
                <input pInputText class="width300">
                <p-button styleClass="p-button-warning" icon="fas fa-check" label="Rechnung freigeben">
                </p-button>
                <p-button styleClass="p-button-danger" icon="fas fa-times" label="Rechnung ablehnen">
                </p-button>
            </sm-toolbar>
        </div>
    </div>
    <enaio-document-file-preview class="col-12 full-height-300x" [documentId]="548837"></enaio-document-file-preview>
</div>
