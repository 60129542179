import { Type } from "class-transformer";
import { HandlerConfigMetrics } from "./HandlerConfigMetrics";

export class SingleRunMetrics {
    commandLine: string;
    processId: number = null;
    @Type(() => Date)
    startDate: Date;
    @Type(() => HandlerConfigMetrics)
    configs: HandlerConfigMetrics[];
}
