import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';

@Component({
    selector: 'app-sm-switch',
    templateUrl: './sm-switch.component.html',
    styleUrls: ['./sm-switch.component.scss']
  })
  export class SmSwitchComponent {

    @Input()
    textFalse = "";
    @Input()
    textTrue = "";
    @Input()
    value = true;
    @Output()
    valueChange = new EventEmitter<boolean>();

    constructor(private app: MainAppService) {
    }

    switch(): void {
        this.valueChange.emit(this.value);
    }

}
