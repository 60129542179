/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";
import { CustomInitializer } from "src/modules/sm-base/shared/custom-initializer.interface";
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Comparable } from "src/modules/utils/shared/comparable";
import { Utils } from "src/modules/utils/shared/utils";
import { Column, Entity, ManyToOne, OneToMany } from "typeorm";
import { LearningContentAttachment } from "./learning-content-attachment.entity";
import { LearningContentCitation } from "./learning-content-citation.entity";
import { LearningContentQuestion } from "./learning-content-question.entity";
import { LearningContent } from "./learning-content.entity";

@Entity()
export class LearningContentChapter extends SmBaseEntity implements CustomInitializer, Comparable<LearningContentChapter> {
    @ManyToOne(() => LearningContent, (o: LearningContent) => o.chapters, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    learningContent: LearningContent;

    @Column({ default: 0})
    index: number = 0;

    @Column({default: ""})
    title: string = "";

    @Column({default: ""})
    text: string = "";

    @OneToMany(() => LearningContentQuestion, o => o.chapter, { cascade: true })
    @Type(() => LearningContentQuestion)
    questions: LearningContentQuestion[];

    @OneToMany(() => LearningContentCitation, o => o.chapter, { cascade: true })
    @Type(() => LearningContentCitation)
    citations: LearningContentCitation[];

    @OneToMany(() => LearningContentAttachment, o => o.chapter, { cascade: true })
    @Type(() => LearningContentAttachment)
    attachments: LearningContentAttachment[];

    @Column({default: 0})
    numQuestions: number = 0;

    customInitializer(): void {
        this.questions = Utils.arrayEnsure(this.questions);
        this.questions.forEach(q => q.customInitializer());
        this.citations = Utils.arrayEnsure(this.citations);
        this.citations.forEach(c => c.customInitializer());
        this.attachments = Utils.arrayEnsure(this.attachments);
    }

    compareTo(other: LearningContentChapter): number {
        return Utils.cmp(this.index, other.index);
    }

    getNumQuestions(): number {
        return this.numQuestions > 0 ? this.numQuestions : this.questions.length;
    }
}
