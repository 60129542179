import { Type } from "class-transformer";
import { EnaioWorkflowDataType } from "./EnaioWorkflowDataType";

export class EnaioWorkflowDataField {
    name = "";
    id = "";
    @Type(() => EnaioWorkflowDataType)
    dataType: EnaioWorkflowDataType;
    declaredTypeId = "";
    initialValue = "";
    length = 0;
    description = "";
}
