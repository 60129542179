import { Comparable } from '../../utils/shared/comparable';
import { Utils } from '../../utils/shared/utils';
import { ClassRegisterSettings } from './class-register-settings.entity';
import { SchoolType } from './school-type.enum';

export class Teacher implements Comparable<Teacher> {

    constructor(public userName: string, public fullName: string) {
    }

    compareTo(other: Teacher): number {
        return Utils.cmp(this.fullName, other.fullName);
    }

    getDisplayName(): string {
        return !Utils.isNoe(this.fullName) ? this.fullName : this.userName;
    }

    isFor(schoolType: SchoolType, settings: ClassRegisterSettings): boolean {
        let ta = settings.teacher.find(t => t.userName == this.userName);
        return ta?.isForSchoolType(schoolType);
    }

}
