import { Type } from "class-transformer";
import { EnaioGuiField } from "./EnaioGuiField";
import { EnaioObjectType } from "./EnaioObjectType";

export class EnaioFieldIdentification {
    @Type(() => EnaioObjectType)
    objectType: EnaioObjectType;
    @Type(() => EnaioGuiField)
    field: EnaioGuiField;
}
