<p-table *ngIf="inited" [value]="pupils" [styleClass]="'p-datatable-gridlines'" [frozenValue]="[{}]">
    <ng-template pTemplate="header">
      <tr>
        <th class="align-left">Nachname</th>
        <th class="align-left">Vorname</th>
        <th *ngFor="let month of monthNames"><div class="center">{{month}}</div></th>
        <th><div class="center">Gesamt</div></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-pupil let-index="rowIndex">
      <tr [pSelectableRow]="pupil">
        <td>{{pupil.lastName}}</td>
        <td>{{pupil.firstName}}</td>
        <td *ngFor="let month of monthNames; index as monthIndex" [pTooltip]="toolTips[index][monthIndex]">
            <div class="center" [class.excused]="sum[index][monthIndex][0] > 0 || sum[index][monthIndex][2] > 0">{{sum[index][monthIndex][0] > 0 || sum[index][monthIndex][2] ? ((sum[index][monthIndex][0] > 0 ? sum[index][monthIndex][0] : '-') + '/' + (sum[index][monthIndex][2] > 0 ? sum[index][monthIndex][2] : '-')): '-'}}</div>
            <div class="center" [class.unexcused]="sum[index][monthIndex][1] > 0 || sum[index][monthIndex][3] > 0">{{sum[index][monthIndex][1] > 0 || sum[index][monthIndex][3] ? ((sum[index][monthIndex][1] > 0 ? sum[index][monthIndex][1] : '-') + '/' + (sum[index][monthIndex][3] > 0 ? sum[index][monthIndex][3] : '-')): '-'}}</div>

        </td>
        <td>
          <div class="center" [class.excused]="total[index][0] > 0 || total[index][2] > 0">{{total[index][0] > 0 || total[index][2] ? ((total[index][0] > 0 ? total[index][0] : '-') + '/' + (total[index][2] > 0 ? total[index][2] : '-')): '-'}}</div>
          <div class="center" [class.unexcused]="total[index][1] > 0 || total[index][3] > 0">{{total[index][1] > 0 || total[index][3] ? ((total[index][1] > 0 ? total[index][1] : '-') + '/' + (total[index][3] > 0 ? total[index][3] : '-')): '-'}}</div>
        </td>
    </tr>
    </ng-template>
  </p-table>
