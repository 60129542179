export enum EnaioDbDataType {
    string = 0,
    stringLetters = 1,
    stringUpper = 2,
    stringDigits = 3,
    bool = 4,
    radioChoice = 5,
    number = 6,
    decimal = 7,
    date = 8,
    dateTime = 9,
    time = 10,
    userRights = 11,
    table = 12,
    visualButton = 13,
    visualGroupBox = 14,
    visualImage = 15,
    visualLabel = 16,
    visualPageControl = 17,
    visualWebControl = 18
}
export namespace EnaioDbDataType{
    export function getName(value: EnaioDbDataType): string {
        switch (value) {
            case EnaioDbDataType.string:
                return "string";
            case EnaioDbDataType.stringLetters:
                return "stringLetters";
            case EnaioDbDataType.stringUpper:
                return "stringUpper";
            case EnaioDbDataType.stringDigits:
                return "stringDigits";
            case EnaioDbDataType.bool:
                return "bool";
            case EnaioDbDataType.radioChoice:
                return "radioChoice";
            case EnaioDbDataType.number:
                return "number";
            case EnaioDbDataType.decimal:
                return "decimal";
            case EnaioDbDataType.date:
                return "date";
            case EnaioDbDataType.dateTime:
                return "dateTime";
            case EnaioDbDataType.time:
                return "time";
            case EnaioDbDataType.userRights:
                return "userRights";
            case EnaioDbDataType.table:
                return "table";
            case EnaioDbDataType.visualButton:
                return "visualButton";
            case EnaioDbDataType.visualGroupBox:
                return "visualGroupBox";
            case EnaioDbDataType.visualImage:
                return "visualImage";
            case EnaioDbDataType.visualLabel:
                return "visualLabel";
            case EnaioDbDataType.visualPageControl:
                return "visualPageControl";
            case EnaioDbDataType.visualWebControl:
                return "visualWebControl";
        }
    }
}
