import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class GeneralObjectKey {
    type: string;
    key: OrdinaryObject;

    constructor(type: string, key: OrdinaryObject) {
        this.type = type;
        this.key = key;
    }


}
