import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { EnaioCertificateService } from '../../services/enaio-certificate.service';

@Component({
  selector: 'app-class-analyses',
  templateUrl: './class-analyses.component.html',
  styleUrls: ['./class-analyses.component.scss']
})
export class ClassAnalysesComponent extends ComponentView {

    service = inject(EnaioCertificateService);

    id: number;

    constructor() {
        super();
        this.neededParams = {id: "number"};
    }

    override async initParams(): Promise<boolean> {
        await this.service.updateNavigationClassRegister(this.id, [{ label: "Auswertungen", routerLink: ["/enaio-certificates", "class-registers", "analyses", this.id] }]);
        return true;
    }

}
