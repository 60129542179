import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnonymousSearchComponent } from './components/anonymous-search/anonymous-search.component';
import { CompletePersonellDataComponent } from './components/complete-personell-data/complete-personell-data.component';
import { EnaioDocumentsDefinitionComponent } from './components/enaio-documents-definition/enaio-documents-definition.component';
import { EnaioDocumentsMainComponent } from './components/enaio-documents-main/enaio-documents-main.component';

const routes: Routes = [
    {
        path: 'home',
        component: EnaioDocumentsMainComponent
    },
    {
        path: 'definition/:defId',
        redirectTo: 'definition/:defId/0',
        pathMatch: 'full'
    },
    {
        path: 'definition/:defId/:parentId',
        component: EnaioDocumentsDefinitionComponent
    },
    {
        path: 'personell/complete_data/:id',
        component: CompletePersonellDataComponent
    },
    {
        path: 'as/:defId',
        component: AnonymousSearchComponent
    },
    {
        path: 'as/:defId/:searchString',
        component: AnonymousSearchComponent
    },
    {
        path: 'definition/as/:defId/:anonymousGuid',
        component: EnaioDocumentsDefinitionComponent
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EnaioDocumentsRoutingModule {
}
