import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigChangerMainComponent } from './components/config-changer-main/config-changer-main.component';
import { ConfigChangerConfigComponent } from './components/config-changer-config/config-changer-config.component';
import { FeatureModuleService } from '../sm-base/services/feature-module.service';
import { AppTab } from '../app-template/models/app-tab.model';
import { Route } from '@angular/router';
import { ConfigChangerRoutingModule } from './config-changer-routing.module';
import { FeatureModule } from '../sm-base/models/feature.module';
import { UtilsModule } from '../utils/utils.module';
import { SmBaseModule } from '../sm-base/sm-base.module';
import { PrimeModule } from '../prime/prime.module';

@NgModule({
  declarations: [
    ConfigChangerMainComponent,
    ConfigChangerConfigComponent
  ],
  imports: [
    CommonModule,
    SmBaseModule,
    UtilsModule,
    PrimeModule
  ]
})
export class ConfigChangerModule implements FeatureModule {

    constructor(private featureModuleService: FeatureModuleService) {
        featureModuleService.register(this);
    }

    registerSidebar(_: AppTab[]): void {
    }

    async updateSidebarVisibility(_: AppTab[]): Promise<void> {
    }

    getMainRoute(): Route {
        return this.featureModuleService.createModuleRoute("config-changer", () => ConfigChangerRoutingModule);
    }
}
