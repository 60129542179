<div class="grid">
    <p-message class="col-12" severity="info" text="Bitte in den DevTools des Browsers einen Breakpoint setzen und bei Erreichen des Breakpoints hier einen Variablennamen eingeben und auf 'Eingabe auswerten' klicken" [escape]=false></p-message>
    <div class="grid">
     <div class="col-12 p-inputgroup">
        <input #evaluateTextComponent pInputText type="text" name="evaluateText" [(ngModel)]="evaluateText" (keypress)="keyPress($event)"/>
        <button pButton icon="fas fa-check" label="Eingabe auswerten" (click)="evaluateInput()"></button>
    </div>
    <p-treeTable class="full-height-200" [value]="evaluationTree" [(contextMenuSelection)]="selectedNode">
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Value</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr [ttRow]="rowNode" [ttContextMenuRow]="rowNode">
                <td>
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{rowData.key}}
                </td>
                <td>{{rowData.value}}</td>
            </tr>
        </ng-template>
    </p-treeTable>
    </div>
</div>
