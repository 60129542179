import { Type } from 'class-transformer';
import { Comparable } from '../../utils/shared/comparable';
import { Utils } from '../../utils/shared/utils';
import { CertificateStatus } from './certificate.status.enum';
import { SchoolType } from './school-type.enum';

export class Pupil implements Comparable<Pupil> {

    static isDomainIgnored(specialRequirement: string, domain: string): boolean {
        return specialRequirement == "FS Lernen - Englisch" && domain == "Hauswirtschaft" || specialRequirement == "FS Lernen - Hauswirtschaft" && domain == "Englisch";
    }

    static isSpecialReqLearning(specialRequirement: string): boolean {
        return specialRequirement == "FS Lernen" || specialRequirement == "FS Lernen - Englisch" || specialRequirement == "FS Lernen - Hauswirtschaft";
    }

    id = 0;
    parentId = 0;
    lastName = "";
    firstName = "";
    @Type(() => Date)
    birthday: Date;
    street = "";
    houseNumber = "";
    zipCode = "";
    city = "";
    typeGs = false;
    typeF = false;
    typeOs = false;
    typeSek1 = false;
    typeSek2 = false;
    custodian1Mail = "";
    custodian1Phone = "";
    custodian2Mail = "";
    custodian2Phone = "";
    grade = 0;
    title = "";
    profile = "";
    active = true;
    visitingYear = 0;
    year = "";
    specialRequirement = "";
    schoolCareer = "";
    therapies = "";
    vaccinated = false;
    afterSchoolCenter = false;
    note1 = "";
    note2 = "";
    secondForeignLanguage = "";
    thirdForeignLanguage = "";
    coursesString = "";
    optionalCompetencies: string[] = [];
    certificateStatus: CertificateStatus = CertificateStatus.none;

    compareTo(other: Pupil): number {
        return Utils.cmp(this.lastName, other.lastName);
    }

    getClassDisplayName(): string {
        return this.grade + this.title;
    }

    getSchoolType(): SchoolType {
        return this.typeGs ? SchoolType.elementary : this.typeF ? SchoolType.special : this.typeOs || this.typeSek1 || this.typeSek2 ? SchoolType.secondary : SchoolType.elementary;
    }

    getSchoolTypeName(): string {
        return this.typeGs ? "Grundschule" : this.typeF ? "Förderschule" : this.typeOs || this.typeSek1 || this.typeSek2 ? "Oberstufe" : "-Unbekannt-";
    }

    getCertificateStatusString(): string {
        return this.certificateStatus === CertificateStatus.none ? "keine Bearbeitung" : this.certificateStatus === CertificateStatus.started ? "Bearbeitung begonnen" :
            this.certificateStatus === CertificateStatus.created ? "Zeugnis erstellt" : "-Unbekannt-";
    }

    getFullName(): string {
        return this.lastName + ", " + this.firstName;
    }

    isCareerRegional(): boolean {
        return this.schoolCareer == "Regionale Schule";
    }

    isCareerGymnasium(): boolean {
        return this.schoolCareer == "Gymnasium";
    }

    isSpecialReqLearning(): boolean {
        return Pupil.isSpecialReqLearning(this.specialRequirement);
    }

    isDomainIgnored(domain: string): boolean {
        return Pupil.isDomainIgnored(this.specialRequirement, domain);
    }
}
