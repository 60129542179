<div class="grid">
    <div class="col-2">
        <sm-form  [form]="form">
        </sm-form>
        <p-button icon="fas fa-cog" label="Holen" (onClick)="get()"></p-button>
    </div>
    <div class="col-10 single-page-view">
        <textarea pInputTextarea spellcheck=false class="width100p full-height-120x monospace no-resize" [ngModel]="result"></textarea>
    </div>
</div>
