import { Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'string-list-editor',
    templateUrl: './string-list-editor.component.html',
    styleUrls: ['./string-list-editor.component.scss']
})
export class StringListEditorComponent {

    _Utils = Utils;

    @Input()
    items: string[];
    @Input()
    addTitle = "";

    @ViewChildren('itemDivs') itemDivs: QueryList<ElementRef>;

    removeItem(index: number): void {
        this.items.splice(index, 1);
    }

    addItem(): void {
        this.items.push("");
        GuiUtils.angularTimer(() => {
            if (this.itemDivs != null && this.itemDivs.length > 0) {
                let ne = this.itemDivs.get(this.itemDivs.length - 1).nativeElement.querySelector("input");
                ne.focus();
            }
        });
    }

    trackByFn(index, _): void {
        return index;
    }

    keyPressed(event: any): void {
        if (event.key == "Enter") {
            this.addItem();
        }
    }

}
