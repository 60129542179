import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditGeneralUserComponent } from './components/edit-general-user/edit-general-user.component';
import { EditGeneralUsersComponent } from './components/edit-general-users/edit-general-users.component';

const routes: Routes = [
    {
        path: "admin/editGeneralUsers",
        component: EditGeneralUsersComponent
    },
    {
        path: "admin/editGeneralUser/:id",
        component: EditGeneralUserComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SmBaseRoutingModule2 {
}
