/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";
import { DatabaseUser } from "src/modules/database/shared/database-user.entity";
import { CustomInitializer } from "src/modules/sm-base/shared/custom-initializer.interface";
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Comparable } from "src/modules/utils/shared/comparable";
import { Utils } from "src/modules/utils/shared/utils";
import { Column, Entity, ManyToOne, Unique } from "typeorm";
import { LearningContent } from "./learning-content.entity";

@Entity()
@Unique(["learningContent", "user"])
export class LearningContentParticipant extends SmBaseEntity implements CustomInitializer, Comparable<LearningContentParticipant> {
    @ManyToOne(() => LearningContent, (o: LearningContent) => o.chapters, { onDelete: "CASCADE", nullable: false, orphanedRowAction: "delete"})
    @Type(() => LearningContent)
    learningContent: LearningContent;

    @ManyToOne(() => DatabaseUser, { onDelete: "CASCADE", nullable: false})
    user: DatabaseUser;

    @Column({nullable: true})
    @Type(() => Date)
    deadlineDate: Date = null;

    @Column()
    @Type(() => Date)
    creationDate: Date = null;

    @Column({nullable: true})
    @Type(() => Date)
    startDate: Date = null;

    @Column({nullable: true})
    @Type(() => Date)
    finishedDate: Date = null;

    @Column({default: false})
    mandatory: boolean = false;

    @Column({default: 0})
    lastChapter: number = 0;

    @Column("boolean", { array: true, nullable: true })
    doneChapters: boolean[] = [];

    @Column({default: true})
    doneDuringOfficeHours: boolean = true;

    @Column("int", {array: true, nullable: true})
    ratings: number[] = [];

    @Column("text", {array: true, nullable: true})
    ratingTexts: string[] = [];

    @Column({default: false})
    notificationSent: boolean = false;

    @Column("int", {array: true, nullable: true})
    activityFrom: number[] = [];

    @Column("int", {array: true, nullable: true})
    activityTo: number[] = [];

    @Column({default: 0})
    finalExamFailCount: number = 0;

    @Column({default: -1})
    finalExamPassScore: number = -1;

    customInitializer(): void {
        this.doneChapters = Utils.arrayEnsure(this.doneChapters);
        this.learningContent?.customInitializer();
        this.activityFrom = Utils.arrayEnsure(this.activityFrom);
        this.activityTo = Utils.arrayEnsure(this.activityTo);
    }

    compareTo(other: LearningContentParticipant): number {
        return Utils.cmpMultiWithFactor([this.isMandatoryOrStarted(), other.isMandatoryOrStarted(), -1, this.deadlineDate, other.deadlineDate, 1, this.learningContent, other.learningContent, 1]);
    }

    isFinished(): boolean {
        return this.finishedDate != null;
    }

    isStarted(): boolean {
        return this.startDate != null || this.finishedDate != null;
    }

    isMandatoryOrStarted(): boolean {
        return this.mandatory || this.isStarted();
    }
}
