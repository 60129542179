/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { ClassRegister } from './class-register.entity';

@Entity()
export class ClassInstruction extends SmBaseEntity {
    @ManyToOne(() => ClassRegister, (o: ClassRegister) => o.instructions, { onDelete: "CASCADE", nullable: false, orphanedRowAction: "delete"})
    classRegister: ClassRegister;

    @Column({default: ""})
    topic: string = "";

    @Column()
    @Type(() => Date)
    date: Date;

    @Column({default: ""})
    instructedBy: string = "";

    @Column({default: ""})
    secondTopic: string = "";

    @Column({ nullable: true })
    @Type(() => Date)
    secondDate: Date;

    @Column({default: ""})
    secondInstructedBy: string = "";

    @Column("int", { array: true, nullable: true })
    missingPupilIds: number[] = [];
}
