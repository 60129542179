import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { FrontendFieldDefinition } from './frontend-field-definition.model';

export class FrontendFormDefinition {

    fields: FrontendFieldDefinition[] = [];
    useAbsolutePositions = false;
    mandatoryFieldsRedAsterisk = false;

    constructor(fields: FrontendFieldDefinition[], useAbsolutePositions = false) {
        this.fields = fields;
        this.useAbsolutePositions = useAbsolutePositions;
    }

    getField(id: string, throwIfMissing = true): FrontendFieldDefinition {
        let result = this.fields.find(f => f.id === id);
        if (result == null && throwIfMissing) {
            throw new Error("Das Feld mit dem Namen '" + id + "' wurde nicht gefunden. Bekannte Feldnamen: " + Utils.arrayItemsToString(Utils.arraySort(this.fields.map(f => f.id))));
        }
        return result;
    }

    getValue(id: string): any {
        return this.getField(id).getValue();
    }

    setValue(id: string, value: any): void {
        this.getField(id).value = value;
    }

    getMainField(field: FrontendFieldDefinition): FrontendFieldDefinition {
        return Utils.isNoe(field.guiGroup) ? field : this.fields.find(f => f.guiGroup === field.guiGroup);
    }

    getHeight(): number {
        return this.fields.map(field => field.getMaxPosition()).reduce((p1, p2) => p1.max(p2.x, p2.y)).y;
    }

    getWidth(): number {
        return this.fields.map(field => field.getMaxPosition()).reduce((p1, p2) => p1.max(p2.x, p2.y)).x;
    }

    fill(from: any): void {
        for (let field of this.fields) {
            field.setValue(Utils.getPropertyNested(from, field.id));
        }
    }

    get<T>(to: T): T {
        for (let field of this.fields) {
            Utils.setPropertyNested(to, field.id, field.getValue());
        }
        return to;
    }

    getAsObject(): OrdinaryObject {
        return this.get<OrdinaryObject>({} as OrdinaryObject);
    }

    validate(to?: any): OrdinaryObject {
        let result: OrdinaryObject = {};
        for (let field of this.fields) {
            let error = field.validate(this);
            if (error != null) {
                result[field.id] = error;
            }
        }

        if (Utils.isObject(to) && 'getFieldMetaData' in to) {
            this.get(to);
            for (let metaData of to.getFieldMetaData()) {
                let field = this.fields.find(f => f.id == metaData.name);
                if (field != null) {
                    let value = field.getValue();
                    metaData.validate(value, to, result);
                }
            }
        }

        for (let field of this.fields) {
            field.errorMessage = null;
        }

        for (let fieldName of Utils.getOwnPropertyNames(result)) {
            let field = this.fields.find(f => f.id == fieldName);
            if (field != null) {
                field.errorMessage = result[fieldName];
            }
        }

        return Utils.getOwnPropertyNames(result).length == 0 ? null : result;
    }

    getGroupedFields(): FrontendFieldDefinition[][] {
        let result: FrontendFieldDefinition[][] = [];
        for (let field of this.fields) {
            let group = Utils.isNoe(field.guiGroup) ? null : result.find(g => g[0].guiGroup == field.guiGroup);
            if (group == null) {
                result.push([field]);
            }
            else {
                group.push(field);
            }
        }
        return result;
    }

}
