import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { EnaioAssignment } from "./EnaioAssignment";
import { EnaioCall } from "./EnaioCall";
import { EnaioTableAssignment } from "./EnaioTableAssignment";
import { EnaioUserIdentification } from "./EnaioUserIdentification";
import { EnaioWorkflowDocument } from "./EnaioWorkflowDocument";
import { EnaioWorkItemParameter } from "./EnaioWorkItemParameter";

export class EnaioCallCompleteWorkItem extends EnaioCall <boolean> {
    @Type(() => EnaioUserIdentification)
    user: EnaioUserIdentification;
    clientType: string;
    workItemId: string;
    send = false;
    @Type(() => EnaioWorkItemParameter)
    parametersRaw: EnaioWorkItemParameter[];
    @Type(() => EnaioAssignment)
    parameters: EnaioAssignment[];
    @Type(() => EnaioTableAssignment)
    tableParameters: EnaioTableAssignment[];
    @Type(() => EnaioWorkflowDocument)
    documents: EnaioWorkflowDocument[];
    getModelType(): AdvancedType {
        return AdvancedType.ofPrimitive("boolean");
    }

}
