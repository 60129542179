export class AppMenuItem {
    label: string;
    url: string;
    icon: string;
    sort: number;
    target: string;
    badge: string;
    badgeClass: string;
    class: string;
    routerLink: string[];
    disabled: boolean;
    command: any;
    items: AppMenuItem[];
}
