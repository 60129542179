export class EnaioDocumentFileProperties {
    count = 0;
    size = 0;
    iconId = 0;
    mimeType: string;
    mimeTypeGroup: string;
    mimeTypeId = 0;
    extension: string;
    pageCount = 0;
}
