import { Type } from "class-transformer";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class EnaioOrganisationObject {
    id: string;
    name: string;
    classId: string;
    attribs: OrdinaryObject<string>;
    @Type(() => EnaioOrganisationObject)
    parents: EnaioOrganisationObject[];
}
