import { ClassConstructor } from "class-transformer";
import { Utils } from "src/modules/utils/shared/utils";

export class LoginUser {
    constructor(public userName: string, public access_token: string, public additional: any) {
    }

    getAdditional<T extends object>(key: string, type: ClassConstructor<T>): T {
        return this.additional == null ? null : Utils.fromPlainUnsafe(type, this.additional[key]);
    }
}
