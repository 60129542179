<div *ngIf="inited">
    <sm-toolbar>
        <p-button icon="fas fa-save" label="Speichern" (onClick)="save()">
        </p-button>
    </sm-toolbar>
    <div class="card">
        <p-tabView>
            <p-tabPanel header="Leistungsnachweistypen">
                <p-table [value]="settings.examTypes" [styleClass]="'p-datatable-gridlines'">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="align-left">Titel</th>
                            <th style="width: 6rem; text-align: center">
                                <p-button class="p-button-secondary" icon="fas fa-plus" (onClick)="addExamType()"></p-button>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-examType let-index="rowIndex">
                        <tr [pSelectableRow]="index">
                            <td  pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText [(ngModel)]="examType.title" (onFocus)="_GuiUtils.selectAll($event)">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{examType.title}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td style="text-align: center">
                                <p-button class="p-button-secondary" icon="fas fa-trash" (onClick)="deleteExamType(index)"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <p-tabPanel *ngIf="settings.type == _SchoolType.secondary" header="Wochenstunden">
                <p-table [value]="domains" [styleClass]="'p-datatable-gridlines'">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Fach</th>
                            <th *ngFor="let grade of grades" style="width: 7%">{{grade}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-domain let-index="rowIndex">
                        <tr [pSelectableRow]="index">
                            <td>{{domain}}</td>
                            <td *ngFor="let grade of grades" pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-inputNumber [(ngModel)]="domainHoursPerWeek[domain + '|' + grade].count" [min]="0" [max]="9" [inputStyle]="{'width': '50px'}" (onFocus)="_GuiUtils.selectAll($event)"></p-inputNumber>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{domainHoursPerWeek[domain + '|' + grade].count > 0 ? domainHoursPerWeek[domain + '|' + grade].count : ''}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <p-tabPanel header="Durchschnittsberechnung">
                <p-messages *ngIf="examTypeAdded" severity="warn">
                    <ng-template pTemplate>
                        <div class="ml-2">Es wurde mindestens ein Leistungsnachweistyp neu eingefügt. Bitte speichern Sie einmal die Einstellungen, um die Faktoren für den neuen Typ editieren zu können</div>
                    </ng-template>
                </p-messages>
                <sm-toolbar class="col-12">
                    Kompetenzbereich:
                    <p-dropdown [styleClass]="'width300'" id="domain" [options]="domains"
                        [(ngModel)]="selectedDomain" (onChange)="changeSelectedDomain()">
                    </p-dropdown>
                    <p-button icon="fas fa-save" label="Zu anderen Kompetenzbereichen kopieren" (onClick)="copyExamTypeFactors()">
                    </p-button>
                </sm-toolbar>
                <p-table *ngIf="selectedDomainSettings != null" [value]="selectedDomainSettings.examTypeFactors" sortField="examType.title" [styleClass]="'p-datatable-gridlines'">
                    <ng-template pTemplate="header">
                        <tr>
                        <th class="align-left">Art des Leistungsnachweises</th>
                        <th class="align-left" style="width:100px">Gewichtung</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-examTypeFactor let-index="rowIndex">
                        <tr [pSelectableRow]="index">
                            <td>{{examTypeFactor.examType.title}}</td>
                            <td  pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-inputNumber [(ngModel)]="examTypeFactor.factor" [min]="0" [max]="100" [inputStyle]="{'width': '50px'}" (onFocus)="_GuiUtils.selectAll($event)"></p-inputNumber>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{examTypeFactor.factor}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <p-tabPanel header="Bewertungsübersicht">
                <sm-toolbar class="col-12">
                    Klassenstufe:
                    <p-dropdown [styleClass]="'width100'" id="grade" [options]="grades"
                        [(ngModel)]="selectedGrade" (onChange)="changeSelectedGrade()">
                    </p-dropdown>
                    <p-button icon="fas fa-save" label="Zu anderen Klassenstufen kopieren" (onClick)="copyMarkTranslation()">
                    </p-button>
                    <span class="ml-3">Art der Noteneingabe:</span>
                    <p-dropdown [styleClass]="'width300'" id="gradeType" [options]="[{label: 'Prozentwerte', value: _GradeType.percent}, {label: 'Schulnoten', value: _GradeType.oneToSix}]"
                        [(ngModel)]="selectedGradeSettings.gradeType">
                    </p-dropdown>

                    <div *ngIf="selectedGradeSettings.gradeType == _GradeType.percent" class="option-div">
                        <p-checkbox class="option ml-3" name="useTextualGrades" [(ngModel)]="selectedGradeSettings.useTextualGrades" [binary]="true"></p-checkbox>
                        <label class="form-label ml-2">Noten zusätzlich verwenden</label>
                    </div>

                    <div class="option-div">
                        <p-checkbox class="option ml-3" name="useDictationTranslation" [(ngModel)]="selectedGradeSettings.useDictationTranslation" [binary]="true"></p-checkbox>
                        <label class="form-label ml-2">Abweichend für Diktate</label>
                    </div>
                </sm-toolbar>
                <ng-container *ngFor="let forDictation of _Utils.arrayWithoutNull([false, selectedGradeSettings.useDictationTranslation ? true : null])">
                    <h3 *ngIf="selectedGradeSettings.useDictationTranslation">{{forDictation ? 'Für Diktate' : 'Allgemein'}}</h3>
                    <p-table *ngIf="selectedGradeSettings != null" [value]="forDictation ? markTranslationDictation : markTranslationGeneral" [styleClass]="'p-datatable-gridlines'" sortField="fromPercent">
                        <ng-template pTemplate="header">
                            <tr>
                            <th class="align-left" style="width:300px">erreichtes Ergebnis in %</th>
                            <th class="align-left">Bewertung</th>
                            <th *ngIf="selectedGradeSettings.useTextualGrades" class="align-left">Note</th>
                            <th style="width: 6rem; text-align: center">
                                <p-button class="p-button-secondary" icon="fas fa-plus" (onClick)="addMarkTranslation(forDictation)"></p-button>
                            </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-mt let-index="rowIndex">
                            <tr [pSelectableRow]="mt">
                            <td pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-inputNumber [(ngModel)]="mt.fromPercent" [min]="0" [max]="100" [inputStyle]="{'width': '50px'}" (onFocus)="_GuiUtils.selectAll($event)"></p-inputNumber>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{mt.fromPercent}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText [(ngModel)]="mt.text" (onFocus)="_GuiUtils.selectAll($event)">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{mt.text}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td *ngIf="selectedGradeSettings.useTextualGrades" pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText [(ngModel)]="mt.textualGrade" (onFocus)="_GuiUtils.selectAll($event)">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{mt.textualGrade}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td style="text-align: center">
                                <p-button class="p-button-secondary" icon="fas fa-trash" (onClick)="deleteMarkTranslation(index, forDictation)"></p-button>
                            </td>
                        </tr>
                        </ng-template>
                    </p-table>
                </ng-container>
            </p-tabPanel>
            <p-tabPanel header="Anzeigeoptionen">
                <sm-form [form]="formOptions">
                </sm-form>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
