import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuiUtils } from '../utils/misc/gui-utils';
import { ConfigChangerMainComponent } from './components/config-changer-main/config-changer-main.component';


const routes: Routes = GuiUtils.updateRoutes([
    {
        path: 'home',
        component: ConfigChangerMainComponent
    },
    {
        path: '',
        redirectTo: "home",
        pathMatch: "full"
    }
]);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ConfigChangerRoutingModule {
}
