/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { CustomInitializer } from 'src/modules/sm-base/shared/custom-initializer.interface';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne, OneToMany } from 'typeorm';
import { GradeType } from './grade-type.enum';
import { MarkTranslation } from './mark-translation.entity';
import { SchoolTypeSettings } from './school-type-settings.entity';

@Entity()
export class GradeSettings extends SmBaseEntity implements CustomInitializer {

    static makeDefault(grade: number): GradeSettings {
        return Utils.fromPlain(GradeSettings, {
            grade,
            markTranslation: grade <= 4 ? [
                new MarkTranslation(0, "Du hast Übungsbedarf"),
                new MarkTranslation(40, "Deine Kenntnisse sind unsicher"),
                new MarkTranslation(60, "Deine Kenntnisse sind teilweise sicher"),
                new MarkTranslation(80, "Deine Kentnisse sind sicher")
            ] : grade <= 6 ? [
                new MarkTranslation(0, "Du hast Übungsbedarf"),
                new MarkTranslation(40, "Deine Kenntnisse sind unsicher"),
                new MarkTranslation(60, "Deine Kenntnisse sind teilweise sicher"),
                new MarkTranslation(80, "Deine Kentnisse sind sicher")
            ] : grade <= 8 ? [
                new MarkTranslation(0, "Du hast Übungsbedarf"),
                new MarkTranslation(40, "Deine Kenntnisse sind unsicher"),
                new MarkTranslation(60, "Deine Kenntnisse sind teilweise sicher"),
                new MarkTranslation(80, "Deine Kentnisse sind sicher"),
                new MarkTranslation(96, "Du hast das erwartete Ergebnis übertroffen")
            ] : [
                new MarkTranslation(0, "Dir fehlen wesentliche Grundkenntnisse"),
                new MarkTranslation(25, "Du hast Übungsbedarf"),
                new MarkTranslation(40, "Deine Kenntnisse sind unsicher"),
                new MarkTranslation(60, "Deine Kenntnisse sind teilweise sicher"),
                new MarkTranslation(80, "Deine Kentnisse sind sicher"),
                new MarkTranslation(96, "Du hast das erwartete Ergebnis übertroffen")
            ]
        });
    }

    @ManyToOne(() => SchoolTypeSettings, (o: SchoolTypeSettings) => o.gradeSettings, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    settings: SchoolTypeSettings;

    @Column({default: GradeType.percent})
    gradeType: GradeType = GradeType.percent;

    @Column({default: 0})
    grade: number = 0;

    @Column({default: false})
    useTextualGrades: boolean = false;

    @Column({default: false})
    useDictationTranslation: boolean = false;

    @OneToMany(() => MarkTranslation, o => o.settings, { cascade: true })
    @Type(() => MarkTranslation)
    markTranslation: MarkTranslation[];

    customInitializer(): void {
        this.markTranslation = Utils.arrayEnsure(this.markTranslation);
    }

    getTextualGrade(value: number, textualGrade?: boolean, forDictation?: boolean): string {
        let sorted = Utils.arraySortBy(this.getTranslation(forDictation), mt => mt.fromPercent, false);
        for (let mt of sorted) {
            if (value >= mt.fromPercent) {
                return textualGrade ? mt.textualGrade : mt.text;
            }
        }
        return "";
    }

    getMarkNamesForCertificates(): string[] {
        return Utils.arraySortBy(this.markTranslation.filter(mt => !mt.forDictation), mt => mt.fromPercent).map(mt => mt.text);
    }

    getTranslation(forDictation: boolean): MarkTranslation[] {
        return this.markTranslation.filter(mt => mt.forDictation == forDictation);
    }

    isUseTextualGrades(): boolean {
        return this.useTextualGrades && this.gradeType == GradeType.percent;
    }
}
