import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { AppTab } from '../models/app-tab.model';

@Injectable()
export class MenuService {

    model: AppTab[] = [];
    visibleTabs: AppTab[] = [];

    private menuSource = new Subject<string>();
    private resetSource = new Subject();

    menuSource$ = this.menuSource.asObservable();
    resetSource$ = this.resetSource.asObservable();

    constructor() {
        GuiUtils.checkSingleton(this);
    }

    onMenuStateChange(key: string): void {
        this.menuSource.next(key);
    }

    reset(): void {
        this.resetSource.next(null);
    }

    updateVisibility(): void {
        this.visibleTabs = this.model.filter(t => t.visible);
    }
}
