import { LoginUserEnaio } from "src/modules/enaio/shared/login-user-enaio.model";
import { LoginUser } from "src/modules/sm-base/shared/login-user.model";
import { Utils } from "src/modules/utils/shared/utils";
import { ClassRegisterSettings } from "./class-register-settings.entity";
import { NumberFormatMode } from "./number-format-mode.enum";
import { SchoolTypeSettings } from "./school-type-settings.entity";
import { SchoolType } from "./school-type.enum";

export class EnaioCertificatesTools {

    static isAdminForCertificates(user: LoginUser): boolean {
        let u = user?.getAdditional("enaio", LoginUserEnaio);
        return u?.groups.includes("ZEUGNISERSTELLUNG_ADMIN") || EnaioCertificatesTools.isDeveloper(user);
    }

    static isCertificateEditor(user: LoginUser): boolean {
        let u = user?.getAdditional("enaio", LoginUserEnaio);
        return u?.groups.includes("ZEUGNISERSTELLUNG") || EnaioCertificatesTools.isDeveloper(user);
    }

    static isClassRegisterEditor(user: LoginUser): boolean {
        let u = user?.getAdditional("enaio", LoginUserEnaio);
        let teacherGroups = EnaioCertificatesTools.getTeacherEnaioGroups();
        return EnaioCertificatesTools.isDeveloper(user) || u?.groups.includes("SCHULSEKRETARIAT") || u?.groups.find(group => teacherGroups.includes(group)) != null;
    }

    static isSecretary(user: LoginUser): boolean {
        let u = user?.getAdditional("enaio", LoginUserEnaio);
        return u?.groups.includes("SCHULSEKRETARIAT") || EnaioCertificatesTools.isDeveloper(user);
    }

    static isDeveloper(user: LoginUser): boolean {
        return user?.userName.toLowerCase() == "awp";
    }

    static getCurrentYear(): number {
        let year = new Date().getFullYear();
        if (new Date().getMonth() < 7) {
            year -= 1;
        }
        return year;
    }

    static getCurrentSchoolYear(): string {
        return this.getSchoolYearFromYear(this.getCurrentYear());
    }

    static getSchoolYearFromYear(year: number): string {
        return year + "/" + (year + 1) % 100;
    }

    static getCurrentIsHalfYear(): boolean {
        return false; //new Date().getMonth() > 7 || new Date().getMonth() < 3;
    }

    static getMarkNames(schoolType: SchoolType, grade: number, domain: string, settings: SchoolTypeSettings, html = true): string[] {
        let result: string[] = [];
        if (schoolType === SchoolType.special) {
            result = ["nicht bewer&shy;tet", "un&shy;sicher / sel&shy;ten", "teil&shy;weise si&shy;cher / teil&shy;weise", "überw. si&shy;cher / häu&shy;fig", "si&shy;cher / im&shy;mer"];
        }
        else if (domain === "Kompetenzentwicklung") {
            result = ["nicht bewer&shy;tet", "im&shy;mer / meist", "häu&shy;fig / teil&shy;weise", "eher we&shy;ni&shy;ger", "sel&shy;ten / nie"];
        }
        else {
            let gradeSettings = settings.getGradeSettings(grade);
            let markNames = gradeSettings.getMarkNamesForCertificates();
            result = ["nicht bewer&shy;tet", ...schoolType === SchoolType.elementary ? markNames : Utils.arrayReverse(markNames)];
            //result = ["nicht bewer&shy;tet", "si&shy;cher / im&shy;mer", "teil&shy;weise sicher", "un&shy;sicher", "Übungs&shy;bedarf"];
        }
        return html ? result : result.map(s => Utils.replaceAll(s, "&shy;", ""));
    }

    static getMarkNamesSorted(schoolType: SchoolType, grade: number, domain: string, settings: SchoolTypeSettings, html = true): string[] {
        let result = this.getMarkNames(schoolType, grade, domain, settings, html).slice(1);
        return schoolType === SchoolType.elementary || schoolType === SchoolType.special ? Utils.arrayReverse(result) : result;
    }

    static getValidGrades(schoolType: SchoolType): number[] {
        switch (schoolType) {
            case SchoolType.elementary:
                return [1, 2, 3, 4];
            case SchoolType.special:
                return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
            case SchoolType.secondary:
                return [5, 6, 7, 8, 9, 10, 11, 12, 13];
            default:
                throw new Error("Ungültiger SchoolType " + schoolType);
        }
    }

    static isConductGrade(domain: string): boolean {
        return domain == "Sozialverhalten" || domain == "Arbeits- und Lernverhalten" || domain == "Arbeits- und Leistungsverhalten" || domain == "Kompetenzentwicklung";
    }

    static getNumberFormatModeName(mode: NumberFormatMode): string {
        switch (mode) {
            case NumberFormatMode.decimalTwo:
                return "Ungerundet auf 2 Nachkommastellen";
            case NumberFormatMode.roundUp:
                return "Runden gegen nächsthöheren Wert";
            case NumberFormatMode.roundDown:
                return "Runden gegen nächstniedrigeren Wert";
            case NumberFormatMode.round:
                return "Mathematisches Runden";
            case NumberFormatMode.decimalZero:
                return "Ungerundet ohne Nachkommastellen";
            case NumberFormatMode.decimalOne:
                return "ungerundet auf 1 Nachkommastelle";
            default:
                throw new Error("Ungültiger Wert für roundingType: " + mode);
        }
    }

    static formatValue(value: number, mode: NumberFormatMode): string {
        switch (mode) {
            case NumberFormatMode.decimalTwo:
                return Utils.restrictDecimalDigits(value, 2);
            case NumberFormatMode.roundUp:
                return Utils.restrictDecimalDigits(Math.ceil(value * 10) / 10, 1);
            case NumberFormatMode.roundDown:
                return Utils.restrictDecimalDigits(Math.floor(value * 10) / 10, 1);
            case NumberFormatMode.round:
                return Utils.restrictDecimalDigits(Math.round(value * 10) / 10, 1);
            case NumberFormatMode.decimalZero:
                return Utils.restrictDecimalDigits(value, 0);
            case NumberFormatMode.decimalOne:
                return Utils.restrictDecimalDigits(value, 1);
            default:
                throw new Error("Ungültiger Wert für roundingType: " + mode);
        }
    }

    static getCovidPersonGroups(): string[] {
        return ["Lehrer", "upF", "B", "s. Pers."];
    }

    static getMaxHoursPerDay(): number {
        return 10;
    }

    static getSpecialDomainsForTimeTable(settings: ClassRegisterSettings): string[] {
        return [...settings.specialDomains.map(sd => sd.title), this.getSpecialDomainCourses()];
    }

    static getSpecialDomainChildren(domain: string): string[] {
        return /*domain == "Fremdsprache" ? ["Französisch", "Spanisch", "Wahlpflichtkurse"] :*/ [domain];
    }

    static getSpecialDomainCourses(): string {
        return "Kurse";
    }

    static getMaxGrade(): number {
        return 13;
    }

    static getAllGrades(): number[] {
        return Utils.getRange(1, this.getMaxGrade());
    }

    static getSchoolTypeNames(): string[] {
        return ["Grundschule", "Gesamtschule", "Förderschule"];
    }

    static getTeacherEnaioGroups(): string[] {
        return ["ZEUGNISERSTELLUNG UPF", "SONDERPÄDAGOGEN", "LEHRER MICHAELSCHULE DD39", "PÄDAGOGISCHE UNTERRICHTSHILFE DD39", "BETREUER MICHAELSCHULE DD39", "LEHRER MICHAELSCHULE FS25", "PÄDAGOGISCHE UNTERRICHTSHILFE FS25", "BETREUER MICHAELSCHULE FS25"];
    }

    static getDomainsForMarkCertificates(): string[] {
        return ["Kompetenzentwicklung"];
    }

    static getDomainGroupForMarkCertificate(domain: string): string {
        return domain == "Fleiß" || domain == "Zuverlässigkeit" ? "Arbeitsverhalten" : domain == "Umgangsformen" || domain == "Teamfähigkeit" ? "Sozialverhalten" : domain.startsWith("course-") ? "Wahlpflichtunterricht" : "Fachnoten";
    }

    static getDomainGroupsForMarkCertificates(): string[] {
        return ["Arbeitsverhalten", "Sozialverhalten", "Fachnoten", "Wahlpflichtunterricht"];
    }

    static getCertificateIsForHalfYear(date: Date): boolean {
        let m = date.getMonth();
        return m > 7 || m < 3;
    }

}
