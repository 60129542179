import { EnaioWorkflowModelState } from "./EnaioWorkflowModelState";

export class EnaioTools {

    static workflowModelStateToString(value: EnaioWorkflowModelState): string {
        switch (value) {
            case EnaioWorkflowModelState.active:
                return "Aktiv";
            case EnaioWorkflowModelState.inProgress:
                return "In Bearbeitung";
            case EnaioWorkflowModelState.freeToEdit:
                return "Zur Bearbeitung freigegeben";
            case EnaioWorkflowModelState.deleted:
                return "Gelöscht";
            case EnaioWorkflowModelState.inUse:
                return "In Benutzung";
            case EnaioWorkflowModelState.historic:
                return "Historisch";
            default:
                return "";
        }
    }
}
