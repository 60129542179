import { Comparable } from "./comparable";
import { Utils } from "./utils";

export class UserInteractionItem implements Comparable<UserInteractionItem> {
    constructor(public date: Date, public type: string, public text: string) {
    }

    compareTo(other: UserInteractionItem): number {
        return Utils.cmp(this.date, other.date);
    }

    toString(): string {
        return Utils.dateFormatDefault(this.date) + ": " + this.type.padEnd(10) + " " + this.text;
    }
}
