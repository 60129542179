import { Type } from "class-transformer";
import { EnaioWorkflowFamilyType } from "./EnaioWorkflowFamilyType";

export class EnaioWorkflowFamily {
    id: string;
    orgId: string;
    name: string;
    description: string;
    osType: EnaioWorkflowFamilyType;
    creatorId: string;
    @Type(() => Date)
    creationTime: Date;
    processCount = 0;
    versionCount = 0;
    priority = 0;
}
