import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { Utils } from "src/modules/utils/shared/utils";
import { TableColumn } from "./table-column.model";
import { TableRow } from "./table-row.model";
import { TableSortColumn } from "./table-sort-column.model";

export class TableData {

    static fromList(data: OrdinaryObject[], cols?: string[], unroll?: boolean): TableData {
        if (cols == null) {
            cols = Utils.arrayGetUnique(Utils.arrayExplode(data, item => Utils.getOwnPropertyNames(item)));
        }
        return new TableData(cols.map(col => new TableColumn(col, col)), data.map(item => {
            let value = item;
            if (unroll) {
                value = {};
                for (let col of cols) {
                    value[col] = Utils.getPropertyNested(item, col);
                }
            }
            return new TableRow(item, value);
        }));
    }

    constructor(public columns: TableColumn[], public rows: TableRow[], public sort: TableSortColumn[] = null, public generator: () => Promise<TableRow> = null) {
    }

    toCsv(rows: TableRow[] = null): string {
        let result = Utils.arrayItemsToString(this.columns, ";", col => col.caption);
        for (let row of rows ?? this.rows) {
            try {
                for (let col of this.columns) {
                    if (!Utils.hasProperty(row.values, col.id)) {
                        throw new Error("Das Objekt enthält nicht das Feld " + col.id);
                    }
                }
                result += "\r\n" + Utils.arrayItemsToString(this.columns, ";", col => col.toCsvString(row.values[col.id]));
            }
            catch (error: any) {
                throw new Error("Konnte Zeile nicht kopieren. " + Utils.toJson(row.values, true) + ". Columns: " + Utils.arrayItemsToString(this.columns.map(col => col.id)) + " Fehlermeldung: " + error);
            }
        }
        return result;
    }

    toId(): string {
        return Utils.arrayItemsToString(this.columns, ", ", col => col.id);
    }

}
