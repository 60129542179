import { Bounds } from "src/modules/utils/shared/bounds.model";
export class EnaioGuiBounds {
    left = 0;
    top = 0;
    width = 0;
    height = 0;
    right = 0;
    bottom = 0;

    toBounds(scaleFactor = 1, shiftX = 0, shiftY = 0): Bounds {
        return new Bounds(Math.floor(shiftX + this.left * scaleFactor), Math.floor(shiftY + this.top * scaleFactor),
            Math.floor(this.width * scaleFactor), Math.floor(this.height * scaleFactor));
    }


}
