/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Column, Entity, ManyToOne } from "typeorm";
import { TemplateDocumentState } from "./template-document-state.entity";

@Entity()
export class TemplateDocumentFieldState extends SmBaseEntity {
    @Column({default: ""})
    templateFieldName: string = "";

    @Column({length: 65_535, default: ""})
    value: string = "";

    @ManyToOne(() => TemplateDocumentState, (o: TemplateDocumentState) => o.fields, { onDelete: "CASCADE", orphanedRowAction: "delete" })
    @Type(() => TemplateDocumentState)
    document: TemplateDocumentState;
}
