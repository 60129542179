<div class="login-body">
    <div class="login-panel ui-fluid">
    <div class="col-12 logo-container">
        <img src="assets/layout/images/logo-colored.png" />
        <h1>Aktivierung</h1>
        <h2>{{app.appName}}</h2>
    </div>
    <div *ngIf="running">
        <div>Aktivierung läuft ...</div>
        <p-progressSpinner></p-progressSpinner>

    </div>
    <p-message *ngIf="!running" [severity]="resultType" [text]="resultMessage" styleClass="mr-2"></p-message>
    <div *ngIf="!running" class="col-12 button-container">
        <a [routerLink]="['/base', 'login']">
            <p-button styleClass="width100p" icon="fas fa-sign-in" label="Zur Anmeldung">
            </p-button>
        </a>
    </div>
  </div>
