
export class FrontendFieldAddon {

    id = "";
    text = "";
    icon = "";
    isButton = false;

    constructor(id: string, text: string, icon: string, isButton = false) {
        this.id = id;
        this.text = text;
        this.icon = icon;
        this.isButton = isButton;
    }

}
