import { Type } from "class-transformer";
import { ProxyConnectionParams } from "./ProxyConnectionParams";

export class FtpConnectionParams {
    host = "";
    port = 0;
    userName = "";
    password = "";
    sftp = false;
    @Type(() => ProxyConnectionParams)
    proxy: ProxyConnectionParams;
}
