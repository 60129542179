import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { EnaioWarning } from 'src/modules/enaio/shared/EnaioWarning';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';
import { ArrayOrSingle } from 'src/modules/sm-base/shared/array-or-single.model';

@Component({
  selector: 'app-enaio-warnings',
  templateUrl: './enaio-warnings.component.html',
  styleUrls: ['./enaio-warnings.component.scss']
})
export class EnaioWarningsComponent extends ComponentView {

    warnings: EnaioWarning[];
    selectedWarningTypes: ArrayOrSingle<TreeNode>;
    warningTypesTree: TreeNode2[];
    tree: TreeNode2[];

    async initParams(): Promise<boolean> {
       // this.warningTypesTrees
        return Promise.resolve(true);
    }

    async find(): Promise<void> {
        this.warnings = Utils.arraySort(await DhTools.backendCall("api/enaio/warnings").list(EnaioWarning));
        this.tree = GuiUtils.generateTree(this.warnings, w => [...Utils.split(w.category, "|"), w.text], (item, key, index) => ({
            data: index >= 1 ? item : null, label: index >= 1 ? item.text : key[index], expanded: true, selectable: index < 1 || item.fixable
        }), true);
    }

    async fix(): Promise<void> {
        this.warnings = Utils.arraySort(await DhTools.backendCall("api/enaio/warnings", {fix: true}).list(EnaioWarning));
        await this.find();
    }

}
