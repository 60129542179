import { Type } from "class-transformer";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class EnaioProcessHistory {
    id: string;
    processId: string;
    index = 0;
    @Type(() => Date)
    date: Date;
    activityId: string;
    activityName: string;
    historyType = 0;
    organisationId: string;
    userId: string;
    userName: string;
    serverId: string;
    serverName: string;
    variables: OrdinaryObject;
}
