<div class="grid">
    <sm-toolbar class="col-12">
        <p-button icon="fas fa-run" label="Generieren" (onClick)="generateSmCode()"></p-button>
        <p-button  icon="fas fa-run" label="Zwischenablage -> Datei" (onClick)="smCodeClipToFile()"></p-button>
    <!--       <p-button icon="fas fa-run" label="Screenshot -> Datei" (onClick)="smCodeScreenshotToFile()"></p-button>-->
    </sm-toolbar>
    <div class="col-12">
        <img [src]="smCodeBlob">
    </div>
</div>
