/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { ClassRegisterSettings } from './class-register-settings.entity';
import { SchoolType } from './school-type.enum';

@Entity()
export class TeacherAdditional extends SmBaseEntity {

    @ManyToOne(() => ClassRegisterSettings, (o: ClassRegisterSettings) => o.holidays, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    settings: ClassRegisterSettings;

    @Column({default: ""})
    userName: string = "";

    @Column({default: false})
    forElementary: boolean = false;

    @Column({default: false})
    forSecondary: boolean = false;

    @Column({default: false})
    forSpecial: boolean = false;

    isForAnySchoolType(): boolean {
        return this.isForSchoolType(SchoolType.elementary) || this.isForSchoolType(SchoolType.secondary) || this.isForSchoolType(SchoolType.special);
    }

    isForSchoolType(schoolType: SchoolType): boolean {
        return schoolType == SchoolType.elementary && this.forElementary || schoolType == SchoolType.secondary && this.forSecondary || schoolType == SchoolType.special && this.forSpecial;
    }
}
