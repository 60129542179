import { Type } from "class-transformer";
import { EnaioDocumentFlags } from "./EnaioDocumentFlags";

export class EnaioDocumentBaseParams {
    createdBy: string;
    @Type(() => Date)
    creationDate: Date;
    @Type(() => Date)
    creationDateOnly: Date;
    @Type(() => Date)
    modificationDate: Date;
    modificationUser: string;
    ownerId: string;
    links = 0;
    systemId = 0;
    foreignId: string;
    archivedBy: string;
    @Type(() => Date)
    archivedDate: Date;
    flags: EnaioDocumentFlags;
}
