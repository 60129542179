import { Utils } from "src/modules/utils/shared/utils";
export class EnaioObjectId {
    name = "";
    internalName = "";
    id = -1;
    system: boolean = null;


    constructor(name: string, internalName: string, system?: boolean) {
        this.name = name;
        this.internalName = internalName;
        this.system = system;
    }

    static byInternalName(internalName: string): EnaioObjectId {
        return new EnaioObjectId(null, internalName);
    }

    static byInternalNames(internalNames: string[]): EnaioObjectId[] {
        return internalNames.map(name => EnaioObjectId.byInternalName(name));
    }

    static byName(name: string): EnaioObjectId {
        return new EnaioObjectId(name, null);
    }

    static byNames(names: string[]): EnaioObjectId[] {
        return names.map(name => EnaioObjectId.byName(name));
    }

    static byId(id: number): EnaioObjectId {
        let result = new EnaioObjectId(null, null);
        result.id = id;
        return result;
    }

    static byIds(ids: number[]): EnaioObjectId[] {
        return ids.map(id => this.byId(id));
    }

    getBestDisplayName(): string {
        return !Utils.isNoe(this.name) ? this.name : this.internalName;
    }


}
