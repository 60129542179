<div class="grid" style="overflow-y : auto; height: 100%">
    <p-panel class="col-8 p-docs" header="Dokumente" [style]="{'margin-bottom':'20px', 'height':'100%'}">
    <sm-table #tDocuments [data]="table" [allowExport]="false" [gridLines]="true" (onRowSelect)="rowSelect($event)" (onCellClicked)="cellClicked($event)"></sm-table>
  </p-panel>
  <p-tabView class="col-4 p-data">
    <p-tabPanel header="Vorschau">
        <enaio-document-file-preview class="full-height-200x" [documentId]="selectedDocument != null ? selectedDocument.id : 0"></enaio-document-file-preview>
    </p-tabPanel>
    <p-tabPanel header="Indexdaten">
        <enaio-document-meta-data [document]="selectedDocument"></enaio-document-meta-data>
    </p-tabPanel>
  </p-tabView>
</div>
