<ng-container *ngIf="inited">
    <sm-toolbar>
        <p-button icon="fas fa-save" label="Speichern" (onClick)="save()">
        </p-button>
    </sm-toolbar>
    <div class="grid">
        <p-card class="col-12 md:col-6">
            <sm-form [form]="form">
            </sm-form>
            <span *ngIf="lesson.changedBy != ''">Geändert von {{lesson.changedBy}} am {{lesson.changedDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
        </p-card>
        <div class="col-12 md:col-6">
            <div class="form-group grid mb-1 mt-1">
                <span class="col-12">
                    <p-checkbox name="missingPupilsChecked" [(ngModel)]="lesson.missingPupilsChecked" [binary]="true"></p-checkbox>
                    <label class="form-label ml-2">Abwesenheit geprüft</label>
                </span>
            </div>
            <p-table class="compact" [value]="pupilRows" [styleClass]="'p-datatable-gridlines'">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="align-left" [style]="{width: '33%'}">Name</th>
                        <th class="align-left" [style]="{width: '33%'}">Anwesenheit</th>
                        <th class="align-left" [style]="{width: '33%'}">Bemerkung</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row let-index="rowIndex">
                    <tr [pSelectableRow]="row">
                        <td class="align-left">{{row.pupil.getFullName()}}</td>
                        <td class="align-left">
                            <p-checkbox name="missing" [(ngModel)]="row.lessonPupil.missing" [trueValue]=false [falseValue]=true [binary]="true"></p-checkbox>
                            <tag-button class="sn ml-2" [value]="row.sickNote == null ? 'Krankmeldung eintragen' : _Utils.crop(row.sickNote.reason, 15)" [severity]="row.sickNote == null ? 'warning' : 'danger'" [active]="row.sickNote" [clickable]="!row.sickNote" (onClick)="addSickNote(row)"></tag-button>
                        </td>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText [(ngModel)]="row.lessonPupil.note" (onFocus)="_GuiUtils.selectAll($event)">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{row.lessonPupil.note}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</ng-container>
