import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '../models/can-component-deactivate.model';

@Injectable({
    providedIn: 'root'
})
export class CanDeactivateGuard {

    constructor(private router: Router, private location: Location) {
    }

    canDeactivate(component: CanComponentDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, _nextState: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        try {
            let result = component?.canDeactivate ? component.canDeactivate() : true;

            //Kleiner Fix, ohne das wird Browserhistory zerstört
            //https://github.com/angular/angular/issues/13586
            if (!result && this.router.getCurrentNavigation().trigger === "popstate") {
                this.location.go(currentState.url);
            }

            return result;
        }
        catch (ex) {
            console.error(ex);
            return true;
        }
    }
}
