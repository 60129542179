import { EnaioAddonAddUserColumn } from "./EnaioAddonAddUserColumn";

export class EnaioAddonAddUserGroup {
    column: EnaioAddonAddUserColumn;
    groups = false;
    users = false;
    excludeGroups: string[];
    exclusiveGroups: string[];
    filterGroups = "";
    sortCol = 0;
}
