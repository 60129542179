import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { AppTemplateModule } from '../app-template/app-template.module';
import { AppTab } from '../app-template/models/app-tab.model';
import { ClassGradesComponent } from '../enaio-certificates/components/class-grades/class-grades.component';
import { GradeBookAdminComponent } from '../enaio-certificates/components/grade-book-admin/grade-book-admin.component';
import { GradeBookMainComponent } from '../enaio-certificates/components/grade-book-main/grade-book-main.component';
import { GradeEditorComponent } from '../enaio-certificates/components/grade-editor/grade-editor.component';
import { PrimeModule } from '../prime/prime.module';
import { FeatureModule } from '../sm-base/models/feature.module';
import { FeatureModuleService } from '../sm-base/services/feature-module.service';
import { SmBaseModule } from '../sm-base/sm-base.module';
import { UtilsModule } from '../utils/utils.module';
import { CertificatesMainComponent } from './components/certificates-main/certificates-main.component';
import { ClassAnalysesDanglingLessonsComponent } from './components/class-analyses-dangling-lessons/class-analyses-dangling-lessons.component';
import { ClassAnalysesIncidentsComponent } from './components/class-analyses-incidents/class-analyses-incidents.component';
import { ClassAnalysesMissDaysComponent } from './components/class-analyses-miss-days/class-analyses-miss-days.component';
import { ClassAnalysesComponent } from './components/class-analyses/class-analyses.component';
import { ClassDutiesComponent } from './components/class-duties/class-duties.component';
import { ClassHistoryComponent } from './components/class-history/class-history.component';
import { ClassInstructionComponent } from './components/class-instruction/class-instruction.component';
import { ClassLessonComponent } from './components/class-lesson/class-lesson.component';
import { ClassPupilsComponent } from './components/class-pupils/class-pupils.component';
import { ClassRegisterAdminComponent } from './components/class-register-admin/class-register-admin.component';
import { ClassRegisterComponent } from './components/class-register/class-register.component';
import { ClassRegistersMainComponent } from './components/class-registers-main/class-registers-main.component';
import { ClassTeacherComponent } from './components/class-teacher/class-teacher.component';
import { ClassTimetableComponent } from './components/class-timetable/class-timetable.component';
import { ClassWeekComponent } from './components/class-week/class-week.component';
import { CovidTestsMainComponent } from './components/covid-tests-main/covid-tests-main.component';
import { CovidTestsComponent } from './components/covid-tests/covid-tests.component';
import { EditCertificateComponent } from './components/edit-certificate/edit-certificate.component';
import { EditClassCourseComponent } from './components/edit-class-course/edit-class-course.component';
import { EditClassCoursesComponent } from './components/edit-class-courses/edit-class-courses.component';
import { GlobalSettingsComponent } from './components/global-settings/global-settings.component';
import { HomeComponent } from './components/home/home.component';
import { PupilListComponent } from './components/pupil-list/pupil-list.component';
import { SickNotesComponent } from './components/sick-notes/sick-notes.component';
import { EnaioCertificatesRoutingModule } from './enaio-certificates-routing.module';
import { EnaioCertificateService } from './services/enaio-certificate.service';
import { ClassHolidaysComponent } from './components/class-holidays/class-holidays.component';
import { ClassAnalysesHomeworkComponent } from './components/class-analyses-homework/class-analyses-homework.component';
import { CovidTestsAnalysesComponent } from './components/covid-tests-analyses/covid-tests-analyses.component';
import { ClassFieldTripsComponent } from './components/class-field-trips/class-field-trips.component';

@NgModule({
    imports: [
        AppTemplateModule,
        CommonModule,
        SmBaseModule,
        EnaioCertificatesRoutingModule,
        PrimeModule,
        UtilsModule,
        TranslocoModule
    ],
    declarations: [
        CertificatesMainComponent,
        PupilListComponent,
        EditCertificateComponent,
        GlobalSettingsComponent,
        GradeBookMainComponent,
        ClassGradesComponent,
        GradeBookAdminComponent,
        GradeEditorComponent,
        CovidTestsMainComponent,
        CovidTestsComponent,
        SickNotesComponent,
        ClassRegistersMainComponent,
        ClassRegisterComponent,
        ClassTimetableComponent,
        ClassHistoryComponent,
        ClassWeekComponent,
        ClassLessonComponent,
        ClassDutiesComponent,
        EditClassCoursesComponent,
        EditClassCourseComponent,
        ClassPupilsComponent,
        ClassRegisterAdminComponent,
        ClassTeacherComponent,
        ClassInstructionComponent,
        ClassAnalysesComponent,
        ClassAnalysesMissDaysComponent,
        ClassAnalysesDanglingLessonsComponent,
        ClassAnalysesIncidentsComponent,
        HomeComponent,
        ClassHolidaysComponent,
        ClassAnalysesHomeworkComponent,
        CovidTestsAnalysesComponent,
        ClassFieldTripsComponent
    ]
})
export class EnaioCertificatesModule implements FeatureModule {

    constructor(private featureModuleService: FeatureModuleService, private enaioCertificateService: EnaioCertificateService) {
        featureModuleService.register(this);
    }

    registerSidebar(model: AppTab[]): void {
        model.push(new AppTab("class-registers", "Digitales Klassenbuch", "assets/images/certificates/iconClassRegister.svg", 100, [], ["/enaio-certificates", "class-registers", "home"], false, true));
        model.push(new AppTab("class-registers-courses", "Kursbuch", "assets/images/certificates/iconCourses.svg", 101, [], ["/enaio-certificates", "class-registers", "courses"], false, true));
        model.push(new AppTab("grade-book", "Notenbuch", "assets/images/certificates/iconGradeBook.svg", 102, [], ["/enaio-certificates", "grade-book", "home"], false, true));
        model.push(new AppTab("certificates", "Zeugnisse", "assets/images/certificates/iconCertificates.svg", 103, [], ["/enaio-certificates", "home"], false, true));
        model.push(new AppTab("covid-tests", "Corona-Testungen", "fas fa-virus", 104, [], ["/enaio-certificates", "covid-tests", "home"]));
    }

    async updateSidebarVisibility(model: AppTab[]): Promise<void> {
        model.find(t => t.id == "class-registers").visible = await this.enaioCertificateService.isClassRegisterEditor();
        model.find(t => t.id == "class-registers-courses").visible = await this.enaioCertificateService.isClassRegisterEditor();
        model.find(t => t.id == "grade-book").visible = await this.enaioCertificateService.isCertificateEditor();
        model.find(t => t.id == "certificates").visible = await this.enaioCertificateService.isCertificateEditor();
        model.find(t => t.id == "covid-tests").visible = await this.enaioCertificateService.isClassRegisterEditor();
    }

    getMainRoute(): Route {
        return this.featureModuleService.createModuleRoute("enaio-certificates", () => EnaioCertificatesRoutingModule);
    }
}
