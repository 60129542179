<ng-container *transloco="let t">
    <div *ngIf="inited" class="grid">
        <div class="col-12" style="text-align: left">
            <p-button class="toolbar-element" icon="fas fa-save" [label]="t('general.save')" (onClick)="save()">
            </p-button>
            <p-button class="toolbar-element" icon="fas fa-times" [label]="t('general.cancel')" (onClick)="cancel()">
            </p-button>
        </div>
        <div class="col-12 grid">
            <p-card class="col-12 md:col-6">
                <sm-form [form]="form">
                </sm-form>
            </p-card>
            <p-tree #tree class="col-6" [value]="pupilsTree.items" selectionMode="checkbox" [(selection)]="pupilsTree.selected"></p-tree>
        </div>
    </div>
</ng-container>
