/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { Pupil } from 'src/modules/enaio-certificates/shared/pupil.model';
import { CustomInitializer } from 'src/modules/sm-base/shared/custom-initializer.interface';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne, OneToMany } from 'typeorm';
import { ExamGrade } from './exam-grade.entity';
import { ExamType } from './exam-type.entity';
import { GradeBookSchoolClass } from './grade-book-school-class.entity';

@Entity()
export class Exam extends SmBaseEntity implements CustomInitializer {
    @ManyToOne(() => GradeBookSchoolClass, (o: GradeBookSchoolClass) => o.exams, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    schoolClass: GradeBookSchoolClass;

    @Column({default: ""})
    domain: string = "";

    @ManyToOne(() => ExamType, (o: ExamType) => o.exams, { onDelete: "SET NULL", nullable: true})
    examType: ExamType;

    @Column({default: ""})
    title: string = "";

    @Column()
    @Type(() => Date)
    date: Date;

    @OneToMany(() => ExamGrade, o => o.exam)
    @Type(() => ExamGrade)
    grades: ExamGrade[];

    average = 0;

    customInitializer(): void {
        this.grades = Utils.arrayEnsure(this.grades);
    }

    fillMissingGrades(pupils: Pupil[]): void {
        for (let pupil of pupils) {
            if (!this.grades.some(g => g.pupilId == pupil.id)) {
                this.grades.push(Utils.fromPlain(ExamGrade, { pupilId: pupil.id }));
            }
        }
    }

    getGradeForPupil(pupilId: number): ExamGrade {
        return this.grades.find(g => g.pupilId == pupilId) || new ExamGrade();
    }

    getStringRepresentation(withDomain = false): string {
        return (withDomain ? this.domain + " - " : "") + (this.examType != null ? this.examType.title : "<kein Typ>") + " - " + this.title + " - " + Utils.dateFormat(this.date, "dd.mm.yyyy");
    }

    getTypeId(): number {
        return this.examType != null ? this.examType.id : 0;
    }

    calculateAverage(): void {
        let result = 0;
        let count = 0;
        for (let eg of this.grades) {
            if (eg.isRated()) {
                count++;
                result += eg.grade;
            }
        }
        this.average = count == 0 ? 0 : result / count;
    }

    isDictation(): boolean {
        return this.examType?.title == "Diktat";
    }
}
