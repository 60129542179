import { Type } from "class-transformer";
import { EnaioWorkflowBasicDataType } from "./EnaioWorkflowBasicDataType";
import { EnaioWorkflowDataMember } from "./EnaioWorkflowDataMember";

export class EnaioWorkflowDataType {
    basic: EnaioWorkflowBasicDataType;
    @Type(() => EnaioWorkflowDataMember)
    recordMembers: EnaioWorkflowDataMember[];
    declaredTypeId: string;
    isArray = false;
    typeId: string;
}
