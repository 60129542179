import { Component, Input, OnDestroy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { BackendProcess } from 'src/modules/utils/shared/backend-process.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { RestEndpoint } from '../../models/rest-endpoint.model';

export interface WaitingFormStatus {
    progress: number;
    state: string;
}

@Component({
  selector: 'app-waiting-form',
  templateUrl: './waiting-form.component.html',
  styleUrls: ['./waiting-form.component.scss']
})
export class WaitingFormComponent implements OnDestroy {

    @Input()
    message = "";
    @Input()
    progressUrl: string = null;
    @Input()
    callback: () => WaitingFormStatus = null;

    timer: Subscription;
    progress = 0;
    progressStatus = "";

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
        this.message = config.data.message || "";
        this.progressUrl = config.data.progressUrl;
        this.callback = config.data.callback;

        if (!this.isIndefinite()) {
            this.timer = Utils.setTimer(1000, this.onTimer.bind(this));
        }
    }

    ngOnDestroy(): void {
        if (this.timer != null) {
            this.timer.unsubscribe();
        }
    }

    async onTimer(): Promise<void> {
        if (this.callback != null) {
            let s = this.callback();
            this.progress = s.progress;
            this.progressStatus = s.state;
        }
        else {
            let p = await RestEndpoint.main().run(this.progressUrl).get(BackendProcess);
            this.progress = p == null ? 0 : Math.ceil(p.done * 100 / Math.max(1, p.max));
            this.progressStatus = p == null ? "" : p.status;
        }
    }

    isIndefinite(): boolean {
        return this.progressUrl == null && this.callback == null;
    }
}
