import { Type } from "class-transformer";
import { EnaioComparison } from "./EnaioComparison";
import { EnaioObjectId } from "./EnaioObjectId";

export class EnaioCondition {
    @Type(() => EnaioObjectId)
    field: EnaioObjectId;
    @Type(() => EnaioObjectId)
    conditionObject: EnaioObjectId;
    value: any;
    comparison = "=";
    @Type(() => EnaioComparison)
    orValues: EnaioComparison[];
    containsPlaceholders = false;

    constructor(field: EnaioObjectId, value: string, comparison = "=", conditionObject: EnaioObjectId = null) {
        this.field = field;
        this.value = value;
        this.comparison = comparison;
        this.conditionObject = conditionObject;
    }


}
