import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { Type } from "class-transformer";
import { EnaioWorkflowDataField } from "./EnaioWorkflowDataField";
import { EnaioWorkflowDocument } from "./EnaioWorkflowDocument";
import { EnaioWorkflowMask } from "./EnaioWorkflowMask";
import { EnaioWorkItemParameter } from "./EnaioWorkItemParameter";
import { EnaioWorkItemState } from "./EnaioWorkItemState";

export class EnaioWorkItem {
    id: string;
    absent = false;
    substitute = false;
    flags = 0;
    name: string;
    email: string;
    user = false;
    state: EnaioWorkItemState;
    personalizedBy: string;
    processId: string;
    processName: string;
    processSubject: string;
    activityId: string;
    activityName: string;
    warningTime = 0;
    overTime = 0;
    @Type(() => Date)
    creationTime: Date;
    workflowId: string;
    iconId = 0;
    adhoc = false;
    workflowVersion = 0;
    objectId: string;
    @Type(() => EnaioWorkItemParameter)
    parameters: EnaioWorkItemParameter[];
    @Type(() => EnaioWorkflowDocument)
    files: EnaioWorkflowDocument[];
    @Type(() => EnaioWorkflowMask)
    masks: EnaioWorkflowMask[];
    @Type(() => EnaioWorkflowDataField)
    dataFields: EnaioWorkflowDataField[];

    public getParameterObject(): OrdinaryObject {
        let result: OrdinaryObject = {};
        for (let param of this.parameters) {
            result[param.name] = param.value.value;
        }
        return result;
    }


}
