import { Type } from "class-transformer";
import { EnaioAddonAddPidItem } from "./EnaioAddonAddPidItem";

export class EnaioAddonAddPid {
    @Type(() => EnaioAddonAddPidItem)
    folder: EnaioAddonAddPidItem;
    @Type(() => EnaioAddonAddPidItem)
    register: EnaioAddonAddPidItem;
    @Type(() => EnaioAddonAddPidItem)
    document: EnaioAddonAddPidItem;
    counterWidth = 0;
    yearLength = 0;
}
