import { Type } from "class-transformer";
import { EnaioAddonCounterRegisterFields } from "./EnaioAddonCounterRegisterFields";

export class EnaioAddonCounter {
    counterWidth = 0;
    initialValue = "";
    useDbCounter = false;
    format = "";
    counterType = 0;
    @Type(() => EnaioAddonCounterRegisterFields)
    fieldsByRegister: EnaioAddonCounterRegisterFields[];
}
