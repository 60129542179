import { Utils } from "src/modules/utils/shared/utils";

export class CourseParticipantDto {
    constructor(public pupilId: number, public courseId: number, public courseName: string, public courseNameForCertificate: string, public forCertificate: boolean) {
    }

    getNameForCertificate(): string {
        return Utils.stringDef(this.courseNameForCertificate, this.courseName);
    }
}
