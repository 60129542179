/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";
import { CustomInitializer } from "src/modules/sm-base/shared/custom-initializer.interface";
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Utils } from "src/modules/utils/shared/utils";
import { Column, Entity, OneToMany, Unique } from "typeorm";
import { ClassDuty } from "./class-duty.entity";
import { ClassHistory } from "./class-history.entity";
import { ClassHoliday } from "./class-holiday.entity";
import { ClassInstruction } from "./class-instruction.entity";
import { ClassLesson } from "./class-lesson.entity";
import { ClassTeacher } from "./class-teacher.entity";
import { ClassTimetableEntry } from "./class-timetable-entry.entity";
import { SchoolClass } from "./school-class.model";
import { SickNote } from "./sick-note.entity";
import { ClassFieldTrip } from "./class-field-trip.entity";

@Entity()
@Unique(["schoolClassId", "isHalfYear"])
export class ClassRegister extends SmBaseEntity implements CustomInitializer {
    @Column({default: 0})
    schoolClassId: number = 0;

    @Column({default: false})
    isHalfYear: boolean = false;

    @Column({nullable: true})
    @Type(() => Date)
    contractedOutDate: Date;

    @OneToMany(() => ClassDuty, o => o.classRegister, { cascade: true })
    @Type(() => ClassDuty)
    duties: ClassDuty[];

    @OneToMany(() => ClassTeacher, o => o.classRegister, { cascade: true })
    @Type(() => ClassTeacher)
    teachers: ClassTeacher[];

    @OneToMany(() => ClassTimetableEntry, o => o.classRegister, { cascade: true })
    @Type(() => ClassTimetableEntry)
    timetableEntries: ClassTimetableEntry[];

    @OneToMany(() => ClassInstruction, o => o.classRegister, { cascade: true })
    @Type(() => ClassInstruction)
    instructions: ClassInstruction[];

    @OneToMany(() => ClassLesson, o => o.classRegister, { cascade: true })
    @Type(() => ClassLesson)
    lessons: ClassLesson[];

    @OneToMany(() => SickNote, o => o.classRegister, { cascade: true })
    @Type(() => SickNote)
    sickNotes: SickNote[];

    @OneToMany(() => ClassHoliday, o => o.classRegister, { cascade: true })
    @Type(() => ClassHoliday)
    holidays: ClassHoliday[];

    @OneToMany(() => ClassFieldTrip, o => o.classRegister, { cascade: true })
    @Type(() => ClassFieldTrip)
    fieldTrips: ClassFieldTrip[];

    @OneToMany(() => ClassHistory, o => o.classRegister, { cascade: true })
    @Type(() => ClassHistory)
    history: ClassHistory[];

    enaioSchoolClass: SchoolClass;

    customInitializer(): void {
        this.duties = Utils.arrayEnsure(this.duties);
        this.teachers = Utils.arrayEnsure(this.teachers);
        this.timetableEntries = Utils.arrayEnsure(this.timetableEntries);
        this.instructions = Utils.arrayEnsure(this.instructions);
        this.lessons = Utils.arrayEnsure(this.lessons);
        this.history = Utils.arrayEnsure(this.history);
        this.holidays = Utils.arrayEnsure(this.holidays);
        this.sickNotes = Utils.arrayEnsure(this.sickNotes);
    }

    getTeacher(domain: string): string {
        return this.teachers.find(t => t.domain == domain)?.teacher;
    }

    getTimetableValidDates(): Date[] {
        return Utils.arraySort(Utils.arrayGetUnique(this.timetableEntries.map(te => te.validFrom), d => d?.getTime()).filter(d => d != null));
    }

    getTimetableEntriesForDate(date: Date): ClassTimetableEntry[] {
        let validDate = this.getTimetablePrevValidDate(date);
        return this.timetableEntries.filter(te => Utils.dateEq(te.validFrom, validDate));
    }

    getTimetablePrevValidDate(date: Date): Date {
        if (date == null) {
            return null;
        }
        let validDates = this.getTimetableValidDates();
        let result: Date = null;
        for (let validDate2 of validDates) {
            if (Utils.dateGreater(validDate2, date)) {
                break;
            }
            result = validDate2;
        }
        return result;
    }

    duplicateTimetableEntries(oldDate: Date, newDate: Date): void {
        let old = this.getTimetableEntriesForDate(oldDate);
        this.timetableEntries = [...this.timetableEntries, ...old.map(te => Utils.fromPlain(ClassTimetableEntry, {
            id: 0,
            classRegister: te.classRegister,
            weekDay: te.weekDay,
            hour: te.hour,
            validFrom: newDate,
            domain: te.domain,
            domainWeekB: te.domainWeekB
        }))];
    }

    isSchoolHour(date: Date, hour: number): boolean {
        let weekDay = Utils.dateDayOfWeek(date);
        if (weekDay == 5 || weekDay == 6) {
            return false;
        }
        return !this.holidays.some(h => h.from <= date && h.to >= date) && !this.fieldTrips.some(ft => ft.approvedDate != null && ft.includes(date, hour));
    }

}
