export enum EnaioScriptType {
    client = 0,
    server = 1,
    clientGlobal = 2,
    serverGlobal = 3,
    all = 4
}
export namespace EnaioScriptType{
    export function getName(value: EnaioScriptType): string {
        switch (value) {
            case EnaioScriptType.client:
                return "client";
            case EnaioScriptType.server:
                return "server";
            case EnaioScriptType.clientGlobal:
                return "clientGlobal";
            case EnaioScriptType.serverGlobal:
                return "serverGlobal";
            case EnaioScriptType.all:
                return "all";
        }
    }
}
