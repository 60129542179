<div *ngIf="loaded" class="login-body">
    <p-toast position="top-center">
    </p-toast>
    <div class="login-panel ui-fluid">
      <div class="grid">
        <div class="col-12 logo-container">
          <img src="assets/layout/images/logo-colored.png" />
          <h1>Erste Anmeldung</h1>
          <h2>{{app.appName}}</h2>
        </div>
        <span *ngIf="authRules.useRecoveryQuestions" style="text-align: left;margin-bottom: 20px">Sie melden sich gerade das erste Mal an. Bitte vergeben Sie ein neues Passwort und beantworten Sie mindestens {{authRules.minRecoveryQuestions}} der untenstehenden Sicherheitsfragen.</span>
        <span *ngIf="!authRules.useRecoveryQuestions" style="text-align: left;margin-bottom: 20px">Sie melden sich gerade das erste Mal an. Bitte vergeben Sie ein neues Passwort.</span>
        <span style="text-align: left;margin-bottom: 20px">{{pwText}}</span>
        <sm-form [form]="form">
        </sm-form>
        <div class="col-12 button-container">
          <p-button styleClass="width100p" icon="fas fa-sign-in" label="Abschließen" (onClick)="send()">
          </p-button>
          <a [routerLink]="['/base', 'login']">
            <p-button styleClass="width100p" icon="fas fa-lock" label="Abbrechen" (onClick)="cancel()">
            </p-button>
          </a>
          <div *ngIf="running">
            <div>Einrichtung läuft ...</div>
            <p-progressSpinner></p-progressSpinner>

        </div>
      </div>
    </div>
  </div>
