<ng-container *transloco="let t">
    <div class="">
        <p-steps *ngIf="!isMobile" class="chapter-steps" [model]="guiChapters" [activeIndex]="selectedChapterIndex" [readonly]="false" (activeIndexChange)="chapterChanged($event)"></p-steps>
        <p-dropdown *ngIf="isMobile" class="" [editable]="false" [styleClass]="'w-full'" [panelStyleClass]="'w-full'" [options]="guiChaptersMobile" [ngModel]="selectedChapterIndex" (ngModelChange)="chapterChanged($event)"></p-dropdown>
        <div class="grid mt-2">
            <p-accordion *ngIf="!_Utils.isNoe(selectedChapter?.attachments)" class="col-12 lg:col-6 lg:col-offset-3">
                <p-accordionTab header="Anhänge" [selected]=true>
                    <div class="grid">
                        <div *ngFor="let attachment of selectedChapter.attachments" class="grid col-12 mt-3 ml-2">
                            <i [class]="'mr-2 fas fa-' + attachment.getIcon()"></i>
                            <a *ngIf="attachment.type == _LearningContentAttachmentType.pdf" href="#" (click)="openAttachment($event, attachment)">{{attachment.title}}</a>
                            <span *ngIf="attachment.type != _LearningContentAttachmentType.pdf">{{attachment.title}}</span>
                            <audio *ngIf="attachment.type == _LearningContentAttachmentType.audio" controls controlsList="nodownload" preload="none" class="audio mt-3">
                                <source [src]="getFileUrl(attachment.fileName)" type="audio/mp3">
                            </audio>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
            <p-panel *ngIf="selectedChapter != null" class="col-12 lg:col-6 lg:col-offset-3" [header]="selectedChapter.title + (showQuestions ? ' ' + t('lea.attend.questionIndex', {index: 0 + 1, total: chapterQuestions.length}) : '')">
                <div *ngIf="!showQuestions" id="chapter-text" [innerHTML]="chapterText">
                </div>
                <app-questions #questionsCompo *ngIf="showQuestions" [chapterQuestions]="chapterQuestions" (smileyStatus)="smileyStatus=$event" [style]="{'height': '100%', 'width': '100%', 'padding': '15px'}" (done)="questionsDone()" (smileyStatus)="setSmiley($event)">
                </app-questions>
            </p-panel>

            <div class="col-12 lg:col-6 lg:col-offset-3" style="text-align: left">
                <button pButton *ngIf="(part != null && (!part.isFinished() || chapterQuestions.length > 0)) && !showQuestions" class="toolbar-element p-button-success" icon="fas fa-check"
                    [label]="t(chapterQuestions.length > 0 ? part.isFinished() ? 'lea.attend.proceedToQuestions' : 'lea.attend.finishChapter' : 'lea.attend.finishChapter')" (click)="finishChapter()">
                </button>
                <p-button *ngIf="showQuestions" class="toolbar-element" icon="fas fa-check" [label]="t('lea.attend.finishQuestion')" (onClick)="finishQuestion()">
                </p-button>
                <p-button *ngIf="showQuestions" class="toolbar-element" icon="fas fa-undo" [label]="t('lea.attend.backToChapter')" (onClick)="backToChapter()">
                </p-button>
                <p-button *ngIf="mayStartFinalExam" class="toolbar-element" icon="fas fa-chalkboard" [label]="t('lea.attend.proceedToFinalExam')" (onClick)="startFinalExam()">
                </p-button>
                <p-button class="toolbar-element" icon="fas fa-question" [label]="t('lea.attend.questionAuthor')" (onClick)="questionAuthor()">
                </p-button>
            </div>
        </div>
    </div>
    <div class="smiley" [class.smiley-happy]="smileyStatus == 1 || smileyStatus == -1" [class.smiley-sad]="smileyStatus == 2|| smileyStatus == -2" [@smiley]="smileyStatus > 0 ? 'visible' : 'hidden'">
        <span class="fas" [class.fa-smile]="smileyStatus == 1|| smileyStatus == -1" [class.fa-frown]="smileyStatus == 2|| smileyStatus == -2"></span>
    </div>

</ng-container>
<p-dialog [header]="displayedAttachment?.title" [(visible)]="displayPdf" [modal]="true" [position]="'bottom'" [style]="isMobile ? {width: '100vw', height: 'calc(100% - 50px)'} : {width: '80vw', height: '80%'}" [contentStyle]="{width: '100%', height: '100%'}" [baseZIndex]="10000"
    [draggable]="true" [resizable]="true">
    <div style="width: 100%; height: 100%">
        <ng2-pdfjs-viewer #viewer ></ng2-pdfjs-viewer>
    </div>
</p-dialog>
<p-dialog [header]="displayedAttachment?.title" [(visible)]="displayAudio" [modal]="true" [contentStyle]="{width: '100%', height: '100%'}" [baseZIndex]="10000"
    [draggable]="true" [resizable]="true">
    <audio controls preload="none">
        <source [src]="displayAudioFile" type="audio/mp3">
    </audio>
</p-dialog>
