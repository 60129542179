<p-tabView *ngIf="inited">
    <p-tabPanel header="Neue Krankmeldung" [headerStyle]="id == null ? {display: 'none'} : {}">
        <div class="grid">
            <div class="col-4 col-offset-4">
                <sm-form  [form]="form">
                </sm-form>
                <p-button class="toolbar-element" icon="fas fa-run" label="Ablegen" (onClick)="addSickNote()"></p-button>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel *ngIf="id != null" header="Krankmeldungen verwalten">
        <sm-table [data]="table" [deltaHeight]=150 [allowDelete]="isAdmin" [allowExport]=false (onDelete)="deleteSickNote($event)" (onCellClicked)="sickNoteClicked($event)">
    </sm-table>
    </p-tabPanel>
</p-tabView>
