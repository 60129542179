import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PrimeModule } from '../prime/prime.module';
import { AuthInterceptor } from '../sm-base/misc/auth.interceptor';
import { SmBaseModule } from '../sm-base/sm-base.module';
import { UtilsModule } from '../utils/utils.module';
import { AppFooterComponent } from './components/app-footer/app.footer.component';
import { AppMainComponent } from './components/app-main/app-main.component';
import { AppMenuItemComponent } from './components/app-menu-item/app.menu-item.component';
import { AppSideBarComponent } from './components/app-sidebar/app.sidebar.component';
import { AppSideBarTabContentComponent } from './components/app-sidebartabcontent/app.sidebartabcontent.component';
import { AppTopbarComponent } from './components/app-topbar/app.topbar.component';
import { MenuService } from './services/app.menu.service';
import { TranslationService } from './services/translation.service';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PrimeModule,
        UtilsModule,
        SmBaseModule
    ],
    declarations: [
        AppMainComponent,
        AppMenuItemComponent,
        AppTopbarComponent,
        AppFooterComponent,
        AppSideBarTabContentComponent,
        AppSideBarComponent
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        MenuService,
        TranslationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ]
})
export class AppTemplateModule {
}
