import { Injectable } from '@angular/core';
import { LoadChildrenCallback, Route, mapToCanActivate } from '@angular/router';
import { Config } from 'src/modules/app-template/models/config.model';
import { AuthGuard } from '../misc/auth.guard';
import { FeatureModule } from '../models/feature.module';

@Injectable({providedIn: "root"})
export class FeatureModuleService {

    modules: FeatureModule[] = [];

    register(module: FeatureModule): void {
        if (Config.get().activeModules.includes(module.constructor.name) && this.modules.find(item => item.constructor.name === module.constructor.name) == null) {
            this.modules.push(module);
        }
    }

    getModules(): FeatureModule[] {
        return this.modules;
    }

    //Der Parameter modulePath sollte auf das RoutingModule verweisen, nicht auf das Modul an sich
    //Im Falle, dass ein Modul ein anderen Modul mit Routen importiert werden sonst Routen vermischt
    createModuleRoute(path: string, modulePath: LoadChildrenCallback): Route {
        return {
            path,
            canActivate: mapToCanActivate([AuthGuard]),
            loadChildren: modulePath
        };
    }
}
