import { Component, Input } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonDefinition } from '../../models/button-definition.model';

@Component({
    selector: 'sm-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: []
})
export class MessageDialogComponent {

    @Input()
    message = "";
    @Input()
    buttons: ButtonDefinition[] = [];
    @Input()
    icon = "";

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
        this.message = config.data.message || "";
        this.buttons = config.data.buttons || [];
    }

    click(id: string): void {
        this.ref.close(id);
    }

}
