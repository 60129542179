import { Type } from "class-transformer";
import { EnaioWorkflowDataType } from "./EnaioWorkflowDataType";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class EnaioWorkItemParamValue {
    plain = "";
    value: any;
    @Type(() => EnaioWorkflowDataType)
    dataType: EnaioWorkflowDataType;
    types: OrdinaryObject<EnaioWorkflowDataType>;
}
