<ng-container *transloco="let t">
    <div class="grid">
        <p-card *ngFor="let question of questions; index as index"  class="col-12 lg:col-6 lg:col-offset-3">
            <h4>
                {{question.question}}
            </h4>
            <h6 *ngIf="question.correctAnswers.length > 1">
                {{t('lea.attend.messages.multipleCorrectAnswers')}}
            </h6>
            <div *ngFor="let a of answers[index]; index as index2" class="mb-2">
                <p-radioButton *ngIf="question.correctAnswers.length == 1" [name]="'choice_' + index2" [value]="index2" [(ngModel)]="givenAnswersSingle[index]" [inputId]="'choice_' + index + '_' + index2"></p-radioButton>
                <p-checkbox *ngIf="question.correctAnswers.length > 1" [name]="'choice_' + index2" [binary]=true [value]="index2" [(ngModel)]="givenAnswersMulti[index][index2]" [inputId]="'choice_' + index + '_' + index2"></p-checkbox>
                {{a}}
            </div>
        </p-card>

        <div class="col-12 lg:col-6 lg:col-offset-3" style="text-align: left">
            <button pButton class="toolbar-element p-button-success" icon="fas fa-check" [label]="t('lea.attend.finalExam.send')" (click)="send()">
            </button>
            <p-button class="toolbar-element" icon="fas fa-times" [label]="t('general.cancel')" (onClick)="cancel()">
            </p-button>
        </div>
    </div>
</ng-container>
