/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Comparable } from "src/modules/utils/shared/comparable";
import { Utils } from "src/modules/utils/shared/utils";
import { Column, Entity, OneToMany } from "typeorm";
import { LearningContent } from "./learning-content.entity";

@Entity()
export class LearningContentCategory extends SmBaseEntity implements Comparable<LearningContentCategory> {
    @Column({default: ""})
    name: string = "";

    @Column({default: 0xffffff, nullable: false})
    color: number = 0xffffff;

    @OneToMany(() => LearningContent, o => o.category)
    @Type(() => LearningContent)
    learningContents: LearningContent[];

    getStringRepresentation(): string {
        return this.name;
    }

    compareTo(other: LearningContentCategory): number {
        return Utils.cmp(this.name, other.name);
    }

}
