import { Comparable } from 'src/modules/utils/shared/comparable';
import { Utils } from 'src/modules/utils/shared/utils';

export class FrontendFieldListItem implements Comparable<FrontendFieldListItem> {

    static flatten(paths: FrontendFieldListItem[][]): FrontendFieldListItem[] {
        let result: FrontendFieldListItem[] = [];

        for (let path of paths) {
            let current: FrontendFieldListItem = null;
            for (let item of path) {
                let found = current != null && current.children == null ? null : (current != null ? current.children : result).find(i => i.value == item.value);
                if (found == null) {
                    found = item;
                    if (current != null && current.children == null) {
                        current.children = [];
                    }
                    (current != null ? current.children : result).push(found);
                }
                current = found;
            }
        }
        return result;
    }

    static sort(items: FrontendFieldListItem[]): FrontendFieldListItem[] {
        for (let item of items) {
            if (item.children != null) {
                item.children = this.sort(item.children);
            }
        }
        return Utils.arraySortBy(items, item => item.getComparable());
    }

    label?: string;
    value: any;
    internalObject: any;
    comparable: string;
    html = false;
    labelPlain?: string;
    styleClass?: string;
    icon?: string;
    title?: string;
    disabled?: boolean;
    children: FrontendFieldListItem[];

    constructor(value: any, text: string, options: Partial<FrontendFieldListItem> = null) {
        this.value = value;
        this.label = text != "" ? text : "\u00A0";
        Object.assign(this, options);
    }

    compareTo(other: FrontendFieldListItem): number {
        return this.getComparable().localeCompare(other.getComparable());
    }

    getComparable(): string {
        return this.comparable ?? this.label;
    }

}
