import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { Utils } from 'src/modules/utils/shared/utils';
import chroma from "chroma-js";

@Component({
    selector: 'app-dashboard-card',
    templateUrl: './dashboard-card.component.html',
    styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent {

    @Input()
    title = "";
    @Input()
    additionalText = "";
    @Input()
    icon = "";
    @Input()
    icon2 = "";
    @Input()
    iconIsFile = false;
    _iconColorAuto = false;
    @Input()
    iconColor = "#58799f";
    @Input()
    iconBackgroundColor = "#d1e8fa";
    @Input()
    border = "";
    @Input()
    menuButton = true;
    @Input()
    routerLink: string[];
    @Output()
    click2 = new EventEmitter();

    _backgroundColor = "white";
    fontColor = "#000000";

    constructor(private app: MainAppService) {
    }

    @Input()
    set backgroundColor(value: string) {
        this._backgroundColor = value;
        this.fontColor = Utils.toColorHexString(Utils.getFontColorForBackground(GuiUtils.colorStringToRgba(this._backgroundColor)));
        this.updateIconColors();
    }

    @Input()
    set iconColorAuto(value: boolean) {
        this._iconColorAuto = value;
        this.updateIconColors();
    }

    async click(): Promise<void> {
        this.click2.emit(null);
        if (this.routerLink != null) {
            await this.app.navigateTo(this.routerLink);
        }
    }

    private updateIconColors(): void {
        if (this._iconColorAuto) {
            this.iconColor = this._backgroundColor;
            this.iconBackgroundColor = chroma(this.iconColor).brighten(3).hex();
        }
    }
}
