import { Config } from 'src/modules/app-template/models/config.model';
import { HttpConnector } from 'src/modules/sm-base/models/http-connector.model';

export class RestEndpoint {
    url: string;

    constructor(url: string) {
        this.url = url;
    }

    static getUrl(): string {
        return Config.get().backendUrl;
    }

    static main(): HttpConnector {
        return new HttpConnector(Config.get().backendUrl);
    }

    get(): HttpConnector {
        return new HttpConnector(this.url);
    }

}
