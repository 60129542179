<div class="login-body">
    <p-toast position="top-center"></p-toast>
    <div class="login-panel ui-fluid">
      <div class="grid" [formGroup]="form">
        <div class="col-12 logo-container">
          <img src="assets/layout/images/logo-colored.png" />
          <h1>Registrierung</h1>
          <h2>{{app.appName}}</h2>
        </div>
        <div class="col-12">
          <input style="width:100%" type="text" autocomplete="off" class="p-inputtext ui-corner-all p-state-default p-component"
            placeholder="Benutzername" formControlName="userName">
        </div>
        <div class="col-12">
          <input style="width:100%" type="password" autocomplete="off" class="p-inputtext p-corner-all p-state-default p-component"
            placeholder="Passwort" formControlName="password">
        </div>
        <div class="col-12">
            <input style="width:100%" type="password" autocomplete="off" class="p-inputtext p-corner-all p-state-default p-component"
              placeholder="Passwort wiederholen" formControlName="passwordRepeat">
          </div>
          <div class="col-12 button-container">
          <p-button styleClass="width100p" icon="fas fa-sign-in" label="Registrieren" (onClick)="send()">
          </p-button>
          <a [routerLink]="['/base', 'login']">
            <p-button styleClass="width100p" icon="fas fa-lock" label="Abbrechen" (onClick)="cancel()">
            </p-button>
          </a>
          <div *ngIf="running">
            <div>Registrierung läuft ...</div>
            <p-progressSpinner></p-progressSpinner>

        </div>
        <p-message *ngIf="!running && done" [severity]="resultType" [text]="resultMessage" styleClass="mr-2"></p-message>
      </div>
    </div>
  </div>
