import { environment } from "src/environments/environment";
import { ClassType } from "../../utils/shared/class-type";
import { Utils } from "../../utils/shared/utils";

export class Config {

    private static instance: Config = null;

    static get(): Config {
        if (Config.instance == null) {
            Config.instance = Utils.fromPlainUnsafe(Config, environment);
            Config.instance.validate();
        }
        return Config.instance;
    }

    //Standard Page Title. Dieser wird je nach geöffneter Ansicht überschrieben
    pageTitle: string;
    //Echtbetrieb
    production = true;
    developer = false;
    //URL des Backends
    backendUrl: string;
    mainRoute: string;
    isPortalBackend = true;
    showBreadcrumbs = true;
    breadcrumbsInToolBar = true;
    staticHeaderHtml: string = null;
    showSidebar = true;
    showBackButton = false;
    reportErrorsToBackend = true;
    allowAnonymous = false;
    activeModules: string[];
    modules: any[];
    privacyPolicy = false;
    backendV2 = false;
    hasAdminUsers = false;

    getModuleConfig<T extends object>(classType: ClassType<T>): T {
        let name = Utils.getTypeName(classType).replace("ModuleConfig", "Module").replace("Config", "Module");
        let module = this.modules?.find(m => m.name === name);
        return Utils.fromPlainUnsafe(classType, module?.config == null ? { } : module.config);
    }

    validate(): void {
        let prefix = "Fehler in der Konfigurationsdatei: ";
        if (Utils.isNoe(this.pageTitle)) {
            throw new Error(prefix + "Feld 'pageTitle' wurde nicht angegeben");
        }
        if (this.backendUrl == null) {
            throw new Error(prefix + "Feld 'backendUrl' wurde nicht angegeben");
        }
        if (this.activeModules == null) {
            throw new Error(prefix + "Feld 'activeModules' wurde nicht angegeben");
        }
    }

}
