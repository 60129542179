import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditTemplateFieldComponent } from './components/edit-field/edit-field.component';
import { EditTemplateFieldsComponent } from './components/edit-fields/edit-fields.component';
import { EnaioFillTemplateComponent } from './components/enaio-fill-template/enaio-fill-template.component';

const routes: Routes = [
    {
        path: 'fill-template/:id/:sources',
        component: EnaioFillTemplateComponent
    },
    {
        path: 'edit-template-fields/:templateId',
        component: EditTemplateFieldsComponent
    },
    {
        path: 'edit-template-field/:id',
        component: EditTemplateFieldComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EnaioTemplatesRoutingModule {
}
