import { EnaioDocument } from 'src/modules/enaio/shared/EnaioDocument';
import { EnaioHelper } from 'src/modules/enaio/shared/enaio-helper.model';
import { OrdinaryObject } from 'src/modules/utils/shared/ordinary-object.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { TemplateFieldDataType } from "./template-field-datatype.enum";
import { TemplateFieldListItem } from './template-field-list-item.model';
import { Template } from './template.model';
import { TemplateSourceDefinition } from './template.source-definition.model';

export class TemplateField {

    static getTemplateFieldName(caption: string, templateFieldName: string): string {
        return Utils.isNoe(templateFieldName) ? Utils.replaceAll(Utils.replaceAll(caption, "\\s", "_"), "[\\[\\]\\.%]", "") : templateFieldName;
    }

    caption = "";
    mandatory = true;
    internal = false;
    dataType: TemplateFieldDataType = TemplateFieldDataType.text;
    sourceId = 0;
    prio = 100;

    sourceFieldName = "";
    templateFieldName = "";

    calculation = "";
    filterPossibility = "";
    value = "";
    dependsOn: string[] = [];
    dropdownEditable = true;
    multiLine = false;
    addBulletPoints = false;
    possibilities: TemplateFieldListItem[] = [];
    toolTip: string = null;
    dynamicRest = false;

    sourceDefinitionName = "";
    sourceDefinition: TemplateSourceDefinition;

    guiGroup = "";
    guiCols = 12;

    guiItems: string[] = [];

    postProcess(template: Template): void {
        this.sourceDefinition = template.sourceDefinitions.find(def => def.name === this.sourceDefinitionName);
    }

    getSourceName(): string {
        return Utils.isNoe(this.sourceFieldName) ? this.caption : this.sourceFieldName;
    }

    getTemplateFieldName(): string {
        return TemplateField.getTemplateFieldName(this.caption, this.templateFieldName);
    }

    fillWithTechnicalFolderEntry(entry: EnaioDocument, suffix = ""): void {
        this.caption = entry.getStringByInternal("caption") + suffix;
        this.templateFieldName = entry.getStringByInternal("fieldName");
        if (!Utils.isNoe(this.templateFieldName)) {
            this.templateFieldName += suffix;
        }
        this.possibilities = entry.getByInternal("options").map((o: any) => Utils.fromPlain(TemplateFieldListItem, { caption: o[1].value }));
    }

    evalCalculation(data: OrdinaryObject): any {
        return Utils.makeFunctionFromCode("let data = arguments[0]; return " + this.calculation + ";")(data);
    }

    updatePossibilities(data: OrdinaryObject): void {
        if (Utils.isNoe(this.filterPossibility)) {
            for (let poss of this.possibilities) {
                poss.visible = true;
            }
        }
        else {
            let code = "let data = arguments[0]; let ownData = arguments[1]; return " + this.filterPossibility + ";";
            let fun = Utils.makeFunctionFromCode(code);
            for (let poss of this.possibilities) {
                try {
                    poss.visible = Utils.toBool(fun(data, poss.data));
                }
                catch (error: any) {
                    throw new Error("Fehler beim Evaluierung für Feld: " + this.templateFieldName + "\nCode: " + code + "\nFehlermeldung: " + error);
                }
            }
        }
        this.guiItems = Utils.arraySort(this.possibilities.filter(p => p.visible).map(p => Utils.getTemplateFunction(p.caption)(data)));
    }

    isCalculated(): boolean {
        return !Utils.isNoe(this.calculation);
    }

    formatValueForTemplate(): string {
        if (this.dataType === TemplateFieldDataType.decimal) {
            return Utils.toString(this.value).replace(".", ",");
        }
        else if (this.dataType === TemplateFieldDataType.date) {
            return this.value == null ? "" : EnaioHelper.toString(new Date(Date.parse(this.value)));
        }
        else {
            return this.addBulletPoints ? "\t• " + Utils.replaceAll(this.value, "\n", "\n\t• ") : this.value;
        }
    }

    formatValueForForm(): any {
        if (this.dataType === TemplateFieldDataType.date) {
            return Utils.isNoe(this.value) ? new Date() : !Number.isNaN(Date.parse(this.value)) ? new Date(Date.parse(this.value)) : Utils.dateFromString(this.value, "DD.MM.YYYY");
        }
        else {
            return this.value;
        }
    }

    recalculate(dataObject: OrdinaryObject): void {
        this.updatePossibilities(dataObject);
        if (this.isCalculated()) {
            this.value = this.evalCalculation(dataObject);
        }
        else if (!Utils.isNoe(this.guiItems) && !this.dropdownEditable && Utils.isNoe(this.value)) {
            this.value = this.guiItems[0];
        }
    }

}
