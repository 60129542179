import { Component } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DhTools } from '../../models/dh-tools.model';

@Component({
    selector: 'app-sm-code-generator',
    templateUrl: './sm-code-generator.component.html',
    styleUrls: ['./sm-code-generator.component.scss']
  })
  export class SmCodeGeneratorComponent extends ComponentView {

    smCodeBlob: SafeUrl;
    anythingFound: boolean = null;

    async generateSmCode(): Promise<void> {
        this.smCodeBlob = this.app.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(await DhTools.rest().get().post().runBlob("api/smcode/file")));
    }

    async smCodeClipToFile(): Promise<void> {
        await DhTools.backendCall("api/smcode/clipToFile").getString();
    }

    async smCodeScreenshotToFile(): Promise<void> {
        await DhTools.backendCall("api/smcode/screenshotToFile").getString();
    }
}
