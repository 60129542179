/* eslint-disable @typescript-eslint/no-inferrable-types */
import { CustomInitializer } from "src/modules/sm-base/shared/custom-initializer.interface";
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Utils } from "src/modules/utils/shared/utils";
import { Column, Entity, ManyToOne } from "typeorm";
import { LearningContent } from "./learning-content.entity";

@Entity()
export class LearningContentFinalQuestion extends SmBaseEntity implements CustomInitializer {
    @ManyToOne(() => LearningContent, (o: LearningContent) => o.finalExamQuestions, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    learningContent: LearningContent;

    @Column({default: ""})
    question: string = "";

    @Column("text", { array: true, nullable: true })
    correctAnswers: string[] = [];

    @Column("text", { array: true, nullable: true })
    wrongAnswers: string[] = [];

    customInitializer(): void {
        this.correctAnswers = Utils.arrayEnsure(this.correctAnswers);
        this.wrongAnswers = Utils.arrayEnsure(this.wrongAnswers);
    }

    answersValid(): boolean {
        return this.correctAnswers.length > 0;
    }
}
