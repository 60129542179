import { Type } from "class-transformer";
export class HandlerConfigMetrics {
    configName: string;
    @Type(() => Date)
    startDate: Date;
    @Type(() => Date)
    endDate: Date;
    usedMemBefore = 0;
    usedMemAfter = 0;
    cpuMillis = 0;
    numObjects = 0;
    numObjectsWithWork = 0;
    numErrorObjects = 0;
    numErrors = 0;

    getRunTime(): number {
        return this.startDate == null || this.endDate == null ? 0 : this.endDate.getTime() - this.startDate.getTime();
    }

    getUsedMem(): number {
        return this.usedMemAfter - this.usedMemBefore;
    }


}
