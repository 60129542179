import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Config } from 'src/modules/app-template/models/config.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private app: MainAppService, private auth: AuthenticationService, private router: Router) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = !req.url.includes(":444") && this.auth.getToken() != null && Config.get().isPortalBackend ? req.clone({
            headers: req.headers.set('Authorization', "Bearer " + this.auth.getToken())
        }) : req;

        return next.handle(authReq).pipe(
            map((event: HttpEvent<any>) => {
                if (event.type == HttpEventType.Response) {
                    this.app.backendVersionRetrieved(event.headers.get("backend-version"));
                }
                if (event.type === HttpEventType.Response && (event as any).status === 401) {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    this.auth.clearLogin();
                }
                return event;
            }),
            catchError(error => {
                if (error.status === 401) {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    this.auth.clearLogin();
                }
                return throwError(() => error);
            }));
    }
}
