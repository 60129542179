<div *ngIf="inited">
    <sm-toolbar *ngIf="isManager">
        <p-button icon="fas fa-check" label="Freizeichnen" (onClick)="contractOut()">
        </p-button>
        <span *ngIf="classRegister.contractedOutDate != null" class="ml-3">Freigezeichnet am {{classRegister.contractedOutDate | date:'dd.MM.yyyy HH:mm:ss'}}</span>
    </sm-toolbar>
    <div class="grid home">
        <app-dashboard-card class="col-12 md:col-6 lg:col-4 lg:col-offset-2" icon="fas fa-lightbulb" title="Klassenämter" [menuButton]="false" [routerLink]="['/enaio-certificates', 'class-registers', 'duties', '' + id]"></app-dashboard-card>
        <app-dashboard-card class="col-12 md:col-6 lg:col-4" icon="fas fa-lightbulb" title="Klassentagebuch" [menuButton]="false" [routerLink]="['/enaio-certificates', 'class-registers', 'teacher', '' + id]"></app-dashboard-card>
        <app-dashboard-card class="col-12 md:col-6 lg:col-4 lg:col-offset-2" icon="fas fa-lightbulb" title="Stundenplan" [menuButton]="false" [routerLink]="['/enaio-certificates', 'class-registers', 'timetable', '' + id]"></app-dashboard-card>
        <app-dashboard-card class="col-12 md:col-6 lg:col-4" icon="fas fa-lightbulb" title="Belehrungen" [menuButton]="false" [routerLink]="['/enaio-certificates', 'class-registers', 'instructions', '' + id]"></app-dashboard-card>
        <app-dashboard-card class="col-12 md:col-6 lg:col-4 lg:col-offset-2" icon="fas fa-lightbulb" title="Schülerverzeichnis" [menuButton]="false" [routerLink]="['/enaio-certificates', 'class-registers', 'pupils', '' + id]"></app-dashboard-card>
        <app-dashboard-card class="col-12 md:col-6 lg:col-4" icon="fas fa-lightbulb" title="Unterrichtswoche" [menuButton]="false" [routerLink]="['/enaio-certificates', 'class-registers', 'week', '' + id]"></app-dashboard-card>
        <app-dashboard-card class="col-12 md:col-6 lg:col-4 lg:col-offset-2" icon="fas fa-lightbulb" title="Unterrichtsgänge" [menuButton]="false" [routerLink]="['/enaio-certificates', 'class-registers', 'field-trips', '' + id]"></app-dashboard-card>
        <app-dashboard-card class="col-12 md:col-6 lg:col-4" icon="fas fa-lightbulb" title="Klassenfahrten" [menuButton]="false" [routerLink]="['/enaio-certificates', 'class-registers', 'holidays', '' + id]"></app-dashboard-card>
        <app-dashboard-card class="col-12 md:col-6 lg:col-4 lg:col-offset-2" icon="fas fa-lightbulb" title="Krankmeldungen" [menuButton]="false" [routerLink]="['/enaio-certificates', 'class-registers', 'sick-notes-class', '' + id]"></app-dashboard-card>
        <app-dashboard-card class="col-12 md:col-6 lg:col-4" icon="fas fa-lightbulb" title="Historie" [menuButton]="false" [routerLink]="['/enaio-certificates', 'class-registers', 'history', '' + id]"></app-dashboard-card>
        <app-dashboard-card class="col-12 md:col-6 lg:col-4 lg:col-offset-2" icon="fas fa-lightbulb" title="Auswertungen" [menuButton]="false" [routerLink]="['/enaio-certificates', 'class-registers', 'analyses', '' + id]"></app-dashboard-card>
    </div>
</div>
