import { Type } from "class-transformer";
import { Comparable } from "src/modules/utils/shared/comparable";
import { Utils } from "src/modules/utils/shared/utils";

export class MissDayDto implements Comparable<MissDayDto> {
    pupilId = 0;
    @Type(() => Date)
    date: Date;
    hour: number;
    excused: boolean;

    constructor(pupilId: number, date: Date, hour: number, excused: boolean) {
        this.pupilId = pupilId;
        this.date = date;
        this.hour = hour;
        this.excused = excused;
    }

    compareTo(other: MissDayDto): number {
        return Utils.cmpMulti([this.date, other.date, this.hour, other.hour]);
    }

    getNumDays(): number {
        return this.hour >= 0 ? 0.166 : 1;
    }
}
