export class EnaioUserIdentification {
    userName: string;
    id = 0;
    guid: string;
    mail: string;
    wfGuid: string;


    constructor(userName: string, id: number, guid: string, wfGuid: string) {
        this.userName = userName;
        this.id = id;
        this.guid = guid;
        this.wfGuid = wfGuid;
    }

    static byUserName(userName: string): EnaioUserIdentification {
        return new EnaioUserIdentification(userName, 0, null, null);
    }

    static byId(id: number): EnaioUserIdentification {
        return new EnaioUserIdentification(null, id, null, null);
    }

    static byGuid(guid: string): EnaioUserIdentification {
        return new EnaioUserIdentification(null, 0, guid, null);
    }

    static byWfGuid(wfGuid: string): EnaioUserIdentification {
        return new EnaioUserIdentification(null, 0, null, wfGuid);
    }


}
