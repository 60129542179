import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { EnaioCall } from "./EnaioCall";

export class EnaioCallGetWorkflowError extends EnaioCall <string> {
    processId: string;
    @Type(() => Date)
    startDate: Date;
    waitIfWfNotFound = false;
    getModelType(): AdvancedType {
        return AdvancedType.ofPrimitive("string");
    }

}
