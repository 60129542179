import { Type } from "class-transformer";
import { EnaioObjectType } from "./EnaioObjectType";

export class EnaioCabinet {
    name = "";
    internalName = "";
    coType = 0;
    @Type(() => EnaioObjectType)
    objectTypes: EnaioObjectType[];
}
