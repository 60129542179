<p-card *ngIf="inited" [header]="config.caption" [style]="{'width': '400px', 'margin': 'auto'}">
    <div class="grid">
        <sm-form class="col-12" [form]="form">
        </sm-form>
        <div class="col-12" style="text-align: left">
            <p-button icon="fas fa-search" label="Suchen" (onClick)="search()">
            </p-button>
        </div>
    </div>
</p-card>
