import { Type } from "class-transformer";
import { EnaioObjectId } from "./EnaioObjectId";

export class EnaioAddonAddPidItem {
    @Type(() => EnaioObjectId)
    objectType: EnaioObjectId;
    @Type(() => EnaioObjectId)
    field: EnaioObjectId;
    fromIndex = 0;
    length = 0;
}
