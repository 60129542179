import { Utils } from "src/modules/utils/shared/utils";
import { Type } from "class-transformer";
import { EnaioDocumentBaseParams } from "./EnaioDocumentBaseParams";
import { EnaioDocumentFileProperties } from "./EnaioDocumentFileProperties";
import { EnaioDocumentRemark } from "./EnaioDocumentRemark";
import { EnaioDocumentStats } from "./EnaioDocumentStats";
import { EnaioMetaDataField } from "./EnaioMetaDataField";
import { EnaioObjectHistoryEntry } from "./EnaioObjectHistoryEntry";
import { EnaioObjectId } from "./EnaioObjectId";
import { EnaioObjectParentRelation } from "./EnaioObjectParentRelation";
import { EnaioVariantInfo } from "./EnaioVariantInfo";

export class EnaioDocument {
    id = 0;
    @Type(() => EnaioObjectId)
    archive: EnaioObjectId;
    @Type(() => EnaioObjectId)
    objectType: EnaioObjectId;
    isTypeless = false;
    isInTray = false;
    @Type(() => EnaioMetaDataField)
    metaData: EnaioMetaDataField[];
    @Type(() => EnaioDocumentFileProperties)
    fileProperties: EnaioDocumentFileProperties;
    @Type(() => EnaioDocumentBaseParams)
    baseParams: EnaioDocumentBaseParams;
    @Type(() => EnaioDocumentStats)
    documentStats: EnaioDocumentStats;
    @Type(() => EnaioDocument)
    parent: EnaioDocument;
    @Type(() => EnaioDocument)
    children: EnaioDocument[];
    @Type(() => EnaioVariantInfo)
    variants: EnaioVariantInfo[];
    @Type(() => EnaioDocumentRemark)
    remarks: EnaioDocumentRemark[];
    @Type(() => EnaioObjectHistoryEntry)
    history: EnaioObjectHistoryEntry[];
    documentTempFileName: string;
    documentTempFileNames: string[];
    digest: string;
    @Type(() => EnaioObjectParentRelation)
    parentInfo: EnaioObjectParentRelation[];

    getByInternal(internalName: string): any {
        let field = this.metaData.find(f => f.field.internalName === internalName);
        if (field == null) {
            throw new Error("Das Feld mit dem internen Namen '" + internalName + "' wurde nicht gefunden");
        }
        return field.value;
    }

    getByName(name: string): any {
        let field = this.metaData.find(f => f.field.name === name);
        if (field == null) {
            throw new Error("Das Feld mit dem Namen '" + name + "' wurde nicht gefunden");
        }
        return field.value;
    }

    getFieldByName(name: string): any {
        return this.metaData.find(f => f.field.name === name);
    }

    getFieldByInternalName(internalName: string): any {
        return this.metaData.find(f => f.field.internalName === internalName);
    }

    getStringByInternal(internalName: string): string {
        return Utils.toString(this.getByInternal(internalName));
    }

    getStringByName(name: string): string {
        return Utils.toString(this.getByName(name));
    }

    getNumberByInternal(internalName: string): number {
        return Utils.toNumber(this.getByInternal(internalName), false, true);
    }

    getNumberByName(name: string): number {
        return Utils.toNumber(this.getByName(name), false, true);
    }

    getBoolByInternal(internalName: string): boolean {
        return Utils.toBool(this.getByInternal(internalName));
    }

    getBoolByName(name: string): boolean {
        return Utils.toBool(this.getByName(name));
    }

    getDateByInternal(internalName: string): Date {
        return this.toDate(this.getStringByInternal(internalName));
    }

    getDateByName(name: string): Date {
        return this.toDate(this.getStringByName(name));
    }

    getMetaDataMap(): any {
        return Utils.arrayToPlainMap(this.metaData, f => f.field.internalName, f => f.value);
    }

    getBestGuessFileName(): string {
        let plain = this.documentStats?.name.toUpperCase().endsWith(".CMEF") && !Utils.isNoe(this.documentTempFileName) ? this.documentTempFileName :
            Utils.isNoe(this.documentStats?.name) ? this.documentTempFileName : this.documentStats.name;
        return Utils.getFileNameWithoutPath(plain);
    }

    private toDate(s: string): Date {
        if (s == "") {
            return null;
        }
        else {
            try {
                return Utils.dateFromString(s, "DD.MM.YYYY");
            }
            catch {
                return new Date(Date.parse(s));
            }
        }
    }


}
