import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppMainComponent } from 'src/modules/app-template/components/app-main/app-main.component';
import { SmBaseModule } from 'src/modules/sm-base/sm-base.module';

//Routen werden in feature-module.ts eingetragen. Dies sind nur die Basisrouten, die verwendet wurden, bis die endgültigen Routen
//anhand der geladenen Module aufgebaut wurden
export const routes: Routes = [
    {
        path: 'base',
        loadChildren: () => SmBaseModule
    },
    {
        path: '',
        component: AppMainComponent
    }
];

export const AppRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', enableTracing: false });
