import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanDeactivate } from '@angular/router';
import { CanDeactivateGuard } from '../sm-base/misc/can-deactivate.guard';
import { CertificatesMainComponent } from './components/certificates-main/certificates-main.component';
import { EditCertificateComponent } from './components/edit-certificate/edit-certificate.component';
import { ClassGradesComponent } from './components/class-grades/class-grades.component';
import { GlobalSettingsComponent } from './components/global-settings/global-settings.component';
import { GradeBookAdminComponent } from './components/grade-book-admin/grade-book-admin.component';
import { GradeBookMainComponent } from './components/grade-book-main/grade-book-main.component';
import { PupilListComponent } from './components/pupil-list/pupil-list.component';
import { CovidTestsMainComponent } from './components/covid-tests-main/covid-tests-main.component';
import { CovidTestsComponent } from './components/covid-tests/covid-tests.component';
import { SickNotesComponent } from './components/sick-notes/sick-notes.component';
import { ClassRegistersMainComponent } from './components/class-registers-main/class-registers-main.component';
import { ClassRegisterComponent } from './components/class-register/class-register.component';
import { ClassTimetableComponent } from './components/class-timetable/class-timetable.component';
import { ClassHistoryComponent } from './components/class-history/class-history.component';
import { ClassWeekComponent } from './components/class-week/class-week.component';
import { ClassLessonComponent } from './components/class-lesson/class-lesson.component';
import { EditClassCoursesComponent } from './components/edit-class-courses/edit-class-courses.component';
import { EditClassCourseComponent } from './components/edit-class-course/edit-class-course.component';
import { ClassDutiesComponent } from './components/class-duties/class-duties.component';
import { ClassPupilsComponent } from './components/class-pupils/class-pupils.component';
import { ClassRegisterAdminComponent } from './components/class-register-admin/class-register-admin.component';
import { ClassTeacherComponent } from './components/class-teacher/class-teacher.component';
import { ClassInstructionComponent } from './components/class-instruction/class-instruction.component';
import { ClassAnalysesComponent } from './components/class-analyses/class-analyses.component';
import { ClassAnalysesMissDaysComponent } from './components/class-analyses-miss-days/class-analyses-miss-days.component';
import { ClassAnalysesDanglingLessonsComponent } from './components/class-analyses-dangling-lessons/class-analyses-dangling-lessons.component';
import { ClassAnalysesIncidentsComponent } from './components/class-analyses-incidents/class-analyses-incidents.component';
import { HomeComponent } from './components/home/home.component';
import { ClassHolidaysComponent } from './components/class-holidays/class-holidays.component';
import { GuiUtils } from '../utils/misc/gui-utils';
import { ClassAnalysesHomeworkComponent } from './components/class-analyses-homework/class-analyses-homework.component';
import { CovidTestsAnalysesComponent } from './components/covid-tests-analyses/covid-tests-analyses.component';
import { ClassFieldTripsComponent } from './components/class-field-trips/class-field-trips.component';


const routes: Routes = GuiUtils.updateRoutes([
    {
        path: 'h',
        component: HomeComponent
    },
    {
        path: 'home',
        component: CertificatesMainComponent
    },
    {
        path: 'pupils/:schoolClassId/:isHalfYear',
        component: PupilListComponent
    },
    {
        path: 'certificate/:schoolClassId/:isHalfYear/:pupilId',
        component: EditCertificateComponent,
        canDeactivate: mapToCanDeactivate([CanDeactivateGuard])
    },
    {
        path: 'globalsettings',
        component: GlobalSettingsComponent
    },
    {
        path: 'grade-book/home',
        component: GradeBookMainComponent
    },
    {
        path: 'grade-book/home/:year',
        component: GradeBookMainComponent
    },
    {
        path: 'grade-book/class/:schoolClassId/:isHalfYear',
        component: ClassGradesComponent,
        canDeactivate: mapToCanDeactivate([CanDeactivateGuard])
    },
    {
        path: 'grade-book/admin/:schoolType/:year/:isHalfYear',
        component: GradeBookAdminComponent
    },
    {
        path: 'covid-tests/home',
        component: CovidTestsMainComponent
    },
    {
        path: 'covid-tests/class/:schoolClassId/:isHalfYear/:history',
        component: CovidTestsComponent,
        canDeactivate: mapToCanDeactivate([CanDeactivateGuard])
    },
    {
        path: 'covid-tests/analyses',
        component: CovidTestsAnalysesComponent
    },
    {
        path: 'class-registers/home',
        component: ClassRegistersMainComponent
    },
    {
        path: 'class-registers/home/:year',
        component: ClassRegistersMainComponent
    },
    {
        path: 'class-registers/class-register/:id',
        component: ClassRegisterComponent
    },
    {
        path: 'class-registers/class-register/:id/:isHalfYear',
        component: ClassRegisterComponent
    },
    {
        path: 'class-registers/timetable/:id',
        component: ClassTimetableComponent
    },
    {
        path: 'class-registers/duties/:id',
        component: ClassDutiesComponent
    },
    {
        path: 'class-registers/holidays/:id',
        component: ClassHolidaysComponent
    },
    {
        path: 'class-registers/field-trips/:id',
        component: ClassFieldTripsComponent
    },
    {
        path: 'class-registers/pupils/:id',
        component: ClassPupilsComponent
    },
    {
        path: 'class-registers/history/:id',
        component: ClassHistoryComponent
    },
    {
        path: 'class-registers/week/:id',
        component: ClassWeekComponent
    },
    {
        path: 'class-registers/week/:id/:date',
        component: ClassWeekComponent
    },
    {
        path: 'class-registers/lesson/:id',
        component: ClassLessonComponent
    },
    {
        path: 'class-registers/lesson/:id/:classId',
        component: ClassLessonComponent
    },
    {
        path: 'class-registers/courses',
        component: EditClassCoursesComponent
    },
    {
        path: 'class-registers/courses/:year',
        component: EditClassCoursesComponent
    },
    {
        path: 'class-registers/course/:year/:id',
        component: EditClassCourseComponent
    },
    {
        path: 'class-registers/course/:id',
        component: EditClassCourseComponent
    },
    {
        path: 'class-registers/admin/:year/:isHalfYear',
        component: ClassRegisterAdminComponent
    },
    {
        path: 'class-registers/sick-notes/:year/:isHalfYear',
        component: SickNotesComponent
    },
    {
        path: 'class-registers/sick-notes-class/:id',
        component: SickNotesComponent
    },
    {
        path: 'class-registers/teacher/:id',
        component: ClassTeacherComponent
    },
    {
        path: 'class-registers/instructions/:id',
        component: ClassInstructionComponent
    },
    {
        path: 'class-registers/analyses/:id',
        component: ClassAnalysesComponent
    },
    {
        path: 'class-registers/analyses/miss-days/:id',
        component: ClassAnalysesMissDaysComponent
    },
    {
        path: 'class-registers/analyses/dangling-lessons/:id',
        component: ClassAnalysesDanglingLessonsComponent
    },
    {
        path: 'class-registers/analyses/homework/:id',
        component: ClassAnalysesHomeworkComponent
    },
    {
        path: 'class-registers/analyses/incidents/:id',
        component: ClassAnalysesIncidentsComponent
    },
    {
        path: '',
        redirectTo: "h",
        pathMatch: "full"
    }
]);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EnaioCertificatesRoutingModule {
}
