import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { EnaioCall } from "./EnaioCall";
import { EnaioLicense } from "./EnaioLicense";

export class EnaioCallGetLicense extends EnaioCall <EnaioLicense> {
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioLicense);
    }

}
