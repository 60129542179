import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { EnaioCall } from "./EnaioCall";
import { EnaioUserIdentification } from "./EnaioUserIdentification";
import { EnaioUserPermissions } from "./EnaioUserPermissions";

export class EnaioCallGetUserPermissions extends EnaioCall <EnaioUserPermissions> {
    @Type(() => EnaioUserIdentification)
    user: EnaioUserIdentification;
    objectTypeId = 0;
    detailed = false;
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioUserPermissions);
    }

}
