import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { ObjectAction } from 'src/modules/sm-base/shared/object-action.model';
import { DocumentHandlerMainComponent } from '../document-handler-main/document-handler-main.component';

@Component({
    selector: 'app-string-tools',
    templateUrl: './string-tools.component.html',
    styleUrls: ['./string-tools.component.scss']
  })
export class StringToolsComponent extends ComponentView {

    text = "";
    actionButtons: ObjectAction[];

    async initParams(): Promise<boolean> {
        return Promise.resolve(true);
    }

    textChanged(): void {
        this.actionButtons = DocumentHandlerMainComponent.instance?.getContextMenuActions().filter(a => a.isUsable(this.text));
    }

    async run(button: ObjectAction): Promise<void> {
        this.text = await button.perform(this.text);
    }

}
