import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { EnaioCall } from "./EnaioCall";
import { EnaioDeleteResult } from "./EnaioDeleteResult";
import { EnaioObjectId } from "./EnaioObjectId";

export class EnaioCallDelete extends EnaioCall <EnaioDeleteResult> {
    @Type(() => EnaioObjectId)
    archive: EnaioObjectId;
    @Type(() => EnaioObjectId)
    objectType: EnaioObjectId;
    objectId = 0;
    hardDelete = false;
    deleteCascading = false;
    getModelType(): AdvancedType {
        return AdvancedType.ofPrimitive("number");
    }

}
