
export class BackendProcess {

    public id = "";
    public name = "";
    public done = 0;
    public max = 100;
    public status = "";
    public finished = "";

    constructor(id: string, name: string, max: number, status = "", done = 0) {
        this.id = id;
        this.name = name;
        this.max = max;
        this.status = status;
        this.done = done;
    }
}
