import { Type } from "class-transformer";
import { EnaioAssignment } from "./EnaioAssignment";

export class EnaioTableRow {
    @Type(() => EnaioAssignment)
    updateKey: EnaioAssignment[];
    insertIfNoMatchingRow = false;
    @Type(() => EnaioAssignment)
    columns: EnaioAssignment[];
}
