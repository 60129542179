import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { AppTab } from '../app-template/models/app-tab.model';
import { FeatureModule } from '../sm-base/models/feature.module';
import { FeatureModuleService } from '../sm-base/services/feature-module.service';
import { DashboardRoutingModule } from './dashboard-routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule
  ]
})
export class DashboardModule implements FeatureModule {

    constructor(private featureModuleService: FeatureModuleService) {
        featureModuleService.register(this);
    }

    registerSidebar(_: AppTab[]): void {
    }

    async updateSidebarVisibility(_: AppTab[]): Promise<void> {
    }

    getMainRoute(): Route {
        return this.featureModuleService.createModuleRoute("dashboard", () => DashboardRoutingModule);
    }
}
