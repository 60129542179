import { Type } from "class-transformer";
import { EnaioObjectId } from "./EnaioObjectId";

export class EnaioAssignment {
    @Type(() => EnaioObjectId)
    field: EnaioObjectId;
    value: any;

    constructor(field: EnaioObjectId, value: any) {
        this.field = field;
        this.value = value;
    }


}
