import { Component } from '@angular/core';

@Component({
  selector: 'sm-toolbar',
  templateUrl: './sm-toolbar.component.html',
  styleUrls: ['./sm-toolbar.component.scss']
})
export class SmToolbarComponent {

}
