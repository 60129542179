import { ClassType } from "./class-type";
import { Utils } from "./utils";

export class AdvancedType {
    primitive = "";
    clazz: ClassType<any>;
    numArrayDims = 0;

    static ofClass(clazz: ClassType<any>, numArrayDims = 0): AdvancedType {
        let result = new AdvancedType();
        result.clazz = clazz;
        result.numArrayDims = numArrayDims;
        return result;
    }

    static ofPrimitive(primitive: string, numArrayDims = 0): AdvancedType {
        let result = new AdvancedType();
        result.primitive = primitive;
        result.numArrayDims = numArrayDims;
        return result;
    }

    fromPlainUnsafe(o: any, useArrayDims: number = null): any {
        if (useArrayDims == null) {
            useArrayDims = this.numArrayDims;
        }
        if (useArrayDims > 0) {
            return (o as any[]).map(item => this.fromPlainUnsafe(item, useArrayDims - 1));
        }

        if (this.primitive !== "") {
            switch (this.primitive) {
                case "string":
                    return Utils.toString(o);
                case "number":
                    return Utils.toNumber(o);
                case "boolean":
                    return Utils.toBool(o);
                default:
                    throw new Error("Unbekannter Primitive Typ: " + this.primitive);
            }
        }
        else if (this.clazz != null) {
            return Utils.fromPlainUnsafe(this.clazz, o);
        }
        else {
            throw new Error("Ungültiger AdvancedType");
        }
    }

    isEmpty(): boolean {
        return this.primitive === "" && this.clazz == null;
    }
}
