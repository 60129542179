/* eslint-disable @typescript-eslint/no-inferrable-types */
import { CustomInitializer } from "src/modules/sm-base/shared/custom-initializer.interface";
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Utils } from "src/modules/utils/shared/utils";
import { Column, Entity, ManyToOne } from "typeorm";
import { CitationField } from "./citation-field.model";
import { CitationType } from "./citation-type.model";
import { LearningContentChapter } from "./learning-content-chapter.entity";

@Entity()
export class LearningContentCitation extends SmBaseEntity implements CustomInitializer {
    @ManyToOne(() => LearningContentChapter, (o: LearningContentChapter) => o.citations, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    chapter: LearningContentChapter;

    @Column({default: ""})
    type: string = "";

    @Column("text", { array: true, nullable: true })
    citationKeys: string[] = [];

    @Column("text", { array: true, nullable: true })
    citationValues: string[] = [];

    customInitializer(): void {
        this.citationKeys = Utils.arrayEnsure(this.citationKeys);
        this.citationValues = Utils.arrayEnsure(this.citationValues);
    }

    getFields(): CitationField[] {
        let result: CitationField[] = [];
        let t = CitationType.types.find(t2 => t2.id == this.type);
        if (t == null) {
            return result;
        }
        for (let i = 0; i < Math.min(this.citationKeys.length, this.citationValues.length); i++) {
            let block = t.blocks.find(b => b.id == this.citationKeys[i]);
            if (block != null) {
                result.push(new CitationField(block, this.citationValues[i]));
            }
        }
        return result;
    }

    format(): string {
        let t = CitationType.types.find(t2 => t2.id == this.type);
        if (t == null) {
            return "---Ungültiger Quellentyp: " + this.type + "---";
        }
        let placeholders = t.format.match(/{{[\dA-Za-z]+}}/g);

        let result = t.format;
        for (let placeholder of placeholders) {
            let blockIndex = this.citationKeys.indexOf(Utils.stringRemovePrefix(Utils.stringRemoveSuffix(placeholder, "}}"), "{{"));
            result = result.replace(placeholder, Utils.arrayGetSafe(this.citationValues, blockIndex));
        }

        return result;
    }

}
