import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Translation, TranslocoLoader, TranslocoModule, TranslocoService, provideTransloco } from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';
import { RootComponent } from 'src/app/components/root/root.component';
import { AppTemplateModule } from 'src/modules/app-template/app-template.module';
import { Config } from 'src/modules/app-template/models/config.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { DocumentHandlerModule } from 'src/modules/document-handler/document-handler.module';
import { EnaioCertificatesModule } from 'src/modules/enaio-certificates/enaio-certificates.module';
import { EnaioDocumentsModule } from 'src/modules/enaio-documents/enaio-documents.module';
import { EnaioLearnModule } from 'src/modules/enaio-learn/enaio-learn.module';
import { EnaioPayrollModule } from 'src/modules/enaio-payroll/enaio-payroll.module';
import { EnaioTemplatesModule } from 'src/modules/enaio-templates/enaio-templates.module';
import { EnaioWorkflowsModule } from 'src/modules/enaio-workflows/enaio-workflows.module';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { SmBaseModule } from 'src/modules/sm-base/sm-base.module';
import { TestModule } from 'src/modules/test/test.module';
import { Utils } from 'src/modules/utils/shared/utils';
import { AppRoutes } from './app.routes';
import { DashboardModule } from 'src/modules/dashboard/dashboard.module';
import { ConfigChangerModule } from 'src/modules/config-changer/config-changer.module';

export function init_app(transloco: TranslocoService) {
    return async () => RestEndpoint.main().run("api/app/translation").getText().then(s => Utils.fromJson(s)).then(async o => {
            TranslocoAppLoader.translation = o;
            transloco.setActiveLang("de");
            return firstValueFrom(transloco.load("de"));
    });
}

@Injectable({ providedIn: 'root' })
export class TranslocoAppLoader implements TranslocoLoader {

    static translation: Translation;

    async getTranslation(_: string): Promise<Translation> {
        return Promise.resolve(TranslocoAppLoader.translation);
    }
}

@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppTemplateModule,
        AppRoutes,
        EnaioDocumentsModule,
        EnaioWorkflowsModule,
        EnaioCertificatesModule,
        EnaioPayrollModule,
        EnaioLearnModule,
        EnaioTemplatesModule,
        DashboardModule,
        DocumentHandlerModule,
        DashboardModule,
        ConfigChangerModule,
        TestModule,
        SmBaseModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: Config.get().production })
    ],
    exports: [
        TranslocoModule
    ],
    declarations: [
        RootComponent
    ],
    bootstrap: [
        RootComponent
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: init_app, deps: [TranslocoService], multi: true },
        provideTransloco({
            config: {
                availableLangs: ['de'],
                defaultLang: 'de',
                prodMode: false
            },
            loader: TranslocoAppLoader
        })
    ]
})
export class AppModule {

    constructor(private app: MainAppService, private transloco: TranslocoService) {
        app.appName = Config.get().pageTitle;
        app.pageTitle = app.appName;
    }
}
