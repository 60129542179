export class EnaioClientEventCode {
    id = 0;
    name: string;
    group: string;
    appClass: string;

    toString(): string {
        return this.id + " - " + this.name + " - " + this.group + " - " + this.appClass;
    }


}
