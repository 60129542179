import { Utils } from "src/modules/utils/shared/utils";
import { Type } from "class-transformer";
import { EnaioClientEventCode } from "./EnaioClientEventCode";

export class EnaioClientEvent {
    @Type(() => EnaioClientEventCode)
    eventCode: EnaioClientEventCode;
    objectTypeId = 0;
    parameters: string;
    code: string;
    fromFileName: string;
    calculatedSpeakingId: string;
    calculatedSpeakingIdShort: string;

    public getCaption(short = false): string {
        let result = short ? this.calculatedSpeakingIdShort : this.calculatedSpeakingId;
        return !Utils.isNoe(result) ? result : this.eventCode.toString() + (Utils.isNoe(this.parameters) ? "" : " - " + this.parameters);
    }


}
