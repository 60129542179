import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { EnaioCall } from "./EnaioCall";
import { EnaioLockedResource } from "./EnaioLockedResource";

export class EnaioCallGetLockedResources extends EnaioCall <EnaioLockedResource[]> {
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioLockedResource, 1);
    }

}
