<div *ngIf="inited">
    <sm-toolbar>
        <p-button [disabled]="!isChanged()" icon="fas fa-save" label="Speichern" (onClick)="save()">
        </p-button>
        <span class="ml-5">Gültig ab:</span>
        <p-dropdown [styleClass]="'width300'" id="validDate" [options]="validDates" [(ngModel)]="validDate" (onChange)="selectValidDate(validDate)">
        </p-dropdown>
        <p-button icon="fas fa-plus" label="Neue Variante" (onClick)="addValidDate()">
        </p-button>
        <p-message *ngIf="!variantIsEditable()" severity="error" text="Varianten, die auch vergangene Wochen abdecken, sind nicht editierbar. Für Änderungen bitte eine neue Variante anlegen" styleClass="ml-4"></p-message>
    </sm-toolbar>
    <p-table [value]="cells" [styleClass]="'p-datatable-gridlines'">
        <ng-template pTemplate="header">
            <tr>
                <th class="align-left">Stunde</th>
                <th *ngFor="let index of rowIndexes" class="align-left" [style.width]="(100 / rowIndexes.length) + '%'">{{weekDayNames[index]}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-index="rowIndex">
            <tr [pSelectableRow]="row">
                <td class="align-left">{{index + 1}}</td>
                <td *ngFor="let index of rowIndexes" class="timetable-cell"  [class.cell-free]="_Utils.isNoe(row[index]) && row[index] != '\xa0'" pEditableColumn>
                    <p-cellEditor *ngIf="variantIsEditable()">
                        <ng-template pTemplate="input">
                            <p-dropdown class="toolbar-element" [styleClass]="(row[index].useAlternating ? 'edit-2' : 'edit-1') + (row[index].isCourses(false) ? '-b' : '')" id="person" [options]="domainsGui" optionLabel="label" optionValue="value" [(ngModel)]="row[index].domain">
                            </p-dropdown>
                            <button pButton type="button" *ngIf="row[index].isCourses(false)" class="p-button-rounded p-button-text mr-1" icon="fas fa-pencil" (click)="editCourses(row[index], false)">
                            </button>
                            <p-dropdown *ngIf="row[index].useAlternating" class="toolbar-element" [styleClass]="'edit-2'" id="person" [options]="domainsGui" optionLabel="label" optionValue="value" [(ngModel)]="row[index].domainWeekB">
                            </p-dropdown>
                            <button pButton type="button" *ngIf="row[index].isCourses(true)" class="p-button-rounded p-button-text mr-1" icon="fas fa-pencil" (click)="editCourses(row[index], true)">
                            </button>
                        <tag-button value="A/B" severity="info" [clickable]=true [active]="row[index].useAlternating" (onClick)="switchAb(row[index])"></tag-button>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <div *ngIf="!row[index].useAlternating" class="mouse-pointer w-full h-full timetable-cell2">
                                <span>{{row[index].getTitle(false)}}</span>
                            </div>
                            <div *ngIf="row[index].useAlternating" class="mouse-pointer w-full h-full timetable-cell2">
                                <span class="width50p">
                                    <p-tag class="tag-a" value="A"></p-tag>
                                    <span>{{row[index].getTitle(false)}}</span>
                                </span>
                                <span class="width50p">
                                    <p-tag class="tag-b" value="B"></p-tag>
                                    <span>{{row[index].getTitle(true)}}</span>
                                </span>
                            </div>
                        </ng-template>
                    </p-cellEditor>
                    <ng-container *ngIf="!variantIsEditable()">
                        <div *ngIf="!row[index].useAlternating" class="w-full h-full timetable-cell2">
                            <span>{{row[index].getTitle(false)}}</span>
                        </div>
                        <div *ngIf="row[index].useAlternating" class="w-full h-full timetable-cell2">
                            <span class="width50p">
                                <p-tag class="tag-a" value="A"></p-tag>
                                <span>{{row[index].getTitle(false)}}</span>
                            </span>
                            <span class="width50p">
                                <p-tag class="tag-b" value="B"></p-tag>
                                <span>{{row[index].getTitle(true)}}</span>
                            </span>
                        </div>
                    </ng-container>
                </td>
        </tr>
        </ng-template>
    </p-table>
</div>
