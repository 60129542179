export enum FrontendFieldType {
    text,
    textArea,
    maskField,
    password,
    comboBox,
    comboBoxCascade,
    comboBoxMulti,
    listBox,
    checkBox,
    radioButton,
    datePicker,
    button,
    table,
    groupBox,
    color,
    number,
    rating,
    stringList,
    fileUpload,
    custom
}
