<form *ngIf="_form != null" [class.ui-fluid]="true"
    [style.width]="_form.useAbsolutePositions ? _form.getWidth() + 'px' : '100%'"
    [style.height]="_form.useAbsolutePositions ? _form.getHeight() + 'px' : undefined"
    spellcheck="false"
>
    <sm-form-field #fields *ngFor="let group of _fields"  width="100%"
    [field]="group.length == 1 ? group[0] : undefined" [multipleFields]="group.length > 1 ? group : undefined"
    [mandatoryFieldsRedAsterisk]="_form.mandatoryFieldsRedAsterisk"
    [style.position] = "_form.useAbsolutePositions ? 'sticky' : undefined"
    [style.left] = "_form.useAbsolutePositions ? '0' : undefined"
    [style.top] = "_form.useAbsolutePositions ? '0' : undefined"></sm-form-field>
</form>
