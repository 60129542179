import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { EnaioDocument } from 'src/modules/enaio/shared/EnaioDocument';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';

@Component({
  selector: 'app-enaio-result-list-view',
  templateUrl: './enaio-result-list-view.component.html',
  styleUrls: ['./enaio-result-list-view.component.scss']
})
export class EnaioResultListViewComponent implements OnInit {

    id: number;
    docs: EnaioDocument[];

    constructor(private app: MainAppService, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.app.subscribeToParamsChanges(this.route, this.init.bind(this));
    }

    async init(params: any): Promise<void> {
        this.id = params.id;

        this.docs = await RestEndpoint.main().query({ id: this.id }).run("api/tst/test/documents").list(EnaioDocument);
    }
}
