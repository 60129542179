import { Component, Input } from '@angular/core';
import * as Diff2Html from 'diff2html';

@Component({
  selector: 'app-text-diff',
  templateUrl: './text-diff.component.html',
  styleUrls: ['./text-diff.component.scss']
})
export class TextDiffComponent {

    diffHtml = "";

    private _fileName = "";
    private _left = "";
    private _right = "";

    @Input()
    leftFileName = "";
    @Input()
    rightFileName = "";

    @Input()
    set fileName(value: string) {
        this._fileName = value;
        this.update();
    }

    @Input()
    set left(value: string) {
        this._left = value;
        this.update();
    }

    @Input()
    set right(value: string) {
        this._right = value;
        this.update();
    }

    private update(): void {
        let diff = require("diff").createPatch(this.fileName, this._left, this._right, "", "") as string;
        this.diffHtml = Diff2Html.html(diff, {
            matching: "lines",
            outputFormat: "side-by-side",
            drawFileList: false,
            srcPrefix: this.leftFileName,
            dstPrefix: this.rightFileName
        });
    }

}
