import { Entity } from "typeorm";
import { CitationBlockType } from "./citation-block-type.enum";
import { CitationBlock } from "./citation-block.model";

@Entity()
export class CitationType {

    static types = [
        new CitationType("book", "Buch", "{{lastName}}, {{firstName}} ({{year}}). {{title}}. {{publisher}}", [
            new CitationBlock("lastName", "Nachname des Autors", CitationBlockType.text),
            new CitationBlock("firstName", "Initialen des Vornamens des Autors", CitationBlockType.text),
            new CitationBlock("year", "Jahr", CitationBlockType.year),
            new CitationBlock("title", "Titel", CitationBlockType.text),
            new CitationBlock("publisher", "Verlag", CitationBlockType.text)
        ]),
        new CitationType("anthology", "Sammelband", "{{lastName}}, {{firstName}} ({{year}}). {{title}} ({{edition}} Aufl.). {{publisher}}", [
            new CitationBlock("lastName", "Nachname des Autors", CitationBlockType.text),
            new CitationBlock("firstName", "Initialen des Vornamens des Autors", CitationBlockType.text),
            new CitationBlock("year", "Jahr", CitationBlockType.year),
            new CitationBlock("title", "Titel", CitationBlockType.text),
            new CitationBlock("edition", "Auflage", CitationBlockType.number),
            new CitationBlock("publisher", "Verlag", CitationBlockType.text)
        ]),
        new CitationType("web", "Internetquelle", "{{lastName}}, {{firstName}} ({{year}}, {{day}}. {{month}}). {{title}}. ({{url}})", [
            new CitationBlock("lastName", "Nachname des Autors", CitationBlockType.text),
            new CitationBlock("firstName", "Initialen des Vornamens des Autors", CitationBlockType.text),
            new CitationBlock("year", "Jahr", CitationBlockType.year),
            new CitationBlock("day", "Tag", CitationBlockType.number),
            new CitationBlock("month", "Monat", CitationBlockType.month),
            new CitationBlock("title", "Titel", CitationBlockType.text),
            new CitationBlock("url", "URL", CitationBlockType.url)
        ]),
        new CitationType("magazine", "Artikel aus einer Zeitschrift", "{{lastName}}, {{firstName}} ({{year}}). {{article}} {{title}}. {{volume}}({{release}}), {{pages}}", [
            new CitationBlock("lastName", "Nachname des Autors", CitationBlockType.text),
            new CitationBlock("firstName", "Initialen des Vornamens des Autors", CitationBlockType.text),
            new CitationBlock("year", "Jahr", CitationBlockType.year),
            new CitationBlock("article", "Titel des Artikels", CitationBlockType.text),
            new CitationBlock("title", "Titel der Zeitschrift", CitationBlockType.text),
            new CitationBlock("volume", "Band (wenn nicht vorhanden eine \"0\" eintragen)", CitationBlockType.text),
            new CitationBlock("release", "Ausgabe (wenn nicht vorhanden eine \"0\" eintragen)", CitationBlockType.text),
            new CitationBlock("pages", "Seiten", CitationBlockType.text)
        ]),
        new CitationType("newspaper", "Zeitungsartikel", "{{lastName}}, {{firstName}} ({{year}}). {{title}} ({{edition}} Aufl.). {{publisher}}", [
            new CitationBlock("lastName", "Nachname des Autors", CitationBlockType.text),
            new CitationBlock("firstName", "Initialen des Vornamens des Autors", CitationBlockType.text),
            new CitationBlock("year", "Jahr", CitationBlockType.year),
            new CitationBlock("title", "Titel der Zeitung", CitationBlockType.text),
            new CitationBlock("edition", "Auflage", CitationBlockType.number),
            new CitationBlock("publisher", "Verlag", CitationBlockType.text)
       ]),
       new CitationType("image", "Abbildung", "{{lastName}}, {{firstName}} ({{year}}). {{title}} ({{edition}} Aufl.). {{publisher}}", [
           new CitationBlock("lastName", "Nachname des Autors", CitationBlockType.text),
           new CitationBlock("firstName", "Initialen des Vornamens des Autors", CitationBlockType.text),
           new CitationBlock("year", "Jahr", CitationBlockType.year),
           new CitationBlock("title", "Buchtitel", CitationBlockType.text),
           new CitationBlock("edition", "Auflage", CitationBlockType.number),
           new CitationBlock("publisher", "Verlag", CitationBlockType.text)
      ])
    ];

    constructor (public id: string, public name: string, public format: string, public blocks: CitationBlock[]) {
    }
}
