import { Type } from 'class-transformer';
import { EnaioDocument } from 'src/modules/enaio/shared/EnaioDocument';
import { EnaioWorkItem } from 'src/modules/enaio/shared/EnaioWorkItem';

export class EnaioWorkItemDto {

    @Type(() => EnaioWorkItem)
    workItem: EnaioWorkItem;
    @Type(() => EnaioDocument)
    documents: EnaioDocument[];
    anonymousGuid: string;
}
