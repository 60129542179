import { Component, Input, ViewChild } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { BackendResponse } from 'src/modules/sm-base/shared/backend-response.model';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { TreeNode2 } from 'src/modules/utils/misc/tree-node2.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { DhTools } from '../../models/dh-tools.model';

@Component({
    selector: 'app-document-handler-eval-console',
    templateUrl: './document-handler-eval-console.component.html',
    styleUrls: ['./document-handler-eval-console.component.scss']
  })
  export class DocumentHandlerEvalConsoleComponent extends ComponentView {

    @Input()
    guid: string = null;

    evaluateText = "";
    evaluationResult = "";
    evaluationTree: TreeNode2[] = [];
    selectedNode: TreeNode2;
    metaData: "{}";
    errorMessage: string = null;
    objectIndex = "";
    tabIndex = 0;

    @ViewChild("evaluateTextComponent") evaluateTextComponent: any;

    async keyPress(event: KeyboardEvent): Promise<void> {
        if (event.key == "Enter") {
            await this.evaluateInput();
        }
    }

    async evaluateAll(): Promise<void> {
        await this.evaluateInput("{{this}}");
    }

    async evaluateSelection(): Promise<void> {
        await this.evaluateInput("{{" + this.evaluateText.substring(this.evaluateTextComponent.nativeElement.selectionStart as number, this.evaluateTextComponent.nativeElement.selectionEnd as number) + "}}");
    }

    async evaluateInput(text: string = null): Promise<void> {
        if (text == null) {
            text = this.evaluateText;
        }
        try {
            let objectIndex = this.objectIndex == "" ? null : Utils.toNumber(this.objectIndex);
            let json = Utils.fromJson(await DhTools.backendCall("api/dh" + (this.guid != null ? "instance" : "") + "/evaluateJson", Utils.objectRemoveNullValues({ instanceGuid: this.guid, text, metaData: this.metaData, objectIndex })).getText());
            this.evaluationResult = await DhTools.backendCall("api/dh" + (this.guid != null ? "instance" : "") + "/evaluate", Utils.objectRemoveNullValues({ instanceGuid: this.guid, text, metaData: this.metaData, objectIndex })).getText();
            this.evaluationTree = GuiUtils.objectToTree(json);
            this.errorMessage = null;
        }
        catch (ex) {
            this.errorMessage = Utils.replaceAll(BackendResponse.getFromException(ex).getMessage(), "\n", "<br>");
        }
    }

    async astDebug(): Promise<void> {
        this.evaluationResult = await DhTools.backendCall("api/dh/astDebug", { text: this.evaluateText }).getText();
        this.tabIndex = 1;
    }
}

