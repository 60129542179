import { Type } from "class-transformer";
import { EnaioWorkflowActivityApplication } from "./EnaioWorkflowActivityApplication";

export class EnaioWorkflowActivityDefinition {
    id = "";
    name = "";
    @Type(() => EnaioWorkflowActivityApplication)
    applications: EnaioWorkflowActivityApplication[];
    performerIds: string[];
}
