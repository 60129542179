export class EnaioComparison {
    value: string;
    comparison = "=";

    constructor(value = "", comparison = "") {
        this.value = value;
        this.comparison = comparison;
    }


}
