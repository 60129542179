/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { ClassRegisterSettings } from './class-register-settings.entity';

@Entity()
export class SpecialDomain extends SmBaseEntity {

    @ManyToOne(() => ClassRegisterSettings, (o: ClassRegisterSettings) => o.holidays, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    settings: ClassRegisterSettings;

    @Column({default: ""})
    title: string = "";

    @Column({default: false})
    isPlaceholder: boolean = false;
}
