<p-chip class="version-label badge" [label]="versionString"></p-chip>
<p-tabView #mainTabView class="compact tab-view-no-pad" id="mainTabView" [(activeIndex)]="tabIndex" (activeIndexChange)="activeTabChanged()">
    <p-tabPanel header="DocumentHandler">
        <p-tabView [class.tab-view-no-pad]="subTabIndex[0] == 0 || subTabIndex[0] == 5" [(activeIndex)]="subTabIndex[0]" (activeIndexChange)="activeTabChanged()">
            <p-tabPanel header="HQ">
                <app-document-handler-hq #viewDocumentHandlerHq [embeddedComponent]=true>
                </app-document-handler-hq>
            </p-tabPanel>
            <p-tabPanel header="Sim Log Suche">
                <app-sim-log #viewSimLog [embeddedComponent]=true>
                </app-sim-log>
            </p-tabPanel>
            <p-tabPanel header="Metrics">
                <app-metrics #viewMetrics [embeddedComponent]=true>
                </app-metrics>
            </p-tabPanel>
            <p-tabPanel header="Historie">
                <app-document-handler-runs #viewDocumentHandlerRuns [embeddedComponent]=true>
                </app-document-handler-runs>
            </p-tabPanel>
            <p-tabPanel header="Recherche">
                <app-document-handler-recherche #viewDocumentHandlerRecherche [embeddedComponent]=true>
                </app-document-handler-recherche>
            </p-tabPanel>
            <p-tabPanel header="Hilfe">
                <app-document-handler-help #viewDocumentHandlerHelp [embeddedComponent]=true>
                </app-document-handler-help>
            </p-tabPanel>
            <p-tabPanel header="Scriban Auswertungskonsole">
                <app-document-handler-eval-console #viewDocumentHandlerEvalConsole [embeddedComponent]=true>
                </app-document-handler-eval-console>
            </p-tabPanel>
            <p-tabPanel header="Tools">
                <app-document-handler-tools #viewDocumentHandlerTools [embeddedComponent]=true>
                </app-document-handler-tools>
            </p-tabPanel>
            <p-tabPanel header="Updater">
                <app-document-handler-updater #viewDocumentHandlerUpdater [embeddedComponent]=true>
                </app-document-handler-updater>
            </p-tabPanel>
        </p-tabView>
    </p-tabPanel>
    <p-tabPanel class="group" header="enaio">
        <p-tabView [(activeIndex)]="subTabIndex[1]" (activeIndexChange)="activeTabChanged()">
            <p-tabPanel header="Objektdefinition">
                <app-enaio-object-def #viewEnaioObjectDef [embeddedComponent]=true>
                </app-enaio-object-def>
            </p-tabPanel>
            <p-tabPanel header="Workflows">
                <app-enaio-workflows #viewEnaioWorkflows [embeddedComponent]=true>
                </app-enaio-workflows>
            </p-tabPanel>
            <p-tabPanel header="Workflow-Details">
                <app-enaio-workflow #viewEnaioWorkflow [embeddedComponent]=true>
                </app-enaio-workflow>
            </p-tabPanel>
            <p-tabPanel header="Workflow-Modelle">
                <app-enaio-workflow-models #viewEnaioWorkflowModels [embeddedComponent]=true>
                </app-enaio-workflow-models>
            </p-tabPanel>
            <p-tabPanel header="Objekte">
                <app-enaio-objects #viewEnaioObjects [embeddedComponent]=true>
                </app-enaio-objects>
            </p-tabPanel>
            <p-tabPanel header="Benutzer">
                <app-enaio-users #viewEnaioUsers [embeddedComponent]=true>
                </app-enaio-users>
            </p-tabPanel>
            <p-tabPanel header="Suchen & Ersetzen">
                <app-enaio-search-and-replace #viewEnaioSearchAndReplace [embeddedComponent]=true>
                </app-enaio-search-and-replace>
            </p-tabPanel>
            <p-tabPanel header="Server API">
                <app-server-api-caller #viewEnaioServerApiCaller [embeddedComponent]=true>
                </app-server-api-caller>
            </p-tabPanel>
            <p-tabPanel header="Warnungen">
                <app-enaio-warnings #viewEnaioWarnings [embeddedComponent]=true>
                </app-enaio-warnings>
            </p-tabPanel>
            <p-tabPanel header="Server Info">
                <app-enaio-server-info #viewEnaioServerInfo [embeddedComponent]=true>
                </app-enaio-server-info>
            </p-tabPanel>
            <p-tabPanel header="ID Suche">
                <app-enaio-id-search #viewEnaioIdSearch [embeddedComponent]=true>
                </app-enaio-id-search>
            </p-tabPanel>
            <p-tabPanel header="Historie">
                <app-enaio-what-happened #viewEnaioWhatHappened [embeddedComponent]=true>
                </app-enaio-what-happened>
            </p-tabPanel>
            <p-tabPanel header="Lizenzen">
                <app-enaio-licenses #viewEnaioLicenses [embeddedComponent]=true>
                </app-enaio-licenses>
            </p-tabPanel>
            <p-tabPanel header="Watcher">
                <app-enaio-watcher #viewEnaioWatcher [embeddedComponent]=true>
                </app-enaio-watcher>
            </p-tabPanel>
            <p-tabPanel header="Web Client Debugger">
                <app-enaio-web-client-debugger #viewEnaioWebClientDebugger [embeddedComponent]=true>
                </app-enaio-web-client-debugger>
            </p-tabPanel>
        </p-tabView>
    </p-tabPanel>
    <p-tabPanel class="group" header="datev">
        <p-tabView [(activeIndex)]="subTabIndex[2]" (activeIndexChange)="activeTabChanged()">
            <p-tabPanel header="Rechnungs-Suche">
                <app-datev-invoice-search #viewDatevInvoiceSearch [embeddedComponent]=true>
                </app-datev-invoice-search>
            </p-tabPanel>
            <p-tabPanel header="Rechnungs-Zusammenfassung">
                <app-datev-invoice-summary #viewDatevInvoiceSummary [embeddedComponent]=true>
                </app-datev-invoice-summary>
            </p-tabPanel>
            <p-tabPanel header="Datenanzeige">
                <app-datev-data-fetcher #viewDatevDataFetcher [embeddedComponent]=true>
                </app-datev-data-fetcher>
            </p-tabPanel>
        </p-tabView>
    </p-tabPanel>
    <p-tabPanel class="group" header="Tools">
        <p-tabView [(activeIndex)]="subTabIndex[3]" (activeIndexChange)="activeTabChanged()">
            <p-tabPanel header="App Starter">
                <app-app-starter #viewAppStarter [embeddedComponent]=true>
                </app-app-starter>
            </p-tabPanel>
            <p-tabPanel header="System Info">
                <app-system-information #viewSystemInformation [embeddedComponent]=true>
                </app-system-information>
            </p-tabPanel>
            <p-tabPanel header="Connection Tester">
                <app-connection-tester #viewConnectionTester [embeddedComponent]=true>
                </app-connection-tester>
            </p-tabPanel>
            <p-tabPanel header="SM Code">
                <app-sm-code-generator #viewSmCodeGenerator [embeddedComponent]=true>
                </app-sm-code-generator>
            </p-tabPanel>
            <p-tabPanel header="Passwörter finden">
                <app-password-retriever #viewPasswordRetriever [embeddedComponent]=true>
                </app-password-retriever>
            </p-tabPanel>
            <p-tabPanel header="Datenbank Tools">
                <app-database-tools #viewDatabaseTools [embeddedComponent]=true>
                </app-database-tools>
            </p-tabPanel>
            <p-tabPanel header="MSGraph Tools">
                <app-ms-graph-tools #viewMsGraphTools [embeddedComponent]=true>
                </app-ms-graph-tools>
            </p-tabPanel>
            <p-tabPanel header="Barcode Tester">
                <app-barcode-tester #viewBarcodeTester [embeddedComponent]=true>
                </app-barcode-tester>
            </p-tabPanel>
            <p-tabPanel header="Text Tools">
                <app-text-tools #viewTextTools [embeddedComponent]=true>
                </app-text-tools>
            </p-tabPanel>
            <p-tabPanel header="Diverses">
                <app-misc-tools #viewMiscTools [embeddedComponent]=true>
                </app-misc-tools>
            </p-tabPanel>
        </p-tabView>
    </p-tabPanel>
</p-tabView>
<p-contextMenu #cm id="global-context-menu" [model]="contextMenu"></p-contextMenu>
