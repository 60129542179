import {
    ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable,
    Injector
} from '@angular/core';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { Utils } from 'src/modules/utils/shared/utils';
import { SmFileUploadInternalComponent } from '../components/sm-file-upload-internal/sm-file-upload-internal.component';
import { BrowserUploadedFile } from '../models/uploaded-file.model';

@Injectable()
export class FileUploadService {

    //Müsste eigentlich über ViewContainerRef gehen, aber da das in einem Service nicht so einfach geht, aktuell zu viel Aufwand
    // eslint-disable-next-line deprecation/deprecation
    constructor(private app: MainAppService, private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {
    }

    async chooseFile(loadContents = true, acceptExtensions?: string[]): Promise<BrowserUploadedFile[]> {
        const componentRef = this.componentFactoryResolver.resolveComponentFactory(SmFileUploadInternalComponent).create(this.injector);
        componentRef.instance.loadContents = loadContents;
        componentRef.instance.acceptExtensions = acceptExtensions;
        let prom = new Promise<BrowserUploadedFile[]>((resolve, _) => {
            componentRef.instance.onFilesAdded.subscribe(files => {
                this.appRef.detachView(componentRef.hostView);
                componentRef.destroy();
                resolve(files);
            });
        });

        this.appRef.attachView(componentRef.hostView);

        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        document.body.appendChild(domElem);

        //Damit ChangeDetection die oben gesetzten Parameter auf das HTML anwendet
        //await Utils.sleep(100);

        (domElem.children[0] as any).click();

        let files = await prom;

        if (acceptExtensions != null) {
            let invalid = files.filter(file => !acceptExtensions.some(ext => file.legacy.name.toLowerCase().endsWith(ext.toLowerCase())));
            if (invalid.length > 0) {
                await this.app.messageDialog.info("Die Datei" + (invalid.length > 1 ? "en " : " ") + Utils.arrayItemsToString(invalid, ", ", file => file.legacy.name, " und ") + (invalid.length > 1 ? " können" : " kann") +
                    " nicht geöffnet werden, da nur die Dateierweiterungen " + Utils.arrayItemsToString(acceptExtensions, ", ", null, " und ") + " erlaubt sind");
                return files.filter(file => acceptExtensions.some(ext => file.legacy.name.toLowerCase().endsWith(ext.toLowerCase())));
            }
        }

        return files;
    }
}
