import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { EnaioCall } from "./EnaioCall";
import { EnaioClientEvent } from "./EnaioClientEvent";

export class EnaioCallGetClientEvents extends EnaioCall <EnaioClientEvent[]> {
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioClientEvent, 1);
    }

}
