/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { ClassLesson } from './class-lesson.entity';

@Entity()
export class ClassLessonPupil extends SmBaseEntity {
    @ManyToOne(() => ClassLesson, (o: ClassLesson) => o.pupils, { onDelete: "CASCADE", nullable: true, orphanedRowAction: 'delete'})
    lesson: ClassLesson;

    @Column({default: 0})
    pupilId: number = 0;

    //nullable nur noch drinnen wegen Altlasten
    @Column({default: false, nullable: true})
    missing: boolean = false;

    @Column({default: ""})
    note: string = "";

    getStringRepresentation(): string {
        return this.pupilId + " (Fehlt: " + (this.missing ? "Ja" : "Nein") + ", " + this.note + ")";
    }
}
