import { Type } from "class-transformer";
import { EnaioCheckPermissionAccessTypeResult } from "./EnaioCheckPermissionAccessTypeResult";
import { EnaioObjectIdAndType } from "./EnaioObjectIdAndType";

export class EnaioCheckPermissionResult {
    @Type(() => EnaioObjectIdAndType)
    forObject: EnaioObjectIdAndType;
    fromUserGroup = -1;
    @Type(() => EnaioCheckPermissionAccessTypeResult)
    accessTypes: EnaioCheckPermissionAccessTypeResult[];
}
