import { Type } from "class-transformer";

export class DanglingLesson {
    @Type(() => Date)
    date: Date;
    hour: number;
    domain: string;
    courseId: number;
    teacher: string;

    constructor(date: Date, hour: number, domain: string, courseId: number, teacher: string) {
        this.date = date;
        this.hour = hour;
        this.domain = domain;
        this.courseId = courseId;
        this.teacher = teacher;
    }
}
