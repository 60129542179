import { Type } from "class-transformer";
import { EnaioObjectId } from "./EnaioObjectId";

export class EnaioObjectTypeId {
    @Type(() => EnaioObjectId)
    archive: EnaioObjectId;
    @Type(() => EnaioObjectId)
    objectType: EnaioObjectId;


    constructor(archive: EnaioObjectId, objectType: EnaioObjectId) {
        this.archive = archive;
        this.objectType = objectType;
    }


}
