import { Type } from "class-transformer";
import { EnaioErrorCode } from "./EnaioErrorCode";
import { JobResult } from "./JobResult";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class EnaioErrorAnalysis {
    errorCode: EnaioErrorCode;
    errorMessage = "";
    analysisProblem: string;
    info: OrdinaryObject;
    temporary = false;
    @Type(() => JobResult)
    jr: JobResult;
}
