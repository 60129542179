import { Type } from "class-transformer";
import { Utils } from "src/modules/utils/shared/utils";
import { LearningContent } from "./learning-content.entity";

export class LearningUserFlat {
    id = 0;
    userName = "";
    firstName = "";
    lastName = "";
    email = "";
    source = "";
    sendNotificationMails = false;
    @Type(() => Date)
    joinedDate: Date = null;
    costCenters: string[] = [];
    costCenterNames: string[] = [];
    managerFor: string[] = [];
    managers: string[] = [];
    jobDescriptions: string[] = [];
    jobDescriptionNames: string[] = [];
    isAdmin = false;
    mayCreateItems = true;
    mayModifyItems = true;
    mayRunAnalyses = true;
    notificationDaysBeforeDeadline = 14;

    getStringRepresentation(): string {
        return this.getFullName();
    }

    getEntityName(): string {
        return "Benutzer";
    }

    toString(): string {
        return this.getStringRepresentation();
    }

    getFullName(): string {
        return this.lastName + ", " + this.firstName;
    }

    getNotificationDate(content: LearningContent): Date {
        let dl = content.getDeadline(this.joinedDate);
        return dl != null ? Utils.dateAdd(dl, "day", -this.notificationDaysBeforeDeadline) : null;
    }

    mayModifyContent(content: LearningContent, mayInspect: number[]): boolean {
        return this.mayModifyItems || mayInspect.includes(content.id) || content.author == this.userName;
    }

    isAdminOrManager(): boolean {
        return this.isAdmin || !Utils.isNoe(this.managerFor);
    }
}
