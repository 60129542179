<div *ngIf="listData != null && listData.length == 0" class="no-notifications">Aktuell keine Benachrichtigungen</div>
<div class="grid">
    <p-accordion class="col-12 lg:col-6 lg:col-offset-3" (onOpen)="openNotification($event.index)">
        <p-accordionTab *ngFor="let n of listData; index as index" [class.unread]="n.readDate == null" [class.read]="n.readDate != null">
            <ng-template pTemplate="header">
                <div class="header">
                    <span>
                        <i class="fas icon" [class.fa-envelope]="n.readDate == null" [class.fa-envelope-open]="n.readDate != null"></i>
                        <a *ngIf="n.senderId != '' && n.senderId != 'SYSTEM'" (click)="answer(index)"><i [ngClass]="'icon fas fa-reply'" [pTooltip]="'Antworten'"></i></a>
                        <a *ngFor="let link of n.links" [routerLink]="link.url" (click)="linkClicked(index)"><i [ngClass]="'icon ' + link.icon" [pTooltip]="link.title"></i></a>
                    </span>
                    <span>{{n.subject}}</span>
                    <span>{{n.date | date: "dd.MM.yyyy HH:mm"}}</span>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div [innerHtml]="n.message | keepHtml"></div>
            </ng-template>
        </p-accordionTab>
    </p-accordion>
</div>
