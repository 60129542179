/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { ColumnTransformerFloat } from 'src/modules/database/shared/column-transformer-float';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne } from 'typeorm';
import { Exam } from './exam.entity';

@Entity()
export class ExamGrade extends SmBaseEntity {
    @ManyToOne(() => Exam, (o: Exam) => o.grades, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    exam: Exam;

    @Column({default: 0})
    pupilId: number = 0;

    @Column({default: ""})
    title: string = "";

    @Column({ nullable: true})
    @Type(() => Date)
    date: Date;

    @Column({ type: 'decimal', precision: 9, scale: 3, default: -1, transformer: new ColumnTransformerFloat()})
    @Type(() => Number)
    grade: number = -1;

    isRated(): boolean {
        return this.grade >= 0;
    }

    hasDifferingTitle(): boolean {
        return !Utils.isNoe(this.title) || this.date != null;
    }
}
