import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GuiUtils } from 'src/modules/utils/misc/gui-utils';
import { GradeType } from '../../shared/grade-type.enum';

@Component({
    selector: 'sm-grade-editor',
    templateUrl: './grade-editor.component.html',
    styleUrls: ['./grade-editor.component.scss']
})
export class GradeEditorComponent {

    _GuiUtils = GuiUtils;
    _GradeType = GradeType;

    @Input()
    gradeType = GradeType.percent;
    _value: number = null;
    @Output() valueChange = new EventEmitter<number>();

    @Input()
    set value(value: number) {
        this._value = value >= 0 && value <= 999 ? value : null;
    }

    change(): void {
        this.valueChange.emit(this._value != null ? this._value : -1);
    }
}
