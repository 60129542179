import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { EnaioCall } from "./EnaioCall";
import { EnaioResource } from "./EnaioResource";

export class EnaioCallGetResources extends EnaioCall <EnaioResource[]> {
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioResource, 1);
    }

}
