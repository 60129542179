export enum EnaioMainType {
    folder = 0,
    grayscale = 1,
    blackWhite = 2,
    color = 3,
    windows = 4,
    multimedia = 5,
    mail = 6,
    xml = 7,
    container = 8,
    register = 99,
    typelessInUserTray = 200,
    portfolio = 203,
    typelessInWorkflowTray = 300,
    note = 32_767
}
export namespace EnaioMainType{
    export function getName(value: EnaioMainType): string {
        switch (value) {
            case EnaioMainType.folder:
                return "folder";
            case EnaioMainType.grayscale:
                return "grayscale";
            case EnaioMainType.blackWhite:
                return "blackWhite";
            case EnaioMainType.color:
                return "color";
            case EnaioMainType.windows:
                return "windows";
            case EnaioMainType.multimedia:
                return "multimedia";
            case EnaioMainType.mail:
                return "mail";
            case EnaioMainType.xml:
                return "xml";
            case EnaioMainType.container:
                return "container";
            case EnaioMainType.register:
                return "register";
            case EnaioMainType.typelessInUserTray:
                return "typelessInUserTray";
            case EnaioMainType.portfolio:
                return "portfolio";
            case EnaioMainType.typelessInWorkflowTray:
                return "typelessInWorkflowTray";
            case EnaioMainType.note:
                return "note";
        }
    }
}
