import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { EnaioCall } from "./EnaioCall";
import { EnaioUser } from "./EnaioUser";
import { EnaioUserIdentification } from "./EnaioUserIdentification";

export class EnaioCallGetUserInfo extends EnaioCall <EnaioUser> {
    @Type(() => EnaioUserIdentification)
    userId: EnaioUserIdentification;
    returnAttributes = false;
    returnGroups = false;
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioUser);
    }

}
