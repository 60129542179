export enum ConfigFieldType {
    string,
    int,
    long,
    double,
    bool,
    date,
    enum,
    enumValue,
    anything,
    object,
    timeSpan
}
