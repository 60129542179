import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { EnaioCall } from "./EnaioCall";
import { EnaioUserIdentification } from "./EnaioUserIdentification";
import { EnaioWorkflowDefinitionSelectData } from "./EnaioWorkflowDefinitionSelectData";
import { EnaioWorkflowModel } from "./EnaioWorkflowModel";

export class EnaioCallGetWorkflowModels extends EnaioCall <EnaioWorkflowModel[]> {
    organisationId: string;
    @Type(() => EnaioUserIdentification)
    forUser: EnaioUserIdentification;
    getDefinition = false;
    selectData: EnaioWorkflowDefinitionSelectData[];
    modelIds: string[];
    familyNames: string[];
    useModelCache = false;
    clientType: string;
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioWorkflowModel, 1);
    }

}
