<div class="grid">
    <div class="col-2">
        <sm-form #form [form]="formInput">
        </sm-form>
        <p-button icon="fas fa-run" label="Starten" (onClick)="run()"></p-button>
    </div>
    <div class="col-10 single-page-view">
        <sm-table-on-push #table *ngIf="tableData != null" [data]="tableData" [deltaHeight]=140></sm-table-on-push>
    </div>
</div>
