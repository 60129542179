import { MainAppService } from "src/modules/app-template/services/main-app.service";
import { Utils } from "src/modules/utils/shared/utils";

export class SmComponent {

    _Utils = Utils;

    overrideCanDeactivate: boolean = null;

    constructor(public app: MainAppService) {
    }

    navigateBack(): void {
        this.overrideCanDeactivate = true;
        this.app.navigateBack();
    }
}
