<div class="grid">
    <div class="col-4">
        <span class="search">
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><i class="fas fa-search"></i></span>
                <input type="text" pInputText placeholder="Stored Index" [(ngModel)]="storedIndexSearch" (ngModelChange)="getStoredIndex()">
            </div>
        </span>
        <sm-form  [form]="formInput">
        </sm-form>
        <p-button icon="fas fa-run" label="Starten" (onClick)="run()"></p-button>
    </div>
    <div class="col-8 single-page-view">
        <textarea pInputTextarea spellcheck=false class="width100p full-height-120x monospace no-resize" [ngModel]="result"></textarea>
    </div>
</div>
