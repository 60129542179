import { Type } from "class-transformer";
import { EnaioDocument } from "./EnaioDocument";
import { EnaioDocumentRemarkType } from "./EnaioDocumentRemarkType";

export class EnaioDocumentRemark {
    id = 0;
    type: EnaioDocumentRemarkType;
    isObjectRelation = false;
    relationDirection = 0;
    relatedObjectId = 0;
    relatedObjectType = 0;
    mediumId = 0;
    createdById = 0;
    createdBy: string;
    @Type(() => Date)
    createDate: Date;
    modifiedById = 0;
    modifiedBy: string;
    textId: number = null;
    text: string;
    @Type(() => EnaioDocument)
    relatedObject: EnaioDocument;
}
