<p-card *ngIf="!done" header="Bewerbung einreichen" [style]="{'width': '400px', 'margin': 'auto'}">
    <div class="grid">
        <p-card class="col-12">
            <sm-form [form]="form">
            </sm-form>
            <p-button class="toolbar-element mt-8" icon="fas fa-upload" label="Dokument anhängen" (onClick)="upload()">
            </p-button>
            <p-tag *ngIf="uploaded != null" severity="success" value="Angehängt"></p-tag>
        </p-card>
        <div class="col-12" style="text-align: left">
            <p-button class="toolbar-element" icon="fas fa-paper-plane" label="Abschicken" (onClick)="send()">
            </p-button>
        </div>
     </div>

</p-card>

<p-card *ngIf="done" header="Vielen Dank" [style]="{'width': '400px', 'margin': 'auto'}">
    Ihre Bewerbung wurde eingereicht
</p-card>
