<ng-container *transloco="let t">
    <div *ngIf="isAuthor == null" class="grid home">
        <div class="col-12 lg:col-6 lg:col-offset-3 dashboard-card-main welcome">
            <div [innerHTML]="t('lea.welcomeText') | keepHtml">
            </div>
        </div>
        <app-dashboard-card class="col-6 lg:col-3 lg:col-offset-3" icon="fas fa-lightbulb" [title]="t('lea.titleStudent')" [additionalText]="t('lea.subtitleStudent')" [menuButton]="false" [routerLink]="['/enaio-learn', 'home', '0']"></app-dashboard-card>
        <app-dashboard-card class="col-6 lg:col-3" icon="fas fa-graduation-cap" [title]="t('lea.titleAuthor')" [additionalText]="t('lea.subtitleAuthor')" [menuButton]="false" [routerLink]="['/enaio-learn', 'home', '1']"></app-dashboard-card>
    </div>

    <ng-container *ngIf="isAuthor != null">
        <app-sm-switch class="mb-2" [(value)]="isTableView" [textFalse]="t('lea.home.viewModeTiles')" [textTrue]="t('lea.home.viewModeTable')" (valueChange)="tableViewChanged()"></app-sm-switch>
        <p-divider></p-divider>
        <div class="dashboard-card-main filter-card grid">
            <p-dropdown class="mr-4 mb-1" [styleClass]="'width200'" [options]="categoriesGui" [(ngModel)]="selectedCat" (onChange)="updateFilter()"></p-dropdown>
            <span class="search">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"><i class="fas fa-search"></i></span>
                    <input class="mouse-pointer" type="text" pInputText [placeholder]="t('lea.home.search')" [(ngModel)]="fullTextSearchTerm" (ngModelChange)="updateFilter()">
                </div>
            </span>
        </div>

        <ng-container *ngIf="isAuthor">
            <div class="grid">
                <app-dashboard-card class="col-6 lg:col-3" icon="fas fa-user-edit" [title]="t('lea.author.filterOnlyOwn')" [menuButton]="false" [backgroundColor]="listTypeAuthor == 0 ? '#FFA0A0' : 'white'" [border]="listTypeAuthor == 0 ? '1px solid red' : ''" (click2)="setListTypeAuthor(0)"></app-dashboard-card>
                <app-dashboard-card class="col-6 lg:col-3" icon="fas fa-glasses" [title]="t('lea.author.filterToInspect')" [menuButton]="false" [backgroundColor]="listTypeAuthor == 1 ? '#FFA0A0' : 'white'"  [border]="listTypeAuthor == 1 ? '1px solid red' : ''" (click2)="setListTypeAuthor(1)"></app-dashboard-card>
                <app-dashboard-card class="col-6 lg:col-3" icon="fas fa-eye" [title]="t('lea.author.filterAll')" [menuButton]="false" [backgroundColor]="listTypeAuthor == 2 ? '#FFA0A0' : 'white'"  [border]="listTypeAuthor == 2 ? '1px solid red' : ''" (click2)="setListTypeAuthor(2)"></app-dashboard-card>
                <app-dashboard-card *ngIf="profile.mayCreateItems" class="col-6 lg:col-3" icon="fas fa-plus" [title]="t('lea.author.add')" [menuButton]="false" [routerLink]="['/enaio-learn', 'learning-content', '0']"></app-dashboard-card>
            </div>
            <p-divider class="divider-caption" [align]="'center'"><b>Lerninhalte</b></p-divider>
            <div *ngIf="!isTableView" class="grid">
                <app-dashboard-card *ngFor="let item of listForAuthorGui" class="col-6 lg:col-3" icon="fas fa-lightbulb" [icon2]="item.getStatus() == _LearningContentStatus.inInspection ? 'fas fa-glasses' : ''" [title]="item.title" [additionalText]="item.category?.name ?? ''"
                [backgroundColor]="_Utils.toColorHexString(item.category?.color ?? 16777215)" [iconColorAuto]=true [menuButton]="false" [routerLink]="['/enaio-learn', 'learning-content', '' + item.id]"></app-dashboard-card>
            </div>
            <div *ngIf="isTableView" class="grid">
                <crud-list class="col-12" [data]="tableAuthor" [gridLines]="true" [allowCreate]="false" detailUrl="/enaio-learn/learning-content/" restUrl="api/lrn/learningcontent">
                </crud-list>
            </div>
        </ng-container>
        <ng-container *ngIf="!isAuthor">
            <div class="grid">
                <app-dashboard-card class="col-6 lg:col-3" icon="fas fa-tasks" [title]="t('lea.student.filterMine')" [menuButton]="false" [backgroundColor]="listTypeStudent == 0 ? '#FFA0A0' : 'white'"  [border]="listTypeStudent == 0 ? '1px solid red' : ''" (click2)="setListTypeStudent(0)"></app-dashboard-card>
                <app-dashboard-card class="col-6 lg:col-3" icon="fas fa-plus-circle" [title]="t('lea.student.filterCompulsory')" [menuButton]="false" [backgroundColor]="listTypeStudent == 1 ? '#FFA0A0' : 'white'"  [border]="listTypeStudent == 1 ? '1px solid red' : ''" (click2)="setListTypeStudent(1)"></app-dashboard-card>
                <app-dashboard-card class="col-6 lg:col-3" icon="fas fa-eye" [title]="t('lea.student.filterAll')" [menuButton]="false" [backgroundColor]="listTypeStudent == 2 ? '#FFA0A0' : 'white'" [border]="listTypeStudent == 2 ? '1px solid red' : ''" (click2)="setListTypeStudent(2)"></app-dashboard-card>
                <app-dashboard-card class="col-6 lg:col-3" icon="fas fa-check" [title]="t('lea.student.filterPast')" [menuButton]="false" [backgroundColor]="listTypeStudent == 3 ? '#FFA0A0' : 'white'" [border]="listTypeStudent == 3 ? '1px solid red' : ''" (click2)="setListTypeStudent(3)"></app-dashboard-card>
            </div>
            <p-divider class="divider-caption" [align]="'center'"><b>Lerninhalte</b></p-divider>
            <div *ngIf="!isTableView" class="grid" style="align-items: stretch">
                <app-dashboard-card *ngFor="let item of listForStudentGui" class="col-6 lg:col-3" icon="fas fa-lightbulb" [title]="item.learningContent.title"
                    [additionalText]="(item.mandatory ? t('lea.learningContent.isMandatory') : t('lea.learningContent.isCompulsory')) + '<br>' + (item.isFinished() ? t('lea.learningContent.isFinished') : item.deadlineDate != null ? t('lea.learningContent.deadline') + ': ' + (item.deadlineDate | date: 'dd.MM.yyyy') : '&nbsp;')"
                    [backgroundColor]="_Utils.toColorHexString(item.learningContent.category?.color ?? 16777215)" [iconColorAuto]=true [menuButton]="false" (click2)="participate(item)"></app-dashboard-card>
            </div>
            <div *ngIf="isTableView" class="grid">
                <sm-table class="col-12" [data]="tableStudent" [gridLines]="true" (onRowSelect)="attend($event)"></sm-table>
            </div>
        </ng-container>

        <p-messages *ngIf="!isAuthor && listForStudentGui != null && listForStudentGui.length == 0 || isAuthor && listForAuthorGui != null && listForAuthorGui.length == 0" severity="info">
            <ng-template pTemplate>
                <div class="ml-2">{{t('lea.home.noContentsVisible')}}</div>
            </ng-template>
        </p-messages>
    </ng-container>
</ng-container>
