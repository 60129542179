import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FileUploadService } from '../../services/file-upload-service';
import { BrowserUploadedFile } from '../../models/uploaded-file.model';

@Component({
  selector: 'app-sm-file-upload',
  templateUrl: './sm-file-upload.component.html',
  styleUrl: './sm-file-upload.component.scss'
})
export class SmFileUploadComponent {

    fileUpload = inject(FileUploadService);

    @Input()
    value: BrowserUploadedFile[];

    @Output()
    valueChange = new EventEmitter<BrowserUploadedFile[]>();

    async upload(): Promise<void> {
        this.value = await this.fileUpload.chooseFile();
        this.valueChange.emit(this.value);
    }
}
