import { Type } from "class-transformer";
import { EnaioWorkflowApplicationParameter } from "./EnaioWorkflowApplicationParameter";

export class EnaioWorkflowApplicationDefinition {
    id = "";
    name = "";
    maskId = "";
    @Type(() => EnaioWorkflowApplicationParameter)
    formalParameters: EnaioWorkflowApplicationParameter[];
}
