import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'sm-overview-box',
    templateUrl: './sm-overview-box.component.html',
    styleUrls: ['./sm-overview-box.component.css']
})
export class SmOverviewBoxComponent {

    @Input()
    count = 0;
    @Input()
    text = "";
    @Input()
    icon = "";
    @Input()
    boxColor = "1";
    @Input()
    footerText = "";
    @Output()
    click2 = new EventEmitter<Event>();

    click(event: Event): void {
        this.click2.emit(event);
    }
}
