/* eslint-disable @typescript-eslint/no-unused-vars */
import { AdvancedType } from "src/modules/utils/shared/advanced-type";

export class EnaioCall<T> {
    contextUserName = "";
    simulate = false;

    getModelType(): AdvancedType {
        return null;
    }


}
