/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { SchoolType } from 'src/modules/enaio-certificates/shared/school-type.enum';
import { CustomInitializer } from 'src/modules/sm-base/shared/custom-initializer.interface';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, OneToMany } from 'typeorm';
import { DomainHoursPerWeek } from './domain-hours-per-week.entity';
import { DomainSettings } from './domain-settings.entity';
import { EnaioCertificatesTools } from './enaio-certificates-tools';
import { ExamType } from './exam-type.entity';
import { GradeSettings } from './grade-settings.entity';
import { NumberFormatMode } from './number-format-mode.enum';

@Entity()
export class SchoolTypeSettings extends SmBaseEntity implements CustomInitializer {
    @Column({default: SchoolType.elementary})
    type: SchoolType = SchoolType.elementary;

    @Column({default: 0})
    year: number = 0;

    @Column({default: false})
    isHalfYear: boolean = false;

    @OneToMany(() => DomainSettings, o => o.settings, { cascade: true})
    @Type(() => DomainSettings)
    domainSettings: DomainSettings[];

    @OneToMany(() => GradeSettings, o => o.settings, { cascade: true})
    @Type(() => GradeSettings)
    gradeSettings: GradeSettings[];

    @OneToMany(() => ExamType, o => o.settings, { cascade: true})
    @Type(() => ExamType)
    examTypes: ExamType[];

    @OneToMany(() => DomainHoursPerWeek, o => o.settings, { cascade: true })
    @Type(() => DomainHoursPerWeek)
    domainHoursPerWeek: DomainHoursPerWeek[];

    @Column({default: NumberFormatMode.decimalTwo})
    roundingType: NumberFormatMode = NumberFormatMode.decimalTwo;

    customInitializer(): void {
        this.domainSettings = Utils.arrayEnsure(this.domainSettings);
        this.domainSettings.forEach(item => item.customInitializer());
        this.gradeSettings = Utils.arrayEnsure(this.gradeSettings);
        this.examTypes = Utils.arrayEnsure(this.examTypes);
        this.domainHoursPerWeek = Utils.arrayEnsure(this.domainHoursPerWeek);
    }

    formatValue(value: number, overrideRoundingType: NumberFormatMode = null): string {
        return EnaioCertificatesTools.formatValue(value, overrideRoundingType || this.roundingType);
    }

    getGradeSettings(grade: number): GradeSettings {
        this.gradeSettings = Utils.arrayEnsure(this.gradeSettings);
        let result = this.gradeSettings.find(s => s.grade == grade);
        if (result == null) {
            result = GradeSettings.makeDefault(grade);
            this.gradeSettings.push(result);
        }
        return result;
    }

    getDomainHoursPerWeek(domain: string, grade: number): number {
        return this.domainHoursPerWeek.find(hpw => hpw.domain == domain && hpw.grade == grade)?.count ?? 0;
    }

    getGgnFactor(domain: string, grade: number): number {
        let pw = this.getDomainHoursPerWeek(domain, grade);
        return pw == 0 || pw == 1 ? 25 : pw == 2 ? 40 : 50;
    }

    getKgnFactor(domain: string, grade: number): number {
        return 100 - this.getGgnFactor(domain, grade);
    }

    canCopyLastYear(grade: number): boolean {
        return this.type == SchoolType.special && grade % 3 != 1;
    }
}
