import { EnaioLocationType } from "./EnaioLocationType";

export class EnaioLocation {
    type: EnaioLocationType;
    id = 0;

    constructor(type: EnaioLocationType, id: number) {
        this.type = type;
        this.id = id;
    }

}
