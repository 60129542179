/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column } from 'typeorm';

export class UploadedFile extends SmBaseEntity {
    @Column()
    belongsTo: string = "";

    @Column()
    path: string = "";

    @Column()
    originalFileName: string = "";

    @Column()
    uploadDate: Date;
}
