import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { CipherMateConfig } from "./CipherMateConfig";
import { EnaioCall } from "./EnaioCall";
import { EnaioDocumentConversion } from "./EnaioDocumentConversion";

export class EnaioCallGetDocument extends EnaioCall <string[]> {
    documentId = 0;
    convert: EnaioDocumentConversion;
    addAnnotations = false;
    refreshCacheIfNeeded = true;
    noFallbackConvert = false;
    getBase64 = false;
    failIfNoDocument = true;
    @Type(() => CipherMateConfig)
    cipherMateConfig: CipherMateConfig;
    cipherMateDecrypt = false;
    cipherMateDecryptOnlyIfExtensionMatching = false;
    resultFileName: string;
    getModelType(): AdvancedType {
        return AdvancedType.ofPrimitive("string", 1);
    }

}
