<sm-toolbar>
    <p-button [disabled]="!isChanged()" icon="fas fa-save" label="Speichern" (onClick)="save()">
    </p-button>
</sm-toolbar>
<p-table [value]="rows" [styleClass]="'p-datatable-gridlines'">
    <ng-template pTemplate="header">
      <tr>
        <th class="align-left" width="19%">Thema&#47;Inhalt</th>
        <th class="align-left" width="19%">Fehlende Schüler</th>
        <th class="align-left" width="19%">Datum&#47;Belehrender</th>
        <th class="align-left" width="19%">Nachbelehrung</th>
        <th class="align-left" width="19%">Datum&#47;Belehrender</th>
        <th class="align-left" width="5%"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-index="rowIndex">
      <tr [pSelectableRow]="row">
        <td pEditableColumn *ngIf="index >= rowCount">
            <p-cellEditor>
                <ng-template pTemplate="input">
                    <p-dropdown id="topic" [filter]="true" appendTo="body" selectionMode="multiple" [styleClass]="'w-full'" [options]="instructionOptions" [ngModel]="row.topic" (ngModelChange)="changeTopic($event, row)">
                    </p-dropdown>
                </ng-template>
                <ng-template pTemplate="output">
                    {{row.topic}}
                </ng-template>
            </p-cellEditor>
        </td>
        <td *ngIf="index < rowCount">
          {{row.topic}}
        </td>
        <td pEditableColumn *ngIf="index >= rowCount">
          <p-cellEditor>
            <ng-template pTemplate="input">
            <p-multiSelect id="missingPupilIds" appendTo="body" selectionMode="multiple" [styleClass]="'w-full'" [options]="pupilsGui" [ngModel]="row.missingPupilIds" (ngModelChange)="changeMissing($event, row)">
            </p-multiSelect>
          </ng-template>
            <ng-template pTemplate="output">
                {{row.missingPupilIds != null ? getPupilList(row.missingPupilIds).join(', ') : ''}}
            </ng-template>
        </p-cellEditor>
        </td>
        <td *ngIf="index < rowCount">
          {{row.missingPupilIds != null ? getPupilList(row.missingPupilIds).join(', ') : ''}}
        </td>
        <td>
          {{ row.date | date:'dd.MM.yyyy' }} - {{ getTeacherByUser(row.instructedBy) }}
        </td>
        <td pEditableColumn *ngIf="secondInstructions.length == 0 || secondInstructions.indexOf(index) == -1">
            <p-cellEditor>
              <ng-template pTemplate="input">
                  <p-dropdown id="secondTopic" selectionMode="multiple" [styleClass]="'w-full'" [options]="instructionOptions" [ngModel]="row.secondTopic" (ngModelChange)="changeSecondTopic($event, row)">
                  </p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                  {{row.secondTopic}}
              </ng-template>
            </p-cellEditor>
        </td>
        <td *ngIf="secondInstructions.length > 0 && secondInstructions.indexOf(index) != -1">
          {{row.secondTopic}}
        </td>
        <td *ngIf="row.secondDate != null">
          {{ row.secondDate | date:'dd.MM.yyyy' }} - {{getTeacherByUser(row.secondInstructedBy)}}
        </td>
        <td *ngIf="row.secondDate == null">
          {{ row.secondDate | date:'dd.MM.yyyy' }}
        </td>
        <td pEditableColumn>
          <button *ngIf="index >= rowCount" pButton pRipple class="p-button-text" icon="fas fa-trash" (click)="removeRow(row)">
          </button>
        </td>
    </tr>
    </ng-template>
</p-table>
<sm-toolbar *ngIf="inited" >
    <p-button icon="fas fa-plus" label="Neue Zeile" (onClick)="addRow()">
    </p-button>
</sm-toolbar>
