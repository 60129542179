<ng-container>
    <a [attr.href]="item.url" (click)="itemClick($event)" *ngIf="!item.routerLink || item.items"
       (mouseenter)="hover=true" (mouseleave)="hover=false"(keydown.enter)="itemClick($event)"
      [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" pRipple>
        <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        <span>{{item.label}}</span>
        <i class="fas fa-angle-down layout-menuitem-toggler" *ngIf="item.items"></i>
        <span class="menuitem-badge" *ngIf="item.badge">{{item.badge}}</span>
    </a>
    <a (click)="itemClick($event)" (mouseenter)="hover=true" (mouseleave)="hover=false" *ngIf="item.routerLink && !item.items"
        [routerLink]="item.routerLink" routerLinkActive="active-menuitem-routerlink"
        [routerLinkActiveOptions]="{exact: true}" [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" pRipple>
        <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        <span>{{item.label}}</span>
        <i class="fas fa-angle-down layout-menuitem-toggler" *ngIf="item.items"></i>
        <span class="menuitem-badge" *ngIf="item.badge">{{item.badge}}</span>
    </a>
    <ul *ngIf="item.items && active" [@children]="active ? 'visibleAnimated' : 'hiddenAnimated'">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
            <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
        </ng-template>
    </ul>
</ng-container>
