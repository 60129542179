import { Type } from "class-transformer";
import { DhConfigInfo } from "./DhConfigInfo";
import { DhCustomerFileType } from "./DhCustomerFileType";

export class DhCustomerFile {
    customer = "";
    path = "";
    type: DhCustomerFileType;
    @Type(() => DhConfigInfo)
    configInfo: DhConfigInfo;
    verificationFailed = false;
}
