import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { Utils } from 'src/modules/utils/shared/utils';

@Component({
    selector: 'chapter-list',
    templateUrl: './chapter-list.component.html',
    styleUrls: ['./chapter-list.component.scss']
})
export class ChapterListComponent {

    _Utils = Utils;

    @Input()
    labelField = "title";
    @Input()
    placeholder = "-Ohne Titel-";
    @Input()
    addText = "";
    @Input()
    emptyItem: any;

    _items: any[];
    _canRearrange = true;

    @Output()
    selectedIndexChange = new EventEmitter<[number, boolean]>();
    @Output()
    addItem = new EventEmitter<any>();

    @ViewChild("cm")
    cm: ContextMenu;

    menuItems: MenuItem[];

    selectedIndex = 0;

    constructor(private app: MainAppService) {
        this.updateMenuItems();
    }

    @Input()
    set items(value: any[]) {
        this._items = value;
        this.selectedIndex = Math.min(this.selectedIndex, value.length - 1);
    }

    @Input()
    set canRearrange(value: boolean) {
        this._canRearrange = value;
        this.updateMenuItems();
    }

    addItemClicked(): void {
        this.addItem.emit(null);
        if (this.addItem != null) {
            this._items.push(Utils.cloneDeep(this.emptyItem));
            this.select(this._items.length - 1);
        }
    }

    async deleteItem(): Promise<void> {
        if (await this.app.messageDialog.yesNo(this.app.t("lea.learningContent.chapter.deleteChapterMessage"), this.app.t("general.warning"))) {
            this._items.splice(this.selectedIndex, 1);
            this.select(Math.min(this.selectedIndex, this._items.length - 1), true);
        }
    }

    moveForward(): void {
        if (this.selectedIndex > 0) {
            let temp = this._items[this.selectedIndex];
            this._items[this.selectedIndex] = this._items[this.selectedIndex - 1];
            this._items[this.selectedIndex - 1] = temp;
            this.select(this.selectedIndex - 1);
        }
    }

    moveBackward(): void {
        if (this.selectedIndex < this._items.length - 1) {
            let temp = this._items[this.selectedIndex];
            this._items[this.selectedIndex] = this._items[this.selectedIndex + 1];
            this._items[this.selectedIndex + 1] = temp;
            this.select(this.selectedIndex + 1);
        }
    }

    select(index: number, wasDeleted = false): void {
        this.selectedIndex = index;
        this.selectedIndexChange.emit([index, wasDeleted]);
    }

    openContextMenu(event): void {
        this.cm.show(event as MouseEvent);
        event.stopPropagation();
    }

    private updateMenuItems(): void {
        this.menuItems = [
            {
                label: this.app.t("general.delete"),
                icon: "fas fa-trash",
                command: this.deleteItem.bind(this)
            }
        ];
        if (this._canRearrange) {
            this.menuItems.push({
                label: this.app.t("lea.learningContent.chapter.moveForward"),
                icon: "fas fa-angle-left",
                command: this.moveForward.bind(this)
            });
            this.menuItems.push({
                label: this.app.t("lea.learningContent.chapter.moveBackward"),
                icon: "fas fa-angle-right",
                command: this.moveBackward.bind(this)
            });
        }
    }
}
