/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { GuiMarkAssignment } from '../shared/gui-mark-assignment.model';
import { Certificate } from './certificate.entity';
import { Competency } from './competency.model';
import { SchoolType } from './school-type.enum';

@Entity()
export class MarkAssignment {
    @PrimaryGeneratedColumn()
    id: number = 0;

    @Column({default: 0})
    competencyId: number = 0;

    @Column({default: 0})
    courseId: number = 0;

    @Column({default: ""})
    domain: string = "";

    @Column({default: ""})
    level1: string = "";

    @Column({default: ""})
    level2: string = "";

    @Column({default: ""})
    title: string = "";

    @Column({default: 0})
    sortDomain: number = 0;

    @Column({default: 0})
    sortLevel1: number = 0;

    @Column({default: 0})
    sortLevel2: number = 0;

    @Column({default: 0})
    sortTitle: number = 0;

    @Column({default: false})
    optional: boolean = false;

    @Column({default: 0})
    mark: number = 0;

    @Column({default: 0})
    trend: number = 0;

    @Column({default: ""})
    note: string = "";

    @Column({default: true})
    hidden: boolean = true;

    @Column({default: ""})
    assignedBy: string = "";

    @Column({nullable: true})
    @Type(() => Date)
    assignedDate: Date = null;

    @ManyToOne(_type => Certificate, o => o.marks, {onDelete: 'CASCADE', orphanedRowAction: "delete"})
    certificate: Certificate;

    hasDefaultParams(schoolType: SchoolType): boolean {
        return this.mark === 0 && this.trend === 0 && this.hidden == (schoolType == SchoolType.special);
    }

    wasUpdated(updated: MarkAssignment): boolean {
        return this.mark != updated.mark || this.trend != updated.trend || this.note != updated.note || this.hidden != updated.hidden;
    }

    toGui(): GuiMarkAssignment {
        let result = new GuiMarkAssignment();
        result.id = this.id;
        result.competencyId = this.competencyId;
        result.courseId = this.courseId;
        result.mark = this.mark;
        result.trend = this.trend;
        result.sortDomain = this.sortDomain;
        result.sortLevel1 = this.sortLevel1;
        result.sortLevel2 = this.sortLevel2;
        result.sortTitle = this.sortTitle;
        result.optional = this.optional;
        result.note = this.note;
        result.hidden = this.hidden;
        result.assignedBy = this.assignedBy;
        result.assignedDate = this.assignedDate;
        result.domain = this.domain;
        result.level1 = this.level1;
        result.level2 = this.level2;
        result.title = this.title;
        return result;
    }

    isCompetencyChanged(c: Competency): boolean {
        return this.domain !== c.domain || this.level1 !== c.level1 || this.level2 !== c.level2 || this.title !== c.title || this.optional != c.optional ||
        this.sortDomain != c.sortDomain || this.sortLevel1 != c.sortLevel1 || this.sortLevel2 != c.sortLevel2 || this.sortTitle != c.sortTitle;
    }

    checkIntegrity(): void {
        this.mark = Utils.toNumber(this.mark);
    }

    fillFromCompetency(c: Competency, overwriteNote = false): void {
        this.competencyId = c.id;
        this.domain = c.domain;
        this.level1 = c.level1;
        this.level2 = c.level2;
        this.title = c.title;
        this.sortDomain = c.sortDomain;
        this.sortLevel1 = c.sortLevel1;
        this.sortLevel2 = c.sortLevel2;
        this.sortTitle = c.sortTitle;
        this.optional = c.optional;
        if (overwriteNote) {
            this.note = c.remark;
        }
    }

    getPathString(): string {
        return Utils.arrayItemsToString(Utils.arrayWithout([this.domain, this.level1, this.level2, this.title], ""), " -> ");
    }
}
