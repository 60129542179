<div class="login-body">
    <p-toast position="top-center">
    </p-toast>
    <div class="login-panel ui-fluid">
      <div class="grid">
        <div class="col-12 logo-container">
          <img src="assets/layout/images/logo-colored.png" />
          <h1>Passwort vergessen</h1>
          <h2>{{app.appName}}</h2>
        </div>
        <sm-form [form]="userName == null ? form1 : form2">
        </sm-form>
        <div class="col-12 button-container">
          <p-button styleClass="width100p" icon="fas fa-sign-in" [label]="userName == null ? 'Weiter' : 'Abschließen'" (onClick)="send()">
          </p-button>
          <a [routerLink]="['/base', 'login']">
            <p-button styleClass="width100p" icon="fas fa-lock" label="Abbrechen" (onClick)="cancel()">
            </p-button>
          </a>
          <div *ngIf="running">
            <div>Rücksetzung läuft ...</div>
            <p-progressSpinner></p-progressSpinner>

        </div>
      </div>
    </div>
  </div>
