<ng-container *ngVar="(field != null ? field : multipleFields != null ? multipleFields[0] : null) as f">
    <div *ngIf="f != null && f.visible"
        class="form-group"
        [class.mt-2]="f.isGuiOneLine()"
        [class.mb-2]="f.isGuiOneLine()"
        [class.relative-component]="f.isFixedPosition()"
        [class.p-f] ="!f.isFixedPosition()"
        [class.grid] ="!f.isFixedPosition()"
    >

        <span *ngIf="f.hasLabel && !f.isGuiOneLine()" for="idForForm"
            class="form-field-label"
            [class.col-12] ="!f.isFixedPosition()"
            [class.md:col-2] ="!stackLabels && !f.isFixedPosition()"
            [style.text-align]="f.labelRightAligned ? 'right' : 'left'"
            [style.position]="f.isFixedPosition() ? 'absolute' : 'relative'"
            [style.left.px]="f.isFixedPosition() ? f.fixedLabelBounds.x : undefined"
            [style.top.px]="f.isFixedPosition() ? f.fixedLabelBounds.y : undefined"
            [style.width.px]="f.isFixedPosition() ? (f.fixedLabelBounds.width + (f.mandatory && mandatoryFieldsRedAsterisk ? 10 : 0)) : undefined"
            [style.height.px]="f.isFixedPosition() ? f.fixedLabelBounds.height : undefined"
            [style.overflow]="'hidden'"
            >
            <label [pTooltip]="f.tooltip" [class.req-asterisk] = "f.mandatory && mandatoryFieldsRedAsterisk" [class.req-underline] = "f.mandatory && !mandatoryFieldsRedAsterisk">
                {{f.caption}}
            </label>
            <p-avatar *ngFor="let hint of f.guiHints" class="hint-icon" styleClass="p-avatar-sm" [icon]="hint.icon" [style]="{'color': '#ffffff', 'background-color': hint.color}" shape="circle" [pTooltip]="hint.toolTip" (onClick)="clickGuiHint(hint)"></p-avatar>
        </span>
        <ng-container *ngIf="multipleFields != null">
            <ng-container *ngFor="let f2 of multipleFields">
               <app-sm-form-field-internal #componentInternal *ngIf="f2.isFixedPosition()" [field]="f2" (onClick)="onClick"></app-sm-form-field-internal>
                <div *ngIf="!f2.isFixedPosition()" [ngClass]="'col-' + f2.guiCols">
                    <app-sm-form-field-wrapper [field]="f2" [child]="child">
                        <ng-template #child>
                            <app-sm-form-field-internal class="width100p" #componentInternal [field]="f2" (onClick)="onClick"></app-sm-form-field-internal>
                        </ng-template>
                    </app-sm-form-field-wrapper>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="multipleFields == null">
            <app-sm-form-field-wrapper *ngIf="f.isFixedPosition()" [field]="field" [child]="child2">
                <ng-template #child2>
                    <app-sm-form-field-internal class="width100p" #componentInternal [field]="field" (onClick)="onClick"></app-sm-form-field-internal>
                </ng-template>
            </app-sm-form-field-wrapper>
            <div *ngIf="!f.isFixedPosition()" [class.col-12] ="true" [class.md:col-10] ="!stackLabels">
                <app-sm-form-field-wrapper [field]="field" [child]="child3">
                    <ng-template #child3>
                        <app-sm-form-field-internal class="width100p" #componentInternal [field]="field" (onClick)="onClick"></app-sm-form-field-internal>
                    </ng-template>
                </app-sm-form-field-wrapper>
                <span *ngIf="f.hasLabel && f.isGuiOneLine()" for="idForForm"
                    class="form-field-label"
                    [class.col-12] ="!f.isFixedPosition()"
                    [class.md:col-2] ="!stackLabels && !f.isFixedPosition()"
                    [style.text-align]="f.labelRightAligned ? 'right' : 'left'"
                    [style.position]="f.isFixedPosition() ? 'absolute' : 'relative'"
                    [style.left.px]="f.isFixedPosition() ? f.fixedLabelBounds.x : undefined"
                    [style.top.px]="f.isFixedPosition() ? f.fixedLabelBounds.y : undefined"
                    [style.width.px]="f.isFixedPosition() ? (f.fixedLabelBounds.width + (f.mandatory && mandatoryFieldsRedAsterisk ? 10 : 0)) : undefined"
                    [style.height.px]="f.isFixedPosition() ? f.fixedLabelBounds.height : undefined"
                    [style.overflow]="'hidden'"
                    >
                    <label [pTooltip]="f.tooltip" [class.req-asterisk] = "f.mandatory && mandatoryFieldsRedAsterisk" [class.req-underline] = "f.mandatory && !mandatoryFieldsRedAsterisk">
                        {{f.caption}}
                    </label>
                    <span *ngFor="let hint of f.guiHints">
                        <p-avatar class="hint-icon" styleClass="p-avatar-sm" [icon]="hint.icon" [style]="{'color': '#ffffff', 'background-color': hint.color}" shape="circle" [pTooltip]="hint.toolTip" (onClick)="clickGuiHint(hint)"></p-avatar>
                        <span *ngIf="hint.text != null && hint.text != ''" class="ml-2" [style]="{'color': hint.color}">{{hint.text}}</span>
                    </span>
                </span>
            </div>
        </ng-container>
    </div>
</ng-container>
