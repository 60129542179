import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { EnaioCall } from "./EnaioCall";
import { EnaioClientEvent } from "./EnaioClientEvent";

export class EnaioCallUpdateClientEvent extends EnaioCall <boolean> {
    @Type(() => EnaioClientEvent)
    evt: EnaioClientEvent;
    getModelType(): AdvancedType {
        return AdvancedType.ofPrimitive("boolean");
    }

}
