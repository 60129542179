import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentHandlerMainComponent } from './components/document-handler-main/document-handler-main.component';
import { ScriptTracingComponent } from './components/script-tracing/script-tracing.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: "home",
        pathMatch: "full"
    },
    {
        path: 'home',
        component: DocumentHandlerMainComponent
    },
    {
        path: 'script-tracing',
        component: ScriptTracingComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DocumentHandlerRoutingModule {
}
