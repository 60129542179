import { Injectable } from "@angular/core";

@Injectable()
export class ScriptService {

    scripts: any = {};

    async load(...scripts: string[]): Promise<unknown[]> {
        return Promise.all(scripts.map(async script => this.loadScript(script)));
    }

    async loadScript(name: string): Promise<any> {
        return new Promise((resolve, _) => {
            if (this.scripts[name].loaded) {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            }
            else {
                let script = window.document.createElement('script') as any;
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                if (script.readyState) { //IE
                    script.onreadystatechange = (): any => {
                        if (script.readyState === "loaded" || script.readyState === "complete") {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                }
                else {
                    script.onload = (): any => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (__: any): any => resolve({ script: name, loaded: false, status: 'Loaded' });
                window.document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }
}
