/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity } from 'typeorm';
import { DatabaseUserAttribute } from './database-user.attribute';

@Entity()
export class DatabaseUser extends SmBaseEntity {
    @Column({default: "", unique: true})
    userName: string = "";

    @Column({default: ""})
    passwordHash: string = "";

    @Column({default: ""})
    firstName: string = "";

    @Column({default: ""})
    lastName: string = "";

    @Column({ default: "", length: 10_000 })
    mail: string = "";

    @Column({default: "", length: 1})
    gender: string = "";

    @Column({default: true})
    active: boolean = true;

    @Column({default: false})
    mustChangePassword: boolean = false;

    @Column({default: ""})
    passwordResetHash: string = "";

    @Column({default: ""})
    phone: string = "";

    @Column({default: false})
    activated: boolean = false;

    @Column({default: "" })
    activationCode: string = "";

    @Column({nullable: true})
    @Type(() => Date)
    activationExpires: Date = null;

    @Column({nullable: false, default: true})
    sendNotificationMails: boolean = true;

    @Column({default: false})
    isAdmin: boolean = false;

    @Column("text", { array: true, nullable: true })
    recoveryQuestions: string[] = [];

    @Column("text", { array: true, nullable: true })
    recoveryAnswers: string[] = [];

    @Column({default: false})
    privacyPolicyAccepted: boolean = false;

    @Column({default: ""})
    source: string = "";

    attributes: DatabaseUserAttribute[] = [];

    getStringRepresentation(): string {
        return this.getFullName();
    }

    getFullName(): string {
        return this.firstName + " " + this.lastName;
    }

    resetToInitial(passwordHash: string): void {
        this.passwordHash = passwordHash;
        this.mustChangePassword = true;
        this.recoveryQuestions = [];
        this.recoveryAnswers = [];
    }
}
