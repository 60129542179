<!--<sm-toolbar *ngIf="isSecretary">
    <p-button styleClass="p-button-warning" icon="fas fa-cog" label="Auswertung" (onClick)="analysesClick()">
    </p-button>
</sm-toolbar>
--><p-card header="Bitte wählen Sie eine Klasse aus">
  <div *ngFor="let grade of getExistingGrades()" class="grid">
    <ng-container *ngFor="let schoolClass of schoolClasses">
      <div *ngIf="schoolClass.grade == grade && isClassRegisterEditor" class="col-6 md:col-2 xl:col-1">
        <p-card [styleClass]="'ui-card-shadow sm-card ' + schoolClass.getGuiClass()">
        <a [style.width]="'100%'" class="ui-card-title" routerLink="/enaio-certificates/covid-tests/class/{{schoolClass.id}}/{{isHalfYear ? 1 : 0}}/0"
            routerLinkActive="active">{{schoolClass.getDisplayName()}}</a>
        <a *ngIf="isSecretary" routerLink="/enaio-certificates/covid-tests/class/{{schoolClass.id}}/{{isHalfYear ? 1 : 0}}/1">
            <span class="fas fa-cog secretary mouse-pointer"></span>
        </a>
        </p-card>
      </div>
    </ng-container>
    <br>
  </div>
</p-card>
