import { Component } from '@angular/core';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { DhTools } from '../../models/dh-tools.model';

@Component({
    selector: 'app-system-information',
    templateUrl: './system-information.component.html',
    styleUrls: ['./system-information.component.scss']
  })
  export class SystemInformationComponent extends ComponentView {

    systemInfo: string = null;

    async initParams(): Promise<boolean> {
        await this.getSystemInfo();
        return true;
    }

    async getSystemInfo(): Promise<void> {
        this.systemInfo = await DhTools.backendCall("api/tools/getSystemInfo").getString();
    }

}
