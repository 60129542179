/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { CustomInitializer } from 'src/modules/sm-base/shared/custom-initializer.interface';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, OneToMany, Unique } from 'typeorm';
import { Holiday } from './holiday.entity';
import { SchoolType } from './school-type.enum';
import { SpecialDomain } from './special-domain.entity';
import { TeacherAdditional } from './teacher-additional.entity';

@Entity()
@Unique(["year", "isHalfYear"])
export class ClassRegisterSettings extends SmBaseEntity implements CustomInitializer {

    @Column({default: 0})
    year: number = 0;

    @Column({default: false})
    isHalfYear: boolean = false;

    @Column({nullable: true})
    @Type(() => Date)
    firstDayOfSchoolYear: Date;

    @Column({nullable: true})
    @Type(() => Date)
    lastDayOfSchoolYear: Date;

    @Column({default: ""})
    managerElementary: string = "";

    @Column({default: ""})
    managerSecondary: string = "";

    @Column({default: ""})
    managerSpecial: string = "";

    @Column("boolean", {array: true, default: []})
    bWeeks: boolean[];

    @Column("text", {array: true, default: []})
    dutyNames: string[];

    @Column("text", {array: true, default: []})
    instructionTexts: string[];

    @Column("text", {array: true, default: []})
    allowedDomains: string[];

    @OneToMany(() => SpecialDomain, o => o.settings, { cascade: true})
    @Type(() => SpecialDomain)
    specialDomains: SpecialDomain[];

    @OneToMany(() => Holiday, o => o.settings, { cascade: true})
    @Type(() => Holiday)
    holidays: Holiday[];

    @OneToMany(() => TeacherAdditional, o => o.settings, { cascade: true})
    @Type(() => TeacherAdditional)
    teacher: TeacherAdditional[];

    customInitializer(): void {
        this.holidays = Utils.arrayEnsure(this.holidays);
        this.teacher = Utils.arrayEnsure(this.teacher);
        this.bWeeks = Utils.arrayEnsure(this.bWeeks);
        this.dutyNames = Utils.arrayEnsure(this.dutyNames);
        this.instructionTexts = Utils.arrayEnsure(this.instructionTexts);
        this.allowedDomains = Utils.arrayEnsure(this.allowedDomains);
        this.specialDomains = Utils.arrayEnsure(this.specialDomains);
    }

    getWeekDates(): Date[] {
        let result: Date[] = [];
        let x = Utils.dateStartOf(this.firstDayOfSchoolYear, "week");
        while (x <= this.lastDayOfSchoolYear) {
            result.push(x);
            x = Utils.dateAdd(x, "week", 1);
        }
        return result;
    }

    getMonthDates(): Date[] {
        let result: Date[] = [];
        let x = Utils.dateStartOf(this.firstDayOfSchoolYear, "month");
        while (x <= this.lastDayOfSchoolYear) {
            result.push(x);
            x = Utils.dateAdd(x, "month", 1);
        }
        return result;
    }

    isSchoolDay(date: Date): boolean {
        let weekDay = Utils.dateDayOfWeek(date);
        if (weekDay == 5 || weekDay == 6) {
            return false;
        }
        return !this.holidays.some(h => h.from <= date && h.to >= date);
    }

    isBWeek(date: Date): boolean {
        let i = 0;
        for (let week of this.getWeekDates()) {
            if (week <= date && Utils.dateAdd(week, "week", 1) > date) {
                return Utils.arrayGetSafe(this.bWeeks, i, false);
            }
            i++;
        }
        return false;
    }

    isPlaceholderDomain(domain: string): boolean {
        return this.specialDomains.some(sd => sd.title == domain && sd.isPlaceholder);
    }

    getManager(schoolType: SchoolType): string {
        return schoolType == SchoolType.elementary ? this.managerElementary : schoolType == SchoolType.secondary ? this.managerSecondary : schoolType == SchoolType.special ? this.managerSpecial : "";
    }

    getTeacher(userName: string): TeacherAdditional {
        return this.teacher.find(t => Utils.stringEqualsCi(t.userName, userName));
    }

}
