export enum ErrorType {
    error = 0,
    warning = 1,
    info = 2
}
export namespace ErrorType{
    export function getName(value: ErrorType): string {
        switch (value) {
            case ErrorType.error:
                return "error";
            case ErrorType.warning:
                return "warning";
            case ErrorType.info:
                return "info";
        }
    }
}
