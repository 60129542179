export class ErrorInformation {
    message: string;
    trace: string;

    additional: any = {};

    constructor(error: Error = null) {
        this.message = error != null ? error.message : "";
        this.trace = error != null ? error.stack : "";
    }
}
