import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicantFormComponent } from './components/applicant-form/applicant-form.component';
import { OrderFormComponent } from './components/order-form/order-form.component';
import { TravelCostsFormComponent } from './components/travel-costs-form/travel-costs-form.component';
import { TestInvoiceApprovalComponent } from './components/test-invoice-approval/test-invoice-approval.component';

const routes: Routes = [
    {
        path: 'applicantform',
        component: ApplicantFormComponent
    },
    {
        path: 'travelcostsform',
        component: TravelCostsFormComponent
    },
    {
        path: 'orderform',
        component: OrderFormComponent
    },
    {
        path: 'invoiceapproval/:type',
        component: TestInvoiceApprovalComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TestRoutingModule {
}
