<div class="grid">
    <div class="col-4">
        <sm-form  [form]="form">
        </sm-form>
        <p-splitButton styleClass="mb-3" icon="fas fa-filter" label="Verbindung laden ..." [model]="connectionItems" (onClick)="loadConnection()" (onDropdownClick)="updateConnectionItems()"></p-splitButton>
        <app-config-editor-tree #configEditorTree [def]="def" [showInfoColumn]="false" [propColumnWidth]="'250px'">
        </app-config-editor-tree>
        <p-button class="toolbar-element" styleClass="mb-3" icon="fas fa-filter" label="Testen" (onClick)="test()"></p-button>
    </div>
    <p-card class="col-8">
        <textarea pInputTextarea spellcheck=false class="width100p full-height-200x monospace no-resize" [ngModel]="result"></textarea>
    </p-card>
</div>
