import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { EnaioLearnService } from '../../services/enaio-learn.service';

@Component({
    selector: 'app-learning-analyses',
    templateUrl: './learning-analyses.component.html',
    styleUrls: ['./learning-analyses.component.scss']
})
export class LearningAnalysesComponent extends ComponentView {

    service = inject(EnaioLearnService);

    async initParams(): Promise<boolean> {
        this.service.updateNavigation(null, [{ label: this.app.t("lea.analyses.title"), routerLink: ["/enaio-learn", "analyses"]}]);
        return Promise.resolve(true);
    }
}
