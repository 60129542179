/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";
import { Column, Entity, PrimaryGeneratedColumn } from "typeorm";

@Entity()
export class GlobalSettings {
    @PrimaryGeneratedColumn()
    id: number = 0;

    @Column({default: true})
    editingAllowed: boolean = true;

    @Column({nullable: true})
    @Type(() => Date)
    dateOfHalfYearCertificate: Date;

    @Column({nullable: true})
    @Type(() => Date)
    dateOfFullCertificate: Date;
}
