import { Type } from "class-transformer";
import { EnaioJobParam } from "./EnaioJobParam";

export class EnaioCallDescription {
    type: string;
    @Type(() => EnaioJobParam)
    parameters: EnaioJobParam[];
    xml: string;
    fileNames: string[];
    callIndex = 0;
}
