import { Type } from "class-transformer";
import { EnaioLicenseModule } from "./EnaioLicenseModule";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class EnaioLicense {
    raw: string;
    info: OrdinaryObject<string>;
    @Type(() => EnaioLicenseModule)
    modules: EnaioLicenseModule[];
    hash: string;
}
