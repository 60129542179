<ng-container *transloco="let t">
    <div *ngIf="_currentQuestion >= 0">
        <h4>
            {{_chapterQuestions[_currentQuestion].question}}
        </h4>
        <h6 *ngIf="_chapterQuestions[_currentQuestion].correctAnswers.length > 1">
            {{t('lea.attend.messages.multipleCorrectAnswers')}}
        </h6>
        <div *ngFor="let a of chapterAnswers[_currentQuestion]; index as index2" class="mb-2">
            <p-radioButton *ngIf="_chapterQuestions[_currentQuestion].correctAnswers.length == 1" [name]="'choice_' + index2" [value]="index2" [(ngModel)]="answer" [inputId]="'choice_' + index2"></p-radioButton>
            <p-checkbox *ngIf="_chapterQuestions[_currentQuestion].correctAnswers.length > 1" [name]="'choice_' + index2" [binary]=true [value]="index2" [(ngModel)]="answers[index2]" [inputId]="'choice_' + index2"></p-checkbox>
            <span  [class.answer-correct]="correctAnswers != null && correctAnswers[index2]">
                {{a}}
            </span>
        </div>
    </div>
</ng-container>
