import { Component, inject } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ComponentView } from 'src/modules/app-template/models/component-view.model';
import { RestEndpoint } from 'src/modules/sm-base/models/rest-endpoint.model';
import { TableCellType } from 'src/modules/sm-base/models/table-cell-type.enum';
import { TableColumn } from 'src/modules/sm-base/models/table-column.model';
import { TableData } from 'src/modules/sm-base/models/table-data.model';
import { TableRow } from 'src/modules/sm-base/models/table-row.model';
import { TableSortColumn } from 'src/modules/sm-base/models/table-sort-column.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { EnaioLearnService } from '../../services/enaio-learn.service';
import { LearningContentParticipantDto } from '../../shared/learning-content-participant.dto';
import { LearningContent } from '../../shared/learning-content.entity';
import { JobDescription } from '../../shared/job-description.entity';

@Component({
    selector: 'app-analyses-open-items',
    templateUrl: './analyses-open-items.component.html',
    styleUrls: ['./analyses-open-items.component.scss']
})
export class AnalysesOpenItemsComponent extends ComponentView {

    service = inject(EnaioLearnService);

    contents: SelectItem[];
    selectedContent: number = null;
    data: LearningContentParticipantDto[];
    table: TableData;
    jobDescriptions: SelectItem[];
    selectedJobDescriptions: string[];
    async initParams(): Promise<boolean> {
        this.service.updateNavigation(null, [
            { label: this.app.t("lea.analyses.title"), routerLink: ["/enaio-learn", "analyses"] },
            { label: this.app.t("lea.analyses.openItems.title"), routerLink: ["/enaio-learn", "analyses", "open-items"] }
        ]);

        this.contents = [ { value: null, label: "<Alle>" }, ...Utils.arraySortBy((await RestEndpoint.main().find().run("api/lrn/learningcontent").list(LearningContent)).map(c => ({ value: c.id, label: c.title })), si => si.label)];

        this.jobDescriptions = Utils.arraySortBy([{ value: "", label: "<Ohne>" }, ...Utils.arrayGetUnique((await RestEndpoint.main().find().run("api/lrn/jobdescription").list(JobDescription)).map(jd => jd.group)).map(c => ({ value: c, label: c }))], si => si.label);
        this.selectedJobDescriptions = this.jobDescriptions.map(jd => jd.value);

        await this.updateFilter(true);        return true;
    }

   async updateFilter(rest: boolean): Promise<void> {
        if (rest) {
            this.data = await RestEndpoint.main().query(Utils.objectRemoveNullValues({ open: true, closed: false, learningContentId: this.selectedContent })).run("api/lrn/learningcontent/analysesParticipants").list(LearningContentParticipantDto);
        }
        this.table = new TableData([
            new TableColumn("learningContentTitle", "Lerninhalt", TableCellType.text, { width2: "ra" }),
            new TableColumn("userName", "Person", TableCellType.text, { width2: "ra" }),
            new TableColumn("department", "Einrichtung", TableCellType.text, { width2: "ra" }),
            new TableColumn("jobDescription", "Dienstart", TableCellType.text, { width2: "ra" }),
            new TableColumn("deadlineDate", "Deadline", TableCellType.date, { width2: "qa" }),
            new TableColumn("finalExamFailCount", "Anzahl Fehlversuche", TableCellType.number, { width2: "2d" })
        ], this.data.filter(p => this.selectedJobDescriptions.includes(p.jobDescription)).map(p => new TableRow(p, {
            learningContentTitle: p.learningContentTitle,
            userName: p.userName,
            department: p.department,
            jobDescription: p.jobDescription,
            deadlineDate: p.deadlineDate,
            finalExamFailCount: p.finalExamFailCount
        })), [new TableSortColumn("deadlineDate"), new TableSortColumn("learningContentTitle"), new TableSortColumn("userName")]);
    }
}
