import { Type } from "class-transformer";
export class EnaioDocumentStats {
    @Type(() => Date)
    date: Date;
    serverGroup = 0;
    mediaName: string;
    numDocuments = 0;
    size = 0;
    name: string;
    extension: string;
    moduleType = 0;
    note: string;
    docState = 0;
    docType = 0;
}
