<div class="layout-sidebar" [ngStyle]="{'overflow':app.sidebarActive ? 'hidden' : 'visible'}"
                            [ngClass]="{'layout-sidebar-dark':app.darkMenu}" (click)="app.onSidebarClick()">
    <div class="layout-tabmenu">
        <ul class="layout-tabmenu-nav">
            <li *ngFor="let tab of menuService.visibleTabs; index as index" [ngClass]="{'active-item':app.activeTabIndex === index}">
                <a href="#" class="tabmenuitem-link" (click)="app.onTabClick($event, index)" pRipple>
                    <i *ngIf="!tab.iconIsFile" [class]="tab.icon"></i>
                    <img *ngIf="tab.iconIsFile" class="icon-from-file" [src]="tab.icon">
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">{{tab.label}}</div>
                </div>
            </li>
        </ul>
        <div *ngIf="menuService.visibleTabs.length > 0 && app.activeTabIndex >= 0" class="layout-tabmenu-contents">
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex >= 0}">
                <div class="layout-submenu-title clearfix">
                    <span>{{menuService.visibleTabs[app.activeTabIndex].label}}</span>
                    <a href="#" class="menu-button" (click)="app.closeSidebar($event)">
                        <i class="fas fa-bars"></i>
                    </a>
                </div>
                <app-sidebarTabContent>
                    <ul class="navigation-menu">
                        <li app-menuitem *ngFor="let item of menuService.visibleTabs[app.activeTabIndex].menuItems; let i = index;" [item]="item" [index]="i" [root]="true"></li>
                    </ul>
                </app-sidebarTabContent>
            </div>
        </div>
    </div>
</div>
