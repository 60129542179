import { Type } from "class-transformer";
import { EnaioAddonAddPid } from "./EnaioAddonAddPid";
import { EnaioAddonAddReq } from "./EnaioAddonAddReq";
import { EnaioAddonAddUser } from "./EnaioAddonAddUser";
import { EnaioAddonAddUserGroup } from "./EnaioAddonAddUserGroup";
import { EnaioAddonAddVbs } from "./EnaioAddonAddVbs";
import { EnaioAddonAddWfOrg } from "./EnaioAddonAddWfOrg";
import { EnaioAddonCounter } from "./EnaioAddonCounter";
import { EnaioAddonRecherche } from "./EnaioAddonRecherche";
import { EnaioDbDataType } from "./EnaioDbDataType";
import { EnaioGuiBounds } from "./EnaioGuiBounds";
import { EnaioGuiControlType } from "./EnaioGuiControlType";
import { EnaioGuiFieldListItem } from "./EnaioGuiFieldListItem";
import { EnaioGuiFieldTableColumn } from "./EnaioGuiFieldTableColumn";
import { EnaioGuiPage } from "./EnaioGuiPage";

export class EnaioGuiField {
    wfFieldId = "";
    name = "";
    internalName = "";
    dbFieldName = "";
    tabOrder = 0;
    prnAlias = "";
    osGuid = "";
    classString = "";
    toolTip = "";
    init = "";
    searchGroupName = "";
    regularExpression = "";
    imageId = "";
    @Type(() => EnaioGuiBounds)
    fieldPos: EnaioGuiBounds;
    @Type(() => EnaioGuiBounds)
    inputPos: EnaioGuiBounds;
    flags = 0;
    flags1 = 0;
    flags2 = 0;
    addOnDll = "";
    maxLength = 0;
    upperCase = false;
    controlType = "";
    rawType = "";
    dataType: EnaioDbDataType;
    mandatory = false;
    isList = false;
    listOrder = false;
    listMultiSelect = false;
    listUseItemValue = false;
    listLinkTo = "";
    hasAddonButton = false;
    @Type(() => EnaioGuiFieldListItem)
    listItems: EnaioGuiFieldListItem[];
    @Type(() => EnaioGuiFieldTableColumn)
    tableColumns: EnaioGuiFieldTableColumn[];
    @Type(() => EnaioGuiPage)
    pages: EnaioGuiPage[];
    controlType2: EnaioGuiControlType;
    isStatic = false;
    isLabelRightAligned = false;
    isReadOnly = false;
    isSupervisorField = false;
    isLabelVisible = false;
    isControlVisible = false;
    isSelectUpsertSupported = false;
    initByFunctionIndex = 0;
    initByConstant = "";
    color = 0;
    keyFieldForTable = false;
    leadingZeros = false;
    iconCatalogue = false;
    fullTextSearch = false;
    objectTypeIndexDataOnly = false;
    objectTypeNoDias = false;
    multiLine = false;
    crossChecked = false;
    crossCheckIndex = 0;
    considerCase = false;
    defaultByFunction = false;
    defaultByValue = false;
    connectedToWdoc = false;
    copyAutomatically = false;
    isReadOnlyWhenArchived = false;
    isReadOnlyWhenInitialised = false;
    recommendationList = false;
    needsLabel = false;
    wfField = false;
    maskFieldVal = "";
    sectionName = "";
    @Type(() => EnaioAddonAddWfOrg)
    addOnAddWfOrg: EnaioAddonAddWfOrg;
    @Type(() => EnaioAddonAddUser)
    addOnAddUser: EnaioAddonAddUser;
    @Type(() => EnaioAddonAddUserGroup)
    addOnAddUserGroup: EnaioAddonAddUserGroup;
    @Type(() => EnaioAddonAddReq)
    addOnAddReq: EnaioAddonAddReq;
    @Type(() => EnaioAddonAddPid)
    addOnAddPid: EnaioAddonAddPid;
    @Type(() => EnaioAddonRecherche)
    addOnRecherche: EnaioAddonRecherche;
    @Type(() => EnaioAddonAddVbs)
    addOnAddVbs: EnaioAddonAddVbs;
    @Type(() => EnaioAddonCounter)
    addOnCounter: EnaioAddonCounter;
    radioButtonGroup = "";
    radioButtonMaster = "";
    radioButtonValue = 0;
    isUrl = false;
    @Type(() => EnaioGuiPage)
    onGuiPage: EnaioGuiPage;
}
