<ng-container *ngIf="editable">
    <p-cellEditor *ngIf="type == _TableCellType.text">
        <ng-template pTemplate="input">
            <sm-form *ngIf="editorForm != null" [form]="editorForm">
            </sm-form>
            <input *ngIf="editorForm == null" pInputText class="w-full" [maxlength]="editableMaxLength > 0 ? editableMaxLength : undefined" [(ngModel)]="_value" (ngModelChange)="afterEdit()">
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && _value == null" class="badge badge-no" (click)="buttonClick($event)">null</span>
            {{_value}}
        </ng-template>
    </p-cellEditor>
    <p-cellEditor *ngIf="type == _TableCellType.dropdown">
        <ng-template pTemplate="input">
            <p-dropdown [styleClass]="'w-full'" [options]="_editor.listItems" optionLabel="label" option_value="_value" [(ngModel)]="_value" appendTo="body" (onChange)="afterEdit()"></p-dropdown>
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && _value == null" class="badge badge-no" (click)="buttonClick($event)">null</span>
            {{getListItemSpeakingName()}}
        </ng-template>
    </p-cellEditor>
    <p-cellEditor *ngIf="type == _TableCellType.number">
        <ng-template pTemplate="input">
            <p-inputNumber class="w-full" [(ngModel)]="_value" (ngModelChange)="afterEdit()"></p-inputNumber>
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && _value == null" class="badge badge-no" (click)="buttonClick($event)">null</span>
            {{_value}}
        </ng-template>
    </p-cellEditor>
    <p-cellEditor *ngIf="type == _TableCellType.byteSize">
        <ng-template pTemplate="input">
            <p-inputNumber class="w-full" [(ngModel)]="_value" (ngModelChange)="afterEdit()"></p-inputNumber>
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && _value == null" class="badge badge-no" (click)="buttonClick($event)">null</span>
            {{_Utils.toByteSizeString(_value)}}
        </ng-template>
    </p-cellEditor>
    <p-cellEditor *ngIf="type == _TableCellType.duration">
        <ng-template pTemplate="input">
            <p-inputNumber class="w-full" [(ngModel)]="_value" (ngModelChange)="afterEdit()"></p-inputNumber>
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && _value == null" class="badge badge-no" (click)="buttonClick($event)">null</span>
            {{_Utils.toDurationString(_value)}}
        </ng-template>
    </p-cellEditor>
    <span *ngIf="type == _TableCellType.yesNo" class="badge mouse-pointer" [class.badge-yes]="_value" [class.badge-no]="!_value" (click)="switchBool()">{{_value ? "Ja" : "Nein"}}</span>
    <p-cellEditor *ngIf="type == _TableCellType.color">
        <ng-template pTemplate="input">
            <input pInputText [(ngModel)]="_value">
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && _value == null" class="badge badge-no" (click)="buttonClick($event)">null</span>
            {{_value}}
        </ng-template>
    </p-cellEditor><p-cellEditor *ngIf="type == _TableCellType.dateTime">
        <ng-template pTemplate="input">
            <p-calendar class="w-full" [(ngModel)]="_value" dateFormat="dd.mm.yy" appendTo="body" (ngModelChange)="afterEdit()"></p-calendar>
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && _value == null" class="badge badge-no" (click)="buttonClick($event)">null</span>
            {{_Utils.isString(_value) ? _value : _value | date: 'dd.MM.yyyy mm:ss'}}
        </ng-template>
    </p-cellEditor>
    <p-cellEditor *ngIf="type == _TableCellType.date">
        <ng-template pTemplate="input">
            <p-calendar class="w-full" [(ngModel)]="_value" dateFormat="dd.mm.yy" appendTo="body" (ngModelChange)="afterEdit()"></p-calendar>
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && _value == null" class="badge badge-no" (click)="buttonClick($event)">null</span>
            {{_Utils.isString(_value) ? _value : _value | date: 'dd.MM.yyyy'}}
        </ng-template>
    </p-cellEditor>
    <p-cellEditor *ngIf="type == _TableCellType.button">
        <ng-template pTemplate="input">
            <input pInputText [(ngModel)]="_value">
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && _value == null" class="badge badge-no" (click)="buttonClick($event)">null</span>
            {{_value}}
        </ng-template>
    </p-cellEditor>
    <p-cellEditor *ngIf="type == _TableCellType.link">
        <ng-template pTemplate="input">
            <input pInputText class="w-full" [(ngModel)]="_value" (ngModelChange)="afterEdit()">
        </ng-template>
        <ng-template pTemplate="output">
            <span *ngIf="showNull && _value == null" class="badge badge-no" (click)="buttonClick($event)">null</span>
            {{_value}}
        </ng-template>
    </p-cellEditor>
</ng-container>
<ng-container *ngIf="!editable && _value != null">
    <div *ngIf="actionButtons && mouseInside" class="action-button-wrapper">
        <button pButton type="button" class="action-button ui-button-secondary p-button-text" [icon]="'fas fa-bars'" (click)="actionButtonClick($event)"></button>
    </div>
    <span *ngIf="type == _TableCellType.text && tableCell?.keepHtml" [innerHTML]="_value | keepHtml" appDynamicTooltip (onToolTip)="handleTooltip($event)" [class.no-wrap-cell]="noWrapCell">{{_value}}</span>
    <span *ngIf="type == _TableCellType.text && !tableCell?.keepHtml" appDynamicTooltip (onToolTip)="handleTooltip($event)" [class.no-wrap-cell]="noWrapCell">{{_value}}</span>
    <span *ngIf="type == _TableCellType.link"><a href="#" (click)="buttonClick($event)" [class.no-wrap-cell]="noWrapCell">{{_value}}</a></span>
    <span *ngIf="type == _TableCellType.number" [class.no-wrap-cell]="noWrapCell">{{_value}}</span>
    <span *ngIf="type == _TableCellType.byteSize" [class.no-wrap-cell]="noWrapCell">{{_Utils.toByteSizeString(_value, false)}}</span>
    <span *ngIf="type == _TableCellType.duration" [class.no-wrap-cell]="noWrapCell">{{_Utils.toDurationStringAdvanced(_value, 2, true)}}</span>
    <span *ngIf="type == _TableCellType.icon" [class.no-wrap-cell]="noWrapCell"><i [class]="_value + ' icon'"></i></span>
    <span *ngIf="type == _TableCellType.yesNo" class="badge" [class.badge-yes]="_value" [class.badge-no]="!_value" [class.no-wrap-cell]="noWrapCell">{{_value ? "Ja" : "Nein"}}</span>
    <span *ngIf="type == _TableCellType.color" class="badge" [style.background]="_Utils.toColorHexString(_value)" [class.no-wrap-cell]="noWrapCell">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <span *ngIf="type == _TableCellType.date" [class.no-wrap-cell]="noWrapCell">{{_Utils.isDate(_value) ? (_value | date:'dd.MM.yyyy') : ''}}</span>
    <span *ngIf="type == _TableCellType.dateTime" [class.no-wrap-cell]="noWrapCell">{{_Utils.isDate(_value) ? (_value | date:'dd.MM.yyyy HH:mm:ss') : ''}}</span>
    <span *ngIf="type == _TableCellType.button" [class.no-wrap-cell]="noWrapCell">
        <button *ngFor="let icon of _Utils.split(_value, ',')" pButton type="button" class="ui-button-secondary p-button-text" [icon]="icon" (click)="buttonClick($event, icon)"></button>
    </span>

</ng-container>
