import { Utils } from "src/modules/utils/shared/utils";
import { Type } from "class-transformer";
import { EnaioCabinet } from "./EnaioCabinet";
import { EnaioGuiBounds } from "./EnaioGuiBounds";
import { EnaioGuiField } from "./EnaioGuiField";
import { EnaioMainType } from "./EnaioMainType";
import { EnaioObjectLimit } from "./EnaioObjectLimit";

export class EnaioObjectType {
    @Type(() => EnaioCabinet)
    parent: EnaioCabinet;
    mainType: EnaioMainType;
    coType = 0;
    name = "";
    internalName = "";
    osGuid = "";
    tableName = "";
    exTableName = "";
    iconId = 0;
    historyFlags = 0;
    compressionFlags = 0;
    referenceType = 0;
    noDias = false;
    onlyIndexData = false;
    multiDoc = false;
    @Type(() => EnaioGuiBounds)
    bounds: EnaioGuiBounds;
    @Type(() => EnaioGuiField)
    fields: EnaioGuiField[];
    @Type(() => EnaioObjectLimit)
    objectLimits: EnaioObjectLimit[];
    Id = 0;


    getAllFields(): EnaioGuiField[] {
        return Utils.arrayExplode(this.fields, f => [f, ...Utils.arrayExplode(f.pages, p => p.fields)]);
    }


}
