/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";
import { DatabaseUser } from "src/modules/database/shared/database-user.entity";
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Column, Entity, JoinTable, ManyToMany, ManyToOne, OneToMany } from "typeorm";
import { LearningContentCategory } from "./learning-content-category.entity";
import { LearningContent } from "./learning-content.entity";

@Entity()
export class InspectionPhase extends SmBaseEntity {
    @Column({default: ""})
    name: string = "";

    @Column({default: 0})
    index: number = 0;

    @Column({default: false})
    authorMayChoose: boolean = false;

    @Column({default: ""})
    deletionGuid: string = "";

    @ManyToMany(() => DatabaseUser, { cascade: ["remove"] })
    @Type(() => DatabaseUser)
    @JoinTable()
    responsible: DatabaseUser[];

    @ManyToOne(() => LearningContentCategory, { onDelete: "CASCADE", nullable: true, orphanedRowAction: "delete"})
    forCategory: LearningContentCategory = null;

    @ManyToMany(() => DatabaseUser, { cascade: ["remove"] })
    @Type(() => DatabaseUser)
    @JoinTable()
    substitues: DatabaseUser[];

    @OneToMany(() => LearningContent, o => o.inspectionPhase)
    @Type(() => LearningContent)
    learningContentsInThisPhase: LearningContent[];

    toString(): string {
        return this.name;
    }
}
