/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Comparable } from "src/modules/utils/shared/comparable";
import { Utils } from "src/modules/utils/shared/utils";
import { Column, Entity, ManyToOne } from "typeorm";
import { InspectionPhase } from "./inspection-phase.entity";
import { LearningContent } from "./learning-content.entity";

@Entity()
export class InspectionHistory extends SmBaseEntity implements Comparable<InspectionHistory> {
    @ManyToOne(() => LearningContent, (o: LearningContent) => o.inspectionHistory, { onDelete: "CASCADE", nullable: true, orphanedRowAction: "delete"})
    @Type(() => LearningContent)
    learningContent: LearningContent = null;

    @ManyToOne(() => InspectionPhase, { onDelete: "SET NULL", nullable: false, orphanedRowAction: 'delete'})
    inspectionPhase: InspectionPhase = null;

    @Column()
    @Type(() => Date)
    inspectionDate: Date;

    @Column({default: 0})
    inspectedBy: number = 0; //ID des Nutzers

    compareTo(other: InspectionHistory): number {
        return Utils.cmp(this.inspectionDate, other.inspectionDate);
    }

}
