import { Type } from "class-transformer";
import { Comparable } from "src/modules/utils/shared/comparable";
import { Utils } from "src/modules/utils/shared/utils";

export class PayrollDocument implements Comparable<PayrollDocument> {
    id: number;
    title: string;
    @Type(() => Date)
    date: Date;
    note: string;
    internalFileName: string;

    compareTo(other: PayrollDocument): number {
        return Utils.cmp(this.title, other.title);
    }

    getFileName(ext: string = null): string {
        if (ext == null) {
            ext = this.getExtension();
        }
        let result = this.title.replace("\\", "_").replace("/", "_");
        if (ext.length > 0 && !result.toUpperCase().endsWith(ext.toUpperCase())) {
            result += "." + ext;
        }
        return result;
    }

    getIconPath(): string {
        let ext = this.getExtension();
        return ext == "docx" ? "/assets/portal/file_formats/docx.png" : "/assets/portal/file_formats/pdf.png";
    }

    getExtension(): string {
        return Utils.getSubstringFromLast(this.internalFileName, ".").toLowerCase();
    }

    isEncrypted(): boolean {
        return this.getExtension() == "cmef";
    }
}
