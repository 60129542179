import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Utils } from 'src/modules/utils/shared/utils';
import { BrowserUploadedFile } from '../../models/uploaded-file.model';

@Component({
    selector: 'app-sm-file-upload-internal',
    templateUrl: './sm-file-upload-internal.component.html',
    styleUrls: []
})
export class SmFileUploadInternalComponent implements OnInit {

    _Utils = Utils;

    @Input()
    loadContents = true;
    @Input()
    acceptExtensions: string[] = null;
    @ViewChild('file')
    file: any;
    @Output()
    onFilesAdded: Subject<BrowserUploadedFile[]> = new Subject<BrowserUploadedFile[]>();

    ngOnInit(): void {
    }

    async _onFilesAdded(): Promise<void> {
        let files: BrowserUploadedFile[] = [];
        const filesTemp: { [key: string]: File } = this.file.nativeElement.files;
        for (let key in filesTemp) {
            if (!isNaN(parseInt(key))) {
                files.push(new BrowserUploadedFile(filesTemp[key], null));
            }
        }

        if (this.loadContents) {
            await Promise.all(files.map(async file => new Promise((resolve, _reject) => {
                let fr = new FileReader();
                fr.onload = (e): void => {
                    file.contents = e.target.result as ArrayBuffer;
                    resolve(null);
                };
                fr.readAsArrayBuffer(file.legacy);
            })));
        }

        this.onFilesAdded.next(files);
    }
}
