import { Type } from "class-transformer";
import { EnaioObjectTypeId } from "src/modules/enaio/shared/EnaioObjectTypeId";
import { FrontendWorkflowActivityDefinition } from "./frontend-workflow-activity-definition.model";

export class FrontendWorkflowDefinition {

    familyName: string;
    @Type(() => EnaioObjectTypeId)
    allowedAttachmentObjectTypes: EnaioObjectTypeId[];
    @Type(() => FrontendWorkflowActivityDefinition)
    activities: FrontendWorkflowActivityDefinition[];

    public getActivity(name: string): FrontendWorkflowActivityDefinition {
        return this.activities.find(a => a.name == name) ?? this.activities.find(a => a.name == "*") ?? new FrontendWorkflowActivityDefinition();
    }

}
