import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanDeactivate } from '@angular/router';
import { CanDeactivateGuard } from '../sm-base/misc/can-deactivate.guard';
import { AnalysesOpenItemsComponent } from './components/analyses-open-items/analyses-open-items.component';
import { AnalysesParticipationsComponent } from './components/analyses-participations/analyses-participations.component';
import { AttendContentComponent } from './components/attend-content/attend-content.component';
import { EditInspectionPhaseComponent } from './components/edit-inspection-phase/edit-inspection-phase.component';
import { EditInspectionPhasesComponent } from './components/edit-inspection-phases/edit-inspection-phases.component';
import { EditJobDescriptionComponent } from './components/edit-job-description/edit-job-description.component';
import { EditJobDescriptionsComponent } from './components/edit-job-descriptions/edit-job-descriptions.component';
import { EditLearningContentCategoriesComponent } from './components/edit-learning-content-categories/edit-learning-content-categories.component';
import { EditLearningContentCategoryComponent } from './components/edit-learning-content-category/edit-learning-content-category.component';
import { EditLearningContentTypeComponent } from './components/edit-learning-content-type/edit-learning-content-type.component';
import { EditLearningContentTypesComponent } from './components/edit-learning-content-types/edit-learning-content-types.component';
import { EditLearningContentComponent } from './components/edit-learning-content/edit-learning-content.component';
import { EditLearningSettingsComponent } from './components/edit-learning-settings/edit-learning-settings.component';
import { EditMyProfileComponent } from './components/edit-my-profile/edit-my-profile.component';
import { EditTextsComponent } from './components/edit-texts/edit-texts.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { EditUsersComponent } from './components/edit-users/edit-users.component';
import { FinalQuestionsComponent } from './components/final-questions/final-questions.component';
import { LearningAdminComponent } from './components/learning-admin/learning-admin.component';
import { LearningAnalysesComponent } from './components/learning-analyses/learning-analyses.component';
import { LearningHomeComponent } from './components/learning-home/learning-home.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { AnalysesRatingsComponent } from './components/analyses-ratings/analyses-ratings.component';
import { EditUserGroupsComponent } from './components/edit-user-groups/edit-user-groups.component';
import { EditUserGroupComponent } from './components/edit-user-group/edit-user-group.component';
import { EditExternalUsersComponent } from './components/edit-external-users/edit-external-users.component';
import { EditExternalUserComponent } from './components/edit-external-user/edit-external-user.component';

const routes: Routes = [
    {
        path: 'home',
        component: LearningHomeComponent
    },
    {
        path: 'home/:isAuthor',
        component: LearningHomeComponent
    },
    {
        path: 'analyses',
        component: LearningAnalysesComponent
    },
    {
        path: 'analyses/participations',
        component: AnalysesParticipationsComponent
    },
    {
        path: 'analyses/open-items',
        component: AnalysesOpenItemsComponent
    },
    {
        path: 'analyses/ratings',
        component: AnalysesRatingsComponent
    },
    {
        path: 'admin',
        component: LearningAdminComponent
    },
    {
        path: 'admin/learning-content-types',
        component: EditLearningContentTypesComponent
    },
    {
        path: 'admin/learning-content-type/:id',
        component: EditLearningContentTypeComponent
    },
    {
        path: 'admin/learning-content-categories',
        component: EditLearningContentCategoriesComponent
    },
    {
        path: 'admin/learning-content-category/:id',
        component: EditLearningContentCategoryComponent
    },
    {
        path: 'admin/users',
        component: EditUsersComponent
    },
    {
        path: 'admin/user/:id',
        component: EditUserComponent
    },
    {
        path: 'admin/external-users',
        component: EditExternalUsersComponent
    },
    {
        path: 'admin/external-user/:id',
        component: EditExternalUserComponent
    },
    {
        path: 'admin/user-groups',
        component: EditUserGroupsComponent
    },
    {
        path: 'admin/user-group/:id',
        component: EditUserGroupComponent
    },
    {
        path: 'admin/job-descriptions',
        component: EditJobDescriptionsComponent
    },
    {
        path: 'admin/job-description/:id',
        component: EditJobDescriptionComponent
    },
    {
        path: 'admin/general-settings',
        component: EditLearningSettingsComponent
    },
    {
        path: 'admin/inspection-phases',
        component: EditInspectionPhasesComponent
    },
    {
        path: 'admin/inspection-phase/:id',
        component: EditInspectionPhaseComponent
    },
    {
        path: 'admin/texts',
        component: EditTextsComponent
    },
    {
        path: 'my-profile',
        component: EditMyProfileComponent
    },
    {
        path: 'learning-content/:id',
        component: EditLearningContentComponent,
        canDeactivate: mapToCanDeactivate([CanDeactivateGuard])
    },
    {
        path: 'attend/:id',
        component: AttendContentComponent
    },
    {
        path: 'final-exam/:id',
        component: FinalQuestionsComponent
    },
    {
        path: 'notifications',
        component: NotificationsComponent
    },
    {
        path: '',
        redirectTo: "home",
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EnaioLearnRoutingModule {
}
