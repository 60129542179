import { Type } from "class-transformer";
import { EnaioDocument } from "src/modules/enaio/shared/EnaioDocument";
import { Utils } from "src/modules/utils/shared/utils";
import { EnaioFrontendColumn } from "./enaio-frontend-column.model";
import { EnaioFrontendSortColumn } from "./enaio-frontend-sort-column.model";

export class EnaioDocumentFrontendResult {
    defId = "";
    name = "";
    nameSingular = "";
    icon = "";
    boxColor = "";
    hasDocuments = true;
    useSearchBar = false;
    searchBarLabel = "";
    canUpload = false;
    displayObjectTypeColumn = false;
    resultCount = 0;
    childDefId = "";
    parentDocument: EnaioDocument;
    parentId = "";
    parentName = "";
    parentObjectName = "";
    toStringTemplate = "";
    showMetaData = true;
    anonymousOnly = false;
    @Type(() => EnaioFrontendColumn)
    columns: EnaioFrontendColumn[] = [];
    @Type(() => EnaioFrontendSortColumn)
    sortColumns: EnaioFrontendSortColumn[] = [];
    @Type(() => EnaioDocument)
    documents: EnaioDocument[] = [];

    getTitle(document: EnaioDocument): string {
        return Utils.isNoe(this.toStringTemplate) ? this.name + " " + document.id : Utils.getTemplateFunction(this.toStringTemplate)(document.getMetaDataMap());
    }
}
