<p-treeTable class="col-10" [value]="nodes" styleClass="p-treetable-sm" [(selection)]="selectedNode" [autoLayout]=true (onNodeSelect)="nodeSelect($event)" (onEditComplete)="valueChanged($event)">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngIf="showInfoColumn" width="450px">Info</th>
            <th [width]="propColumnWidth">Eigenschaft</th>
            <th >Wert</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr [ttSelectableRow]="rowNode" (mouseenter)="hover(rowData, rowNode)" (mouseleave)="hover(null, null)">
            <td>
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                <span [class.mandatory-field]="rowData.def.mandatory" [class.property-set]="!rowData.isDefault()">{{rowData.getPropertyName(speakingNames)}}</span>
            </td>
            <td *ngIf="showInfoColumn">
                <tag-button [class.hidden-take-space]="rowData.isDefault()" value="D" severity="info" [clickable]=true [toolTip]="rowData.isDefault() ? 'Das ist der Default-Wert für dieses Feld' : 'Klicken, um den Default-Wert wiederherzustellen'" (onClick)="resetToDefault(rowData, rowNode)"></tag-button>
                <tag-button [class.hidden-take-space]="!rowData.def.allowNull()" value="N" severity="warn" [clickable]="rowData.value != null" [active]="rowData.value == null" [toolTip]="rowData.value != null ? 'Klicken, um den den Wert auf NULL zu setzen' : ''" (click)="setNull(rowData, rowNode)"></tag-button>
                <tag-button value="T" severity="success" [clickable]=true [active]="rowData.useTemplateString" [toolTip]="rowData.useTemplateString ? 'Klicken, um einen Template-String zu verwenden' : 'Klicken, um den Template-String zu entfernen'" (click)="toggleTemplateString(rowData, rowNode)"></tag-button>
                <tag-button value="E" severity="danger" [active]="rowData.errorMessage != null" [toolTip]="rowData.errorMessage"></tag-button>
                <tag-button value="D" severity="danger" [clickable]=true (onClick)="debug(rowData, rowNode)"></tag-button>
                <ng-container *ngIf="quickValues && rowData.def.specialValues != null">
                    <tag-button *ngFor="let specialValue of _Utils.getOwnPropertyNames(rowData.def.specialValues)" [value]="specialValue" severity="info" [clickable]=true
                    [active]="rowData.value == rowData.def.specialValues[specialValue]" (onClick)="setValue(rowData, rowNode, rowData.def.specialValues[specialValue])"></tag-button>
                </ng-container>
                <ng-container *ngIf="rowData.def.type == _ConfigFieldType.object">
                    <tag-button *ngIf="rowData.value != null && rowData.def.allowNull()" icon="fas fa-trash" value="Unterobjekt entfernen" [clickable]=true severity="danger" (onClick)="removeObject(rowData, rowNode)"></tag-button>
                    <tag-button *ngIf="rowData.value == null" icon="fas fa-plus" value="Unterobjekt erzeugen" [clickable]=true severity="success" (onClick)="addObject(rowData, rowNode)"></tag-button>
                    <tag-button *ngIf="rowData.value != null && rowData.def.arrayDimensions > 0" icon="fas fa-plus" value="Eintrag hinzufügen" [clickable]=true severity="success" (onClick)="addArrayEntry(rowData, rowNode)"></tag-button>
                    <tag-button *ngIf="rowData.value != null && rowData.indexOnArray >= 0" icon="fas fa-trash" value="Eintrag entfernen" [clickable]=true severity="danger" (onClick)="removeArrayEntry(rowData, rowNode)"></tag-button>
                </ng-container>
            </td>
            <td [ttEditableColumn]="rowData.def.type != _ConfigFieldType.object">
                <p-dropdown *ngIf="rowData.def.arrayDimensions == 0 && rowData.def.type == _ConfigFieldType.object && rowData.value != null && def.getByName(rowData.def.typeName)?.subClasses.length > 0" class="class-name-dropdown" [styleClass]="'width300'" [options]="def.getByName(rowData.def.typeName).guiListItems" optionLabel="label" optionValue="value" [(ngModel)]="rowData.typeName" (onChange)="changeType(rowData, rowNode, $event)"></p-dropdown>
                <span *ngIf="rowData.def.type != _ConfigFieldType.object">
                    <sm-tree-table-cell [(value)]="rowData.value" [editable]="true" [type]="rowData.def.getTableCellType()" [editor]="rowData.fieldDefinition" (valueChange)="rowData.setValue(rowData.value)"></sm-tree-table-cell>
                </span>
            </td>
        </tr>
    </ng-template>
</p-treeTable>
