export class EnaioCpmQueueConditions {
    queueNames: string[];
    objectTypeIds: number[];
    onlyThisObjectType = false;
    limit = 10;
    maxWaitTime: string;
    initialWait = "00:00:10";
    waitIncrease = "00:00:10";
    waitIncreaseFactor = 1;
}
