import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { EnaioCall } from "./EnaioCall";
import { EnaioCondition } from "./EnaioCondition";
import { EnaioDuplicateDataset } from "./EnaioDuplicateDataset";
import { EnaioObjectId } from "./EnaioObjectId";

export class EnaioCallGetDuplicateDatasets extends EnaioCall <EnaioDuplicateDataset[]> {
    @Type(() => EnaioObjectId)
    archive: EnaioObjectId;
    @Type(() => EnaioObjectId)
    objectType: EnaioObjectId;
    @Type(() => EnaioObjectId)
    fields: EnaioObjectId[];
    @Type(() => EnaioCondition)
    conditions: EnaioCondition[];
    maxResults = 0;
    minCount = 2;
    getCount = true;
    getIds = true;
    resultsAsString = false;
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(EnaioDuplicateDataset, 1);
    }

}
