import { Type } from "class-transformer";
import { EnaioChangeEntryType } from "./EnaioChangeEntryType";

export class EnaioChangeEntry {
    type: EnaioChangeEntryType;
    id: string;
    name: string;
    @Type(() => Date)
    date: Date;
    userName: string;
    description: string;
}
