export class EnaioWorkflowDocument {
    id = 0;
    objectTypeId = 0;
    rights = 0;
    inTray = false;
    inWorkArea = false;
    isNew = false;
    deleteable = false;
    moveable = false;
    useActiveVariant = false;
    display = false;
    originalId = 0;
}
