export class DbConnectionConfig {
    className = "";
    host = "";
    database = "";
    catalog = "";
    userName = "";
    password = "";
    windowsAuth = false;
    connectionString = "";
}
