import { Type } from "class-transformer";
import { EnaioCallInsert } from "src/modules/enaio/shared/EnaioCallInsert";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";
import { Utils } from "src/modules/utils/shared/utils";
import { TemplateDocumentFieldState } from "./template-document-field-state.entity";
import { TemplateDocumentState } from "./template-document-state.entity";
import { TemplateField } from "./template-field.model";
import { TemplateSourceDefinition } from "./template.source-definition.model";

export class Template {
    id = "";
    title = "";
    @Type(() => TemplateSourceDefinition)
    sourceDefinitions: TemplateSourceDefinition[] = [];
    @Type(() => TemplateField)
    fields: TemplateField[] = [];
    consistsOf: { id: string; title: string }[] = null;
    templateDocumentId = 0;
    templateFileName: string;
    selectedTemplate: string;
    saveAs: EnaioCallInsert;
    attachToWf: boolean;
    needsToBeInGroup: string[];

    postProcess(): void {
        for (let field of this.fields) {
            field.postProcess(this);
        }
    }

    getTitle(): string {
        return Utils.stringDef(this.title, this.id);
    }

    toDataObject(format = false): OrdinaryObject {
        let result: OrdinaryObject = {};

        for (let field of this.fields) {
            result[field.getTemplateFieldName()] = format ? field.formatValueForTemplate() : field.value;
        }

        return result;
    }

    recalculate(): void {
        let dataObject = this.toDataObject(true);
        for (let field of this.fields) {
            field.recalculate(dataObject);
        }
    }

    toState(state: TemplateDocumentState): void {
        state.templateId = this.id;
        state.selectedTemplate = this.selectedTemplate;

        let oldFields = [...state.fields ?? []];
        state.fields = [];
        for (let f of this.fields) {
            let fieldName = f.getTemplateFieldName();
            let oldIndex = oldFields.findIndex(of2 => of2.templateFieldName == fieldName);
            let old: TemplateDocumentFieldState = null;
            if (oldIndex >= 0) {
                old = oldFields[oldIndex];
                oldFields = oldFields.splice(oldIndex, 1);
            }
            else {
                old = new TemplateDocumentFieldState();
                old.templateFieldName = fieldName;
            }
            old.value = f.value;
            state.fields.push(old);
        }
    }

    fromState(state: TemplateDocumentState): void {
        this.selectedTemplate = state.selectedTemplate;
        for (let f of state.fields ?? []) {
            let field = this.fields.find(field2 => field2.getTemplateFieldName() == f.templateFieldName);
            if (field != null) {
                field.value = f.value;
            }
        }
    }

    getField(caption: string, templateFieldName: string): TemplateField {
        let tfn = TemplateField.getTemplateFieldName(caption, templateFieldName);
        let result = this.fields.find(item => item.getTemplateFieldName() == tfn);
        if (result == null) {
            result = Utils.fromPlain(TemplateField, {
                caption,
                templateFieldName
            });
            this.fields.push(result);
        }
        return result;
    }

}
