<div style="padding: 10px" [innerHTML] = "message | keepHtml">
</div>
<div class="spinner-container">
    <p-progressSpinner *ngIf="isIndefinite()">
    </p-progressSpinner>
    <div *ngIf="!isIndefinite()" class="width100p">
        <p-progressBar [value]="progress" [showValue]=false></p-progressBar>
        {{progressStatus}}
    </div>
</div>
