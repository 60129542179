/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { CustomInitializer } from 'src/modules/sm-base/shared/custom-initializer.interface';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Comparable } from 'src/modules/utils/shared/comparable';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, OneToMany, Unique } from 'typeorm';
import { CovidTest } from './covid-test.entity';

@Entity()
@Unique(["schoolClassId", "isHalfYear", "day"])
export class CovidTestDay extends SmBaseEntity implements CustomInitializer, Comparable<CovidTestDay> {
    @Column({default: 0})
    schoolClassId: number = 0;

    @Column({default: false})
    isHalfYear: boolean = false;

    @Column()
    @Type(() => Date)
    day: Date;

    @Column({default: ""})
    createUser: string = "";

    @Column()
    @Type(() => Date)
    createDate: Date;

    @OneToMany((_type) => CovidTest, o => o.testDay, { cascade: true})
    @Type(() => CovidTest)
    tests: CovidTest[];

    customInitializer(): void {
        this.tests = Utils.arrayEnsure(this.tests);
    }

    compareTo(other: CovidTestDay): number {
        return Utils.cmp(this.day, other.day);
    }

}
