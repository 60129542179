/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from "src/modules/sm-base/shared/sm-base-entity.model";
import { Column, Entity } from "typeorm";

@Entity()
export class LearningSettings extends SmBaseEntity {
    @Column({default: ""})
    defaultIntroductionText: string = "";

    @Column({default: ""})
    activationMailSubject: string = "";

    @Column({default: ""})
    activationMailText: string = "";

    @Column({default: false})
    needsPrivacyPolicy: boolean = false;

    @Column({default: ""})
    privacyPolicyText: string = "";

    @Column({default: ""})
    adminContactInfo: string = "";

    @Column({array: true})
    allowedFormatting: boolean = true;
}
