import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { EnaioCall } from "./EnaioCall";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class EnaioCallGetObjectCounts extends EnaioCall <OrdinaryObject<number>> {
    objectTypeInternalNames: string[];
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(Map);
    }

}
