/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { FieldMetaDataProvider } from 'src/modules/utils/shared/field-meta-data-provider.interface';
import { FieldMetaData } from 'src/modules/utils/shared/field-meta-data.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne } from 'typeorm';
import { ClassRegisterSettings } from './class-register-settings.entity';
import { ClassRegister } from './class-register.entity';

@Entity()
export class SickNote extends SmBaseEntity implements FieldMetaDataProvider {
    @ManyToOne(() => ClassRegister, (o: ClassRegister) => o.sickNotes, { onDelete: "CASCADE", nullable: false, orphanedRowAction: "delete"})
    classRegister: ClassRegister;

    @Column({default: 0})
    pupilId: number = 0;

    @Column()
    @Type(() => Date)
    fromDate: Date;

    @Column({default: -1})
    fromHour: number = -1;

    @Column()
    @Type(() => Date)
    toDate: Date;

    @Column({default: -1})
    toHour: number = -1;

    @Column({default: ""})
    reason: string = "";

    @Column({default: false})
    excused: boolean = false;

    getFieldMetaData(): FieldMetaData[] {
        return [
            new FieldMetaData("fromDate", "Von Datum", { dateIntervalToField: "toDate", allowEmptyInterval: true}),
            new FieldMetaData("toDate", "Bis Datum", { dateIntervalFromField: "fromDate", allowEmptyInterval: true})
        ];
    }

    getMissDays(settings: ClassRegisterSettings): Date[] {
        let result: Date[] = [];
        let d = this.fromDate;
        while (d <= this.toDate) {
            if (Utils.dateEq(d, this.fromDate) && this.fromHour >= 1) {
                d = Utils.dateAdd(d, "day", 1);
                continue;
            }
            if (Utils.dateEq(d, this.toDate) && this.toHour >= 0) {
                d = Utils.dateAdd(d, "day", 1);
                continue;
            }
            if (settings.isSchoolDay(d)) {
                result.push(d);
            }
            d = Utils.dateAdd(d, "day", 1);
        }
        return result;
    }

    includes(date: Date, hour: number): boolean {
        let fromHour = Utils.dateEq(date, this.fromDate) ? this.fromHour : -1;
        let toHour = Utils.dateEq(date, this.toDate) ? this.toHour : -1;
        return Utils.dateGeq(date, this.fromDate) && Utils.dateLeq(date, this.toDate) && (fromHour == -1 || fromHour <= hour) && (toHour == -1 || toHour >= hour);
    }

    intersects(other: SickNote): boolean {
        return this.getCompareToDate().getTime() > other.getCompareFromDate().getTime() && this.getCompareFromDate().getTime() < other.getCompareToDate().getTime();
    }

    private getCompareFromDate(): Date {
        return Utils.dateAdd(new Date(this.fromDate), "hour", this.fromHour == -1 ? 0 : this.fromHour + 1);
    }

    private getCompareToDate(): Date {
        return Utils.dateAdd(Utils.dateAdd(new Date(this.toDate), "hour", this.fromHour == -1 ? 23 : this.fromHour + 1), "minute", 30);
    }
}
