import { Type } from "class-transformer";

export class HomeworkDto {
    lessonId = 0;
    @Type(() => Date)
    date: Date;
    hour = 0;
    domain = "";
    courseId = 0;
    homework = "";

    constructor(lessonId: number, date: Date, hour: number, domain: string, courseId: number, homework: string) {
        this.lessonId = lessonId;
        this.date = date;
        this.hour = hour;
        this.domain = domain;
        this.courseId = courseId;
        this.homework = homework;
    }
}
