import { Type } from "class-transformer";
import { EnaioWarningType } from "./EnaioWarningType";
import { GeneralObjectKey } from "./GeneralObjectKey";

export class EnaioWarning {
    type: EnaioWarningType;
    text: string;
    category: string;
    detail: string;
    fixable = false;
    @Type(() => GeneralObjectKey)
    keys: GeneralObjectKey[];
}
