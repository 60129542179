/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from "class-transformer";

export class LearningContentParticipantDto {

    learningContentTitle: string;
    learningContentId: number;
    userName: string;
    userId: number;
    department: string;
    jobDescription: string;
    @Type(() => Date)
    startDate: Date;
    @Type(() => Date)
    finishedDate: Date;
    @Type(() => Date)
    deadlineDate: Date;
    mandatory: boolean;
    exposureTime: number;
    activityFrom: number[] = [];
    activityTo: number[] = [];
    doneDuringOfficeHours: boolean;
    finalExamFailCount: number;
    ratings: number[] = [];
    ratingTexts: string[] = [];
}
