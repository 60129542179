/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { CovidTestDay } from './covid-test-day.entity';

@Entity()
export class CovidTest extends SmBaseEntity {
    @ManyToOne(() => CovidTestDay, (o: CovidTestDay) => o.tests, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    testDay: CovidTestDay;

    @Column({default: 0})
    pupilId: number = 0;

    @Column({default: ""})
    person: string = "";

    @Column({default: 0})
    numNasal: number = 0;

    @Column({default: 0})
    numSaliva: number = 0;

    @Column({default: 0})
    numSelf: number = 0;

    wasUpdated(updated: CovidTest): boolean {
        return this.numNasal !== updated.numNasal || this.numSaliva !== updated.numSaliva || this.numSelf !== updated.numSelf;
    }

}
