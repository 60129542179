import { Component } from '@angular/core';
import { MenuService } from '../../services/app.menu.service';
import { AppMainComponent } from '../app-main/app-main.component';

@Component({
    selector: 'app-sidebar',
    styleUrls: ['./app.sidebar.component.scss'],
    templateUrl: './app.sidebar.component.html'
})
export class AppSideBarComponent {

    constructor(public app: AppMainComponent, public menuService: MenuService) {}

}
