/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { Course } from './course.entity';

@Entity()
export class CourseParticipant extends SmBaseEntity {
    @ManyToOne(() => Course, (o: Course) => o.participants, { onDelete: "CASCADE", nullable: false, orphanedRowAction: 'delete'})
    course: Course;

    @Column({default: 0})
    pupilId: number = 0;

}
