import { Type } from "class-transformer";
import { OrdinaryObject } from "src/modules/utils/shared/ordinary-object.model";

export class EnaioObjectHistoryEntry {
    guid: string;
    @Type(() => Date)
    date: Date;
    actionId = 0;
    actionTitle: string;
    description: string;
    userId = 0;
    userName: string;
    stationId: string;
    station: string;
    info: string;
    indexData: OrdinaryObject;
    indexDataDiff: OrdinaryObject;
}
