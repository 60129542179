/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { CustomInitializer } from 'src/modules/sm-base/shared/custom-initializer.interface';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, OneToMany } from 'typeorm';
import { ClassLesson } from './class-lesson.entity';
import { CourseParticipant } from './course-participant.entity';
import { SchoolType } from './school-type.enum';

@Entity()
export class Course extends SmBaseEntity implements CustomInitializer {
    @Column({default: 0})
    year: number = 0;

    @Column({nullable: false, default: false})
    isHalfYear: boolean = false;

    @Column({default: SchoolType.elementary})
    type: SchoolType = SchoolType.elementary;

    @Column({default: ""})
    title: string = "";

    @Column({default: ""})
    group: string = "";

    @Column({default: ""})
    instructor: string = "";

    @Column({nullable: true})
    @Type(() => Date)
    endDate: Date;

    @Column({default: false})
    forCertificate: boolean = false;

    @Column({default: ""})
    titleForCertificate: string = "";

    @Column("int", { array: true, nullable: true })
    forGrades: number[] = [];

    @OneToMany(() => CourseParticipant, o => o.course, { cascade: true})
    @Type(() => CourseParticipant)
    participants: CourseParticipant[];

    @OneToMany(() => ClassLesson, o => o.course, { cascade: false})
    @Type(() => ClassLesson)
    lessons: ClassLesson[];

    customInitializer(): void {
        this.participants = Utils.arrayEnsure(this.participants);
        this.lessons = Utils.arrayEnsure(this.lessons);
        this.forGrades = Utils.arrayEnsure(this.forGrades);
    }

    getTitleForCertificate(): string {
        return !Utils.isNoe(this.titleForCertificate) ? this.titleForCertificate : this.title;
    }
}
