import { Utils } from "src/modules/utils/shared/utils";
import { BackendResponseItem } from "./backend-response-item.model";
import { BackendResponseStatus } from "./backend-response-status.enum";

export class BackendResponse extends Error {

    static error(message: string, errorId = 0, recoverable = true): BackendResponse {
        return new BackendResponse([new BackendResponseItem(BackendResponseStatus.error, message, errorId, recoverable)]);
    }

    static success(message: string, errorId = 0): BackendResponse {
        return new BackendResponse([new BackendResponseItem(BackendResponseStatus.success, message, errorId)]);
    }

    static getFromException(ex: any): BackendResponse {
        return ex instanceof BackendResponse ? ex : "error" in ex ? Utils.fromPlainUnsafe(BackendResponse, Utils.fromJson(Utils.toString(ex.error))) :
            BackendResponse.error(ex.message as string);
    }

    constructor(public items: BackendResponseItem[]) {
        super("");
        this.name = "BackendResponse";
    }

    getMessage(): string {
        return Utils.arrayItemsToString(this.items, "\n", item => item.message);
    }

    isErrorId(id: number): boolean {
        return this.items.some(bri => bri.errorId == id);
    }
}
