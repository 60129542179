import { Component, Input } from '@angular/core';

@Component({
    selector: 'sm-quick-help',
    templateUrl: './quick-help.component.html',
    styleUrls: ['./quick-help.component.scss']
})
export class QuickHelpComponent {

    @Input()
    text = "";
    @Input()
    icon = "fas fa-question-circle";
    displayHint = false;

}
