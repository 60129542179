import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Utils } from 'src/modules/utils/shared/utils';

@Pipe({ name: 'keepHtml' })
export class KeepHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(content: any): SafeResourceUrl {
        return Utils.isString(content) ? this.sanitizer.bypassSecurityTrustHtml(content as string) : null;
    }
}
