/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type } from 'class-transformer';
import { CustomInitializer } from 'src/modules/sm-base/shared/custom-initializer.interface';
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Utils } from 'src/modules/utils/shared/utils';
import { Column, Entity, ManyToOne, OneToMany, Unique } from 'typeorm';
import { ClassLessonPupil } from './class-lesson-pupil.entity';
import { ClassRegister } from './class-register.entity';
import { Course } from './course.entity';

@Entity()
@Unique(["course", "classRegister", "date", "hour", "domain"])
export class ClassLesson extends SmBaseEntity implements CustomInitializer {
    @ManyToOne(() => Course, (o: Course) => o.lessons, { onDelete: "SET NULL", nullable: true, orphanedRowAction: 'delete'})
    course: Course;

    @ManyToOne(() => ClassRegister, (o: ClassRegister) => o.lessons, { onDelete: "CASCADE", nullable: true, orphanedRowAction: "delete"})
    classRegister: ClassRegister;

    @Column("int", { array: true, nullable: true })
    classRegisterIds: number[];

    @Column()
    @Type(() => Date)
    date: Date;

    @Column({default: 0})
    hour: number = 0;

    @Column({default: ""})
    domain: string = "";

    @Column({default: false})
    isSubstitute: boolean = false;

    @Column({default: ""})
    substituteDomain: string = "";

    @Column({default: false})
    skipped: boolean = false;

    @Column({default: ""})
    autoSkippedHoliday: string = "";

    @Column({default: ""})
    description: string = "";

    @Column({default: ""})
    homework: string = "";

    @Column({default: ""})
    note: string = "";

    @Column({default: ""})
    incidents: string = "";

    @Column({default: false})
    missingPupilsChecked: boolean = false;

    @Column({default: ""})
    changedBy: string = "";

    @Column({nullable: true})
    @Type(() => Date)
    changedDate: Date;

    @Column("int", { array: true, nullable: true })
    forGrades: number[] = [];

    @OneToMany(() => ClassLessonPupil, o => o.lesson, { cascade: true})
    @Type(() => ClassLessonPupil)
    pupils: ClassLessonPupil[];

    customInitializer(): void {
        this.pupils = Utils.arrayEnsure(this.pupils);
    }

    isDone(): boolean {
        return this.missingPupilsChecked || this.skipped;
    }

    getWeekDay(): number {
        return Utils.dateDayOfWeek(this.date);
    }

    getTitle(): string {
        return this.hour + 1 + ". Stunde, " + Utils.getWeekDayName(Utils.dateDayOfWeek(this.date)) + ", " + Utils.dateFormat(this.date, "dd.mm.yyyy");
    }

    getDomainTitle(): string {
        return !Utils.isNoe(this.domain) ? this.domain : this.course?.title ?? "<???>";
    }
}
