import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'bypassSecurity', pure: false })
export class BypassSecurityPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(content: any): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(content as string);
    }
}
