import { Type } from "class-transformer";
import { HandlerConfigMetrics } from "./HandlerConfigMetrics";
import { HandlerConfigMetricsGroup } from "./HandlerConfigMetricsGroup";

export class HandlerConfigMetricsSummary {
    @Type(() => HandlerConfigMetrics)
    raw: HandlerConfigMetrics[];
    @Type(() => HandlerConfigMetricsGroup)
    grouped: HandlerConfigMetricsGroup[];
}
