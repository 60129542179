export class EnaioObjectIdAndType {
    id = 0;
    typeId = 0;


    constructor(id: number, typeId: number) {
        this.id = id;
        this.typeId = typeId;
    }


}
