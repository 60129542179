export enum DhCustomerFileType {
    config = 0,
    configFolder = 1,
    global = 2,
    asset = 3,
    template = 4
}
export namespace DhCustomerFileType{
    export function getName(value: DhCustomerFileType): string {
        switch (value) {
            case DhCustomerFileType.config:
                return "config";
            case DhCustomerFileType.configFolder:
                return "configFolder";
            case DhCustomerFileType.global:
                return "global";
            case DhCustomerFileType.asset:
                return "asset";
            case DhCustomerFileType.template:
                return "template";
        }
    }
}
