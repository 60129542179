import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { AppMenuItem } from '../app-template/models/app-menu-item.model';
import { AppTab } from '../app-template/models/app-tab.model';
import { PrimeModule } from '../prime/prime.module';
import { FeatureModule } from '../sm-base/models/feature.module';
import { FeatureModuleService } from '../sm-base/services/feature-module.service';
import { SmBaseModule } from '../sm-base/sm-base.module';
import { Utils } from '../utils/shared/utils';
import { UtilsModule } from '../utils/utils.module';
import { EnaioPayrollMainComponent } from './components/enaio-payroll-main/enaio-payroll-main.component';
import { EnaioPayrollRoutingModule } from './enaio-payroll-routing.module';
import { PayrollUsersComponent } from './components/payroll-users/payroll-users.component';

@NgModule({
    declarations: [
        EnaioPayrollMainComponent,
        PayrollUsersComponent
    ],
    imports: [
        CommonModule,
        EnaioPayrollRoutingModule,
        SmBaseModule,
        PrimeModule,
        UtilsModule,
        PdfJsViewerModule
    ]
})
export class EnaioPayrollModule implements FeatureModule {

    constructor(private featureModuleService: FeatureModuleService) {
        featureModuleService.register(this);
    }

    registerSidebar(model: AppTab[]): void {
        model.push(new AppTab("payroll", "Gehalt", "fas fa-copy", 100, [
            Utils.fromPlain(AppMenuItem, { sort: 100, label: "Dokumente", icon: "", routerLink: ["/enaio-payroll", "home"] })
        ]));
    }

    async updateSidebarVisibility(_: AppTab[]): Promise<void> {
    }

    getMainRoute(): Route {
        return this.featureModuleService.createModuleRoute("enaio-payroll", () => EnaioPayrollRoutingModule);
    }

}
