/* eslint-disable @typescript-eslint/no-inferrable-types */
import { SmBaseEntity } from 'src/modules/sm-base/shared/sm-base-entity.model';
import { Column, Entity, ManyToOne } from 'typeorm';
import { ClassRegister } from './class-register.entity';

@Entity()
export class ClassTeacher extends SmBaseEntity {
    @ManyToOne(() => ClassRegister, (o: ClassRegister) => o.teachers, { onDelete: "CASCADE", nullable: false, orphanedRowAction: "delete"})
    classRegister: ClassRegister;

    @Column({default: ""})
    domain: string = "";

    @Column({default: ""})
    teacher: string = "";

    @Column({default: ""})
    teacherSecond: string = "";

    @Column({default: ""})
    teacherRegional: string = "";

    @Column({default: ""})
    teacherRegionalSecond: string = "";
}
