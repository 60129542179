<div class="login-body">
  <p-toast position="top-center"></p-toast>
  <div *ngIf="authRules != null" class="login-panel ui-fluid">
    <div class="grid" [formGroup]="form">
      <div class="col-12 logo-container">
        <img src="assets/layout/images/logo-colored.png" />
        <h1>Login</h1>
        <h2>{{app.appName}}</h2>
      </div>
      <div class="col-12">
        <input style="width:100%" type="text" autocomplete="off" class="p-inputtext ui-corner-all p-state-default p-component"
          placeholder="Benutzername" formControlName="userName">
      </div>
      <div class="col-12">
          <!-- styleClass used to fix this issue https://github.com/primefaces/primeng/issues/10788 -->
        <p-password placeholder="Passwort" formControlName="password" [toggleMask]=true [style]="{'width':'100%'}" [inputStyle]="{'width' :'100%'}" [feedback]="false"
        styleClass="p-password p-component p-inputwrapper p-input-icon-right" (keyup.enter)="send()">
        </p-password>
      </div>
      <div class="col-12 button-container">
        <p-button styleClass="width100p" icon="fas fa-sign-in" label="Einloggen" (onClick)="send()">
        </p-button>
        <p-button *ngIf="authRules?.canRevokePassword" styleClass="width100p" icon="fas fa-lock" label="Passwort vergessen" [routerLink]="['/base', 'resetpassword']">
        </p-button>
        <a *ngIf="authRules?.canRegister" [routerLink]="['/base', 'register']"><p-button styleClass="width100p" icon="fas fa-lock" label="Registrieren">
        </p-button></a>
        </div>
        <div *ngIf="waiting" class="spinner-wrapper">
            <p-progressSpinner [style]="{width: '30px', height: '30px'}"></p-progressSpinner>
        </div>
    </div>
  </div>
</div>
