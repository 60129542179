import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainAppService } from 'src/modules/app-template/services/main-app.service';
import { Utils } from 'src/modules/utils/shared/utils';
import { RestEndpoint } from '../../models/rest-endpoint.model';
import { BackendResponse } from '../../shared/backend-response.model';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    form: FormGroup;

    running = false;
    done = false;
    resultType = "";
    resultMessage = "";

    constructor(public app: MainAppService, private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            userName: ['', Validators.required],
            password: ['', Validators.required],
            passwordRepeat: ['', Validators.required]
        });
    }

    async send(): Promise<void> {
        this.running = true;
        try {
            await RestEndpoint.main().contentTypeForm().post().body({userName: this.form.get("userName").value, password: this.form.get("password").value}).run("api/app/auth/register").get(BackendResponse);
            this.resultType = "success";
            this.resultMessage = "Die Registrierung war erfolgreich. Bitte schauen Sie in ihrem E-Mail Postfach nach der Aktivierungs-Mail";
        }
        catch (ex) {
            let error = Utils.fromPlainUnsafe(BackendResponse, Utils.fromJson(Utils.toString(ex.error)));
            this.resultType = "error";
            this.resultMessage = "Die Registrierung war nicht erfolgreich. " + error.items[0].message;
        }
        this.running = false;
        this.done = true;
    }

    async cancel(): Promise<void> {

    }
}
