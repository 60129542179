import { AdvancedType } from "src/modules/utils/shared/advanced-type";
import { Type } from "class-transformer";
import { EnaioCall } from "./EnaioCall";
import { EnaioJobParam } from "./EnaioJobParam";
import { JobResult } from "./JobResult";

export class EnaioCallGeneric extends EnaioCall <JobResult> {
    type: string;
    @Type(() => EnaioJobParam)
    input: EnaioJobParam[];
    xml: string;
    fileNames: string[];
    files64: string[];
    getBase64 = false;
    getModelType(): AdvancedType {
        return AdvancedType.ofClass(JobResult);
    }

}
