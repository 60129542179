import { EnaioMappedField } from "src/modules/enaio/shared/enaio-mapped-field.interface";
import { EnaioDocument } from "src/modules/enaio/shared/EnaioDocument";
import { EnaioMetaDataField } from "src/modules/enaio/shared/EnaioMetaDataField";
import { EnaioObjectId } from "src/modules/enaio/shared/EnaioObjectId";
import { EnaioObjectTypeId } from "src/modules/enaio/shared/EnaioObjectTypeId";
import { Utils } from "src/modules/utils/shared/utils";
import { Entity } from "typeorm";

@Entity()
export class CostCenter implements EnaioMappedField {

    static archiveName = "technical_folder";
    static objectTypeName = "Kostenstelle";

    name: string;
    description: string;
    department: string;
    businessUnit: string;
    managerWfRole: string;
    managers: string[] = [];
    enaioId: number;

    fromDocument(doc: EnaioDocument): void {
        this.enaioId = doc.id;
        this.name = doc.getStringByInternal("Kostenstelle");
        this.description = doc.getStringByInternal("Kostenstellenbezeichnung");
        this.department = doc.getStringByInternal("department");
        this.businessUnit = doc.getStringByInternal("businessUnit");
        this.managerWfRole = doc.getStringByInternal("PersonalWFEinrichtungsleitung");
    }

    toDocument(): EnaioDocument {
        return Utils.fromPlain(EnaioDocument, {
            id: this.enaioId,
            metaData: [
                new EnaioMetaDataField(EnaioObjectId.byInternalName("Kostenstelle"), this.name),
                new EnaioMetaDataField(EnaioObjectId.byInternalName("Kostenstellenbezeichnung"), this.description),
                new EnaioMetaDataField(EnaioObjectId.byInternalName("department"), this.department),
                new EnaioMetaDataField(EnaioObjectId.byInternalName("businessUnit"), this.businessUnit),
                new EnaioMetaDataField(EnaioObjectId.byInternalName("PersonalWFEinrichtungsleitung"), this.businessUnit)
            ]
        });
    }

    getEnaioObjectTypeId(): EnaioObjectTypeId {
        return new EnaioObjectTypeId(EnaioObjectId.byInternalName(CostCenter.archiveName), EnaioObjectId.byInternalName(CostCenter.objectTypeName));
    }

    getFullName(): string {
        return this.name + " - " + this.description;
    }

    getGroupName(): string {
        return this.businessUnit + " - " + this.department;
    }

}
