<div *ngIf="inited">
    <sm-toolbar>
        <p-button styleClass="p-button-warning" icon="fas fa-redo" label="Kompetenzen neu generieren" (onClick)="refresh()">
        </p-button>
    </sm-toolbar>
    <p-card header="Bitte wählen Sie einen Schüler aus">
        <p-table [value]="pupils" selectionMode="single" (onRowSelect)="selectRow($event)" sortField="lastName">
            <ng-template pTemplate="header">
            <tr>
                <th class="align-left" width="30%" [pSortableColumn]="'lastName'">Nachname<p-sortIcon [field]="'lastName'"></p-sortIcon></th>
                <th class="align-left" width="30%" [pSortableColumn]="'firstName'">Vorname<p-sortIcon [field]="'firstName'"></p-sortIcon></th>

                <th class="align-left" width="15%">Schultyp</th>
                <th class="align-left" width="10%">FS Lernen</th>
                <th class="align-left" width="15%">Zeugnisstatus</th>
            </tr>
            </ng-template>
            <ng-template pTemplate="body" let-pupil>
            <tr [pSelectableRow]="pupil">
                <td><a routerLink="/enaio-certificates/certificate/{{schoolClassId}}/{{isHalfYear ? 1 : 0}}/{{pupil.id}}"
                    routerLinkActive="active">{{pupil.lastName}}</a></td>
                <td>{{pupil.firstName}}</td>
                <td>{{pupil.getSchoolTypeName()}}</td>
                <td>
                <span class ="badge" [class.badge-yes]="pupil.isSpecialReqLearning()" [class.badge-no]="!pupil.isSpecialReqLearning()">{{_Utils.getYesNoString(pupil.isSpecialReqLearning())}}</span>
                </td>
                <td>
                <span class ="badge" [class.badge-no]="pupil.certificateStatus == 0" [class.badge-mid]="pupil.certificateStatus == 1" [class.badge-yes]="pupil.certificateStatus == 2">{{pupil.getCertificateStatusString()}}</span>
                </td>
            </tr>
            </ng-template>
        <!-- <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5" style="text-align:left">Es wurden keine Schüler gefunden</td>
            </tr>
        </ng-template>-->
        </p-table>
    </p-card>
</div>
