export enum EnaioWorkflowBasicDataType {
    unknown = 0,
    string = 1,
    integer = 2,
    float = 3,
    date = 4,
    dateTime = 5,
    time = 6,
    array = 7,
    record = 8
}
export namespace EnaioWorkflowBasicDataType{
    export function getName(value: EnaioWorkflowBasicDataType): string {
        switch (value) {
            case EnaioWorkflowBasicDataType.unknown:
                return "unknown";
            case EnaioWorkflowBasicDataType.string:
                return "string";
            case EnaioWorkflowBasicDataType.integer:
                return "integer";
            case EnaioWorkflowBasicDataType.float:
                return "float";
            case EnaioWorkflowBasicDataType.date:
                return "date";
            case EnaioWorkflowBasicDataType.dateTime:
                return "dateTime";
            case EnaioWorkflowBasicDataType.time:
                return "time";
            case EnaioWorkflowBasicDataType.array:
                return "array";
            case EnaioWorkflowBasicDataType.record:
                return "record";
        }
    }
}
