import { Type } from "class-transformer";
import { LearningContentRatingDto } from "./learning-content-rating.dto";

export class LearningContentRatingsDto {
    learningContentId: number;
    learningContentTitle: string;
    department: string;
    @Type(() => LearningContentRatingDto)
    ratings: LearningContentRatingDto[];
}
