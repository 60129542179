export enum EnaioWorkflowModelState {
    unknown = 0,
    active = 1,
    freeToEdit = 2,
    inProgress = 3,
    deleted = 5,
    inUse = 6,
    historic = 999
}
export namespace EnaioWorkflowModelState{
    export function getName(value: EnaioWorkflowModelState): string {
        switch (value) {
            case EnaioWorkflowModelState.unknown:
                return "unknown";
            case EnaioWorkflowModelState.active:
                return "active";
            case EnaioWorkflowModelState.freeToEdit:
                return "freeToEdit";
            case EnaioWorkflowModelState.inProgress:
                return "inProgress";
            case EnaioWorkflowModelState.deleted:
                return "deleted";
            case EnaioWorkflowModelState.inUse:
                return "inUse";
            case EnaioWorkflowModelState.historic:
                return "historic";
        }
    }
}
