import { EnaioAssignment } from "src/modules/enaio/shared/EnaioAssignment";
import { EnaioTableAssignment } from "src/modules/enaio/shared/EnaioTableAssignment";

export class EnaioFrontendInsertData {
    defId = "";
    parentId = 0;
    fields: EnaioAssignment[];
    tableFields: EnaioTableAssignment[];
    documentFileName = "";
    documentBase64 = "";
}
