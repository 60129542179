import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, FilterMatchMode, MessageService, PrimeNGConfig } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
//import { CarouselModule } from 'primeng/carousel';
import { CascadeSelect, CascadeSelectModule } from 'primeng/cascadeselect';
//import {ChartModule} from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
//import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ColorPickerModule } from 'primeng/colorpicker';
//import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
//import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
//import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
//import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
//import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
//import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
//import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
//import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
//import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
//import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpeedDialModule } from 'primeng/speeddial';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
//import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
//import { TerminalModule } from 'primeng/terminal';
//import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
//import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { NgxColorsModule } from 'ngx-colors';
import { AutoFocusColumnFiltersDirective } from './directives/auto-focus-column-filters.directive';
import { DynamicTooltipDirective } from './directives/dynamic-tooltip.directive';

@NgModule({
    declarations: [
        AutoFocusColumnFiltersDirective,
        DynamicTooltipDirective
    ],
    imports: [
        AccordionModule,
        AvatarModule,
        AvatarGroupModule,
        AutoCompleteModule,
        BadgeModule,
        BlockUIModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
//        CarouselModule,
        CascadeSelectModule,
//        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
//        CodeHighlighterModule,
//        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DividerModule,
        DialogModule,
        DynamicDialogModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
//        GalleriaModule,
        InplaceModule,
        InputMaskModule,
        InputNumberModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
//        LightboxModule,
        ListboxModule,
//        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
//        OrganizationChartModule,
        OverlayPanelModule,
//        PaginatorModule,
        PanelModule,
//        PanelMenuModule,
        PasswordModule,
//        PickListModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        RadioButtonModule,
        RatingModule,
//        ScrollPanelModule,
        SelectButtonModule,
//        SlideMenuModule,
        SliderModule,
        SpeedDialModule,
        SpinnerModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
//        TabMenuModule,
        TabViewModule,
        TagModule,
//        TerminalModule,
//        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        TriStateCheckboxModule,
//        VirtualScrollerModule
        FormsModule,
        NgxColorsModule
    ],
    exports: [
        AccordionModule,
        AutoCompleteModule,
        AutoFocusColumnFiltersDirective,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BlockUIModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
//        CarouselModule,
        CascadeSelectModule,
//        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
//        CodeHighlighterModule,
//        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        DynamicDialogModule,
        DynamicTooltipDirective,
        FieldsetModule,
        FileUploadModule,
//        GalleriaModule,
        InplaceModule,
        InputMaskModule,
        InputNumberModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
//        LightboxModule,
        ListboxModule,
//        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
//        OrganizationChartModule,
        OverlayPanelModule,
//        PaginatorModule,
        PanelModule,
//        PanelMenuModule,
        PasswordModule,
//        PickListModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        RadioButtonModule,
        RatingModule,
//        ScrollPanelModule,
        SelectButtonModule,
//        SlideMenuModule,
        SliderModule,
        SpeedDialModule,
        SpinnerModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
//        TabMenuModule,
        TabViewModule,
        TagModule,
//       TerminalModule,
//        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        TriStateCheckboxModule,
//        VirtualScrollerModule
        FormsModule,
        NgxColorsModule
    ],
    providers: [
        CascadeSelect,
        ConfirmationService,
        DialogService,
        MessageService
    ]
})
export class PrimeModule {

    constructor(private config: PrimeNGConfig) {
        this.config.ripple = true;
        this.config.setTranslation({
            "startsWith": "Beginnt mit",
            "contains": "Beinhaltet",
            "notContains": "Beinhaltet nicht",
            "endsWith": "Endet mit",
            "equals": "Gleich",
            "notEquals": "Ungleich",
            "noFilter": "Kein Filter",
            "lt": "Kleiner als",
            "lte": "Kleiner als oder gleich",
            "gt": "Größer als",
            "gte": "Größer als oder gleich",
            "is": "Ist",
            "isNot": "Ist nicht",
            "before": "Vorher",
            "after": "Nachher",
            "clear": "Löschen",
            "apply": "Anwenden",
            "matchAll": "Alle passen",
            "matchAny": "Einer passt",
            "addRule": "Neue Regel",
            "removeRule": "Regel entfernen",
            "accept": "Ja",
            "reject": "Nein",
            "choose": "Auswählen",
            "upload": "Hochladen",
            "cancel": "Abbrechen",
            "dayNames": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
            "dayNamesShort": ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
            "dayNamesMin": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
            "monthNames": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
            "monthNamesShort": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
            "today": "Heute",
            "weekHeader": "Wo",
            "weak": 'Schwach',
            "medium": 'Mittel',
            "strong": 'Stark',
            "passwordPrompt": 'Passwort eingeben',
            "emptyMessage": 'Keine Ergebnisse gefunden',
            "emptyFilterMessage": 'Keine Ergebnisse gefunden'
        });
        this.config.filterMatchModeOptions = {
            text: [
                FilterMatchMode.CONTAINS,
                FilterMatchMode.EQUALS,
                FilterMatchMode.NOT_EQUALS,
                FilterMatchMode.STARTS_WITH,
                FilterMatchMode.NOT_CONTAINS,
                FilterMatchMode.ENDS_WITH
            ],
            numeric: [
                FilterMatchMode.EQUALS,
                FilterMatchMode.NOT_EQUALS,
                FilterMatchMode.LESS_THAN,
                FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
                FilterMatchMode.GREATER_THAN,
                FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            ],
            date: [
                FilterMatchMode.DATE_IS,
                FilterMatchMode.DATE_IS_NOT,
                FilterMatchMode.DATE_BEFORE,
                FilterMatchMode.DATE_AFTER
            ]
        };
    }

}
